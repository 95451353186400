import React, { useState } from 'react';
import { CircularProgress } from 'src/components';
import { ActivityTypeItem, CreateActivitiesDrawer } from './components';
import { useGetActivityTypes } from 'src/hooks';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

const ActivitiesTypeAdmin = () => {
  const { data: activityTypes, isLoading } = useGetActivityTypes();
  const [openDrawer, setOpenDrawer] = useState(false);
  const sortedActivityTypes = activityTypes?.results?.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <div className="flex flex-1 flex-col">
          <h2 className="text-lg font-medium text-neutral-500">
            Activity Types
          </h2>
          <p className="text-xs text-neutral-400">
            Types of activities that can be created in the system.
          </p>
        </div>
        {/* TODO: create PulseUI button component */}
        <button
          className="flex items-center 
          gap-2 rounded-lg bg-neutral-050 
          border border-neutral-100
          text-neutral-500 text-sm px-4 py-2 font-semibold
          hover:bg-neutral-100 hover:border-neutral-200
          transition-all duration-300"
          onClick={() => setOpenDrawer(true)}
        >
          <Icon icon={faPlus} />
          Create Types
        </button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex justify-end">
          <span className="relative text-xs text-neutral-400">
            <sup className="text-neutral-500 text-xs absolute -top-0.5 left-0">
              *
            </sup>
            <span className="pl-2 text-neutral-500">
              Items that have activities cannot be deleted.
            </span>
          </span>
        </div>
        <div
          className={`flex flex-col  
              rounded-lg transition-all duration-300 ${
                isLoading || !sortedActivityTypes?.length
                  ? 'h-48 border-2 border-dashed border-neutral-200 hover:border-neutral-300 hover:bg-neutral-100 cursor-pointer'
                  : 'border border-neutral-100'
              }`}
        >
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <CircularProgress size="sm" color="neutral" />
            </div>
          ) : sortedActivityTypes?.length ? (
            sortedActivityTypes.map((item) => (
              <div
                key={item.id}
                className="first:rounded-t-lg 
                bg-white
                shadow-sm
                last:rounded-b-lg border-b border-neutral-100
                last:border-b-0 px-4"
              >
                <ActivityTypeItem item={item} />
              </div>
            ))
          ) : (
            <div
              className="flex justify-center items-center text-neutral-400 text-sm h-full"
              onClick={() => setOpenDrawer(true)}
            >
              No activity types available click here to create one
            </div>
          )}
        </div>
      </div>
      <CreateActivitiesDrawer
        isOpen={openDrawer}
        onClose={() => setOpenDrawer(false)}
      />
    </div>
  );
};

export default ActivitiesTypeAdmin;
