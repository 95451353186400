import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faRegistered } from '@fortawesome/pro-regular-svg-icons';
import { Chip } from '../Chip';

const CODE_STYLES = {
  cpt: {
    backgroundColor: '!bg-sky-200',
    decoratorColor: '!bg-sky-700',
    border: '!border !border-sky-700',
  },
  hcpcs: {
    backgroundColor: '!bg-magenta-100',
    decoratorColor: '!bg-magenta-400',
    border: '!border !border-magenta-400',
  },
  default: {
    backgroundColor: '!bg-indigo-100',
    decoratorColor: '!bg-indigo-400',
    border: '!border !border-indigo-400',
  },
} as const;

interface ParsedCode {
  prefix: string;
  suffix: string;
}

const parseCode = (code: string): ParsedCode => {
  const [prefix = code, ...rest] = code.split('-');
  const letterMatch = prefix.match(/^[A-Za-z]+/);

  return {
    prefix: letterMatch?.[0] ?? prefix,
    suffix: rest.length ? `-${rest.join('-')}` : '',
  };
};

interface CodeChipProps {
  code: string;
}

const CodeChip: React.FC<CodeChipProps> = ({ code }) => {
  const { prefix, suffix } = parseCode(code);
  const styles = CODE_STYLES[prefix?.toLowerCase()] ?? CODE_STYLES.default;
  const showRegistered = prefix === 'CPT';

  return (
    <Chip
      size="sm"
      className={`${styles.backgroundColor} ${styles.border} flex items-center h-6 pl-0`}
      startDecorator={
        <div
          className={`${styles.decoratorColor} w-2 h-6 rounded-l-full pl-3`}
        />
      }
    >
      <div className="flex items-center pr-3">
        <p className="text-primary font-normal text-sm">{prefix}</p>
        {showRegistered && (
          <Icon
            icon={faRegistered}
            style={{ fontSize: '0.5rem', marginBottom: '0.25rem' }}
          />
        )}
        <p className="text-primary font-normal text-sm">{suffix}</p>
      </div>
    </Chip>
  );
};

export default CodeChip;
