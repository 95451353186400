import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import {
  getSearchQueryParamsForAPI,
  serializeQueryArray,
} from 'src/utils/url.helpers';

interface GetProviderListItems
  extends MedScout.Pagination,
    MedScout.Sortable,
    MedScout.Searchable {
  listId: string | number;
  enabledSearchIds?: string[];
  mode?: 'treating' | 'sending' | 'receiving';
  enabled?: boolean;
  dateRange?: {
    lte: string;
    gte: string;
  };
  statusFilters?: string[];
  include_unpushed?: number;
  shouldRefetch?: boolean;
  volume_type?: 'claims' | 'patients';
}

/**
 * @description Returns a list of all items in the given provider list
 * Standard pagination parameters
 * Search and sort parameters are not supported on this API but they can be added if needed
 * @param listId
 */
const useGetProviderListItems = ({
  listId,
  page,
  pageSize,
  sort,
  order,
  sort_search,
  sort_value,
  volume_type,
  q,
  enabledSearchIds = [],
  mode = 'treating',
  enabled = true,
  dateRange,
  statusFilters = [],
  include_unpushed = 0,
  shouldRefetch = false,
}: GetProviderListItems) => {
  let queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    sort_search,
    sort_value,
    volume_type,
    page: page + 1,
    pageSize,
    mode,
    q,
    dateRange,
    include_unpushed,
  });

  const searchIdParams = enabledSearchIds
    .map(
      (id) => `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
    )
    .join('');

  queryParams += searchIdParams;

  const statusFilterParams = statusFilters
    ?.sort((a, b) => a.localeCompare(b))
    ?.map(
      (status) =>
        `&${encodeURIComponent('crm_status')}=${encodeURIComponent(status)}`
    )
    ?.join('');

  queryParams += statusFilterParams;

  return useQuery(
    [QUERY_KEYS.providerListItems, listId, queryParams],
    async () => {
      return await request({
        url: `/v1/provider-lists/lists/${listId}/items/${queryParams}`,
        method: 'GET',
      });
    },
    {
      enabled: !!listId && enabled,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchInterval: shouldRefetch ? 60000 : false, // 60000 ms = 1 minute
    }
  );
};

export default useGetProviderListItems;
