import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useMap } from '@vis.gl/react-google-maps';
import useSupercluster from 'use-supercluster';
import { MAX_ZOOM } from '../../../Map/constants';
import { FeaturesClusterMarker, FeatureMarker } from './components';
import { useMedScoutMap, useSite } from 'src/context';
import { useGetMedMapSearchResults } from 'src/hooks';
import { useDebounce } from 'use-debounce';

const MedMapMarkers = ({
  activeMarker,
  setActiveMarker,
  addToList,
  showProviderLists,
  showMarkers,
}) => {
  const map = useMap();
  const { state } = useSite();
  const { zoom, bounds } = useMedScoutMap();
  const { data: searchResults } = useGetMedMapSearchResults();

  // State to manage transition and marker stability
  const [isVisible, setIsVisible] = useState(false);
  const shouldRenderRef = useRef(false);
  const prevMarkersRef = useRef(new Map());

  // Generate a stable key for each marker
  const getStableKey = useCallback((feature) => {
    const id = feature.id || feature.properties?.id;
    const [lng, lat] = feature.geometry.coordinates;
    return `${id}-${lng}-${lat}`;
  }, []);

  // Use longer debounce time for even smoother transitions
  // Match the heat map debounce time for consistency
  const [debouncedSearchResults] = useDebounce(searchResults?.results, 700);
  const [debouncedBounds] = useDebounce(bounds, 700);

  // Enhanced function to stabilize markers and prevent flickering
  const stabilizeMarkers = useCallback(
    (newClusters) => {
      if (!newClusters) return [];

      const newMarkersMap = new Map();
      const result = newClusters.map((feature) => {
        // Use our stable key function for consistency
        const key = getStableKey(feature);

        // Check if we already have this marker
        const existingMarker = prevMarkersRef.current.get(key);
        if (existingMarker) {
          // Update properties but keep the same reference
          // Use a more efficient update approach
          Object.assign(existingMarker.properties, feature.properties);
          Object.assign(existingMarker.geometry, feature.geometry);
          existingMarker.details = feature.details;

          // We don't need to preserve animation state anymore

          newMarkersMap.set(key, existingMarker);
          return existingMarker;
        }

        // New marker, add to map
        // We don't need animation flags anymore since we're using a simple fade
        // This prevents unnecessary re-renders

        newMarkersMap.set(key, feature);
        return feature;
      });

      // Update the ref for next render
      prevMarkersRef.current = newMarkersMap;
      return result;
    },
    [getStableKey]
  );

  // Handle visibility transitions when showMarkers or showProviderLists change
  useEffect(() => {
    if (showMarkers || showProviderLists) {
      // When markers should be shown
      shouldRenderRef.current = true;
      // Small delay to ensure DOM is ready before starting animation
      setTimeout(() => setIsVisible(true), 50);
    } else {
      // When markers should be hidden
      setIsVisible(false);
      // Wait for fade-out animation to complete before unmounting
      const timer = setTimeout(() => {
        shouldRenderRef.current = false;
      }, 500); // Match transition duration (500ms)
      return () => clearTimeout(timer);
    }
  }, [showMarkers, showProviderLists]);

  // We don't need a separate effect for search results changes
  // The markers will naturally update with the debounced results

  // Initialize the clusters
  let radius = 75;
  if (zoom >= MAX_ZOOM) {
    radius = 0;
  } else if (zoom < MAX_ZOOM && zoom > 14) {
    radius = 25;
  } else if (zoom < 11 && zoom > 8) {
    radius = 50;
  }

  const clusterObjects = useMemo(() => {
    // Only update cluster objects when both bounds and search results are stable
    const pointsToShow = showProviderLists
      ? state?.lists?.formattedProspectLists
      : showMarkers
      ? debouncedSearchResults || []
      : [];
    if (!pointsToShow?.length) return [];

    // Map points to GeoJSON format
    const mappedPoints = pointsToShow.map((point) => ({
      details: point,
      properties: {
        cluster: false,
        id: point.id,
        count: point.count,
        provider_id: point.provider_id,
        hovered:
          activeMarker?.toString() ===
          (point?.provider_id?.toString() || point?.id?.toString()),
      },
      geometry: {
        type: 'Point',
        coordinates: [point.lng, point.lat],
      },
    }));

    // Stabilize the markers to prevent flickering
    return stabilizeMarkers(mappedPoints);
  }, [
    showProviderLists,
    state?.lists?.formattedProspectLists,
    debouncedSearchResults,
    showMarkers,
    activeMarker,
    stabilizeMarkers,
  ]);

  // Use memoized bounds to prevent unnecessary recalculations
  const memoizedBounds = useMemo(() => {
    if (!debouncedBounds) return undefined;
    // Format as [west, south, east, north] which is what useSupercluster expects
    return [
      debouncedBounds[0][1], // west
      debouncedBounds[1][0], // south
      debouncedBounds[1][1], // east
      debouncedBounds[0][0], // north
    ] as [number, number, number, number]; // Type assertion to fix TS error
  }, [debouncedBounds]);

  // Use memoized options to prevent unnecessary recalculations
  const clusterOptions = useMemo(
    () => ({
      clickableIcons: false,
      radius,
      maxZoom: MAX_ZOOM,
      map: (props) => ({
        provider_id: props.provider_id || props.id,
        hovered: props.hovered,
        count: props.count || 1,
      }),
      reduce: (acc, props) => {
        acc.hovered = acc.hovered || props.hovered;
        acc.count = (acc.count || 0) + (props.count || 1);
        return acc;
      },
    }),
    [radius]
  );

  // Get clusters with stable references
  const { clusters: rawClusters, supercluster } = useSupercluster({
    points: clusterObjects,
    bounds: memoizedBounds,
    zoom,
    options: clusterOptions,
  });

  // Stabilize clusters to prevent flickering
  const clusters = useMemo(() => {
    if (!rawClusters) return [];
    return stabilizeMarkers(rawClusters);
  }, [rawClusters, stabilizeMarkers]);

  // Only render markers when we have stable bounds and data
  if (!debouncedBounds || !debouncedSearchResults || !shouldRenderRef.current)
    return null;

  const handleClusterClick = (clusterId: number, lat: number, lng: number) => {
    const zoomLevel = supercluster.getClusterExpansionZoom(clusterId);
    const expansionZoom = isNaN(zoomLevel)
      ? Math.min(zoom + 2, MAX_ZOOM)
      : zoomLevel;

    if (expansionZoom >= MAX_ZOOM && zoom === expansionZoom - 1) {
      // When we have hit max zoom and a cluster is clicked on w/ the same zoom level
      // Focus the marker, to display info for any locations under the cluster
      // maintain toggle functionality
      setActiveMarker(activeMarker === clusterId ? null : clusterId);
    } else {
      // Not at max zoom for cluster, zoom to attempt showing more fine grained markers
      // Remove any previously focused marker
      map.panTo({ lat, lng });
      map.setZoom(expansionZoom);
      setActiveMarker(null);
    }
  };

  const handleMarkerClick = (markerId: number) => {
    const marker = markerId === activeMarker ? null : markerId;
    setActiveMarker(marker);
  };

  return (
    <div
      className={`
      relative z-10 
      ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'} 
      transition-all duration-500 ease-out 
      will-change-transform
    `}
    >
      {clusters?.map((feature) => {
        const { geometry, properties, id } = feature;
        const [lng, lat] = geometry.coordinates;
        const { cluster: isCluster, count } = properties;
        const showClusterMarkerInfo = isCluster && zoom === MAX_ZOOM;
        const stableKey = getStableKey(feature);

        let leaves = [];
        if (isCluster && showClusterMarkerInfo) {
          leaves = supercluster?.getLeaves(Number(id), 200) || [];
        }
        const showLeaves = showClusterMarkerInfo && leaves.length > 1;
        const isHovered =
          activeMarker === Number(feature.id) ||
          feature.properties?.hovered ||
          feature?.details?.hovered;

        return isCluster ? (
          <FeaturesClusterMarker
            key={stableKey}
            clusterId={Number(feature.id)}
            position={{ lat, lng }}
            size={count}
            sizeAsText={String(count)}
            isHovered={isHovered}
            addToList={addToList}
            clusterLeaves={showLeaves ? leaves : []}
            onMarkerClick={() =>
              handleClusterClick(Number(feature.id), lat, lng)
            }
            onMouseOver={() => {
              setActiveMarker(Number(feature.id));
            }}
            onMouseOut={() => {
              setActiveMarker(null);
            }}
          />
        ) : (
          <FeatureMarker
            key={stableKey}
            feature={feature}
            featureId={feature?.properties?.id}
            position={{ lat, lng }}
            size={count || 1}
            sizeAsText={String(count || 1)}
            isHovered={isHovered}
            addToList={addToList}
            onMarkerClick={() => handleMarkerClick(feature?.properties?.id)}
            onMouseOver={() => {
              setActiveMarker(feature?.properties?.id);
            }}
            onMouseOut={() => {
              setActiveMarker(null);
            }}
          />
        );
      })}
    </div>
  );
};

export default MedMapMarkers;
