import React, { useState, useRef, useCallback, useEffect } from 'react';

interface ColumnWidth {
  key: string;
  width: number;
  minWidth?: number;
}

interface UseColumnResizingOptions {
  minWidth?: number;
  maxWidth?: number;
  defaultWidths?: ColumnWidth[];
  innerColumnOffset?: number;
}

export function useColumnResizing({
  minWidth = 100,
  maxWidth = 350,
  innerColumnOffset = 0,
  defaultWidths = [],
}: UseColumnResizingOptions = {}) {
  const [columnWidths, setColumnWidths] =
    useState<ColumnWidth[]>(defaultWidths);
  const [isResizing, setIsResizing] = useState<string | null>(null);
  const startXRef = useRef(0);
  const startWidthRef = useRef(0);

  const getColumnWidth = useCallback(
    (key: string) => {
      const column = columnWidths.find((col) => col.key === key);
      return column?.width || 200; // Default width if not found
    },
    [columnWidths]
  );

  const startResizing = useCallback(
    (key: string, e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsResizing(key);
      startXRef.current = e.pageX;
      startWidthRef.current = getColumnWidth(key);
    },
    [getColumnWidth]
  );

  useEffect(() => {
    if (!isResizing) return;

    const handleMouseMove = (e: MouseEvent) => {
      const diff = e.pageX - startXRef.current;
      const colMinWidth =
        columnWidths.find((col) => col.key === isResizing)?.minWidth ||
        minWidth;
      const newWidth = Math.max(
        colMinWidth,
        Math.min(maxWidth, startWidthRef.current + diff)
      );

      setColumnWidths((prev) =>
        prev.map((col) =>
          col.key === isResizing ? { ...col, width: newWidth } : col
        )
      );
    };

    const handleMouseUp = () => {
      setIsResizing(null);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, minWidth, maxWidth]);

  const getAdjustedWidth = useCallback(
    ({ columnId, isInnerTable = false }) => {
      const column = columnWidths.find((col) => col.key === columnId);
      if (!column)
        return {
          width: 200,
          minWidth: 200,
        };
      if (isInnerTable)
        return {
          width: Math.min(column.width - innerColumnOffset, maxWidth),
          minWidth: Math.min(
            column.minWidth - innerColumnOffset,
            column.minWidth || minWidth
          ),
        };
      return {
        width: Math.min(column.width, maxWidth),
        minWidth: column.minWidth || minWidth,
      };
    },
    [columnWidths]
  );

  const registerColumn = useCallback((key: string, initialWidth = 200) => {
    setColumnWidths((prev) => {
      // If column already exists, don't add it again
      if (prev.some((col) => col.key === key)) return prev;
      return [...prev, { key, width: initialWidth }];
    });
  }, []);

  return {
    columnWidths,
    startResizing,
    isResizing,
    registerColumn,
    getAdjustedWidth,
  };
}

export default useColumnResizing;
