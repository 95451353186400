import React from 'react';
import { TabSet, Tab, TabPanel } from 'src/components';
import { DataIssueTable, TerritoryUpload } from './components';

const Admin = () => {
  const tabOptions = [
    {
      label: 'Data Issues',
      component: <DataIssueTable />,
    },
    {
      label: 'Territories',
      component: <TerritoryUpload />,
    },
  ];
  return (
    <div className="w-full">
      <div className="grid grid-cols-6 gap-2">
        <div className="col-span-2 sm:col-span-1">
          <TabSet id="admin-tabs" direction="vertical">
            {tabOptions.map((tab, index) => (
              <Tab key={tab.label} tabSetId="admin-tabs" index={index}>
                {tab.label}
              </Tab>
            ))}
          </TabSet>
        </div>
        <div className="col-span-4 sm:col-span-5">
          {tabOptions.map((tab, index) => (
            <TabPanel key={tab.label} tabSetId="admin-tabs" index={index}>
              <div className="px-4">{tab.component}</div>
            </TabPanel>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Admin;
