import { request } from 'src/api/request';

export const getCompanies = async (query) => {
  return await request({
    url: `v1/accounts/companies/${query}`,
    method: 'GET',
  });
};

export const getCompany = async (id: string) => {
  if (!id) return;
  return await request({
    url: `v1/accounts/companies/${id}/`,
    method: 'GET',
  });
};

export const createCompany = async (data: any) => {
  if (!data) return;
  return await request({
    url: 'v1/accounts/companies/',
    method: 'POST',
    data,
  });
};

export const updateCompany = async (id: string, data: any) => {
  if (!id || !data) return;
  return await request({
    url: `v1/accounts/companies/${id}/`,
    method: 'PATCH',
    data,
  });
};

export const deleteCompany = async (id: string) => {
  if (!id) return;
  return await request({
    url: `v1/accounts/companies/${id}/`,
    method: 'DELETE',
  });
};

export const updateNonMedScoutUserSettings = async (
  companyId: string,
  data: any
) => {
  if (!data || !companyId) return;
  return await request({
    url: `v1/accounts/companies/${companyId}/non_medscout_user_settings/`,
    method: 'PATCH',
    data,
  });
};
