import { Card, Typography } from '@mui/joy';
import { faExternalLinkAlt, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { hasContent } from 'src/utils';
import { contentStyles } from './styleConstants';
import InfoUnavailable from './InfoUnavailable';
import { useProfile } from 'src/context';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box } from 'src/components/PulseUI';

const ProfileOrganizations = () => {
  const { details } = useProfile();
  const { sameTabRouting, newPayerMix } = useFlags();

  const title = `Organization${
    details?.hcp?.hcp_groups?.length === 1 ? '' : 's'
  }`;

  const hcpGroups = details?.hcp?.hcp_groups
    ? [...details.hcp.hcp_groups].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  return (
    <Card sx={{ padding: 3 }}>
      <Box
        className="flex flex-row gap-2 items-center"
        aria-label="Organization"
      >
        <Icon icon={faUsers} color="#525252" className="h-4 w-4" />
        <Typography className="text-lg font-bold">{title}</Typography>
      </Box>
      <Box className="flex flex-col gap-4">
        {hasContent(hcpGroups) ? (
          hcpGroups.map((group, i) => {
            return newPayerMix ? (
              <Box key={`${group.id}-${i}`} className="flex flex-col gap-2">
                <Box className="flex flex-col">
                  {group.description && (
                    <Typography level="body-sm" className="text-tertiary">
                      {group.description}
                    </Typography>
                  )}
                  <Typography
                    level="body-sm"
                    className="text-link font-medium flex flex-row gap-2 items-center"
                  >
                    <a
                      href={`/profiles/hcp-group/${group.id}`}
                      target={sameTabRouting ? '_self' : '_blank'}
                      rel="noopener noreferrer"
                      className="flex flex-row gap-2 items-center"
                    >
                      {group.name}
                      <Icon
                        icon={faExternalLinkAlt}
                        style={{
                          height: '1rem',
                          width: '1rem',
                        }}
                        color="#2391D9"
                      />
                    </a>
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                key={`${group.id}-${i}`}
                className="flex flex-row gap-2 items-center px-4"
                sx={{
                  ...contentStyles(i),
                  display: 'block !important',
                }}
              >
                <Typography
                  level="body-sm"
                  sx={{
                    color: '#021B9C',
                  }}
                >
                  <a
                    href={`/profiles/hcp-group/${group.id}`}
                    target={sameTabRouting ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                  >
                    {group.name} <Icon icon={faExternalLinkAlt} size="xs" />
                  </a>
                </Typography>
                {group.description && (
                  <Typography level="body-xs">{group.description}</Typography>
                )}
              </Box>
            );
          })
        ) : (
          <InfoUnavailable />
        )}
      </Box>
    </Card>
  );
};

export default ProfileOrganizations;
