import React from 'react';
import { Card, Typography } from '@mui/joy';
import { faExternalLinkAlt, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { sentenceCase } from 'src/utils';
import { contentStyles } from './styleConstants';
import InfoUnavailable from './InfoUnavailable';
import { useMemo } from 'react';
import { useProfile } from 'src/context';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box } from 'src/components/PulseUI';

const ProfileCenterOrganizations = () => {
  const { details } = useProfile();
  const { sameTabRouting, newPayerMix } = useFlags();

  // Use the newOrgs from the details context
  const newOrgs = useMemo(() => {
    return details?.center?.organizations_v2;
  }, [details]);

  return (
    <Card sx={{ padding: 3 }}>
      <Box
        className="flex flex-row gap-2 items-center"
        aria-label="Organization"
      >
        <Icon icon={faUsers} color="#525252" className="h-4 w-4" />{' '}
        <Typography className="text-lg font-bold">Organization</Typography>
      </Box>
      {newPayerMix ? (
        <Box className="flex flex-col gap-2">
          {Object.keys(newOrgs)?.length > 0 ? (
            Object.keys(newOrgs).map((key, index) => {
              const value = newOrgs[key];

              // If the value is an array, display it as a group
              if (Array.isArray(value)) {
                return (
                  <Box key={index} className="flex flex-col">
                    <Typography level="body-sm" className="text-tertiary">
                      Groups
                    </Typography>
                    {value.map((group, i) => (
                      <Box key={i} className="flex flex-col">
                        {group.description && (
                          <Typography level="body-sm" className="text-tertiary">
                            {group.description}
                          </Typography>
                        )}
                        <Typography
                          level="body-sm"
                          className="text-link font-medium flex flex-row gap-1 items-center"
                        >
                          <a
                            href={`/profiles/hcp-group/${group.id}`}
                            target={sameTabRouting ? '_self' : '_blank'}
                            rel="noopener noreferrer"
                            className="flex flex-row gap-2 items-center"
                          >
                            {group.name}
                            <Icon
                              icon={faExternalLinkAlt}
                              style={{
                                height: '1rem',
                                width: '1rem',
                              }}
                              color="#2391D9"
                            />
                          </a>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                );
              }

              // If the value is not an array, display the key-value pair
              return (
                <Box key={index} className="flex flex-col">
                  <Typography level="body-sm" className="text-tertiary">
                    {sentenceCase(key)}
                  </Typography>
                  <Typography className="font-medium text-sm text-primary">
                    {value}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <InfoUnavailable />
          )}
        </Box>
      ) : (
        <Box className="flex flex-col gap-4">
          {Object.keys(newOrgs)?.length > 0 ? (
            Object.keys(newOrgs).map((key, index) => {
              const value = newOrgs[key];

              // If the value is an array, display it as a group
              if (Array.isArray(value)) {
                return (
                  <Box
                    key={index}
                    sx={{
                      ...contentStyles(index),
                    }}
                  >
                    <Typography level="body-sm">Groups</Typography>
                    {value.map((group, i) => (
                      <Box
                        key={i}
                        sx={{
                          display: 'block !important',
                        }}
                      >
                        <Typography
                          level="body-sm"
                          sx={{
                            color: '#021B9C',
                          }}
                        >
                          <a
                            href={`/profiles/hcp-group/${group.id}`}
                            target={sameTabRouting ? '_self' : '_blank'}
                            rel="noopener noreferrer"
                          >
                            {group.name}{' '}
                            <Icon
                              icon={faExternalLinkAlt}
                              size="xs"
                              style={{ marginLeft: '0.5rem' }}
                            />
                          </a>
                        </Typography>
                        <Typography level="body-sm">
                          {group.description ?? ''}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                );
              }

              // If the value is not an array, display the key-value pair
              return (
                <Box key={index} sx={contentStyles(index)}>
                  <Typography level="body-sm">{sentenceCase(key)}</Typography>
                  <Typography level="body-sm">{value}</Typography>
                </Box>
              );
            })
          ) : (
            <InfoUnavailable />
          )}
        </Box>
      )}
    </Card>
  );
};

export default ProfileCenterOrganizations;
