import React from 'react';
import { Box } from '@mui/material';
import { green } from '@mui/material/colors';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

const StandardCell = ({ row, field }) => {
  function renderCell() {
    switch (field) {
      case 'is_canonical':
        return (
          <Icon
            icon={faCheckCircle}
            color={green[900]}
            style={{
              display: row.is_canonical ? 'block' : 'none',
            }}
          />
        );
      case 'owner':
        return <Box>{`${row[field].first_name} ${row[field].last_name}`}</Box>;
      default:
        return <Box>{row[field]}</Box>;
    }
  }

  return renderCell();
};

export default StandardCell;
