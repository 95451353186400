import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';

interface ReimbursementAccordionRowProps {
  id?: string;
  isExpanded: boolean;
  onExpand: () => void;
  sx?: React.CSSProperties;
  children: React.ReactNode;
  startDecorator?: React.ReactNode;
  className?: string;
}

const ReimbursementAccordionRow = ({
  id = 'accordion-row',
  isExpanded = false,
  onExpand,
  sx = {},
  children,
  className,
  startDecorator,
}: ReimbursementAccordionRowProps) => {
  return (
    <div
      data-testid={id}
      className={`flex items-center gap-1 font-bold overflow-hidden text-ellipsis whitespace-nowrap px-2 py-1.5 cursor-pointer hover:bg-gray-100 ${className}`}
      style={{ ...sx }}
      onClick={onExpand}
    >
      {startDecorator ? (
        startDecorator
      ) : (
        <Icon
          size="lg"
          icon={faAngleDown}
          className={`text-neutral-600 transition-transform h-6 w-6 ${
            isExpanded ? '' : '-rotate-90'
          }`}
        />
      )}
      {children}
    </div>
  );
};

export default ReimbursementAccordionRow;
