import React from 'react';
import { TabsProvider } from './context';

const Tabs = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <TabsProvider>
      <div className={className}>{children}</div>
    </TabsProvider>
  );
};

export default Tabs;
