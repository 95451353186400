export default function verifyOptions({
  option_type,
  crm_api_code,
  currentOption,
  autopopulateOptions,
  userInputOptions,
}) {
  if (!option_type || !crm_api_code) return;

  if (option_type === 'auto_populate') {
    const existing = autopopulateOptions?.find((item) => {
      return (
        currentOption?.crm_api_code?.toLowerCase() !==
          crm_api_code?.toLowerCase() &&
        item?.crm_api_code?.toLowerCase() === crm_api_code?.toLowerCase()
      );
    });

    if (existing) {
      return 'CRM Duplicate Option';
    }
  } else {
    const existing = userInputOptions?.find((item) => {
      return (
        currentOption?.crm_api_code?.toLowerCase() !==
          crm_api_code?.toLowerCase() &&
        item.crm_api_code?.toLowerCase() === crm_api_code?.toLowerCase()
      );
    });
    if (existing) {
      return 'CRM Duplicate Option';
    }
  }

  return true;
}
