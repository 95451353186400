import React, { useMemo } from 'react';
import { Card } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightLong,
  faCircleQuestion,
} from '@fortawesome/pro-solid-svg-icons';
import { Link, CodeChip, Tooltip } from 'src/components';
import { useRouter } from 'next/router';
import { getComparisonDetails } from '../../utils';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { titleCase } from 'src/utils';

type CardProps = {
  code: string;
  avg_reimbursement_predicted: number;
  inpatient_outpatient_flag: string;
  payer_name: string;
  payer_type: string;
  percent_of_total: number;
  provider_state_percentile_by_payer_name: number;
  provider_state_percentile_by_payer_type: number;
  provider_state_rank_by_payer_name: number;
  state_avg_reimbursement_predicted: number;
  total_reimbursement_predicted: number;
};

const formatReimbursement = (amount?: number) =>
  amount
    ? `$${amount.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })}`
    : '';

const ReimbursementCard = ({ item }: { item: CardProps }) => {
  const router = useRouter();
  const queryParams = getSearchQueryParamsForAPI({
    code: item.code,
    payer_type: titleCase(item.payer_type),
    payer_name: titleCase(item.payer_name),
  });

  const newHref = `${router.asPath.replace(/\/$/, '')}/payer-mix${queryParams}`;

  const metrics = useMemo(
    () => ({
      avgReimbursement: formatReimbursement(item.avg_reimbursement_predicted),
      ...getComparisonDetails(
        item.avg_reimbursement_predicted,
        item.state_avg_reimbursement_predicted
      ),
    }),
    [item]
  );

  // Check if there's no absolute difference between avg_reimbursement_predicted and state_avg_reimbursement_predicted
  const avgReimbursementDifferenceIsZero =
    Math.abs(
      item.avg_reimbursement_predicted - item.state_avg_reimbursement_predicted
    ) === 0;

  // Check if avg_reimbursement_predicted is zero
  const avgReimbursementIsZero = item.avg_reimbursement_predicted === 0;

  return (
    <Card
      data-testid={`reimbursement-card-${item.code}`}
      className="flex flex-col gap-4 border-neutral-200 border-1 bg-neutral-050 h-full min-w-full"
      sx={{
        borderRadius: '16px',
      }}
      variant="outlined"
    >
      <CodeChip code={item.code} />
      <div className="flex flex-col gap-1">
        {avgReimbursementIsZero ? (
          <div className="flex flex-col items-center gap-2">
            <Icon
              icon={faCircleQuestion}
              className="text-neutral-600 self-start"
              size='2x'
            />
            <p className="text-neutral-600 text-sm font-medium italic">
              Not enough evidence to calculate reimbursement
            </p>
          </div>
        ) : (
          <>
            <p className="text-primary text-4xl font-extrabold">
              {metrics?.avgReimbursement || ''}
            </p>
            <div className="flex items-center gap-1">
              {avgReimbursementDifferenceIsZero ? (
                <div className="inline-block">
                  <Tooltip
                    content="Not enough evidence for an accurate comparison"
                    position="top"
                    slotProps={{
                      containerClassName: "max-w-56 whitespace-normal bg-neutral-900 text-white"
                    }}
                  >
                    <span className="text-xs text-primary cursor-pointer hover:underline max-w-56 italic">
                      vs. Payer State avg. not available
                    </span>
                  </Tooltip>
                </div>
              ) : (
                <>
                  <div className="flex items-center gap-1">
                    <Icon
                      icon={metrics?.diffArrowIcon}
                      size="sm"
                      className={`${metrics?.diffArrowColor}`}
                    />
                    <p className="text-sm font-bold">
                      ${metrics?.amountVersusStateAvg}
                    </p>
                  </div>
                  <p className="text-xs text-primary">vs. Payer State avg.</p>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <Link
        id={`reimbursement-card-view-details-${item.code}`}
        href={newHref}
        underline="none"
        className="flex flex-row items-center gap-2"
        target="_self"
        rel="noopener noreferrer"
      >
        <p className="text-sky-900 text-xs font-medium line-height-1.25rem ">
          View Details
        </p>
        <Icon icon={faArrowRightLong} className="text-sky-900" size="xs" />
      </Link>
    </Card>
  );
};

export default ReimbursementCard;
