import { Box, Typography } from '@mui/joy';
import format from 'date-fns/format';
import React from 'react';

const TimestampCell = ({ row }) => {
  const formattedDate = format(new Date(row.started), 'MM/dd/yyyy');

  return (
    <Box sx={{ width: '100%', maxHeight: 75, overflowY: 'auto' }}>
      <Typography sx={{ fontSize: '0.8rem' }}>{formattedDate}</Typography>
    </Box>
  );
};

export default TimestampCell;
