import { useMemo } from 'react';
import { useIntegrations } from '../../context';
import { NewBreadcrumbs } from 'src/components';

type BreadcrumbItem = {
  id: string;
  label: string;
  items?: BreadcrumbItem[];
  isLast?: boolean;
  onClick?: () => void;
};

const IntegrationsBreadcrumbs = () => {
  const { mappings, currentIntegration, currentMapping, dispatch } =
    useIntegrations();

  const breadcrumbItems = useMemo(() => {
    const items: BreadcrumbItem[] = [
      {
        id: 'home',
        label: 'Integrations',
        onClick: () => {
          dispatch({ type: 'SET_CURRENT_INTEGRATION', payload: null });
          dispatch({ type: 'SET_CURRENT_MAPPING', payload: null });
        },
      },
    ];

    if (currentIntegration) {
      items.push({
        id: currentIntegration,
        label: 'Mappings',
        onClick: () => {
          dispatch({ type: 'SET_CURRENT_MAPPING', payload: null });
        },
      });
    }

    if (currentMapping) {
      items.push({
        id: String(currentMapping.id),
        label: currentMapping.label,
        items: mappings?.map((mapping) => ({
          id: String(mapping.id),
          label: mapping.label,
          isSelected: currentMapping.id === mapping.id,
          onClick: () =>
            dispatch({ type: 'SET_CURRENT_MAPPING', payload: mapping }),
        })),
        onClick: () => null,
      });
    }

    return items;
  }, [currentIntegration, currentMapping, dispatch]);

  return <NewBreadcrumbs items={breadcrumbItems} />;
};

export default IntegrationsBreadcrumbs;
