import { combineReducers } from '@reduxjs/toolkit';
import { siteReducer, initialSiteState } from './siteProviderReducer';
import {
  savedSearchReducer,
  initialSavedSearchState,
} from './savedSearchReducer';
import { profileReducer, initialProfileState } from './profileReducer';
import { activityReducer, initialActivityState } from './activityReducer';
import { listReducer, initialListState } from './listReducer';
import {
  companyAdminReducer,
  initialCompanyAdminState,
} from './companyAdminReducer';
import { mapReducer, initialMapState } from './mapReducer';

export const initialState = {
  activity: initialActivityState,
  companyAdmin: initialCompanyAdminState,
  lists: initialListState,
  map: initialMapState,
  profile: initialProfileState,
  savedSearch: initialSavedSearchState,
  siteProvider: initialSiteState,
};

export const rootReducer = combineReducers({
  activity: activityReducer,
  companyAdmin: companyAdminReducer,
  lists: listReducer,
  map: mapReducer,
  profile: profileReducer,
  savedSearch: savedSearchReducer,
  siteProvider: siteReducer,
});
