import { Typography } from '@mui/joy';
import { Box } from '@mui/material';
import React from 'react';

const StreetAddressCell = ({ row }) => {
  return (
    <Box>
      {row.address_line_1 && (
        <Typography level="body-sm">{row.address_line_1}</Typography>
      )}
      {row.address_line_2 && (
        <Typography level="body-sm">{row.address_line_2}</Typography>
      )}
    </Box>
  );
};

export default StreetAddressCell;
