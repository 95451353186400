import { Typography } from '@mui/joy';
import React from 'react';

enum TitleType {
  PROVIDER = 'provider',
  CENTER = 'center',
  CLINIC = 'clinic',
}

type TitleProps = {
  type: 'provider' | 'center' | 'clinic';
};

const ProfileTitle = ({ type }: TitleProps) => {
  const title =
    type === TitleType.PROVIDER
      ? 'Provider'
      : type === TitleType.CENTER
      ? 'Center'
      : 'Clinic';

  return (
    <Typography className="text-3xl font-extrabold font-inter w-full pb-8 pt-4">
      {`${title} Summary`}
    </Typography>
  );
};

export default ProfileTitle;