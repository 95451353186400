import { Tooltip, Typography, Box } from '@mui/joy';
import { useMemo } from 'react';

interface ListItem {
  name: string;
  [key: string]: any;
}

const ListItemsCell = ({ row, field }) => {
  const items = useMemo(() => {
    const rawItems =
      row[field] || row.hcp?.[field] || row.provider?.[field] || [];
    return [...rawItems].sort((a, b) => a.name.localeCompare(b.name));
  }, [row, field]);

  if (!items.length) return null;

  const showAllItems = items.length <= 3;
  const visibleItems = items.slice(0, showAllItems ? 3 : 2);
  const hiddenCount = showAllItems ? 0 : items.length - 2;

  return (
    <Box
      sx={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {visibleItems.map((item: ListItem) => (
        <Typography
          key={item.name}
          level="body-sm"
          sx={{
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            paddingRight: '0.5rem',
          }}
        >
          {item.name}
        </Typography>
      ))}
      {hiddenCount > 0 && (
        <Tooltip
          title={
            <Box>
              {items.map((item, index) => (
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: 'white',
                    fontWeight: index >= 2 ? 800 : 400,
                  }}
                  key={index}
                >
                  {item.name}
                </Typography>
              ))}
            </Box>
          }
          variant="solid"
          arrow={true}
          enterDelay={500}
        >
          <Box>
            <Typography
              level="body-sm"
              sx={{
                width: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                paddingRight: '0.5rem',
                textDecoration: 'underline',
                cursor: 'default',
              }}
            >
              +{hiddenCount} more
            </Typography>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default ListItemsCell;
