import React, { useState } from 'react';
import { Tooltip } from 'src/components';

interface RadioProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  label: string;
  sx?: React.CSSProperties;
  size?: 'sm' | 'md' | 'lg'; // size customized so it was omitted from the props
  color?: 'primary' | 'secondary' | 'neutral' | 'soft';
  variant?: 'solid' | 'outlined';
  disabledReason?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio = ({
  label,
  checked,
  sx,
  className,
  size = 'md',
  color = 'primary',
  variant = 'outlined',
  disabled = false,
  disabledReason,
  onChange,
  ...props
}: RadioProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  const isSelected = checked ?? isChecked;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    onChange?.(event);
  };

  const sizeClasses = {
    sm: 'w-3 h-3',
    md: 'w-4 h-4',
    lg: 'w-5 h-5',
  };

  const colorClasses = {
    primary: {
      solid: 'border-blue-500 bg-blue-500',
      outlined: 'border-blue-500',
    },
    secondary: {
      solid: 'border-gray-500 bg-gray-500',
      outlined: 'border-gray-500',
    },
    neutral: {
      solid: 'border-gray-300 bg-gray-300',
      outlined: 'border-gray-300',
    },
    soft: {
      solid: 'border-gray-200 bg-gray-200',
      outlined: 'border-gray-200',
    },
  };

  const radioClasses = `
    ${sizeClasses[size]}
    ${isSelected ? colorClasses[color][variant] : 'border-gray-300'}
    border rounded-full relative
    ${
      isSelected && variant === 'solid'
        ? 'after:absolute after:w-2 after:h-2 after:bg-white after:rounded-full after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2'
        : ''
    }
    ${
      isSelected && variant === 'outlined'
        ? `after:absolute after:${sizeClasses[size]} after:scale-50 after:${colorClasses[color].outlined} after:rounded-full after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2`
        : ''
    }
    ${className || ''}
  `;

  const radio = (
    <label className="flex items-center gap-2 cursor-pointer">
      <input
        type="radio"
        className="hidden"
        checked={isSelected}
        disabled={disabled}
        onChange={handleChange}
        {...props}
        style={{ ...sx }}
      />
      <span className={radioClasses}></span>
      <span
        className={`text-sm ${
          size === 'lg' ? 'text-base' : size === 'sm' ? 'text-xs' : ''
        }`}
      >
        {label}
      </span>
    </label>
  );

  return disabled && disabledReason ? (
    <Tooltip content={disabledReason}>{radio}</Tooltip>
  ) : (
    radio
  );
};

export default Radio;
