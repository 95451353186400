import React, { useCallback } from 'react';
import { faUserNinja } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/joy';
import { useRouter } from 'next/router';
import { useAuth, useSite } from 'src/context';
import { useUserImpersonation } from 'src/hooks';
import { Button } from 'src/components';

interface ImpersonateButtonProps {
  userId: string;
  variant?: 'regular' | 'compact' | 'icon';
}

const ImpersonateButton = ({
  userId,
  variant = 'regular',
}: ImpersonateButtonProps) => {
  const { user } = useAuth();
  const router = useRouter();
  const { state } = useSite();
  const { currentUser } = state.companyAdmin;

  const { impersonate } = useUserImpersonation();

  const isMedScoutUser = user?.is_superuser;

  const handleImpersonate = useCallback(
    async (id) => {
      await impersonate(id);

      router.push('/search');
    },
    [impersonate, router]
  );

  if (!isMedScoutUser) return null;

  switch (variant) {
    case 'regular': {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleImpersonate(userId)}
          startDecorator={<Icon icon={faUserNinja} />}
          disabled={!isMedScoutUser || !userId}
        >
          {currentUser?.id?.toString() === userId
            ? `${currentUser?.first_name}`
            : 'Support'}
        </Button>
      );
    }

    case 'compact': {
      return (
        <Button
          variant="soft"
          color="primary"
          onClick={() => handleImpersonate(userId)}
          disabled={!isMedScoutUser || !userId}
        >
          <Icon icon={faUserNinja} />
        </Button>
      );
    }
    case 'icon': {
      return (
        <IconButton
          onClick={() => handleImpersonate(userId)}
          className="w-7 h-7 min-w-7 min-h-7 bg-transparent text-neutral-500 hover:text-sky-600 hover:bg-transparent disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!isMedScoutUser || !userId}
        >
          <Icon icon={faUserNinja} className="text-sm" />
        </IconButton>
      );
    }

    default:
      break;
  }
};

export default ImpersonateButton;
