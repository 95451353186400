import React from 'react';
import { useCompanyUsers, useGetManagers } from 'src/hooks';
import { useSite } from 'src/context';
import {
  Card,
  CardContent,
  TabSet,
  Tabs,
  Tab,
  TabPanel,
  CircularProgress,
} from 'src/components';
import ManagersTabContent from './components/ManagersTabContent';
import ReportsTabContent from './components/ReportsTabContent';

const ManagerCard = () => {
  const { state } = useSite();
  const { currentCompany, currentUser } = state.companyAdmin;
  const { isLoading: isUsersLoading } = useCompanyUsers(currentCompany.id);
  const { isLoading: isManagersLoading } = useGetManagers(currentCompany.id);

  const isManager = currentUser?.permissions?.is_manager;
  const loading = isManagersLoading || isUsersLoading;

  return (
    <Card className="relative">
      <CardContent className="p-6">
        <div className="flex flex-col gap-6">
          <Tabs>
            <div className="flex flex-col w-full">
              <TabSet id="manager-tabs">
                <Tab tabSetId="manager-tabs" index={0}>
                  Managers
                </Tab>
                {isManager && (
                  <Tab tabSetId="manager-tabs" index={1}>
                    Direct Reports
                  </Tab>
                )}
              </TabSet>

              <TabPanel tabSetId="manager-tabs" index={0}>
                <ManagersTabContent />
              </TabPanel>
              <TabPanel tabSetId="manager-tabs" index={1}>
                <ReportsTabContent />
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </CardContent>

      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/20">
          <CircularProgress size="sm" color="neutral" />
        </div>
      )}
    </Card>
  );
};

export default ManagerCard;
