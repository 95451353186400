import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Box } from '@mui/joy';
import {
  DataGridPremium,
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  GridFilterModel,
  GridRowId,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import {
  baseColumns,
  InnerContainer,
  NewTableToolbar,
  NoVolumeOverlay,
  TableCheckbox,
  TableColumn,
  TableControls,
  TableFooter,
  TableLoadingIndicator,
  TablePrimaryHeader,
  TableSecondaryHeader,
  TableSettings,
  VolumeSelectors,
  VolumeTableContainer,
} from 'src/components';
import {
  useCurrentTable,
  useGetMappings,
  useGetProviderListItems,
  useGetProviderLists,
  useGetSavedSearches,
  useLocalStorage,
  useSyncRowHovered,
  useSyncTableScroll,
  useVolumeColumns,
} from 'src/hooks';
import {
  useCRM,
  useCRMButtonContext,
  useLogging,
  useNotification,
  useProspectSearch,
  useProspectSelection,
  useTable,
} from 'src/context';
import { VOLUME_SELECT_OPTIONS } from 'src/components/VolumeSelectors/constants';
import { getListExport } from 'src/api/lists/listExportApi';

const listColumns: GridColDef[] = [
  TableColumn?.tags('tags', 'Tags'),
  TableColumn?.affiliations('affiliations', 'Affiliations'),
  TableColumn?.groups('groups', 'Groups'),
  TableColumn?.streetAddress('street_address', 'Street Address'),
  TableColumn?.secondaryAddresses(
    'secondary_addresses_v2',
    'Secondary Addresses'
  ),
  TableColumn?.dateAdded('created_at', 'Date Added'),
  TableColumn?.systems('systems', 'Hospital Systems'),
];

const ListsTable = () => {
  const router = useRouter() as any;
  const log = useLogging();
  const apiRef = useGridApiRef();
  const apiRef2 = useGridApiRef();
  // Sync the scroll of the two tables
  useSyncTableScroll(apiRef, apiRef2);
  const { isRowHovered } = useSyncRowHovered(apiRef, apiRef2);

  const { clickedButtons } = useCRMButtonContext();
  const { setNotification } = useNotification();
  const { prospectSearch, prospectMode, prospectVolumeType } =
    useProspectSearch();

  const { setCurrentSearchIds } = useTable();
  const { statusFilterOptions, setStatusFilterOptions, setSelectedBuckets } =
    useCRM();

  const { currentTable, currentYears } = useCurrentTable('lists');

  const { volumeColumns, optionColumns } = useVolumeColumns();

  const { data: lists } = useGetProviderLists();
  const selectedList = lists?.results?.find(
    (list) => list.id === Number(router?.query?.id)
  );

  const { data: allMappings } = useGetMappings({
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const crmTypes = useMemo(() => {
    return Array.from(
      new Set(
        allMappings?.results
          ?.filter((mapping) => mapping.enabled)
          .map((mapping) => mapping.crm?.toLowerCase())
      )
    );
  }, [allMappings]);
  const { data: savedSearches = [] } = useGetSavedSearches();

  const {
    state: [, setNewProspects],
  } = useProspectSelection();

  const [isExporting, setIsExporting] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const [sort, setSort] = useState({
    field: 'name',
    order: 'desc',
    sort_search: '',
    sort_value: '',
  });

  useEffect(() => {
    if (!prospectSearch?.id) return;
    // set the sort value to the current saved search if one is selected
    setSort({
      field: 'saved_search',
      order: 'desc',
      sort_search: `${prospectSearch?.id}`,
      sort_value: prospectVolumeType,
    });

    return () => {
      setSort({
        field: 'total_patients',
        order: 'desc',
        sort_search: '',
        sort_value: '',
      });
      setStatusFilterOptions([]);
      setSelectedBuckets([]);
    };
  }, []);

  useEffect(() => {
    if (!apiRef.current?.getSelectedRows) return;
    setNewProspects(Array.from(apiRef.current.getSelectedRows().values()));

    return () => setNewProspects([]);
  }, [selectionModel]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage(
    'providerTable',
    {
      name: true,
      crm_links: true,
      specialty: true,
      systems: false,
      rep_name: false,
      tags: false,
      provider_id: false,
      address: false,
      street_address: false,
      secondary_addresses_v2: false,
      city: false,
      state: false,
      zip_code: false,
      affiliations: false,
      groups: false,
      actions: false,
      phone: false,
      email: false,
      created_at: false,
    }
  );

  const [showVolume, setShowVolume] = useLocalStorage(
    'showVolumeProspects',
    true
  );

  useEffect(() => {
    if (prospectSearch?.id) {
      setShowVolume(columnVisibilityModel[prospectSearch?.id]);
    }
  }, [columnVisibilityModel, prospectSearch?.id, setShowVolume]);

  const handlePageChange = (e, newPage) => {
    setPaginationModel((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (e) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(e.target.value, 10),
      page: 0,
    }));
  };

  const searchIds = useMemo(
    () =>
      Object.keys(columnVisibilityModel).filter(
        (k) =>
          columnVisibilityModel[k] &&
          savedSearches.map((s) => s.id).indexOf(Number(k)) > -1
      ),
    [columnVisibilityModel, savedSearches]
  );

  useEffect(() => {
    setCurrentSearchIds(searchIds);
  }, [searchIds]);

  useEffect(() => {
    // Verify values have changed before setting sort preventing infinite loop
    const isValid =
      searchIds?.length === 1 && sort?.sort_search !== searchIds[0];
    if (!isValid) return;

    setSort({
      field: 'saved_search',
      order: 'desc',
      sort_search: `${searchIds[0]}`,
      sort_value: prospectVolumeType,
    });
  }, [prospectVolumeType, searchIds]);

  const isThirdParty = crmTypes[0]?.toLowerCase() === 'third_party';
  const shouldRefetch = isThirdParty && clickedButtons?.length > 0;

  const listScope = {
    listId: selectedList?.id,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    sort: sort?.field,
    order: sort?.order,
    sort_search: sort?.sort_search,
    sort_value: sort?.sort_value,
    volume_type: prospectVolumeType,
    q: filterModel.quickFilterValues?.join(' ') || null,
    enabledSearchIds: searchIds,
    mode: prospectMode,
    dateRange: {
      gte: currentTable?.gte,
      lte: currentTable?.lte,
    },
    statusFilters:
      statusFilterOptions.filter((status) => status !== 'no_status') || [],
    include_unpushed: statusFilterOptions?.includes('no_status') ? 1 : 0,
    shouldRefetch,
  };

  const {
    data: list,
    isLoading,
    isRefetching,
  } = useGetProviderListItems(listScope);

  // maintain column visibility state when currentTable trending changes.. this is to show the volume columns
  useEffect(() => {
    if (!currentTable) return;
    if (!volumeColumns?.length) return;
    if (!optionColumns?.length) return;
    // set options columns to visible if the table is trending
    setColumnVisibilityModel((columnVisibilityState) => ({
      ...columnVisibilityState,
      ...serializeColumnVisibility(columnVisibilityState),
    }));
  }, [currentTable, prospectSearch, volumeColumns]);

  const enhancedRows = useMemo(() => {
    if (!list?.results || !prospectSearch) return [];

    return list.results.map((row) => {
      return row;
    });
  }, [list, prospectSearch]);

  // stop gap before we move to new table structure
  const mainColumns = useMemo(() => {
    return [
      ...baseColumns.slice(0, 2),
      ...listColumns,
      ...baseColumns.slice(2),
    ];
  }, []);

  const serializeColumnVisibility = (currentVisibility) => {
    // Process volume columns
    const volumeVisibility = volumeColumns.reduce((acc, col) => {
      if (col.field === 'crm') return acc;

      // Special handling for prospect search column
      if (col.field === prospectSearch?.id?.toString()) {
        // If currentVisibility[prospectSearch.id] is false but showVolume is true,
        // this might be the initial render
        acc[prospectSearch.id] =
          (showVolume && !!prospectSearch?.id) ||
          !!currentVisibility[prospectSearch.id];
        return acc;
      }

      // Standard volume column handling
      acc[col.field] = !!currentVisibility[col.field];
      return acc;
    }, {});

    // Process option (trending) columns
    const optionVisibility = optionColumns.reduce((acc, col) => {
      const [_, year] = col.field.split('_');
      const isValidYear = currentYears.includes(Number(year));
      const isInOptions = VOLUME_SELECT_OPTIONS?.some((o) => o.value === year);

      acc[col.field] =
        isInOptions &&
        isValidYear &&
        currentTable?.isTrending &&
        searchIds?.length > 0;
      return acc;
    }, {});

    // Combine all visibility settings
    return {
      ...volumeVisibility,
      ...optionVisibility,
      crm: crmTypes?.length > 0,
    };
  };

  const handleExportList = (type: string) => {
    const visibleColumns = apiRef.current
      .getVisibleColumns()
      .map((col) => col.field)
      .concat(['claims', 'patients', 'trends'])
      .filter((col) => col.indexOf('__') === -1);

    const fileName = `${selectedList?.name}`;

    setIsExporting(true);
    try {
      const res = getListExport({
        searchParams: listScope,
        fileType: type,
        columns: visibleColumns,
        fileName,
      });

      if (!res) return;
      setNotification({
        title: 'Success',
        message:
          'Export initiated - You will receive an email when it is ready for download',
        type: 'success',
      });
      log.event('Export', {
        type: 'lists',
        source: `${selectedList.name} - List`,
        columns: visibleColumns,
        count: selectedList?.num_items,
      });
    } catch (err: any) {
      log.exception(`Error exporting List: ${err}`);

      setNotification({
        title: 'Error',
        message: err?.error_message || 'Error exporting, please try again.',
        type: 'error',
      });
    } finally {
      setIsExporting(false);
    }
  };

  const handleSortChange = (model) => {
    let newField = 'name';
    let sort_search = '';
    let sort_value = '';

    if (model?.length) {
      const { field } = model[0];
      newField =
        isNaN(Number(field)) && field !== 'volume' ? field : 'saved_search';

      if (newField === 'saved_search') {
        sort_search = field !== 'volume' ? field : `${prospectSearch?.id}`;
        sort_value = prospectVolumeType;
      }
    }

    setSort({
      field: newField,
      order: model?.length ? model[0].sort : 'desc',
      sort_search,
      sort_value,
    });
  };

  const handleFilterChange = (model) => {
    setFilterModel(model);
    if (paginationModel.page !== 0) {
      setPaginationModel((prev) => ({ ...prev, page: 0 }));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        borderRadius: '0.5rem',
        bottom: 0,
        height: '100%',
        width: '100%',
        minWidth: 0,
        overflow: 'hidden',
      }}
    >
      <InnerContainer>
        <DataGridPremium
          disableVirtualization
          apiRef={apiRef}
          rows={enhancedRows || []}
          rowCount={list?.count || 0}
          columns={mainColumns}
          scrollbarSize={0}
          hideFooter={true}
          checkboxSelection
          getRowClassName={isRowHovered}
          disableColumnMenu
          disableRowSelectionOnClick
          sx={{
            // remove border from table and only show overflow on x axis
            border: 'none',
            borderRadius: 0,
            '& .MuiDataGrid-main, & .MuiDataGrid-footerContainer': {
              backgroundColor: 'white',
            },
            '& .MuiDataGrid-virtualScroller': {
              overflowY: 'scroll',
              overflowX: 'auto',
              marginRight: '-1rem', // hides the Y axis scrollbar
            },
            '& .MuiDataGrid-columnHeadersInner>div:first-of-type, & .MuiDataGrid-groupHeader, & .MuiDataGrid-columnHeader--emptyGroup':
              {
                height: 'auto !important',
              },
            '& .MuiDataGrid-row:hover, & .Mui-hovered': {
              backgroundColor: 'rgba(204, 204, 194, 0.13)',
              '&.Mui-hovered': {
                backgroundColor: 'rgba(204, 204, 194, 0.13)',
                transition: 'background-color 0.3s ease-in-out',
              },
            },
          }}
          initialState={{
            columns: { columnVisibilityModel },
            pagination: { paginationModel: { page: 0, pageSize: 50 } },
          }}
          slots={{
            toolbar: NewTableToolbar,
            baseCheckbox: TableCheckbox,
            columnMenu: CustomColumnMenu,
            ...TableSettings.slots,
          }}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
            toolbar: {
              variant: 'primary',
              bottomHeight: '100px',
              headerSlots: {
                slot1: (
                  <TablePrimaryHeader
                    title={
                      selectedList?.type != 'smart' &&
                      (selectedList?.name || 'Select list to see details')
                    }
                    subTitle={
                      [
                        selectedList?.owner?.first_name,
                        selectedList?.owner?.last_name,
                      ].join(' ') || ''
                    }
                  />
                ),
                slot2: (
                  <TableControls
                    onExport={handleExportList}
                    isExporting={isExporting}
                    columns={mainColumns}
                    columnVisibilityModel={columnVisibilityModel}
                    setColumnVisibilityModel={setColumnVisibilityModel}
                    showFilterButton={crmTypes[0]?.toLowerCase() === 'medrm'}
                    showDeleteButton={selectedList?.type !== 'smart'}
                    table="lists"
                  />
                ),
              },
            },
          }}
          // Pagination
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          // Sorting
          sortingMode="server"
          onSortModelChange={handleSortChange}
          // Filtering
          filterMode="server"
          onFilterModelChange={handleFilterChange}
          filterModel={filterModel}
          // Column visibility
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={setColumnVisibilityModel}
          // Rows
          rowHeight={75}
          onRowSelectionModelChange={setSelectionModel}
          rowSelectionModel={selectionModel}
        />
        <VolumeTableContainer width="500px">
          <DataGridPremium
            disableVirtualization
            apiRef={apiRef2}
            rows={searchIds?.length > 0 ? enhancedRows : []}
            rowCount={list?.count || 0}
            columns={[...volumeColumns, ...optionColumns]}
            hideFooter={true}
            getRowClassName={isRowHovered}
            disableColumnMenu
            disableRowSelectionOnClick
            sx={{
              // remove border from table
              border: 'none',
              borderLeft: '#005b3860 1px solid',
              borderRadius: 0,
              background: 'var(--secondary-outlined-ActiveBg, #D6F6F1)',
              '& .muiDataGrid-withBorderColor': {
                border: 'none',
              },
              '& .MuiDataGrid-virtualScroller': {
                overflowY: 'auto',
                overflowX: 'hidden',
              },
              '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                display: 'flex',
                alignItems: 'center',
                flex: '1 0 0',
                alignSelf: 'stretch',
              },
              '& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer':
                {
                  borderBottom: '#005b3860 1px solid',
                },
              // This fixes that little notch on the right created by the scrollbar
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'var(--secondary-outlined-ActiveBg, #D6F6F1)',
                borderBottom: '#005b3860 1px solid',
                '& .MuiDataGrid-columnSeparator': {
                  color: 'none',
                },
              },
              '& .MuiDataGrid-overlayWrapperInner': {
                backgroundColor: 'var(--secondary-outlined-ActiveBg, #D6F6F1)',
              },
              '& .MuiDataGrid-columnHeaderGroupCell': {
                borderBottom: 'none', // Remove group header cell borders if present
              },
              '& .MuiDataGrid-row:hover, & .Mui-hovered': {
                backgroundColor: 'rgba(204, 204, 194, 0.13)',
                '&.Mui-hovered': {
                  backgroundColor: 'rgba(204, 204, 194, 0.13)',
                },
              },
              '& .MuiDataGrid-cell': {
                borderBottom: '#005b3860 1px solid',
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none !important',
              },
            }}
            slots={{
              toolbar: NewTableToolbar,
              noRowsOverlay: NoVolumeOverlay,
              noResultsOverlay: NoVolumeOverlay,
              ...TableSettings.slots,
            }}
            slotProps={{
              columnsPanel: {
                disableHideAllButton: true,
                disableShowAllButton: true,
              },
              toolbar: {
                bottomHeight: '100px',
                headerSlots: {
                  slot1: (
                    <TableSecondaryHeader
                      columns={volumeColumns}
                      columnVisibilityModel={columnVisibilityModel}
                      setColumnVisibilityModel={setColumnVisibilityModel}
                      showVolumeMenu
                      table="lists"
                    />
                  ),
                  slot2: (
                    <VolumeSelectors
                      showTreatingReferring
                      table="lists"
                      enabledSearchIds={searchIds}
                    />
                  ),
                },
              },
            }}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            // Sorting
            sortingMode="server"
            onSortModelChange={handleSortChange}
            // Filtering
            filterMode="server"
            onFilterModelChange={handleFilterChange}
            filterModel={filterModel}
            // Column visibility
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            // Rows
            rowHeight={75}
          />
        </VolumeTableContainer>
        {(isLoading || isRefetching) && <TableLoadingIndicator top="255px" />}
      </InnerContainer>
      <TableFooter
        page={paginationModel.page}
        pageSize={paginationModel.pageSize}
        rowCount={list?.count || 0}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </Box>
  );
};

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuFilterItem: null,
        columnMenuAggregationItem: null,
        columnMenuGroupingItem: null,
      }}
    />
  );
};

export default ListsTable;
