import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import {
  ActivityProvider,
  AnalyticsProvider,
  AuthProvider,
  CRMProvider,
  DashboardProvider,
  LayoutControlProvider,
  NotificationProvider,
  ProspectProvider,
  PushToCRMButtonProvider,
  SiteProvider,
  TableProvider,
  UserSettingsProvider,
} from 'src/context';
// TODO: Move to src/context and consolidate
import {
  IntegrationsProvider,
  IntegrationLayoutProvider,
} from 'src/components/Settings/Integrations/context';

export const GlobalProvider = ({ children, pageProps }) => {
  const router = useRouter();
  const queryClientState = new QueryClient();
  const [queryClient] = useState(queryClientState);

  function ErrorFallback({ resetErrorBoundary }) {
    resetErrorBoundary();
    return null;
  }

  function errorHandler(error) {
    Sentry.captureMessage('App page error boundary hit');
    Sentry.captureException(error);
  }

  return (
    <AnalyticsProvider>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={errorHandler}
              onReset={() => {
                router.reload();
              }}
            >
              <SiteProvider>
                <UserSettingsProvider>
                  <IntegrationLayoutProvider>
                    <LayoutControlProvider>
                      <NotificationProvider>
                        <ProspectProvider>
                          <CRMProvider>
                            <DashboardProvider>
                              <IntegrationsProvider>
                                <PushToCRMButtonProvider>
                                  <TableProvider>
                                    <ActivityProvider>
                                      {children}
                                    </ActivityProvider>
                                  </TableProvider>
                                </PushToCRMButtonProvider>
                              </IntegrationsProvider>
                            </DashboardProvider>
                          </CRMProvider>
                        </ProspectProvider>
                      </NotificationProvider>
                    </LayoutControlProvider>
                  </IntegrationLayoutProvider>
                </UserSettingsProvider>
              </SiteProvider>
            </ErrorBoundary>
          </Hydrate>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
};
