import React, { useMemo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/joy';
import { styled } from '@mui/material';
import { useLogging, useNotification, useProfile } from 'src/context';
import { ActivityItem } from '../ActivityItem';
import { deleteActivity } from 'src/api';
import { useGetEntityActivities } from 'src/hooks';
import { AddEditActivity } from '../AddEditActivity';

const ActivityListContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  padding: '0 var(--1, 0.5rem)',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: 'var(--3, 1.5rem)',
  zIndex: 1,
  width: '100%',
});

const LineContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  width: '100%',
});

const Line = styled(Box)({
  position: 'absolute',
  width: '0.125rem',
  height: '100%',
  backgroundColor: 'var(--Neutral-200, #DDDFE065)',
  left: '1.44rem',
  top: '1px',
});

const NoDataContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 'var(--2, 16px)',
  alignSelf: 'stretch',
  paddingLeft: '2rem',
});

interface ActivityListProps {
  showLoading?: boolean;
  isFiltered?: boolean;
}

const ActivityList = ({
  showLoading = false,
  isFiltered = false,
}: ActivityListProps) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { details } = useProfile();
  const {
    data: activities,
    isLoading,
    error,
    mutate,
  } = useGetEntityActivities(details?.provider_id);

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this activity?')) return;
    try {
      await deleteActivity({
        activityId: id,
      });
      mutate({
        ...activities,
        results: activities?.results?.filter((item) => item.id !== id),
      });

      log.event('Delete Activity', {
        location: 'Activity List',
        data: id,
      });
    } catch (err) {
      log.exception('Delete Activity', {
        tags: {
          location: 'Activity List',
          data: id,
          error: err,
        },
      });
      setNotification({
        title: 'Error',
        message: err?.error_message || 'Failed to delete activity',
        type: 'error',
      });
    } finally {
      mutate();
    }
  };

  const activityItems = useMemo(() => {
    if (!activities?.results) return [];
    return activities?.results;
  }, [activities]);

  return (
    <LineContainer>
      <Line />
      <ActivityListContainer>
        {(isLoading || !details) && showLoading ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                minHeight: '10rem',
              }}
            >
              <CircularProgress size="sm" />
            </Box>
          </>
        ) : activityItems?.length > 0 ? (
          activityItems?.map((item) => (
            <ActivityItem key={item.id} item={item} onDelete={handleDelete} />
          ))
        ) : (
          !isLoading &&
          activityItems?.length === 0 && (
            <>
              {!isFiltered && <AddEditActivity />}
              <NoDataContainer>
                <Typography level="title-md">No activities</Typography>
              </NoDataContainer>
            </>
          )
        )}
      </ActivityListContainer>
    </LineContainer>
  );
};

export default ActivityList;
