import React from 'react';
import { Box, Select, Option, Typography, styled } from '@mui/joy';

const SelectorLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.125rem' /* 18px */,
  paddingBottom: '0.5rem',
}));

interface SelectorProps {
  label: string;
  options: { value: string; label: string; disabled?: boolean }[];
  value: string;
  setValue: (value: string) => void;
  id?: string;
}

const Selector = ({ label, options, value, setValue, id }: SelectorProps) => {
  return (
    <Box sx={{ flex: 1 }}>
      <SelectorLabel>{label}</SelectorLabel>
      <Select
        id={id}
        sx={{ width: '100%', borderRadius: '0.5rem' }}
        aria-label="Date"
        value={value}
        renderValue={(value) => {
          return <Typography level="body-sm">{value.value}</Typography>;
        }}
        onChange={(e, newValue) => setValue(newValue)}
        slotProps={{
          listbox: {
            sx: {
              zIndex: 9999, // This would not show any other way on the result lists
            },
          },
        }}
      >
        {options.map((option, index) => (
          <Option
            id={`${id}-option-${option.value}`}
            key={index}
            value={option.value}
            aria-label={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </Box>
  );
};

export default Selector;
