import React from 'react';
import { Box, Container, Typography } from '@mui/joy';
import { DateSelect, TypeSelect, SearchInput } from './components';
import { useSite } from 'src/context';
import { useRouter } from 'next/router';
import { SiteActionTypes } from 'src/context/reducers/actionTypes';
import { SavedSearchFilter } from '../SavedSearchFilter';
import {
  faFolderBookmark,
  faCaretDown,
} from '@fortawesome/pro-solid-svg-icons';

const PageFilterBar = () => {
  const router = useRouter();
  const { type: location } = router.query as { type: string };
  const { state, dispatch } = useSite();

  const setSearch = (search) => {
    dispatch({
      type: SiteActionTypes.SET_SEARCH,
      payload: { location, search },
    });
  };

  const setSelectedType = (selectedType) => {
    dispatch({
      type: SiteActionTypes.SET_SELECTED_TYPE,
      payload: { location, selectedType },
    });
  };

  const setSelectedDate = (selectedDate) => {
    dispatch({
      type: SiteActionTypes.SET_SELECTED_DATE,
      payload: { location, selectedDate },
    });
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        display: 'flex',
        gap: '0.5rem',
        minWidth: 0,
      }}
    >
      <SearchInput
        id="filter-providers-search"
        search={state?.siteProvider[location]?.search}
        setSearch={setSearch}
      />

      {/* Divider wouldn't show, so we old skool it. */}
      <Box
        sx={{
          display: 'flex',
          height: 'auto',
          width: '1px',
          minWidth: '1px',
          backgroundColor: '#636B744D',
          marginInline: '0.5rem',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          height: 'auto',
          width: '100%',
          minWidth: 210,
          bottom: 0,
        }}
      >
        <Typography
          level="body-sm"
          sx={{
            fontSize: '0.875rem',
            fontWeight: 500,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          View volume for:
        </Typography>
        <SavedSearchFilter
          id="filter-providers-saved-search"
          multiple
          startDecorator={faFolderBookmark}
          endDecorator={faCaretDown}
          context="site"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          width: '100%',
          flex: 2,
        }}
      >
        <TypeSelect
          id="filter-providers-type-select"
          selectedType={state?.siteProvider[location]?.selectedType}
          setSelectedType={setSelectedType}
        />
        <DateSelect
          id="filter-providers-date-select"
          selectedDate={state?.siteProvider[location]?.selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </Box>
    </Container>
  );
};

export default PageFilterBar;
