import { ActivityIcon, IconPicker, TailwindColorPicker } from 'src/components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

interface CreateActivityTypeProps {
  item: MedScout.ActivityType;
  onChange: (updatedItem: MedScout.ActivityType) => void;
  onDelete: (id: number) => void;
  onSave: () => void;
}

const CreateActivityTypeItem = ({
  item,
  onChange,
  onDelete,
  onSave,
}: CreateActivityTypeProps) => {
  const handleIconSelect = (icon: any) => {
    onChange({ ...item, icon: icon.icon?.iconName });
  };

  const handleColorSelect = (color: string) => {
    onChange({ ...item, color });
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...item, label: e.target.value });
  };

  return (
    <div className="flex items-center gap-2 w-full py-2">
      <IconPicker
        onIconSelect={handleIconSelect}
        onColorSelect={handleColorSelect}
        slots={{
          anchor: (
            <div
              className={
                item.deactivated
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:opacity-60 transition-all duration-300 cursor-pointer'
              }
            >
              <ActivityIcon item={item} />
            </div>
          ),
        }}
        placement="bottom-start"
      />
      <div className="flex items-center gap-2 flex-1">
        <div
          className="flex items-center gap-2 w-full border border-neutral-200 
          rounded-lg px-2 py-1 focus-within:border-neutral-300
          transition-all duration-300"
        >
          <input
            type="text"
            value={item.label}
            onChange={handleLabelChange}
            placeholder="Activity type name..."
            className="border-none outline-none w-full flex-1"
            autoFocus
            onKeyDown={(e) => {
              if (e.key === 'Enter' && item.label.trim()) {
                onSave();
              }
            }}
          />
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button
          className="w-6 h-6 bg-red-200 hover:bg-red-300 rounded-full
            transition-all duration-300"
          onClick={() => onDelete(item.id)}
        >
          <Icon size="sm" icon={faTimes} className="text-red-600" />
        </button>
      </div>
    </div>
  );
};

export default CreateActivityTypeItem;
