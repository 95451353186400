import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDeleteProviderListItem } from 'src/hooks';
import { useLogging, useProspectSelection, useNotification } from 'src/context';
import { useRouter } from 'next/router';
/**
 * Dialog to confirm the removal of a prospect from a list
 *
 * @param {*} { show, onHide }
 * @return {*}
 */
const RemoveProvidersDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  const { query } = useRouter() as any;
  const { setNotification } = useNotification();
  const { isLoading: isDeletingProvider, mutateAsync: removeProspect } =
    useDeleteProviderListItem();

  const log = useLogging();

  const {
    state: [selectedProviders, setSelectedProviders],
  } = useProspectSelection();

  const handleDeleteProspect = async (e) => {
    try {
      await Promise.all(
        selectedProviders.map((prospect) => {
          return removeProspect({
            itemId: prospect.id,
            listId: query?.id,
          });
        })
      );
      setNotification({
        title: 'Provider removed',
        message:
          selectedProviders.length > 1
            ? 'The providers have been removed from the list.'
            : 'The provider has been removed from the list.',
        type: 'success',
      });
      setSelectedProviders([]);
      onClose(e, 'backdropClick');
    } catch (err) {
      setNotification({
        title: 'Error',
        message:
          err?.error_message ||
          'An error occurred while removing the provider(s) from the list.',
        type: 'error',
      });
      log.exception(`Error deleting providers from list: ${err}`, {
        tags: {
          source: 'RemoveProvidersDialog',
          context: selectedProviders,
        },
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Delete {selectedProviders.length === 1 ? 'Provider' : 'Providers'} from
        List
      </DialogTitle>
      <DialogContent>
        <p>
          Are you sure you want to remove&nbsp;
          {selectedProviders.length === 1 ? (
            <strong>{selectedProviders[0]?.name}</strong>
          ) : (
            <strong>{selectedProviders.length} providers</strong>
          )}
          ?
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={isDeletingProvider}
          onClick={(e) => {
            onClose(e, 'backdropClick');
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isDeletingProvider}
          onClick={(e) => handleDeleteProspect(e)}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveProvidersDialog;
