import useSWR from 'swr';
import { getRoles } from 'src/api';
import { QUERY_KEYS } from './constants';

export const useGetRoles = ({ companyId }: { companyId: string }) => {
  return useSWR(
    [QUERY_KEYS.get_roles, companyId],
    () => getRoles({ companyId }),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    }
  );
};

export default useGetRoles;
