import { useForm, Controller } from 'react-hook-form';
import { FormControlLabel, Switch } from '@mui/material';
import { useEffect } from 'react';
import { useUpdateAutopopulateOption } from 'src/hooks';
import { useLogging } from 'src/context';
import { useRouter } from 'next/router';

const OptionsEnabledCell = ({ row }) => {
  const router = useRouter();
  const log = useLogging();
  const { id } = router.query;
  const { mutateAsync: updateOption } = useUpdateAutopopulateOption();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      ...row,
    },
  });

  // This resets the form when the row changes if not the data is stale
  useEffect(() => {
    if (!row) return;
    reset({
      ...row,
    });
  }, [row]);

  async function onSubmit(data: any) {
    if (!data || !id) return;
    try {
      await updateOption({ data, mappingId: id?.toString() });
      log.event(`Updated Option`, {
        ...data,
      });
    } catch (err: any) {
      log.exception(`Error updating option: ${err}`);
    }
  }

  return (
    <Controller
      control={control}
      name="enabled"
      render={({ field }) => (
        <FormControlLabel
          control={
            <Switch
              {...field}
              checked={field.value}
              onChange={handleSubmit(onSubmit)}
              onClick={field.onChange}
            />
          }
          label="Enabled"
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '0.8rem',
              fontWeight: 500,
            },
          }}
        />
      )}
    />
  );
};

export default OptionsEnabledCell;
