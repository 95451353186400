import React from 'react';
import { useAuthorization } from 'src/hooks';
import { useRouter } from 'next/router';

const InternalUseOnlyBanner = () => {
  const auth = useAuthorization();
  const router = useRouter();
  const { view } = router.query as { view: string };

  const showBanner =
    (view === 'patient_patterns' &&
      auth.hasPermission('show.internal_banner.referrals')) ||
    (view === 'affiliations' &&
      auth.hasPermission('show.internal_banner.site_providers'));

  return (
    showBanner && (
      <div className="w-full flex items-center gap-2 bg-red-50 border-l-4 border-red-500 px-4 py-2 rounded-md">
        <div className="flex-1">
          <p className="text-lg text-red-800 font-semibold">
            FOR INTERNAL USE ONLY. DO NOT DISTRIBUTE.
          </p>
        </div>
      </div>
    )
  );
};

export default InternalUseOnlyBanner;
