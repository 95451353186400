import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Collapse, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { FileSelector } from './components';
import { useUploadTerritories, useDeserializeTerritories } from 'src/hooks';
import {
  ControlledCheckbox,
  ControlledInput,
  SmoothnessControl,
  GoogleStaticMap,
} from 'src/components';

import { request } from 'src/api/request';
import { useLogging, useNotification } from 'src/context';
import { Typography } from '@mui/joy';
import { red } from '@mui/material/colors';
import CompanyUserSelector from 'src/components/CompanyUserSelector';

type NewTerritory = {
  name: string;
  geometry: string[];
  user: number;
  is_canonical: boolean;
  smooth_level: number;
};

const TerritoryUpload = () => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const [processedTemplate, setProcessedTemplate] =
    useState<MedScout.Territory | null>(null);

  const { mutateAsync: uploadTerritories, isLoading: isUploading } =
    useUploadTerritories();
  const { mutateAsync: deserializeTerritories, isLoading: isDeserializing } =
    useDeserializeTerritories();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [validFile, setValidFile] = useState<boolean>(false);

  const methods = useForm<NewTerritory>();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = methods;

  async function handlePreviewTerritory(template: File) {
    try {
      setSelectedFile(template);
      const deserializedTerritories = await deserializeTerritories(template);
      setProcessedTemplate(deserializedTerritories);
      setValidFile(true);

      log.event('Deserialized territory', {
        source: 'TerritoryUploadForm',
        data: template,
      });
    } catch (err) {
      setSelectedFile(null);
      setValidFile(false);
      log.exception('Failed to deseralize territory', {
        tags: {
          source: 'TerritoryUploadForm',
          error: err,
        },
      });
      setNotification({
        title: 'Error',
        type: 'error',
        message: 'Failed to deserialize territory',
      });
    }
  }

  const handleUploadTerritory: SubmitHandler<MedScout.Territory> = async (
    data
  ) => {
    try {
      await uploadTerritories(data);

      setProcessedTemplate(null);
      setSelectedFile(null);
      reset();
    } catch (err) {
      setValidFile(false);
      log.exception('Failed to upload territory', {
        tags: {
          source: 'TerritoryUploadForm',
          error: err,
        },
      });
      setNotification({
        title: 'Error',
        type: 'error',
        message: err?.error_message || 'Failed to upload territory',
      });
    }
  };

  async function handlePreviewUpdate(smooth: number[]) {
    // Get data from react-hook-form
    const data = methods.getValues();

    if (!data.name) return;
    // get the preview of the territory
    try {
      const response = await request({
        url: `v1/accounts/territories/?preview=1&smooth=${smooth}`,
        method: 'POST',
        data: data,
      });
      setProcessedTemplate(response);
    } catch (err) {
      log.exception('Failed to get territory preview', {
        tags: {
          source: 'TerritoryUploadForm',
          error: err,
        },
      });

      setNotification({
        title: 'Error',
        type: 'error',
        message: err?.error_message || 'Failed to get territory preview',
      });
    }
  }

  useEffect(() => {
    if (!processedTemplate) return;
    const sources = ['states', 'counties', 'zip_codes'];
    const geometryIds = sources
      .reduce((acc, source) => {
        return [...acc, ...(processedTemplate?.source[source] || [])];
      }, [])
      .map(({ id }) => id);

    setValue('name', processedTemplate?.name || '');
    setValue('is_canonical', processedTemplate?.is_canonical || false);
    setValue('user', processedTemplate?.owner?.id || null);
    setValue('geometry', geometryIds);
  }, [processedTemplate, setValue]);

  return (
    <Card>
      <CardHeader title="Upload a Territory" />
      <CardContent>
        <FileSelector
          file={selectedFile}
          setFile={handlePreviewTerritory}
          isValid={validFile}
          isLoading={isDeserializing}
        />
        <Collapse in={processedTemplate?.polygons?.length > 0 || false}>
          {processedTemplate?.polygons?.length > 1 ? (
            <>
              <Box>
                <GoogleStaticMap
                  polygons={processedTemplate?.polygons || []}
                  id={processedTemplate?.id?.toString()}
                  size="400x220"
                />
              </Box>
              <Box sx={{ pt: '1rem' }}>
                <FormProvider {...methods}>
                  <Box
                    onSubmit={handleSubmit(handleUploadTerritory)}
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.5rem',
                    }}
                  >
                    <SmoothnessControl onSubmit={handlePreviewUpdate} />
                    <Box
                      sx={{
                        mt: 2,
                        display: 'flex',
                        alignItems: 'flex-end',
                        gap: 2,
                      }}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <ControlledInput
                          variant="outlined"
                          name="name"
                          label="Territory Name"
                        />
                      </Box>
                      <ControlledCheckbox
                        name="is_canonical"
                        label="Canonical"
                      />
                    </Box>
                    <CompanyUserSelector />
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isUploading}
                    >
                      Create
                    </LoadingButton>
                  </Box>
                </FormProvider>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                p: '1rem',
                mt: '1rem',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `1px solid ${red[300]}`,
              }}
            >
              <Typography level="title-md" color="danger">
                No polygons found in that territory
              </Typography>
            </Box>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default TerritoryUpload;
