import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import {
  getSearchQueryParamsForAPI,
  serializeQueryArray,
} from 'src/utils/url.helpers';

interface ReferralDashboardProps extends MedScout.Sortable {
  providerId: string;
  direction?: 'inbound' | 'outbound';
  mode?: 'treating' | 'sending' | 'receiving';
  enabledSearchIds?: string[];
  enabled?: boolean;
  dateRange?: {
    lte: string;
    gte: string;
  };
}

const useReferralDashboard = ({
  providerId,
  direction = 'inbound',
  mode = 'treating',
  enabledSearchIds = [],
  enabled = false,
  sort,
  order,
  sort_search,
  sort_value,
  dateRange,
}: ReferralDashboardProps) => {
  let queryParams = getSearchQueryParamsForAPI({
    direction,
    mode,
    sort,
    order,
    sort_search,
    sort_value,
    dateRange,
  });

  // determine if the combination of direction and mode is correct
  const isValidCombination =
    (direction === 'inbound' && mode === 'sending') ||
    (direction === 'outbound' && mode === 'receiving') ||
    mode === 'treating';

  const searchIdParams = enabledSearchIds
    .map(
      (id) => `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
    )
    .join('');

  queryParams += searchIdParams;

  return useQuery(
    [QUERY_KEYS.referrals, providerId, queryParams],
    () => {
      return request({
        url: `/v1/analytics/referrals/${providerId}/${queryParams}`,
        method: 'GET',
        data: {},
      });
    },
    {
      enabled: isValidCombination,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 60_000,
    }
  );
};

export default useReferralDashboard;
