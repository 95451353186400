import React, { useMemo, useCallback } from 'react';
import { useAuth } from 'src/context';
import { useGetReports } from 'src/hooks';

const useDirectReports = () => {
  const { user } = useAuth();

  const { data: reports } = useGetReports({
    companyId: user?.company?.id?.toString(),
    managerId: user?.id?.toString(),
  });

  const directReports = useMemo(() => {
    return reports?.results?.map((report) => report.id);
  }, [reports]);

  const checkReport = useCallback(
    (userId: number) => {
      return directReports?.includes(userId);
    },
    [directReports]
  );

  return {
    directReports,
    checkReport,
  };
};

export default useDirectReports;
