import React, { useState } from 'react';
import { Button, IconButton, CircularProgress } from '@mui/joy';
import { LockRounded } from '@mui/icons-material';
import { sendResetPasswordEmail } from 'src/hooks';
import { useLogging, useNotification, useSite } from 'src/context';

interface PasswordResetButtonProps {
  userId: string;
  variant?: 'regular' | 'compact' | 'icon';
}

const PasswordResetButton = ({
  userId,
  variant = 'regular',
}: PasswordResetButtonProps) => {
  const { state } = useSite();
  const { currentCompany } = state.companyAdmin;
  const log = useLogging();
  const { setNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const handleSendPasswordReset = async () => {
    setIsLoading(true);
    try {
      await sendResetPasswordEmail(currentCompany.id, userId);
      setNotification({
        title: 'Success',
        message: 'Password reset email sent',
        type: 'success',
      });
    } catch (err) {
      log.exception('Error sending password reset email', {
        tags: {
          userId,
          error: err,
        },
      });

      setNotification({
        title: 'Error',
        message: err?.error_message || 'Error sending password reset email',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  switch (variant) {
    case 'regular': {
      return (
        <Button
          loading={isLoading}
          size="sm"
          variant="outlined"
          color="primary"
          onClick={handleSendPasswordReset}
        >
          Send Password Reset
        </Button>
      );
    }

    case 'compact': {
      return (
        <IconButton
          size="sm"
          variant="soft"
          color="primary"
          onClick={handleSendPasswordReset}
        >
          {isLoading ? (
            <CircularProgress size="sm" color="neutral" />
          ) : (
            <LockRounded sx={{ fontSize: '1.1rem' }} />
          )}
        </IconButton>
      );
    }
    case 'icon': {
      return (
        <IconButton
          onClick={handleSendPasswordReset}
          sx={{
            width: '1.75rem',
            height: '1.75rem',
            minWidth: '1.75rem',
            minHeight: '1.75rem',
            maxWidth: '1.75rem',
            maxHeight: '1.75rem',
            backgroundColor: 'transparent',
            color: '#636B74',
            '&:hover': {
              color: 'var(--joy-palette-primary-500, #0B6BCB)',
              backgroundColor: 'transparent',
            },
          }}
        >
          {isLoading ? (
            <CircularProgress
              sx={{
                '--CircularProgress-size': '16px',
                '--CircularProgress-progressThickness': '1px',
                '--CircularProgress-trackThickness': '1px',
              }}
              color="neutral"
            />
          ) : (
            <LockRounded sx={{ fontSize: '1.1rem' }} />
          )}
        </IconButton>
      );
    }

    default:
      break;
  }
};

export default PasswordResetButton;
