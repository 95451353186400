import { useState } from 'react';
import {
  MenuItem,
  ListItemText,
  ListItemIcon,
  Button,
  Typography,
  Divider,
} from '@mui/material';
import { Link, OverlayMenu } from 'src/components';
import { useAuth } from 'src/context';
import { useRouter } from 'next/router';
import {
  faUserCircle,
  faLock,
  faChevronDown,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

/**
 * Account dropdown with user account settings and logout. Allows superusers to select a company context.
 *
 * @param {*}
 * @return {*}
 */
const AccountMenu = () => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(anchor);
  const { user } = useAuth();
  const accountName = [
    user?.first_name,
    user?.last_name,
    user?.company?.name ? '(' + user?.company?.name + ')' : '',
  ].join(' ');
  const router = useRouter();

  function handleOpen(e) {
    setAnchor(e.currentTarget);
  }

  function handleClose() {
    setAnchor(null);
  }

  return (
    <>
      <Button
        id="account-button"
        onClick={handleOpen}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-label="Account Button"
        sx={{
          minWidth: 'unset',
          pl: { xs: 0, lg: 2 },
          height: '100%',
          color: 'white',
        }}
        endIcon={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'inherit',
              gap: '0.5rem',
            }}
          >
            <Icon
              alignmentBaseline="middle"
              icon={faUserCircle}
              style={{ fontSize: '1.25em' }}
            />
            <Icon
              icon={faChevronDown}
              size="sm"
              style={{ fontSize: '0.5em' }}
            />
          </div>
        }
      >
        <Typography
          fontSize="0.75rem"
          sx={{ display: { xs: 'none', lg: 'inline' } }}
        >
          {accountName}
        </Typography>
      </Button>
      <OverlayMenu
        id="account-menu"
        anchorEl={anchor}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'account-button', dense: true }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {!user?.is_superuser && (
          <Typography
            sx={{
              fontSize: '0.9rem',
              fontWeight: 500,
              padding: '0 1rem 0.5rem',
              borderBottom: '1px solid #E0E0E0',
            }}
          >
            {user?.company?.name}
          </Typography>
        )}
        <MenuItem onClick={handleClose} sx={{ p: 0 }}>
          <ListItemText>
            <Link
              href="/settings"
              underline="none"
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                px: 2,
                py: 1,
              }}
            >
              Settings
            </Link>
          </ListItemText>
        </MenuItem>

        <Divider />
        <MenuItem
          onClick={() => router.push('/auth/logout?user_initiated=true')}
        >
          <ListItemIcon>
            <Icon icon={faLock} size="sm" />
          </ListItemIcon>
          <ListItemText>Log out</ListItemText>
        </MenuItem>
      </OverlayMenu>
    </>
  );
};

export default AccountMenu;
