import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Drawer } from 'src/components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { CreateActivityTypeItem } from '../CreateActivityTypeItem';
import { createActivityType } from 'src/api';
import { useGetActivityTypes } from 'src/hooks';
import { useNotification } from 'src/context';

interface CreateActivitiesDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateActivitiesDrawer = ({
  isOpen,
  onClose,
}: CreateActivitiesDrawerProps) => {
  const { setNotification } = useNotification();

  const lastItemRef = useRef<HTMLDivElement>(null);
  const [activityTypes, setActivityTypes] = useState<MedScout.ActivityType[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const { data: types, mutate } = useGetActivityTypes();

  useEffect(() => {
    setActivityTypes([
      {
        id: 1,
        label: 'Activity Type 1',
        icon: 'bolt',
        color: '000000',
        activity_count: 0,
      },
    ]);
  }, []);

  useEffect(() => {
    if (activityTypes?.length > 0) {
      lastItemRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activityTypes?.length]);

  const handleAddActivityType = () => {
    setActivityTypes([
      ...activityTypes,
      {
        id: activityTypes.length + 1,
        label: `Activity Type ${activityTypes.length + 1}`,
        icon: 'bolt',
        color: '000000',
        activity_count: 0,
      },
    ]);
  };

  const handleDeleteActivityType = (id: number) => {
    setActivityTypes(activityTypes.filter((type) => type.id !== id));
  };

  const handleSubmit = async () => {
    const activityTypesToCreate = activityTypes.map(
      ({ id, activity_count, ...rest }) => rest
    );

    try {
      setIsLoading(true);
      await createActivityType({ data: activityTypesToCreate });
      setNotification({
        title: 'Success',
        message: 'Activity types created successfully',
        type: 'success',
      });
      handleCancel();
    } catch (err) {
      console.error(err);
      setNotification({
        title: 'Error',
        message: err?.error_message || 'Failed to create activity types.',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
      mutate();
    }
  };

  const handleCancel = () => {
    setActivityTypes([
      {
        id: 1,
        label: 'Activity Type 1',
        icon: 'bolt',
        color: '000000',
        activity_count: 0,
      },
    ]);
    onClose();
  };

  const handleChangeActivityType = (updatedItem: MedScout.ActivityType) => {
    setActivityTypes(
      activityTypes.map((type) =>
        type.id === updatedItem.id ? updatedItem : type
      )
    );
  };

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} size="lg" hideBackdrop>
      <Drawer.Header className="flex-none border-b border-neutral-100 !bg-sky-700">
        <div className="flex items-center justify-between w-full">
          <h2 className="text-lg font-medium text-white">
            Create Activity Types
          </h2>
          <button
            onClick={handleAddActivityType}
            className="flex items-center gap-2 
            text-white text-xs px-4 py-2 font-semibold border border-neutral-200 rounded-lg
            hover:bg-sky-800
            transition-all duration-300"
          >
            <Icon icon={faPlus} size="sm" className="text-white" />
            Add
          </button>
        </div>
      </Drawer.Header>

      <Drawer.Content className="flex-1 min-h-0">
        <div className="flex justify-end py-2 px-4 flex-none"></div>

        <div className="flex flex-col px-4 py-2 overflow-y-auto">
          {activityTypes.length > 0 ? (
            activityTypes.map((activityType, index) => (
              <div
                ref={index === activityTypes.length - 1 ? lastItemRef : null}
                key={activityType.id}
              >
                <CreateActivityTypeItem
                  item={activityType}
                  onChange={handleChangeActivityType}
                  onSave={handleAddActivityType}
                  onDelete={handleDeleteActivityType}
                />
              </div>
            ))
          ) : (
            <button
              onClick={handleAddActivityType}
              className="flex flex-col justify-center items-center h-48 w-full
                text-neutral-500 hover:text-neutral-600 text-sm gap-2
                border-2 border-dashed border-neutral-200 
                hover:border-neutral-300 hover:bg-neutral-100
                rounded-lg cursor-pointer transition-all duration-300"
            >
              <span>Click here to add a new activity type</span>
            </button>
          )}
        </div>
      </Drawer.Content>

      <Drawer.Footer className="flex-none">
        <div className="flex justify-end gap-2">
          <button
            onClick={handleCancel}
            className="text-neutral-500 text-sm px-4 py-2 font-semibold border border-neutral-200 rounded-lg hover:bg-neutral-100 hover:border-neutral-200 transition-all duration-300"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="text-white text-sm px-4 py-2 font-semibold bg-sky-500 border border-sky-200 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed
            hover:bg-sky-600 hover:border-sky-200
            transition-all duration-300"
            disabled={activityTypes.length === 0 || isLoading}
          >
            {isLoading ? (
              <CircularProgress size="sm" color="primary" />
            ) : (
              'Save'
            )}
          </button>
        </div>
      </Drawer.Footer>
    </Drawer>
  );
};

export default CreateActivitiesDrawer;
