import * as Sentry from '@sentry/nextjs';
import { SearchBoxConstants } from './SearchBox.constants';
import { useLogging } from 'src/context';
import { Box, Checkbox, Typography } from '@mui/material';
import { grey, blue } from '@mui/material/colors';

const AutocompleteResults = ({
  searchResults,
  resultsVisible,
  setResultsVisible,
  searchTypeInfo,
  isComboVolume,
  context,
  filters,
  setMinMaxSelectEntity,
  setMinMaxSelectError,
  onSelectFilter,
  type,
}) => {
  const log = useLogging();

  return (
    <Box style={{ position: 'relative' }}>
      <Box
        sx={{
          display: resultsVisible ? 'block' : 'none',
          position: 'absolute',
          top: '0',
          left: '2rem',
          right: 0,
          width: 'auto',
          height: 'fit-content',
          maxHeight: '300px',
          zIndex: 1500,
          border: `1px solid ${grey[100]}`,
          borderTop: 'none',
          overflowY: 'auto',
        }}
      >
        {searchResults.map((result, idx) => {
          const { inputType } = searchTypeInfo;
          const resultContext = isComboVolume ? result.context_type : context;
          const { titlePath, valuePath, subTitlePath } = isComboVolume
            ? SearchBoxConstants.searchTypeMap[result.context_type]
                ?.resultInfoMap || {}
            : searchTypeInfo.resultInfoMap;
          if (!titlePath) {
            Sentry.captureMessage(
              'Suggestion returned without frontend support'
            );
            return null;
          }
          const title = result?.[titlePath] ?? '';
          const subTitle = result?.[subTitlePath] ?? '';
          const isMinMaxSelect =
            inputType === SearchBoxConstants.SEARCH_INPUT_TYPES.SELECT_MIN_MAX;
          const value = result?.[valuePath] ?? '';

          const isSelected = filters.some((filter) => {
            return (
              filter.context === result.context_type && filter.value === value
            );
          });

          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                padding: '0.5rem',
                backgroundColor: isSelected ? blue[200] : 'white',
                borderBottom: `1px solid ${isSelected ? grey[200] : grey[100]}`,
                zIndex: 1500,
                '&:hover': {
                  backgroundColor: isSelected ? blue[100] : grey[100],
                  cursor: 'pointer',
                },
                '&:active': {
                  backgroundColor: grey[200],
                },
              }}
              key={`${value}_${idx}`}
              onClick={() => {
                if (isMinMaxSelect) {
                  setResultsVisible(false);
                  setMinMaxSelectError('');
                  setMinMaxSelectEntity({
                    title,
                    subTitle,
                    id: value,
                    context,
                  });
                } else {
                  onSelectFilter({
                    title,
                    subTitle,
                    value,
                    context: resultContext,
                  });
                }
              }}
            >
              {!isMinMaxSelect && (
                <Checkbox
                  checked={isSelected}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '1.1rem',
                    },
                    height: '1.2rem',
                    width: '1.2rem',
                    cursor: 'pointer',
                  }}
                  onChange={(e) => {
                    log.event('filterDropdownCheckboxClicked', {
                      checked: e.currentTarget.checked,
                      title,
                      value,
                      context: resultContext,
                    });
                  }}
                  id={`search-box-${type}`}
                />
              )}

              <Box sx={{ alignSelf: 'center', ml: '0.3rem' }}>
                <Typography
                  aria-label={`Search Result ${title}`}
                  sx={{
                    fontSize: '0.85rem',
                    fontWeight: 600,
                    lineHeight: '1.2rem',
                    color: isSelected && blue[900],
                  }}
                >
                  {title}
                  {isComboVolume && result.context_type
                    ? ` (${result.context_type.toUpperCase()}${
                        result.context_type === 'cpt' ? '®' : ''
                      })`
                    : ''}
                </Typography>
                {result.category && (
                  <Typography
                    sx={{
                      fontSize: '0.75rem',
                      fontWeight: 600,
                      lineHeight: '1.1rem',
                      color: isSelected && blue[900],
                    }}
                  >
                    {result.category}
                  </Typography>
                )}
                {result.sub_categories?.length > 0 && (
                  <Typography
                    sx={{
                      fontSize: '0.75rem',
                      lineHeight: '1.1rem',
                      color: isSelected && blue[900],
                    }}
                  >
                    {result.sub_categories.join(', ')}
                  </Typography>
                )}
                {subTitle && (
                  <Typography
                    sx={{
                      fontSize: '0.75rem',
                      lineHeight: '1.1rem',
                      color: isSelected && blue[900],
                    }}
                  >
                    {subTitle}
                  </Typography>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default AutocompleteResults;
