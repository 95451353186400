import { Avatar } from '@mui/joy';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  ControlledJoyCheckbox,
  ControlledJoyInput,
  Button,
  Card,
  CardContent,
} from 'src/components';
import {
  DeactivateButton,
  DeactivateDialog,
} from 'src/components/Settings/Users';

import {
  useDashboard,
  useLogging,
  useNotification,
  useSite,
  useAuth,
} from 'src/context';
import { updateUser } from 'src/hooks';

const permissions = [
  { label: 'Is Manager', value: 'is_manager' },
  { label: 'Is Integration Admin', value: 'is_integration_admin' },
  { label: 'Is LIR Eligible', value: 'is_lir_eligible' },
  {
    label: 'View All Territories',
    value: 'is_able_to_view_all_territories',
  },
];

const UserCard = () => {
  const { user } = useAuth();
  const log = useLogging();
  const { setNotification } = useNotification();
  const { state, dispatch } = useSite();

  const { currentCompany, currentUser } = state.companyAdmin;

  const { showConfirmDeleteModal, setShowConfirmDeleteModal } = useDashboard();

  const methods = useForm({
    values: {
      first_name: currentUser?.first_name,
      last_name: currentUser?.last_name,
      username: currentUser?.username,
      is_manager: currentUser?.permissions?.is_manager,
      is_integration_admin: currentUser?.permissions?.is_integration_admin,
      is_lir_eligible: currentUser?.is_lir_eligible,
      is_able_to_view_all_territories:
        currentUser?.permissions?.is_able_to_view_all_territories,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = methods;

  const handleConfirmDeleteModalClose = () => {
    setShowConfirmDeleteModal({ show: false, company: null, user: null });
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const newUser = await updateUser(currentCompany.id, currentUser.id, data);
      if (newUser) {
        dispatch({
          type: 'SET_CURRENT_USER',
          payload: newUser,
        });
      }
      log.event('User updated', {
        source: 'UserProfile',
        data: data,
      });
      setNotification({
        title: 'Success',
        message: 'User updated successfully',
        type: 'success',
      });
    } catch (err) {
      log.exception('Error updating user', {
        tags: {
          source: 'UserProfile',
          error: err,
        },
      });

      setNotification({
        title: 'Error',
        message: err?.error_message || 'Failed to update user',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isMedScoutUser = user?.is_superuser;
  const isSupportUser = user?.permissions?.is_support_user;

  return (
    <FormProvider {...methods}>
      <Card className="relative">
        <CardContent className="p-6">
          <div className="flex flex-col gap-6">
            {/* Profile Header */}
            <div className="flex flex-col sm:flex-row gap-6">
              <div className="flex flex-col items-center gap-2">
                <Avatar
                  src={currentUser?.profilePicture}
                  alt="Profile"
                  className="w-28 h-28"
                />
                {(isMedScoutUser || isSupportUser) && (
                  <div className="text-sm text-neutral-500 pb-2">
                    User id: {currentUser.id}
                  </div>
                )}
              </div>
              {/* Name and Email Section */}
              <div className="flex-1 flex flex-col gap-2 max-w-xl">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="flex flex-col gap-1.5">
                    <label className="text-sm font-medium text-neutral-600">
                      First name
                    </label>
                    <ControlledJoyInput
                      name="first_name"
                      className="w-full"
                      data-testid="first-name-input"
                    />
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <label className="text-sm font-medium text-neutral-600">
                      Last name
                    </label>
                    <ControlledJoyInput
                      name="last_name"
                      className="w-full"
                      data-testid="last-name-input"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-1.5">
                  <label className="text-sm font-medium text-neutral-600">
                    Email
                  </label>
                  <ControlledJoyInput
                    name="username"
                    disabled
                    className="w-full"
                    data-testid="email-input"
                  />
                </div>
              </div>
            </div>

            {/* Permissions Section */}
            <div className="flex flex-col gap-2">
              <h3 className="text-sm font-medium text-neutral-600">
                User Permissions
              </h3>
              <div className="bg-neutral-50 rounded-lg divide-y divide-neutral-200 border border-neutral-200">
                {permissions?.map((permission, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between px-3 py-2 first:rounded-t-lg last:rounded-b-lg hover:bg-neutral-100 transition-colors"
                  >
                    <span className="text-sm text-neutral-700">
                      {permission.label}
                    </span>
                    <ControlledJoyCheckbox
                      name={permission.value}
                      size="sm"
                      data-testid={`permission-checkbox-${permission.value}`}
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Actions */}
            <div className="flex justify-between items-center border-t border-neutral-200 pt-4">
              <DeactivateButton selectedUser={currentUser} />
              <Button
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
                variant="solid"
                color="primary"
                disabled={!isDirty || !isValid}
                data-testid="update-button"
              >
                Update
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
      <DeactivateDialog
        open={showConfirmDeleteModal?.show || false}
        onClose={handleConfirmDeleteModalClose}
      />
    </FormProvider>
  );
};

export default UserCard;
