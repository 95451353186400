import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useLogging } from 'src/context';
import { Box } from '@mui/material';
import { useGetMappings } from 'src/hooks';
import { useIntegrations } from 'src/components/Settings/Integrations';
//TODO: Consolidate with TableColumn in src/components
import { TableColumn } from 'src/components/Settings/Integrations/components/TableColumn';
import { currentTableStyle } from 'src/styles/CurrentTableStyle';

const MappingTable = () => {
  const router = useRouter();
  const log = useLogging();
  const { currentIntegration, dispatch } = useIntegrations();
  const [sort, setSort] = useState({
    field: 'label',
    sort: 'asc',
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const { data, isLoading, isFetching } = useGetMappings({
    sort: sort.field,
    order: sort.sort,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    crm: currentIntegration?.toString(),
  });

  const baseColumns: GridColDef[] = [
    TableColumn.label('label', 'Label'),
    TableColumn.medscoutObjectType(
      'medscout_object_type',
      'MedScout Object Type'
    ),
    TableColumn.crmObjectType('crm_object_type', 'CRM Object Type'),
    TableColumn.enabled('enabled', 'Enabled'),
  ];

  useEffect(() => {
    log.event('Viewed Mappings', {
      integration: currentIntegration,
      page: router.pathname,
    });
  }, []);

  const loading = isLoading || isFetching;

  const onSortModelChange = (model) => {
    if (!model.length)
      return setSort({
        field: 'label',
        sort: 'asc',
      });
    const { field, sort } = model[0];
    setSort({
      field,
      sort,
    });
  };

  const onRowClick = (params) => {
    if (!params.row) return;
    dispatch({ type: 'SET_CURRENT_MAPPING', payload: params.row });
  };

  return (
    <Box sx={{ mb: 1, overflow: 'auto' }}>
      <DataGridPremium
        autoHeight
        sx={{
          ...currentTableStyle,
          '& .MuiDataGrid-cell': { cursor: 'pointer' },
          border: '1px solid #e0e0e0',
        }}
        loading={loading}
        rows={data?.results || []}
        rowCount={data?.count || 0}
        columns={baseColumns}
        disableColumnMenu
        disableMultipleRowSelection
        disableRowSelectionOnClick
        pagination
        pageSizeOptions={[10, 25, 50]}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        onRowClick={onRowClick}
      />
    </Box>
  );
};

export default MappingTable;
