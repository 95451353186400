import { Box, Typography } from '@mui/joy';
import { exists, formatPhoneNumber, hasContent } from 'src/utils';
import Linkify from 'react-linkify';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import InfoUnavailable from '../InfoUnavailable';
import { contentStyles } from '../styleConstants';
import { useFlags } from 'launchdarkly-react-client-sdk';

type DetailsDisplayProps = {
  items: any[];
  options?: { [key: string]: string };
  allDetails?: boolean;
};

const DetailsDisplay: FC<DetailsDisplayProps> = ({
  items,
  options,
  allDetails,
}) => {
  const { sameTabRouting, newPayerMix } = useFlags();

  const displayFields = ['npi', 'email', 'phone'];

  // Filter the items to only include allowed fields
  const filteredItems = items.filter((item) =>
    displayFields.includes(item.title?.toLowerCase())
  );

  const displayItems = allDetails ? items : filteredItems;

  return (
    <>
      {exists(displayItems) ? (
        displayItems.map((item, i) => {
          const isLast = i === displayItems.length - 1;
          const isPhone = item.title === 'Phone';
          const formattedValue = isPhone
            ? formatPhoneNumber(item.value)
            : item.value;

          return newPayerMix ? (
            <Box key={`${item.title}-${i}`} className="flex flex-col">
              {exists(options) ? (
                <Typography
                  level="body-sm"
                  className={`${
                    isLast ? 'pb-0' : 'pb-4'
                  } text-link font-medium `}
                >
                  <a
                    href={`${options.basePath}${item[options.idKey]}`}
                    target={sameTabRouting ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                    className="flex flex-row gap-2 items-center"
                  >
                    {item[options.displayKey]}{' '}
                    <Icon
                      icon={faExternalLinkAlt}
                      style={{
                        height: '1rem',
                        width: '1rem',
                      }}
                      color="#2391D9"
                    />
                  </a>
                </Typography>
              ) : (
                <>
                  <Typography level="body-sm" className="text-tertiary">
                    {item.title}
                  </Typography>
                  <Box>
                    {Array.isArray(formattedValue) &&
                    hasContent(formattedValue) ? (
                      formattedValue.map((value, index) => (
                        <Typography
                          className="font-medium text-sm text-primary"
                          key={index}
                        >
                          {value}
                        </Typography>
                      ))
                    ) : (
                      <Typography className="font-medium text-sm text-primary">
                        {formattedValue}
                      </Typography>
                    )}
                  </Box>
                </>
              )}
            </Box>
          ) : (
            <Box key={`${item.title}-${i}`} sx={contentStyles(i, allDetails)}>
              {exists(options) ? (
                <Typography
                  level="body-sm"
                  sx={{
                    color: '#021B9C',
                  }}
                >
                  <a
                    href={`${options.basePath}${item[options.idKey]}`}
                    target={sameTabRouting ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                  >
                    {item[options.displayKey]}{' '}
                    <Icon icon={faExternalLinkAlt} size="xs" />
                  </a>
                </Typography>
              ) : (
                <>
                  <Typography
                    level="body-sm"
                    sx={{ alignSelf: 'flex-start', marginRight: '.25rem' }}
                  >
                    {item.title}
                  </Typography>
                  <Typography level="body-sm" sx={{ color: '#3f4148' }}>
                    {isPhone && formattedValue ? (
                      <a href={`tel:${formattedValue}`}>{formattedValue}</a>
                    ) : (
                      <Linkify
                        componentDecorator={(href, text, key) => (
                          <a
                            href={href}
                            key={key}
                            target={sameTabRouting ? '_self' : '_blank'}
                            rel="noreferrer"
                          >
                            {text}
                          </a>
                        )}
                      >
                        {Array.isArray(formattedValue) &&
                        hasContent(formattedValue) ? (
                          formattedValue.map((value, index) => (
                            <Box sx={{ textAlign: 'right' }} key={index}>
                              {value}
                            </Box>
                          ))
                        ) : (
                          <Box sx={{ textAlign: 'right' }}>
                            {formattedValue}
                          </Box>
                        )}
                      </Linkify>
                    )}
                  </Typography>
                </>
              )}
            </Box>
          );
        })
      ) : (
        <InfoUnavailable />
      )}
    </>
  );
};

export default DetailsDisplay;
