import React from 'react';
import { IconButton } from '@mui/joy';
import { useMedScoutMap } from 'src/context';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faFire } from '@fortawesome/pro-solid-svg-icons';
import { useLogging } from 'src/context';

const HeatmapButton = ({ inGroup = false }) => {
  const log = useLogging();
  const { heatMap, setHeatMap } = useMedScoutMap();
  return (
    <IconButton
      id={`discovery-territory-heatmap-button`}
      variant="outlined"
      color="neutral"
      onClick={() => {
        setHeatMap(!heatMap);
        log.event('Heatmap', { enabled: !heatMap });
      }}
      sx={{
        backgroundColor: heatMap ? 'danger.500' : 'white',
        color: heatMap ? 'white' : 'inherit',
        ...(inGroup && {
          borderTopLeftRadius: 0, // Flat top-left corner
          borderBottomLeftRadius: 0, // Flat bottom-left corner
          borderTopRightRadius: 'sm', // Round top-right corner
          borderBottomRightRadius: 'sm', // Round bottom-right corner
        }),
      }}
    >
      <Icon icon={faFire} />
    </IconButton>
  );
};

export default HeatmapButton;
