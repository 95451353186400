import React from 'react';
import { Box } from '@mui/material';

const OptionLabelCell = ({ row }) => {
  return (
    <Box
      className="text-neutral-900"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {row.label}
    </Box>
  );
};

export default OptionLabelCell;
