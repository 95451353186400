import { Autocomplete, FormHelperText, TextField } from '@mui/material';
import { useGetCrmObjects } from 'src/hooks';
import { Controller, useFormContext } from 'react-hook-form';
import ControlLabel from 'src/components/ControlledFields/ControlLabel';
import CrmOptionItem from './CrmOptionItem';
import { useState, useMemo } from 'react';

interface ControlledCrmObjectSelectProps {
  name: string;
  label?: string;
  options?: any[];
  required?: boolean;
  rules?: any;
  placeholder?: string;
  loading?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  helperText?: string;
  hasTooltip?: boolean;
  tooltipText?: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
  writableOnly?: boolean;
  crmType: string;
  integration: string;
  showLabel?: boolean;
}

// This is also called Destination field
const ControlledCrmObjectSelect = ({
  ...props
}: ControlledCrmObjectSelectProps) => {
  const [activeItem, setActiveItem] = useState(null);

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const {
    data: crmObjects,
    isLoading,
    isFetching,
  } = useGetCrmObjects({
    crmType: props.crmType,
    crm: props.integration,
    writable_only: props?.writableOnly || false,
    isThirdParty: props.integration === 'third_party',
  });
  // sort options by required first and then alphabetically
  // All other options are sorted alphabetically
  const orderedOptions = useMemo(() => {
    if (!crmObjects) return [];
    return crmObjects.sort((a, b) => {
      if (a.required && !b.required) return -1;
      if (!a.required && b.required) return 1;
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  }, [crmObjects]);

  if (!props) return null;
  const errorMessage = props?.helperText || 'This field is required';
  const required = props?.required || props.rules?.required;
  const name = props?.name;
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: props.required ? errorMessage : false,
        ...props.rules,
      }}
      render={({ field }) => {
        return (
          <ControlLabel
            label={props?.label || ''}
            required={required}
            hasTooltip={props.hasTooltip}
            tooltipText={props.tooltipText}
            showLabel={props.showLabel}
          >
            <Autocomplete
              {...field}
              loading={isLoading || isFetching}
              size={props.size || 'medium'}
              options={orderedOptions || []}
              value={
                orderedOptions.find((option) => option.code === field.value) ||
                null
              }
              onChange={(event, value) => {
                field.onChange(value?.code || null);
                setActiveItem(value);
              }}
              isOptionEqualToValue={(option, value) => {
                return option?.code === value?.code;
              }}
              renderOption={(props, option) => (
                <CrmOptionItem
                  key={`territory-item-${option.code}`}
                  props={props}
                  option={option}
                  activeItem={activeItem || watch(name)}
                />
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={props.variant || 'standard'}
                  fullWidth
                  placeholder={props.placeholder || 'Select...'}
                  required={required}
                  error={!!errors[props.name]}
                />
              )}
            />
            <FormHelperText error sx={{ ml: 0 }}>
              {!!errors[props.name] && (errors[props.name]?.message as string)}
            </FormHelperText>
          </ControlLabel>
        );
      }}
    />
  );
};

export default ControlledCrmObjectSelect;
