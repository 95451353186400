import { Typography } from '@mui/joy';
import { formatPhoneNumber } from 'src/utils';

const PhoneCell = ({ row }) => {
  const formattedNumber = row.phone
    ? formatPhoneNumber(row.phone)
    : row.provider?.phone
    ? formatPhoneNumber(row.provider.phone)
    : '';

  if (!formattedNumber) return null;

  return <Typography level="body-sm">{formattedNumber}</Typography>;
};

export default PhoneCell;
