import { Box } from '@mui/material';
import React from 'react';
import { useProspectSearch, useLogging } from 'src/context';
import { faSignInAlt, faSignOutAlt } from '@fortawesome/pro-solid-svg-icons';
import { Toggle } from 'src/components';

const ReferringTreatingToggle = () => {
  const { prospectMode, setProspectMode } = useProspectSearch();
  const log = useLogging();
  const toggleOrientation = prospectMode === 'treating' ? 'left' : 'right';

  const nameMap = {
    treating: 'Treating',
    sending: 'Referring',
  };

  function handleToggleChange(value: 'treating' | 'sending') {
    setProspectMode(value);
    log.event(`Toggle Changed: ${nameMap[value]}`);
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Toggle
        showLabels
        leftLabel="Treating"
        rightLabel="Referring"
        leftIcon={faSignInAlt}
        rightIcon={faSignOutAlt}
        onLeft={() => handleToggleChange('treating')}
        onRight={() => handleToggleChange('sending')}
        status={toggleOrientation}
      />
    </Box>
  );
};

export default ReferringTreatingToggle;
