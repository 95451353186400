import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useGetManagers, updateUser } from 'src/hooks';
import { useLogging, useNotification, useSite } from 'src/context';
import { Button, Checkbox } from 'src/components';

const ManagersTabContent = () => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { state, dispatch } = useSite();
  const { currentCompany, currentUser } = state.companyAdmin;

  const { data: managers } = useGetManagers(currentCompany.id);

  const [isLoading, setIsLoading] = useState(false);

  const currentManagers = useMemo(() => {
    if (!currentUser?.managers) return [];
    return currentUser?.managers.map((manager) => manager.id);
  }, [currentUser]);

  const sortedManagers = useMemo(() => {
    if (!managers?.results) return;
    return managers?.results
      ?.sort((a, b) => {
        // order by first_name
        return a.first_name?.localeCompare(b.first_name);
      })
      ?.sort((a, b) => {
        // if the user is in the managers, move them to the top
        if (currentManagers?.includes(a.id)) {
          return -1;
        }
        if (currentManagers?.includes(b.id)) {
          return 1;
        }
      });
  }, [currentManagers, managers?.results]);

  const methods = useForm({
    values: {
      managers: currentManagers,
    },
  });

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { isDirty, isValid },
  } = methods;

  // Reset form when currentUser changes
  useEffect(() => {
    reset({ managers: currentManagers });
  }, [currentUser, reset]);

  const handleCancel = () => {
    reset();
  };

  const onSubmit = async (data) => {
    if (!data) return;
    setIsLoading(true);
    try {
      const updatedUser = await updateUser(
        currentCompany.id,
        String(currentUser?.id),
        data
      );

      if (updatedUser) {
        dispatch({
          type: 'SET_CURRENT_USER',
          payload: updatedUser,
        });
      }

      log.event('User managers updated', {
        source: 'ManagersTabContent',
        user: currentUser?.id,
        data: data,
      });

      setNotification({
        title: 'Success',
        message: 'Users managers updated',
        type: 'success',
      });
    } catch (err) {
      log.exception('Error updating user managers', {
        tags: {
          source: 'ManagersTabContent',
          error: err,
        },
      });
      setNotification({
        title: 'Error',
        message: err?.error_message || 'Error updating user managers',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
      reset();
    }
  };

  const handleChange = (e, field, user) => {
    const currentUserManagers = getValues('managers');
    field.onChange(
      e.target.checked
        ? [...currentUserManagers, user.id]
        : currentUserManagers.filter((report) => report !== user.id)
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2 pt-4">
          <div
            className="bg-neutral-50 rounded-lg divide-y divide-neutral-200 h-48 overflow-hidden overflow-y-auto border border-neutral-200"
            aria-label="managers"
          >
            {sortedManagers?.map((user, index) => (
              <div
                key={index}
                className="flex items-center justify-between px-3 py-2 first:rounded-t-lg last:rounded-b-lg hover:bg-neutral-100 transition-colors"
              >
                <span className="text-sm text-neutral-700">
                  {`${user.first_name} ${user.last_name}`}
                </span>
                <Controller
                  name="managers"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        size="sm"
                        color="primary"
                        data-testid={`manager-checkbox-${user.id}`}
                        checked={field.value?.includes(user.id)}
                        onChange={(e) => handleChange(e, field, user)}
                      />
                    );
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-between items-center border-t border-neutral-200 pt-4">
          <Button
            variant="outlined"
            color="neutral"
            onClick={handleCancel}
            disabled={!isDirty}
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            variant="solid"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid}
          >
            Update
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default ManagersTabContent;
