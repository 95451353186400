import { ReactNode, forwardRef, HTMLAttributes } from 'react';

interface BoxProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  component?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  className?: string;
  role?: string;
  sx?: React.CSSProperties;
}

const Box = forwardRef<HTMLDivElement, BoxProps>(
  (
    {
      children,
      component: Component = 'div',
      className = '',
      role,
      sx,
      ...props
    },
    ref
  ) => {
    return (
      <Component
        ref={ref}
        role={role}
        className={`
        box
        ${className}
      `}
        {...props}
        style={sx}
      >
        {children}
      </Component>
    );
  }
);

Box.displayName = 'Box';

export default Box;
