import React, { useRef } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faClose, faSearch } from '@fortawesome/pro-solid-svg-icons';

interface SearchProps {
  size?: 'sm' | 'md' | 'lg';
  placeholder?: string;
  variant?: 'outlined' | 'solid' | 'neutral';
  className?: string;
  sx?: React.CSSProperties;
  onSearch: (value: string) => void;
  slotProps?: {
    searchIcon?: React.ReactNode;
    clearIcon?: React.ReactNode;
    inputStyle?: React.CSSProperties;
    iconClassName?: string;
  };
}

const Search = ({
  onSearch,
  size = 'md',
  placeholder = 'Search...',
  variant = 'outlined',
  className,
  sx,
  slotProps,
}: SearchProps) => {
  const searchRef = useRef<HTMLInputElement>(null);

  const searchIconSizeMap = {
    sm: 'h-4',
    md: 'h-5',
    lg: 'h-6',
  };

  const sizeMap = {
    sm: 'h-8 px-2',
    md: 'h-10 px-3',
    lg: 'h-12 px-4',
  };

  const variantMap = {
    outlined: 'border border-gray-300',
    solid: 'bg-gray-100',
    neutral: 'bg-neutral-100',
  };

  const variantClass = variantMap[variant];
  const sizeClass = sizeMap[size];
  const classNames = `${variantClass} ${sizeClass} ${className}`;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onSearch(value);
  };

  const handleClear = () => {
    onSearch('');
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  return (
    <div
      className={`flex items-center gap-1 
          focus-within:border-neutral-500 rounded-lg transition-all 
          duration-300 ${classNames}`}
      style={sx}
    >
      {slotProps?.searchIcon || (
        <Icon
          icon={faSearch}
          className={`text-neutral-400 ${searchIconSizeMap[size]} ${slotProps?.iconClassName}`}
        />
      )}
      <input
        ref={searchRef}
        className={`border-none focus:outline-none w-full bg-transparent`}
        placeholder={placeholder}
        onChange={handleSearch}
        style={slotProps?.inputStyle}
      />
      {slotProps?.clearIcon || (
        <Icon
          icon={faClose}
          className={`text-neutral-400 hover:text-neutral-500 cursor-pointer ${
            slotProps?.iconClassName
          }
            ${searchRef.current?.value ? 'opacity-100' : 'opacity-0'}
          `}
          onClick={handleClear}
        />
      )}
    </div>
  );
};

export default Search;
