export const variants = {
  outlined: 'border border-gray-200',
  soft: 'bg-gray-100',
  solid: 'bg-gray-800 text-white',
};

export const sizes = {
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
};
