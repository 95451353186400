import React, { useRef, useEffect, createContext, useContext } from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  hideBackdrop?: boolean;
  showCloseButton?: boolean;
  className?: string;
}

// Create context to share onClose function
const DialogContext = createContext<{ onClose: () => void } | undefined>(
  undefined
);

const Dialog = ({
  isOpen,
  onClose,
  children,
  size = 'md',
  hideBackdrop = false,
  showCloseButton = false,
  className = '',
}: DialogProps) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  if (!isOpen) return null;

  const sizeClasses = {
    sm: 'w-full max-w-sm h-48', // 192px
    md: 'w-full max-w-md h-64', // 256px
    lg: 'w-full max-w-lg h-80', // 320px
    xl: 'w-full max-w-xl h-96', // 384px
  };

  return createPortal(
    <DialogContext.Provider value={{ onClose }}>
      <div
        className="fixed inset-0 z-50 flex items-center justify-center"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClose();
        }}
      >
        {!hideBackdrop && (
          <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" />
        )}
        <div
          ref={dialogRef}
          className={`relative w-full ${sizeClasses[size]} bg-white rounded-lg shadow-xl m-4 flex flex-col overflow-hidden scrollbar-gutter-stable ${className}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {showCloseButton && <Dialog.CloseButton />}
          {children}
        </div>
      </div>
    </DialogContext.Provider>,
    document.body
  );
};

const DialogCloseButton = ({ className = '' }: { className?: string }) => {
  const context = useContext(DialogContext);
  if (!context) throw new Error('CloseButton must be used within a Dialog');
  return (
    <button
      onClick={context.onClose}
      className={`absolute top-2 right-2 w-5 h-5 flex items-center font-bold
        justify-center text-neutral-500 hover:text-neutral-600 transition-colors ${className}`}
      aria-label="Close dialog"
    >
      <Icon icon={faTimes} className="w-4 h-4" />
    </button>
  );
};
DialogCloseButton.displayName = 'Dialog.CloseButton';
Dialog.CloseButton = DialogCloseButton;

const DialogHeader = ({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={`px-6 py-4 border-b border-neutral-200 flex items-center ${className}`}
  >
    <div className="flex-1">{children}</div>
  </div>
);
DialogHeader.displayName = 'Dialog.Header';
Dialog.Header = DialogHeader;

const DialogContent = ({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={`flex-1 overflow-y-auto min-h-0 ${className}`}>
    {children}
  </div>
);
DialogContent.displayName = 'Dialog.Content';
Dialog.Content = DialogContent;

const DialogActions = ({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={`px-6 py-4 border-t border-neutral-200 flex items-center justify-end gap-2 ${className}`}
  >
    {children}
  </div>
);
DialogActions.displayName = 'Dialog.Actions';
Dialog.Actions = DialogActions;

export default Dialog;
