import React from 'react';
import { EmailSettings } from './components';

const UserPreferences = () => {
  return (
    <div>
      <EmailSettings />
    </div>
  );
};

export default UserPreferences;
