import { Option, Select, Tooltip, Box, styled, Typography } from '@mui/joy';
import { useProspectSearch, useLogging } from 'src/context';

const SelectorLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.125rem' /* 18px */,
  paddingBottom: '0.5rem',
}));

const VolumeTypeToggle = ({ enabledSearchIds, table }) => {
  const log = useLogging();
  const { prospectVolumeType, setProspectVolumeType } = useProspectSearch();

  const isDisabled = enabledSearchIds.length === 0 && table !== 'discovery';
  return (
    <Box sx={{ width: '100%' }}>
      <SelectorLabel>Volume Type</SelectorLabel>
      <Tooltip
        title="Please select a saved search."
        arrow
        disableHoverListener={!isDisabled}
        disableFocusListener={!isDisabled}
        disableTouchListener={!isDisabled}
        placement="top-start"
      >
        <Select
          id={`${table}-volume-type-toggle`}
          defaultValue="claims"
          value={prospectVolumeType}
          onChange={(e, newValue) => {
            setProspectVolumeType(newValue as 'claims' | 'patients');
            log.event('Volume Type Changed', {
              value: newValue,
            });
          }}
          disabled={isDisabled}
          sx={{ width: '100%', minWidth: '140px', borderRadius: '0.5rem' }}
          slotProps={{
            listbox: {
              sx: {
                zIndex: 9999, // This would not show any other way on the result lists
              },
            },
          }}
          aria-label="Volume Type"
        >
          <Option
            value="claims"
            aria-label="claims"
            id={`${table}-volume-type-toggle-option-claims`}
          >
            Claims
          </Option>
          <Option
            value="patients"
            aria-label="patients"
            id={`${table}-volume-type-toggle-option-patients`}
          >
            Patients
          </Option>
        </Select>
      </Tooltip>
    </Box>
  );
};

export default VolumeTypeToggle;
