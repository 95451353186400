import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faFontAwesome } from '@fortawesome/pro-solid-svg-icons';
import { Tab, TabPanel, Tabs, TabSet } from 'src/components';

import { createPortal } from 'react-dom';
import { ColorComponent, IconComponent } from './components';

interface IconPickerProps {
  slots?: {
    buttonIcon?: React.ReactNode;
    anchor?: React.ReactElement;
  };
  onColorSelect: (color: string) => void;
  onIconSelect: (icon: any) => void;
  placement?: 'bottom-start' | 'bottom-end' | 'top-start' | 'top-end';
}

const IconPicker = ({
  slots,
  onColorSelect,
  onIconSelect,
  placement = 'bottom-end',
}: IconPickerProps) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const activeRef = slots?.anchor ? anchorRef.current : buttonRef.current;
      if (
        dropdownRef.current &&
        activeRef &&
        !dropdownRef.current.contains(event.target as Node) &&
        !activeRef.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, slots?.anchor]);

  useEffect(() => {
    const handleResize = () => {
      if (dropdownRef.current && open) {
        const position = getDropdownPosition();
        dropdownRef.current.style.top = `${position.top}px`;
        dropdownRef.current.style.left = `${position.left}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [open]);

  const getDropdownPosition = () => {
    const activeRef = slots?.anchor ? anchorRef.current : buttonRef.current;
    if (!activeRef) return { top: 0, left: 0 };
    const rect = activeRef.getBoundingClientRect();

    const positions = {
      'bottom-start': {
        top: rect.bottom + 4,
        left: rect.left,
      },
      'bottom-end': {
        top: rect.bottom + 4,
        left: rect.right - 256, // 256px is width of dropdown (w-64)
      },
      'top-start': {
        top: rect.top - (dropdownRef.current?.offsetHeight || 0) - 4,
        left: rect.left,
      },
      'top-end': {
        top: rect.top - (dropdownRef.current?.offsetHeight || 0) - 4,
        left: rect.right - 256,
      },
    };

    return positions[placement];
  };

  const handleIconSelect = (icon: any) => {
    onIconSelect(icon);
  };

  const handleColorSelect = (color: string) => {
    onColorSelect(color);
  };

  return (
    <div>
      {slots?.anchor ? (
        <div
          ref={anchorRef}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            setOpen(!open);
            slots.anchor.props?.onClick?.(e);
          }}
        >
          {slots.anchor}
        </div>
      ) : (
        <button
          ref={buttonRef}
          data-testid="icon-picker-button"
          className="w-8 h-8 bg-neutral-100 hover:bg-neutral-200 rounded-full
          transition-all duration-300"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
        >
          <Icon size="sm" icon={faFontAwesome} className="text-neutral-600" />
        </button>
      )}
      {open &&
        createPortal(
          <div
            ref={dropdownRef}
            className="fixed z-50 border border-neutral-200 w-64 h-1/3 bg-white rounded-lg shadow-lg flex flex-col"
            style={getDropdownPosition()}
            onClick={(e) => e.stopPropagation()}
          >
            <Tabs className="h-full flex flex-col">
              <TabSet id="icon-picker-tabs" className="px-2 pt-1">
                <Tab tabSetId="icon-picker-tabs" index={0}>
                  Icons
                </Tab>
                <Tab tabSetId="icon-picker-tabs" index={1}>
                  Colors
                </Tab>
              </TabSet>
              <div className="flex-1 min-h-0">
                <TabPanel
                  tabSetId="icon-picker-tabs"
                  index={0}
                  className="h-full"
                >
                  <IconComponent onSelect={handleIconSelect} />
                </TabPanel>
                <TabPanel
                  tabSetId="icon-picker-tabs"
                  index={1}
                  className="h-full"
                >
                  <ColorComponent onSelect={handleColorSelect} />
                </TabPanel>
              </div>
            </Tabs>
          </div>,
          document.body
        )}
    </div>
  );
};

export default IconPicker;
