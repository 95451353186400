'use client';

import React from 'react';
import { useAccordionDetails } from '../../context/AccordionProvider';

interface AccordionDetailsProps {
  children: React.ReactNode;
  index: number;
}

const AccordionDetails = ({ children, index }: AccordionDetailsProps) => {
  const { isOpen } = useAccordionDetails(index);

  return (
    <div
      aria-hidden={!isOpen}
      data-testid={`accordion-details-${index}`}
      className={`overflow-hidden transition-all duration-300 ease-in-out ${
        isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
      }`}
    >
      <div className="p-4">{children}</div>
    </div>
  );
};

export default AccordionDetails;
