'use client';

import React, { createContext, useContext, useReducer } from 'react';

interface AccordionState {
  openIndex: number[];
}

type AccordionAction = {
  type: 'TOGGLE';
  payload: {
    index: number;
  };
};

interface AccordionContextType {
  state: AccordionState;
  dispatch: React.Dispatch<AccordionAction>;
}

const initialState: AccordionState = {
  openIndex: [],
};

const AccordionContext = createContext<AccordionContextType | undefined>(
  undefined
);

const accordionReducer = (
  state: AccordionState,
  action: AccordionAction
): AccordionState => {
  switch (action.type) {
    case 'TOGGLE':
      return {
        ...state,
        openIndex: state.openIndex?.includes(action.payload.index)
          ? state.openIndex.filter((index) => index !== action.payload.index)
          : [...(state.openIndex || []), action.payload.index],
      };
  }
};

export const AccordionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(accordionReducer, initialState);

  return (
    <AccordionContext.Provider value={{ state, dispatch }}>
      {children}
    </AccordionContext.Provider>
  );
};

export const useAccordion = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('useAccordion must be used within an AccordionProvider');
  }
  return context;
};

export const useAccordionItem = (index: number) => {
  const { state, dispatch } = useAccordion();
  return {
    isOpen: state.openIndex?.includes(index) ?? false,
    onToggle: () => dispatch({ type: 'TOGGLE', payload: { index } }),
  };
};

export const useAccordionSummary = (index: number) => {
  const { state, dispatch } = useAccordion();
  return {
    isOpen: state.openIndex?.includes(index) ?? false,
    onToggle: () => dispatch({ type: 'TOGGLE', payload: { index } }),
  };
};

export const useAccordionDetails = (index: number) => {
  const { state } = useAccordion();
  return {
    isOpen: state.openIndex?.includes(index) ?? false,
  };
};
