import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { rootReducer, initialState } from 'src/context/reducers';
import { useAuth } from 'src/context';
import { useLocalStorage } from 'src/hooks';
import { SavedSearchActionTypes } from 'src/context/reducers/actionTypes';
import { getSavedSearchFolders } from 'src/api';
const SiteContext = createContext<{
  state: typeof initialState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const SiteProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();
  const [storedSiteState, setStoredSiteState] = useLocalStorage(
    'pageFilterBar',
    initialState.siteProvider
  );

  // Initialize the state by combining the persisted `site` state and transient `savedSearches`
  const [state, dispatch] = useReducer(rootReducer, {
    ...initialState,
    siteProvider: storedSiteState,
  });

  // set folders
  const [savedSearchFolders, setSavedSearchFolders] = useState(null);

  // Persist only the `site` part of the state to localStorage
  useEffect(() => {
    setStoredSiteState(state.siteProvider);
  }, [state.siteProvider]);

  useEffect(() => {
    if (!user) return;
    const fetchFolders = async () => {
      try {
        const response = await getSavedSearchFolders();
        setSavedSearchFolders(response);
      } catch (error) {
        console.error('Error fetching saved search folders', error);
      }
    };

    fetchFolders();
  }, [user]);

  useEffect(() => {
    if (savedSearchFolders) {
      dispatch({
        type: SavedSearchActionTypes.SET_FOLDERS,
        payload: savedSearchFolders?.results,
      });
    }
  }, [savedSearchFolders]);

  return (
    <SiteContext.Provider value={{ state, dispatch }}>
      {children}
    </SiteContext.Provider>
  );
};

export const useSite = () => {
  const context = useContext(SiteContext);
  if (!context) throw new Error('useSite must be used within a SiteProvider');
  return context;
};
