import { request } from 'src/api/request';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from './constants';
import { useNotification } from 'src/context';

interface DeleteProviderListItemProps {
  listId: string | number;
  itemId: string | number;
}

/**
 * @description Deletes the given provider list item
 * @param listId
 * @param itemId
 */

const useDeleteProviderListItem = () => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotification();

  return useMutation(
    ({ listId, itemId }: DeleteProviderListItemProps) => {
      return request({
        url: `/v1/provider-lists/lists/${listId}/items/${itemId}/`,
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.providerLists);
        queryClient.invalidateQueries(QUERY_KEYS.providerListItems);
      },
      onError: (err: any) => {
        setNotification({
          type: 'error',
          title: 'Error',
          message:
            err?.error_message ||
            'There was an issue removing your prospects, please try again later.',
        });
      },
    }
  );
};

export default useDeleteProviderListItem;
