import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateSetting } from 'src/hooks';
import { useLogging } from 'src/context';
import { FormControl, Typography, useTheme, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { DynamicInput, IconButton } from 'src/components';

interface SettingProps {
  setting: MedScout.Setting;
}

const Setting = ({ setting }: SettingProps) => {
  const theme = useTheme();
  const log = useLogging();
  const { mutateAsync: updateSetting } = useUpdateSetting();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, isDirty },
  } = useForm({
    defaultValues: {
      ...setting,
    },
  });

  useEffect(() => {
    if (!setting) return;
    reset({
      ...setting,
    });
  }, [setting]);

  async function onSubmit(data: MedScout.Setting) {
    if (!data) return;
    try {
      await updateSetting(data);

      log.event('Integration Setting Updated', {
        ...data,
      });
      reset({
        ...data,
      });
    } catch (err: any) {
      log.exception(`Error Updating an Integration Setting: ${err}`);
    }
  }

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        mt: 1,
        mb: 1,
        '&:not(:last-of-child)': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      <Grid
        item
        xs={12}
        sm={3}
        sx={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <Typography
          sx={{ fontSize: '0.85rem', fontWeight: 500 }}
          textTransform="uppercase"
        >
          {setting?.code || ''}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7}>
        <FormControl fullWidth>
          <DynamicInput
            name="value"
            type={setting?.value_type?.toString()?.toLowerCase()}
            disabled={setting?.read_only}
            placeholder="e.g. The value of the setting"
            variant="standard"
            control={control}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ display: 'flex', justifyContent: 'flex-end', gap: '0.75rem' }}
      >
        {!setting?.read_only && (
          <>
            <IconButton
              disabled={!isValid || !isDirty}
              size="small"
              onClick={() => reset(setting)}
              icon={faTimes}
              style={{ color: isDirty ? theme.palette.error.main : grey[500] }}
            />
            <IconButton
              disabled={!isValid || !isDirty}
              size="small"
              onClick={handleSubmit(onSubmit)}
              icon={faCheck}
              style={{
                color: isDirty ? theme.palette.success.main : grey[500],
              }}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Setting;
