import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers'; // TODO: This is a bit of a hack, we should probably move this to a more generic location

class ProspectService {
  static sortByVolume(a: string, b: string) {
    const aNum = Number(a === '<11' ? '1' : a || '0');
    const bNum = Number(b === '<11' ? '1' : b || '0');

    return aNum - bNum;
  }
  static getGroupedFilters(savedSearch) {
    if (!savedSearch || typeof savedSearch !== 'object') {
      return [];
    }

    const validSearchContext = {
      ex_pr: 'ex_pr',
      apc: 'apc',
      cpt: 'cpt',
      drg: 'drg',
      drug: 'drug',
      hcpcs: 'hcpcs',
      icd: 'icd',
      icdp: 'icdp',
      spec: 'spec',
      system: 'system',
      payments: 'payments',
      tags: 'tags',
      entity: 'name', // special cased from the API due to the name of saved search colliding
      site: 'site',
      payer: 'payer',
      lists: 'lists',
      gpo_affiliations: 'gpo_affiliations',
      crm_owners: 'crm_owners',
      crm_statuses: 'crm_statuses',
      fellowships: 'fellowships',
      residencies: 'residencies',
      medical_schools: 'medical_schools',
      year: 'year',
    };

    return Object.entries(savedSearch).reduce((result, [key, value]) => {
      const context = validSearchContext[key];
      if (context && Array.isArray(value)) {
        value.forEach((filter) => {
          result.push({ context, ...filter });
        });
      }

      return result;
    }, []);
  }

  static getQueryString(filters, type) {
    const groupedFiltersByContext = filters.reduce(
      (result, { context, value }) => {
        if (!result[context]) {
          result[context] = [value];
        } else {
          result[context].push(value);
        }

        return result;
      },
      {}
    );

    const sortedParams = Object.entries(groupedFiltersByContext).reduce(
      (result, [key, currentParams]) => {
        if (Array.isArray(currentParams)) {
          result[key] = Array.from(new Set(currentParams)).sort();
        } else {
          result[key] = currentParams;
        }

        return result;
      },
      {}
    );

    return getSearchQueryParamsForAPI({
      type,
      ex_pr: 0,
      ...sortedParams,
    });
  }
}

export default ProspectService;
