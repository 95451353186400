import React from 'react';
import { Box } from '@mui/joy';

interface GridContainerProps {
  singleColumn?: boolean;
  children: React.ReactNode;
  sx?: object;
}

const GridContainer = ({ singleColumn = false, children, sx }: GridContainerProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: singleColumn ? '1fr' : { xs: '1fr', md: '1fr 1fr' },
        gap: '1rem',
        width: '100%',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default GridContainer;
