import useSWRInfinite from 'swr/infinite';
import { getInfiniteUsers } from './usersApi';
import { useDashboard } from 'src/context';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface CompanyUsersResponse {
  next?: string | null;
  results: Array<{ id: number; [key: string]: any }>;
}

const useInfiniteCompanyUsers = (companyId: string, userId: string) => {
  const { userQueryParams } = useDashboard();

  const getKey = (pageIndex: number, previousPageData: any) => {
    if (!companyId) return null;
    if (previousPageData && !previousPageData.next) return null;
    // if the previousPageData.next is not null we need to get the url
    // from the /v1 on
    const url = previousPageData?.next
      ? previousPageData.next.split('api/')[1]
      : `/v1/accounts/companies/${companyId}/users/${getSearchQueryParamsForAPI(
          userQueryParams
        )}`;
    return url;
  };

  const shouldFetch = !!userId || !!companyId;

  const { data, size, setSize, isValidating, error } =
    useSWRInfinite<CompanyUsersResponse>(
      shouldFetch ? getKey : null,
      async (url: string) => {
        const response = await getInfiniteUsers(url);
        return response;
      }
    );

  const isEmpty = !data || data[0]?.results?.length === 0;
  const lastPage = data ? data[data.length - 1] : undefined;
  const hasNext = lastPage?.next;
  const isReachingEnd = isEmpty || !hasNext;

  const isLoadingInitialData = !data && !error;
  const isTryingToLoadNext =
    size > 0 && data && typeof data[size - 1] === 'undefined';

  const isLoadingMore =
    !isReachingEnd && (isLoadingInitialData || isTryingToLoadNext);

  // Then show a loading indicator only when truly loading more pages.
  const showLoading = isLoadingMore || (!data && !error);

  return {
    data,
    size,
    setSize,
    isLoadingMore,
    isReachingEnd,
    isValidating,
    showLoading,
  };
};

export default useInfiniteCompanyUsers;
