import { useState } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { useGetSearchCRM } from 'src/hooks';
import { useDebounce } from 'use-debounce';
import { Controller } from 'react-hook-form';

interface CRMLookupProps {
  crmType: string;
  crm: string;
  option: MedScout.UserInputOption;
  control: any;
  rules?: any;
}

const CRMLookup = ({
  crmType,
  crm,
  option,
  control,
  rules = [],
}: CRMLookupProps) => {
  const [search, setSearch] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [open, setOpen] = useState(false);

  const [searchTerm] = useDebounce(search, 500);
  const {
    data: searchCRM,
    isLoading,
    isFetching,
  } = useGetSearchCRM({
    crmType,
    q: searchTerm,
    option,
    crm,
  });

  const loading = isLoading || isFetching;
  const noOptionsText = (() => {
    if (loading && search) return 'Loading...';
    if (!loading && searchCRM?.length === 0) return 'No options found';
    if (!search) return 'Start typing to search';
    if (search.length < 3) return 'Please enter at least 3 characters';
    return 'Loading...';
  })();

  const handleInputChange = (event, newInputValue) => {
    setSearch(newInputValue);

    // Open dropdown if there's input
    if (newInputValue) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <Controller
      name={option.crm_api_code}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <Autocomplete
            freeSolo
            open={open} // Control the dropdown's open state
            onOpen={() => setOpen(true)} // Reopen dropdown on focus
            onClose={() => setOpen(false)} // Close dropdown on blur
            loading={loading}
            value={selectedOption ?? null}
            onChange={(e, value) => {
              if (!value) return;
              field.onChange(value?.value || '');
              const option = searchCRM?.find(
                (item) => item.value === value?.value
              );
              setSelectedOption(option ?? null);
              setSearch(''); // Clear search input after selecting an option
            }}
            inputValue={search}
            onInputChange={handleInputChange}
            id="crm-lookup"
            options={searchCRM || []}
            getOptionLabel={(option) => {
              return option.label;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.label}>
                  {option.label}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search..."
                variant="standard"
                aria-label="CRM Lookup"
                onFocus={() => {
                  // Reopen dropdown on focus if there's input
                  if (search) {
                    setOpen(true);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            noOptionsText={noOptionsText}
          />
        );
      }}
    />
  );
};

export default CRMLookup;
