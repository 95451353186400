import React from 'react';
import { createPortal } from 'react-dom';
import { Button, IconButton } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { useLogging, useSite } from 'src/context';
import { AddEditActivity } from '../AddEditActivity';

const NewActivityLog = ({ isSmall = false, smallHeader = false }) => {
  const log = useLogging();
  const { state, dispatch } = useSite();
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const portalRef = React.useRef<HTMLDivElement>(null);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(!isOpen);
    log.event('Activity Log Button Clicked');
  };

  const handleCancel = () => {
    if (state?.activity?.hasData) {
      if (
        !confirm('You have unsaved changes. Are you sure you want to close?')
      ) {
        return;
      }
    }
    setIsOpen(false);
    dispatch({ type: 'SET_HAS_DATA', payload: false });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // Only check for the Select menu container, not the options
      const isSelectMenu =
        target.closest('[role="listbox"]') ||
        target.closest('[role="presentation"]');

      if (isSelectMenu) {
        return;
      }

      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(target) &&
        portalRef.current &&
        !portalRef.current.contains(target)
      ) {
        handleCancel();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      {isSmall ? (
        <IconButton
          id="activity-log-button"
          variant="solid"
          onClick={toggleMenu}
          sx={{
            backgroundColor: '#2391D9',
          }}
        >
          <Icon icon={faBolt} />
        </IconButton>
      ) : (
        <Button
          id="activity-log-button"
          startDecorator={<Icon icon={faBolt} />}
          variant="solid"
          onClick={toggleMenu}
          sx={{
            backgroundColor: '#2391D9',
            whiteSpace: 'nowrap',
          }}
        >
          Log Activity
        </Button>
      )}
      {isOpen &&
        typeof window !== 'undefined' &&
        createPortal(
          <div
            ref={portalRef}
            className="fixed z-[99999]"
            style={{
              left: smallHeader
                ? (dropdownRef.current?.getBoundingClientRect().right ?? 0) -
                  386 // align right edges
                : dropdownRef.current?.getBoundingClientRect().left ?? 0, // original left alignment
              top: dropdownRef.current?.getBoundingClientRect().bottom ?? 0,
              width: '386px',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="bg-transparent rounded-lg shadow-lg mt-1">
              <AddEditActivity
                onClose={handleClose}
                onCancel={handleCancel}
                checkHasData
              />
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default NewActivityLog;
