import React, { useCallback, useRef } from 'react';
import {
  AdvancedMarker,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { Dropdown, Menu } from '@mui/joy';
import { useOnClickOutside } from 'src/hooks';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { MarkerCircle } from '../MarkerCircle';
import { useFlags } from 'launchdarkly-react-client-sdk';

type TreeClusterMarkerProps = {
  clusterId: number;
  onMarkerClick?: (marker: any, clusterId: number) => void;
  position: any;
  size: number;
  sizeAsText: string;
  isHovered?: boolean;
  onMouseOver?: (clusterId: number) => void;
  onMouseOut?: () => void;
  clusterLeaves?: any[];
  addToList?: (details: any) => void;
};

const FeaturesClusterMarker = ({
  position,
  size,
  sizeAsText,
  isHovered = false,
  onMarkerClick,
  onMouseOver,
  onMouseOut,
  clusterId,
  clusterLeaves,
  addToList,
}: TreeClusterMarkerProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { sameTabRouting } = useFlags();

  const handleClick = useCallback(
    () => onMarkerClick && onMarkerClick(marker!, clusterId),
    [onMarkerClick, marker, clusterId]
  );

  const handleHoverMenu = (event: React.MouseEvent<HTMLElement>, clusterId) => {
    onMouseOver(clusterId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onMouseOut();
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  return (
    <AdvancedMarker
      ref={markerRef}
      position={position}
      zIndex={size}
      onClick={handleClick}
      className="w-9 h-9 rounded-full cursor-pointer transition-opacity duration-800 animate-markerFade"
    >
      <div
        className="absolute w-full h-3 top-0 left-0 bg-transparent pointer-events-auto"
        onMouseOver={(e) => handleHoverMenu(e, clusterId)}
      />
      <MarkerCircle
        featureId={clusterId}
        sizeAsText={sizeAsText}
        isHovered={isHovered}
        onMouseOut={onMouseOut}
        handleHoverMenu={handleHoverMenu}
      />
      <Dropdown>
        <Menu
          ref={menuRef}
          placement="top"
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && isHovered && clusterLeaves?.length > 2}
          onMouseLeave={onMouseOut}
          className="p-2 flex flex-col shadow-sm flex-grow-0 max-h-72 overflow-auto"
        >
          {clusterLeaves?.map((leaf, index) => {
            return (
              <div
                key={index}
                className="py-2 border-b border-neutral-200 last:border-none"
              >
                <div className="flex flex-col">
                  <a
                    href={`/profiles/${leaf?.details?.content_type}/${leaf?.details?.provider_id}`}
                    target={sameTabRouting ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                    className="w-full no-underline"
                  >
                    <span className="text-sm text-sky-800 no-underline flex items-center gap-2 hover:text-sky-900">
                      {index ? `${index + 1}) ` : `${1}) `}
                      {leaf?.details?.name}
                      <Icon
                        icon={faExternalLinkAlt}
                        size="sm"
                        color="inherit"
                      />
                    </span>
                  </a>
                  <span className="text-xs">{leaf?.details?.specialty}</span>
                  <span className="text-xs">
                    {leaf?.details?.address_line_1}
                  </span>
                  <span className="text-xs">
                    {leaf?.details?.address_line_2}
                  </span>
                  <span className="text-xs">
                    {leaf?.details?.city}, {leaf?.details?.state}
                  </span>
                  <span
                    className="text-xs text-sky-800 no-underline w-full flex items-center gap-2 hover:cursor-pointer hover:text-sky-900"
                    onClick={(e) => {
                      e.stopPropagation();
                      addToList(leaf?.details);
                    }}
                  >
                    Add to List
                  </span>
                </div>
              </div>
            );
          })}
        </Menu>
      </Dropdown>
    </AdvancedMarker>
  );
};

export default FeaturesClusterMarker;
