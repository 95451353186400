import useSWR from 'swr';
import { getTopProviders } from 'src/api';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useNewProfile } from 'src/context';

const useGetTopProviders = ({
  providerId,
}: {
  providerId: string | number;
}) => {
  const { savedSearchQueryParams } = useNewProfile();

  const queryParams = getSearchQueryParamsForAPI({
    cpt: savedSearchQueryParams.cpt?.map((item) => item.value),
    hcpcs: savedSearchQueryParams.hcpcs?.map((item) => item.value),
    icd: savedSearchQueryParams.icd?.map((item) => item.value),
    drg: savedSearchQueryParams.drg?.map((item) => item.value),
    icdp: savedSearchQueryParams.icdp?.map((item) => item.value),
  });

  return useSWR(
    [QUERY_KEYS.GET_TOP_PROVIDERS, providerId, queryParams],
    () => getTopProviders({ providerId, queryParams }),
    {
      keepPreviousData: true,
    }
  );
};

export default useGetTopProviders;
