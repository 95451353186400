import React, { useEffect, useMemo, useRef } from 'react';
import { Box, Chip } from '@mui/joy';
import { useDebounce } from 'use-debounce';
import {
  useAuth,
  useLogging,
  useNewProfile,
  useProspectSearch,
  useSite,
  useUserSettings,
} from 'src/context';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { Section } from './components';
import { ClickAwayListener } from 'src/components';
import { sections } from './constants';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetSavedSearches } from 'src/hooks';
interface SavedSearchFilterProps {
  id?: string;
  context?: 'provider' | 'site' | 'payer';
  multiple?: boolean;
  startDecorator?: IconProp;
  endDecorator?: IconProp;
  maxVisible?: number;
}

interface SavedSearch {
  id: string;
  search_name: string;
  filters?: {
    cpt: string[];
    hcpcs: string[];
    icd: string[];
    drg: string[];
    icdp: string[];
  };
}

//TODO: Jon will refactor to simplify and optimize, because this is starting to be a mess.
// Entirely my doing.. I'm sorry.

const SavedSearchFilter = ({
  id,
  context,
  multiple = false,
  startDecorator,
  endDecorator,
  maxVisible = 1,
}: SavedSearchFilterProps) => {
  const isSavedSearchSet = useRef(false);

  const log = useLogging();
  const { user } = useAuth();
  const router = useRouter();
  const { newPayerMix } = useFlags();

  const { type: location } = router.query as { type: string };
  const { state, dispatch } = useSite();
  const { state: userSettingsState, dispatch: userSettingsDispatch } =
    useUserSettings();
  const { setSavedSearchQueryParams } = useNewProfile();
  const { prospectSearch } = useProspectSearch();

  const { data: savedSearches } = useGetSavedSearches();

  const selectedSavedSearch = useMemo(() => {
    let newSelectedSavedSearch;

    if (multiple) {
      const savedLocalSavedSearches =
        state?.siteProvider[location]?.selectedSearches;

      // if one of these no longer exists in saved searches we need to remove it.
      const newSelectedSavedSearch = savedLocalSavedSearches.filter((search) =>
        savedSearches?.some((savedSearch) => savedSearch.id === search.id)
      );

      return newSelectedSavedSearch;
    } else if (newPayerMix) {
      // For new payer mix, use user settings or prospect search if not previously set
      newSelectedSavedSearch =
        userSettingsState?.selectedSavedSearch ??
        (!isSavedSearchSet.current ? prospectSearch : null);

      // Mark that we've set the initial search
      isSavedSearchSet.current = true;
    } else {
      newSelectedSavedSearch =
        state?.savedSearch?.filterBySavedSearch?.selectedSavedSearch;
    }

    return newSelectedSavedSearch;
  }, [
    multiple,
    newPayerMix,
    state?.siteProvider,
    state?.savedSearch?.filterBySavedSearch?.selectedSavedSearch,
    location,
    savedSearches,
    userSettingsState?.selectedSavedSearch,
    prospectSearch,
  ]);

  const locationMap = {
    provider: 'LocationMix',
    site: 'TopProviders',
    payer: 'PayerMix',
  };

  const [showSearches, setShowSearches] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const isValidSavedSearch = useMemo(() => {
    if (!selectedSavedSearch || multiple) return true;

    const hasProcedureCodes =
      selectedSavedSearch?.filters?.cpt?.length > 0 ||
      selectedSavedSearch?.filters?.hcpcs?.length > 0 ||
      selectedSavedSearch?.filters?.icd?.length > 0 ||
      selectedSavedSearch?.filters?.drg?.length > 0 ||
      selectedSavedSearch?.filters?.icdp?.length > 0;

    return hasProcedureCodes;
  }, [multiple, selectedSavedSearch]);

  // group by type
  const groupedData = useMemo(() => {
    if (!state.savedSearch?.folders) return [];
    const folders = state.savedSearch.folders;
    const tempFolders = state.savedSearch?.tempFolders || [];

    const combined = [...tempFolders, ...folders];
    // group by type to remain in an array
    const grouped = combined?.reduce((acc, item) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }

      acc[item.type].push(item);
      return acc;
    }, {});

    return Object.entries(grouped).map(([type, results]) => ({
      type,
      results,
    }));
  }, [state?.savedSearch?.folders, state?.savedSearch?.tempFolders]);

  useEffect(() => {
    if (selectedSavedSearch && !multiple) {
      savedSearchHandler(selectedSavedSearch);
    }
  }, [selectedSavedSearch]);

  useEffect(() => {
    // if we select a saved search, clear the search value
    // this only applies to single select
    if (selectedSavedSearch && !multiple) {
      setSearchValue('');
    }
  }, [multiple, selectedSavedSearch]);

  const handleSearch = (e) => {
    // we need to clear the selected search if we are typing
    // this only applies to single select
    if (selectedSavedSearch && !multiple) {
      dispatch({
        type: 'SET_SELECTED_SAVED_SEARCH',
        payload: null,
      });
    }

    setSearchValue(e.target.value);
  };

  const toggleAccordion = (index) => {
    const newIndex = state?.savedSearch?.openAccordion === index ? null : index;
    dispatch({
      type: 'SET_ACCORDION',
      payload: newIndex,
    });

    // clear search value
    setSearchValue('');

    log.event('toggleSavedSearchAccordion', {
      location: 'SavedSearch',
      action: 'onSubmit',
      object: index === 0 ? 'PERSONAL' : 'COMPANY',
      user: user,
    });
  };

  const handleShowSearches = () => {
    setShowSearches((prev) => !prev);
  };

  const handleClose = () => {
    setShowSearches(false);
    setSearchValue('');
    setShowAll(false);
  };

  const handleClearSearch = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSearchValue('');

    // Don't clear selections in multiple mode
    if (multiple) {
      log.event('Saved Search Cleared', { source: locationMap[context] });
      return;
    }

    if (newPayerMix) {
      userSettingsDispatch({
        type: 'UPDATE_USER_SETTINGS',
        payload: { selectedSavedSearch: null },
      });
    } else {
      dispatch({
        type: 'SET_SELECTED_SAVED_SEARCH',
        payload: null,
      });
    }
    if (!multiple) {
      setSavedSearchQueryParams({
        cpt: [],
        hcpcs: [],
        icd: [],
        drg: [],
        icdp: [],
      });
    }

    log.event('Saved Search Cleared', { source: locationMap[context] });
  };

  const handleRemoveSearch = (
    e: React.MouseEvent,
    search: SavedSearch
  ): void => {
    e.stopPropagation();
    if (!selectedSavedSearch || !multiple) return;

    const newValues = selectedSavedSearch.filter(
      (selected) => selected.id !== search.id
    );
    dispatch({
      type: 'SET_SELECTED_SEARCHES',
      payload: { location, selectedSearches: newValues },
    });

    log.event('Saved Search Removed', {
      source: locationMap[context],
      searchId: search.id,
      searchName: search.search_name,
    });
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
    setShowSearches(true);
    setShowAll(true);
  };

  const handleSavedSearchChange = (
    e: React.MouseEvent,
    search: SavedSearch
  ): void => {
    e.stopPropagation();

    savedSearchHandler(search);

    if (!multiple) {
      setShowSearches(false);
    }
    log.event('Saved Search Clicked', {
      source: locationMap[context],
      search: search,
    });
  };

  const getFilterArrays = (search) => {
    if (!search)
      return {
        cpt: [],
        hcpcs: [],
        icd: [],
        drg: [],
        icdp: [],
      };
    return {
      cpt: search.filters?.cpt,
      hcpcs: search.filters?.hcpcs,
      icd: search.filters?.icd,
      drg: search.filters?.drg,
      icdp: search.filters?.icdp,
    };
  };

  const handleSingleSelect = (search) => {
    if (newPayerMix) {
      userSettingsDispatch({
        type: 'UPDATE_USER_SETTINGS',
        payload: { selectedSavedSearch: search },
      });
    } else {
      dispatch({ type: 'SET_SELECTED_SAVED_SEARCH', payload: search });
    }
    setShowSearches(false);
    setSavedSearchQueryParams(getFilterArrays(search));
  };

  const handleMultiSelect = (search) => {
    dispatch({
      type: 'SET_SELECTED_SEARCHES',
      payload: {
        location,
        selectedSearches: Array.from(new Set([...selectedSavedSearch, search])),
      },
    });
  };

  const savedSearchHandler = (search) => {
    const handler = multiple ? handleMultiSelect : handleSingleSelect;

    handler(search);
    log.event('Saved Search Clicked', {
      source: locationMap[context],
      search,
    });
  };

  const handleShowAll = (e) => {
    e.stopPropagation();
    setShowAll(!showAll);
  };

  // visible searches if multiple
  const { visible, remaining } = useMemo(() => {
    if (!selectedSavedSearch || !multiple)
      return { visible: [], remaining: [] };

    if (showAll) {
      return { visible: selectedSavedSearch, remaining: [] };
    }

    const visible = selectedSavedSearch?.slice(0, maxVisible);
    const remaining = selectedSavedSearch?.slice(maxVisible);

    return { visible, remaining };
  }, [maxVisible, multiple, selectedSavedSearch, showAll]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box
        id={id ?? 'saved-search-filter'}
        sx={{ flex: 1, position: 'relative' }}
        onClick={handleShowSearches}
      >
        <Box
          sx={{
            height: showAll ? 'auto' : '2.25rem',
            width: '100%',
            border: '1px solid #d2dbe4',
            background: '#fbfcfe',
            display: 'flex',
            alignItems: 'center',
            padding: '0 0.75rem',
            borderRadius: 'md',
            gap: '0.5rem',
            '&:focus-within': {
              outline: '1px solid #0077FF',
            },
            /* --joy-shadow-xs */
            boxShadow: '0px 1px 2px 0px rgba(21, 21, 21, 0.08)',
          }}
        >
          {startDecorator && (
            <Icon
              icon={startDecorator}
              color="#555E68"
              size="sm"
              style={{
                cursor: 'pointer',
              }}
            />
          )}
          {multiple && selectedSavedSearch?.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',

                maxWidth: showAll ? '100%' : 'calc(100% - 2rem)',
                gap: '0.25rem',
                whiteSpace: showAll ? 'none' : 'nowrap',
                flexWrap: showAll ? 'wrap' : 'nowrap',
                padding: showAll ? '0.25rem' : '0',
              }}
            >
              {multiple &&
                visible?.map((search, index) => (
                  <Chip
                    key={index}
                    variant="plain"
                    color="neutral"
                    sx={{
                      minWidth: 0,
                      backgroundColor: '#D6F6F1',
                      color: '#009976',
                      cursor: 'pointer',
                      '& .MuiChip-action': {
                        background: '#D6F6F1 !important',
                        '&:hover': {
                          background: '#CBE9E4 !important',
                        },
                      },
                    }}
                    onClick={(e) => handleRemoveSearch(e, search)}
                    endDecorator={<Icon icon={faClose} />}
                  >
                    {search.search_name}
                  </Chip>
                ))}
              {remaining?.length > 0 && (
                <Chip onClick={handleShowAll}>+{remaining?.length}</Chip>
              )}
            </Box>
          )}
          <input
            type="text"
            placeholder="Filter by a saved search..."
            value={searchValue || selectedSavedSearch?.search_name || ''}
            onChange={handleSearch}
            onClick={handleInputClick}
            onFocus={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            style={{
              flex: 1,
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '1.5rem',
              color: 'text.primary',
              border: 'none',
              outline: 'none',
              ...(!isValidSavedSearch && {
                color: 'text.disabled',
                opacity: 0.5,
              }),
              padding: '0.33rem',
              background: 'transparent',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent:
                selectedSavedSearch && !multiple ? 'space-between' : 'flex-end',
              gap: '0.25rem',
              width: '2rem',
            }}
          >
            {selectedSavedSearch && !multiple && (
              <Icon
                icon={faClose}
                color="#555E68"
                onClick={handleClearSearch}
                size="sm"
                style={{
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                }}
              />
            )}
            {endDecorator && (
              <Icon
                icon={endDecorator}
                color="#555E68"
                style={{
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                }}
              />
            )}
          </Box>
        </Box>
        {showSearches && (
          <Box
            sx={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              zIndex: 9999,
              marginTop: '0.25rem',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                minHeight: '10rem',
                maxHeight: '20rem',
                overflow: 'hidden',
                overflowY: 'auto',
                border: '1px solid #E0E0E0',
                borderRadius: 'sm',
                boxShadow: 'sm',
                background: 'white',
              }}
            >
              {sections.map((name, index) => {
                const section = groupedData.find(
                  (group) => group.type === name
                );
                return (
                  <Section
                    key={name}
                    multiple={multiple}
                    index={index}
                    context={context}
                    name={name}
                    section={section}
                    searchValue={debouncedSearchValue}
                    onToggle={toggleAccordion}
                    onChange={handleSavedSearchChange}
                  />
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SavedSearchFilter;
