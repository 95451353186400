import { Box } from '@mui/joy';
import React from 'react';
import { ControlledRadioCard } from 'src/components';

const options = {
  title: 'Option Type',
  description: 'Select a type of option to add.',
  content: [
    {
      type: 'radio',
      name: 'option_type',
      label: 'Autopopulate Option',
      description:
        'Autopopulate options are automatically populated based on the given properties fields and require no user interaction.',
      value: 'auto_populate',
      gridWidth: {
        xs: 12,
      },
    },
    {
      type: 'radio',
      name: 'option_type',
      label: 'Lookup Option',
      description:
        'This option looks up a value from the CRM based on a user supplied value. This is useful for dynamic lookups.',
      value: 'crm_lookup',
      gridWidth: {
        xs: 12,
      },
    },
    {
      type: 'radio',
      name: 'option_type',
      label: 'User Input Option',
      description:
        'This option is for fields that are not automatically populated or looked up from the CRM. This is useful for custom fields.',
      value: 'user_input',
      gridWidth: {
        xs: 12,
      },
    },
  ],
};

const OptionSelection = ({ isThirdParty }) => {
  const limitedOptions = options.content.filter(
    (option) => option.value !== 'crm_lookup'
  );
  const displayOptions = isThirdParty ? limitedOptions : options.content || [];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      {displayOptions.map((content, index) => (
        <ControlledRadioCard
          key={index}
          name={content.name}
          value={content.value}
          label={content.label}
          description={content.description}
        />
      ))}
    </Box>
  );
};

export default OptionSelection;
