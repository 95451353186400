import React from 'react';
import { Typography } from '@mui/joy';

const StringCell = ({ field, row }) => {
  return (
    <Typography level="body-sm" sx={{ paddingRight: '0.5rem' }}>
      {row[field]}
    </Typography>
  );
};

export default StringCell;
