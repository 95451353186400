import React from 'react';
import { variants, sizes } from '../constants';

interface CardProps {
  children: React.ReactNode;
  sx?: React.CSSProperties;
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  className?: string;
  id?: string;
}

const Card = ({
  children,
  variant = 'outlined',
  size = 'md',
  className = '',
  id,
  ...props
}: CardProps) => {
  return (
    <div
      id={id}
      className={`rounded-lg shadow-sm transition-transform ${variants[variant]} ${sizes[size]} ${className}`}
    >
      {children}
    </div>
  );
};

export default Card;
