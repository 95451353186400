'use client';

import React, {
  createContext,
  useContext,
  useMemo,
  ReactNode,
  useReducer,
} from 'react';

interface TabState {
  activeIndex: number;
  panels: string[];
  direction: 'horizontal' | 'vertical';
}

type TabsState = Record<string, TabState>;

type TabsAction =
  | {
      type: 'REGISTER_TABSET';
      payload: {
        id: string;
        defaultIndex: number;
        direction: 'horizontal' | 'vertical';
      };
    }
  | { type: 'UNREGISTER_TABSET'; payload: { id: string } }
  | { type: 'SET_ACTIVE_INDEX'; payload: { id: string; index: number } }
  | { type: 'REGISTER_PANEL'; payload: { id: string; panelId: string } }
  | { type: 'UNREGISTER_PANEL'; payload: { id: string; panelId: string } }
  | {
      type: 'SET_DIRECTION';
      payload: { id: string; direction: 'horizontal' | 'vertical' };
    };

interface TabsContextType {
  state: TabsState;
  dispatch: React.Dispatch<TabsAction>;
}

const tabsReducer = (state: TabsState, action: TabsAction): TabsState => {
  switch (action.type) {
    case 'REGISTER_TABSET':
      if (state[action.payload.id]) return state;
      return {
        ...state,
        [action.payload.id]: {
          activeIndex: action.payload.defaultIndex,
          panels: [],
          direction: action.payload.direction,
        },
      };
    case 'UNREGISTER_TABSET': {
      const newState = { ...state };
      delete newState[action.payload.id];
      return newState;
    }

    case 'SET_ACTIVE_INDEX':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          activeIndex: action.payload.index,
        },
      };

    case 'REGISTER_PANEL':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          panels: [
            ...(state[action.payload.id]?.panels || []),
            action.payload.panelId,
          ],
        },
      };

    case 'UNREGISTER_PANEL':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          panels: state[action.payload.id].panels.filter(
            (panelId) => panelId !== action.payload.panelId
          ),
        },
      };

    case 'SET_DIRECTION':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          direction: action.payload.direction,
        },
      };

    default:
      return state;
  }
};

const TabsContext = createContext<TabsContextType | undefined>(undefined);

export const TabsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(tabsReducer, {});
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};

export const useTabsContext = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error('Tabs must be used within a TabsProvider');
  }
  return context;
};

export const useTab = (tabSetId: string, index: number) => {
  const { state, dispatch } = useTabsContext();
  const tabState = state[tabSetId];

  if (!tabState) {
    return {
      isActive: false,
      onClick: () => null,
      direction: 'horizontal' as const,
    };
  }

  return {
    isActive: tabState.activeIndex === index,
    onClick: () =>
      dispatch({
        type: 'SET_ACTIVE_INDEX',
        payload: { id: tabSetId, index },
      }),
    direction: tabState.direction,
  };
};

export const useTabPanel = (tabSetId: string, index: number) => {
  const { state } = useTabsContext();
  const tabState = state[tabSetId];

  if (!tabState) {
    return { isActive: false, direction: 'horizontal' as const };
  }

  return {
    isActive: tabState.activeIndex === index,
    direction: tabState.direction,
  };
};
