import {
  ControlledAutocomplete,
  ControlledInput,
  ControlledSelect,
} from 'src/components';
import DateRangeForm from './DateRangeForm';

const inputKeyMap = {
  autocomplete: ControlledAutocomplete,
  selector: ControlledSelect,
  string: ControlledInput,
  integer: ControlledInput,
  date_range: DateRangeForm,
};

const SecondaryInput = ({ ...props }) => {
  if (!props.showSecondaryInput || !props.input) return null;

  const inputType = props.input?.input_type?.toString()?.toLowerCase();
  const InputComponent = inputKeyMap[inputType];

  return (
    <InputComponent
      {...props.attribute}
      name={`secondary_inputs.${props.input?.code}`}
      size="small"
      label={`${props.input?.label || 'Dynamic Input'}`}
      rules={{ required: !!props.showSecondaryInput }}
      type={inputType}
      variant="standard"
      options={props.input?.options || []}
      hasTooltip
      tooltipText={
        props.input?.description || props.input?.label || 'Dynamic Input'
      }
    />
  );
};

export default SecondaryInput;
