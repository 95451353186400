import React from 'react';
import { ListItem, Typography, useTheme } from '@mui/material';

const CrmOptionItem = ({ props, option, activeItem }) => {
  const theme = useTheme();

  const isOptionActive =
    option.label === activeItem?.label || option.label === activeItem;

  return (
    <ListItem
      {...props}
      key={option.label}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        fontSize: '0.9rem',
        fontWeight: isOptionActive ? 600 : 400,
        borderLeft: `4px ${
          isOptionActive ? theme.palette.highlight.main : 'white'
        } solid`,
        '&:hover': {
          borderLeft: `4px ${
            isOptionActive
              ? theme.palette.grey[400]
              : theme.palette.highlight.main
          } solid`,
        },
        color: theme.palette.grey[700],
        backgroundColor: 'white !important',
      }}
      aria-label={option.label}
    >
      <span>{option.label}</span>
      {option?.required && (
        <Typography
          variant="caption"
          sx={{ color: theme.palette.grey[500], fontWeight: 600 }}
        >
          (required)
        </Typography>
      )}
    </ListItem>
  );
};

export default CrmOptionItem;
