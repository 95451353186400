'use client';

import React from 'react';
import { useAccordionSummary } from '../../context/AccordionProvider';

interface AccordionSummaryProps {
  children: React.ReactNode;
  index: number;
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  className?: string;
  sx?: React.CSSProperties;
}

const AccordionSummary = ({
  children,
  index,
  startDecorator,
  endDecorator,
  className,
  sx,
}: AccordionSummaryProps) => {
  const { isOpen, onToggle } = useAccordionSummary(index);

  return (
    <div
      role="button"
      className={`w-full flex items-center p-3 gap-2 
                    text-left font-medium hover:bg-gray-200 
                    transition-all cursor-pointer ${className}`}
      style={sx}
      onClick={onToggle}
      aria-expanded={isOpen}
      aria-controls={`accordion-details-${index}`}
    >
      {startDecorator && startDecorator}
      {children}
      <span
        className={`transform transition-transform duration-300 ease-in-out ${
          isOpen ? '-rotate-90' : 'rotate-0'
        }`}
      >
        {endDecorator && endDecorator}
      </span>
    </div>
  );
};

export default AccordionSummary;
