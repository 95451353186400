import React from 'react';

interface ChipProps {
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  children?: React.ReactNode;
  variant?: 'solid' | 'soft' | 'outlined';
  color?: 'primary' | 'neutral' | 'danger' | 'success' | 'warning';
}

const Chip = ({
  size = 'md',
  className = '',
  startDecorator,
  endDecorator,
  children,
  variant = 'solid',
  color = 'neutral',
}: ChipProps) => {
  const chipSizeClasses = {
    sm: 'h-6 text-xs',
    md: 'h-8 text-sm',
    lg: 'h-10 text-base',
  };

  const variantClasses = {
    solid: {
      primary: 'bg-blue-600 text-white',
      neutral:
        'bg-neutral-800 text-white dark:bg-neutral-200 dark:text-neutral-900',
      danger: 'bg-red-600 text-white',
      success: 'bg-green-600 text-white',
      warning: 'bg-yellow-600 text-white',
    },
    soft: {
      primary: 'bg-blue-100 text-blue-900 dark:bg-blue-900 dark:text-blue-100',
      neutral:
        'bg-neutral-100 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-100',
      danger: 'bg-red-100 text-red-900 dark:bg-red-900 dark:text-red-100',
      success:
        'bg-green-100 text-green-900 dark:bg-green-900 dark:text-green-100',
      warning:
        'bg-yellow-100 text-yellow-900 dark:bg-yellow-900 dark:text-yellow-100',
    },
    outlined: {
      primary: 'border-2 border-blue-500 text-blue-600 dark:text-blue-400',
      neutral:
        'border-2 border-neutral-300 text-neutral-700 dark:border-neutral-700 dark:text-neutral-300',
      danger: 'border-2 border-red-500 text-red-600 dark:text-red-400',
      success: 'border-2 border-green-500 text-green-600 dark:text-green-400',
      warning:
        'border-2 border-yellow-500 text-yellow-600 dark:text-yellow-400',
    },
  };

  return (
    <div
      className={`inline-flex items-center justify-center gap-1.5 rounded-full w-fit
        ${chipSizeClasses[size]} ${variantClasses[variant][color]} ${className}`}
    >
      {startDecorator && (
        <span className="flex items-center justify-center">
          {startDecorator}
        </span>
      )}
      <span>{children}</span>
      {endDecorator && (
        <span className="flex items-center justify-center">{endDecorator}</span>
      )}
    </div>
  );
};

export default Chip;
