import {
  faArrowDown,
  faArrowUp,
  faMinus,
} from '@fortawesome/pro-regular-svg-icons';

export const getComparisonDetails = (current: number, stateAvg: number) => {
  const isGreaterThanStateAvg = current > stateAvg;
  const isEqualToStateAvg = current === stateAvg;

  return {
    amountVersusStateAvg: Math.round(Math.abs(current - stateAvg)),
    diffArrowIcon: isGreaterThanStateAvg
      ? faArrowUp
      : isEqualToStateAvg
      ? faMinus
      : faArrowDown,
    diffArrowColor: isGreaterThanStateAvg
      ? 'text-green-500'
      : isEqualToStateAvg
      ? 'text-neutral-500'
      : 'text-red-500',
  };
};
