import React, { useMemo, useState } from 'react';
import { Search } from 'src/components';
import { COMMON_ICONS } from '../../constants';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
// All icons for search
const ALL_ICONS = Object.entries(fas).map(([prefix, icon], index) => ({
  id: `${prefix}-${index}`,
  name: icon.iconName,
  icon: icon,
}));

const IconComponent = ({ onSelect }: { onSelect: (icon: any) => void }) => {
  const [search, setSearch] = useState('');
  const [showAll, setShowAll] = useState(false);

  const filteredIcons = useMemo(() => {
    // Start with common icons
    let iconList = [...COMMON_ICONS];

    // Add all other icons if showAll is true
    if (showAll) {
      const commonIconIds = new Set(COMMON_ICONS.map((i) => i.icon.iconName));
      const remainingIcons = ALL_ICONS.filter(
        (icon) => !commonIconIds.has(icon.name)
      );
      iconList = [...iconList, ...remainingIcons];
    }

    // Apply search filter if needed
    if (search) {
      const searchLower = search.toLowerCase();
      return iconList.filter((icon) =>
        icon.name.toLowerCase().includes(searchLower)
      );
    }

    return iconList;
  }, [search, showAll]);

  const handleIconSelect = (icon: any) => {
    onSelect(icon);
  };

  return (
    <div className="h-full flex flex-col">
      <div className="p-2">
        <Search onSearch={setSearch} placeholder="Search icons..." />
      </div>
      <div className="overflow-y-auto p-2">
        <div className="grid grid-cols-6 gap-2">
          {filteredIcons.map((icon) => (
            <button
              key={icon.id}
              data-testid={`icon-picker-icon-${icon.name}`}
              className="w-full aspect-square p-2 rounded-full hover:bg-neutral-100 flex items-center justify-center border border-neutral-200"
              title={icon.name}
              onClick={(e) => {
                e.stopPropagation();
                handleIconSelect(icon);
              }}
            >
              <Icon size="sm" icon={icon.icon} />
            </button>
          ))}
        </div>
      </div>
      <div className="p-2 mt-auto border-t border-neutral-200">
        <button
          className="w-full text-xs text-neutral-500 hover:text-neutral-700"
          onClick={(e) => {
            e.stopPropagation();
            setShowAll(!showAll);
          }}
        >
          {showAll ? 'Show Common Icons Only' : 'Show All Icons'}
        </button>
      </div>
    </div>
  );
};

export default IconComponent;
