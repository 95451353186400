import { MapStateType, ReducerAction } from '../map';

type initialMapStateType = {
  createNewTerritoryMode: boolean;
  drawingManagerDrawMode: string;
  editingTerritory: MedScout.Territory;
  selectedTerritories: MedScout.Territory[];
  existingTerritories: MedScout.Territory[];
  tempSelectedStates: string[];
  tempSelectedCounties: string[];
  tempSelectedZipCodes: string[];
  tempTerritoryCoordinates: string[];
  clearOverlay: boolean;
  boundariesLoading?: boolean;
  territoriesLoading?: boolean;
  data: any[];
  cachedRegions: Record<string, any[]>;
  isLoading: boolean;
  error: string | null;
};

export const initialMapState: initialMapStateType = {
  createNewTerritoryMode: false,
  drawingManagerDrawMode: 'polygon',
  editingTerritory: null,
  selectedTerritories: [],
  existingTerritories: [],
  tempSelectedStates: [],
  tempSelectedCounties: [],
  tempSelectedZipCodes: [],
  tempTerritoryCoordinates: [],
  clearOverlay: false,
  data: [],
  cachedRegions: {},
  isLoading: false,
  error: null,
};

export const mapReducer = (state: initialMapStateType, action: any) => {
  switch (action.type) {
    case 'SET_SELECT_TERRITORIES': {
      if (action.payload.preventEditingAndCreationReset)
        return {
          ...state,
          selectedTerritories: action.payload.territories,
        };

      return {
        ...state,
        selectedTerritories: action.payload.territories,
      };
    }
    case 'SET_EXISTING_TERRITORIES': {
      return {
        ...state,
        existingTerritories: action.payload,
      };
    }
    case 'ADD_TEMP_TERRITORY_COORDINATE': {
      return {
        ...state,
        tempTerritoryCoordinates: [...action.payload],
      };
    }
    case 'REMOVE_TEMP_TERRITORY_COORDINATE': {
      return {
        ...state,
        tempTerritoryCoordinates: state.tempTerritoryCoordinates.filter(
          (coordinate) => coordinate !== action.payload
        ),
      };
    }
    case 'ADD_TEMP_SELECTED_STATE': {
      const uniqueStates = new Set(state.tempSelectedStates);
      action.payload.forEach((state: string) => uniqueStates.add(state));
      return {
        ...state,
        tempSelectedStates: [...Array.from(uniqueStates)],
      };
    }
    case 'REMOVE_TEMP_SELECTED_STATE': {
      let copyState = [...state.tempSelectedStates];
      if (action.payload.removeAll) {
        copyState = [];
      } else {
        action.payload.items.forEach((state: string) => {
          const index = copyState.indexOf(state);
          if (index > -1) copyState.splice(index, 1);
        });
      }
      return {
        ...state,
        tempSelectedStates: [...copyState],
      };
    }
    case 'ADD_TEMP_SELECTED_COUNTY': {
      const uniqueCounties = new Set(state.tempSelectedCounties);
      action.payload.forEach((county: string) => uniqueCounties.add(county));
      return {
        ...state,
        tempSelectedCounties: [...Array.from(uniqueCounties)],
      };
    }
    case 'REMOVE_TEMP_SELECTED_COUNTY': {
      let copyState = [...state.tempSelectedCounties];
      if (action.payload.removeAll) {
        copyState = [];
      } else {
        action.payload.items.forEach((county: string) => {
          const index = copyState.indexOf(county);
          if (index > -1) copyState.splice(index, 1);
        });
      }
      return {
        ...state,
        tempSelectedCounties: [...copyState],
      };
    }
    case 'ADD_TEMP_SELECTED_ZIP_CODE': {
      const uniqueZips = new Set(state.tempSelectedZipCodes);
      action.payload.forEach((zip: string) => uniqueZips.add(zip));
      return {
        ...state,
        tempSelectedZipCodes: [...Array.from(uniqueZips)],
      };
    }
    case 'REMOVE_TEMP_SELECTED_ZIP_CODE': {
      let copyZips = [...state.tempSelectedZipCodes];

      if (action.payload.removeAll) {
        copyZips = [];
      } else {
        action.payload.items.forEach((zip: string) => {
          const index = copyZips.indexOf(zip);
          if (index > -1) copyZips.splice(index, 1);
        });
      }

      return {
        ...state,
        tempSelectedZipCodes: [...copyZips],
      };
    }
    case 'TOGGLE_EDIT_TERRITORY_MODE': {
      return {
        ...state,
        createNewTerritoryMode: action.payload,
        existingTerritories: [],
      };
    }
    case 'EDIT_TERRITORY': {
      const coordinatesClone = [...(action.payload?.coordinates ?? [])];

      const coordinates =
        action.payload?.geometry_type === 'Polygon'
          ? [coordinatesClone]
          : action.payload?.geometry_type === 'MultiPolygon'
          ? coordinatesClone
          : [];

      return {
        ...state,
        editingTerritory: action.payload,
        tempTerritoryCoordinates: coordinates,
        tempSelectedCounties: [],
        tempSelectedZipCodes: [],
        createNewTerritoryMode: false,
        existingTerritories: [],
      };
    }
    case 'SET_DRAWING_MANAGER_DRAW_MODE': {
      return {
        ...state,
        drawingManagerDrawMode: action.payload,
      };
    }
    case 'CLEAR_OVERLAY': {
      return {
        ...state,
        clearOverlay: action.payload,
      };
    }
    case 'BOUNDARIES_LOADING': {
      return {
        ...state,
        boundariesLoading: action.payload,
      };
    }
    case 'TERRITORIES_LOADING': {
      return {
        ...state,
        territoriesLoading: action.payload,
      };
    }
    case 'SET_MAP_DATA': {
      return {
        ...state,
        data: action.payload,
      };
    }
    case 'SET_CACHE_REGION': {
      return {
        ...state,
        cachedRegions: {
          ...state.cachedRegions,
          [action.payload.region]: action.payload.data,
        },
      };
    }
    case 'SET_MAP_LOADING': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'SET_MAP_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
