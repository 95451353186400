import React, { useState } from 'react';
import { Dialog, Button } from 'src/components';
import { useSite, useAuth } from 'src/context';
//TODO: move this to the api folder
import {
  deleteCompany,
  useGetAllCompanies,
  useUserImpersonation,
} from 'src/hooks';
import { useLogging, useNotification } from 'src/context';

interface DeactivateCompanyDialogProps {
  open: boolean;
  onClose: () => void;
}

const DeactivateCompanyDialog = ({
  open,
  onClose,
}: DeactivateCompanyDialogProps) => {
  const { user } = useAuth();
  const log = useLogging();
  const { setNotification } = useNotification();
  const { stopImpersonating } = useUserImpersonation();

  const { state, dispatch } = useSite();
  const { currentCompany } = state.companyAdmin;
  const { mutate } = useGetAllCompanies();
  const isSupportUser = user?.permissions?.is_support_user;

  const [isLoading, setIsLoading] = useState(false);

  const handleDeactivate = async () => {
    try {
      setIsLoading(true);
      await deleteCompany(currentCompany?.id);
      dispatch({
        type: 'SET_CURRENT_COMPANY',
        payload: null,
      });
      mutate();
      setNotification({
        title: 'Success',
        message: 'Company deactivated successfully',
        type: 'success',
      });
      if (isSupportUser) {
        stopImpersonating();
      }
    } catch (err) {
      log.exception('Failed to deactivate company', err);
      setNotification({
        title: 'Error',
        message: err?.error_message || 'Failed to deactivate company',
        type: 'error',
      });
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  return (
    <Dialog isOpen={open} onClose={onClose} showCloseButton={true}>
      <Dialog.Header>
        <div className="text-lg font-medium text-neutral-600">
          Deactivate Company
        </div>
      </Dialog.Header>
      <Dialog.Content>
        <div className="flex flex-col gap-2 p-2 h-full w-full">
          <span className="font-medium text-neutral-500 p-2">
            Are you sure you want to deactivate{' '}
            <strong>{currentCompany?.name}</strong>?
          </span>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <div className="flex flex-row gap-2">
          <Button
            variant="outlined"
            color="neutral"
            onClick={onClose}
            data-testid="deactivate-company-dialog-cancel"
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            variant="outlined"
            color="danger"
            onClick={handleDeactivate}
            data-testid="deactivate-company-dialog-deactivate"
          >
            Deactivate
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default DeactivateCompanyDialog;
