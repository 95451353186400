import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

export interface GetReferralExportProps {
  fileType: string;
  fileName: string;
  columns: string[];
  searchParams: {
    providerId: string;
    direction?: 'inbound' | 'outbound';
    mode?: 'treating' | 'sending' | 'receiving';
    enabledSearchIds?: string[];
    enabled?: boolean;
    dateRange?: {
      lte: string;
      gte: string;
    };
    page: number;
    pageSize: number;
    sort: any;
    order: any;
    sort_search: any;
    sort_value: string;
  };
}

export const getReferralExport = async ({
  searchParams,
  fileType,
  fileName,
  columns,
}: GetReferralExportProps) => {
  const {
    providerId,
    direction,
    dateRange,
    page,
    pageSize,
    sort,
    order,
    sort_search,
    sort_value,
    mode,
    enabledSearchIds,
  } = searchParams;
  let queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    sort_search,
    sort_value,
    page: page + 1,
    pageSize,
    mode,
    dateRange,
  });

  queryParams += `&direction=${direction ?? 'inbound'}`;

  const searchIdParams = enabledSearchIds
    .map(
      (id) => `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
    )
    .join('');

  queryParams += searchIdParams;

  const url = `https://api.medscout.io/api/v1/analytics/referrals/${providerId}/${queryParams}`;

  return request({
    url: '/v1/admin/export/',
    method: 'POST',
    data: {
      url,
      file_format: fileType,
      file_name: fileName,
      columns: columns,
    },
  });
};
