import {
  styled,
  Accordion,
  AccordionProps,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { useRouter } from 'next/router';
import { useLogging } from 'src/context';

const StyledAccordion = styled((props: AccordionProps & { active }) => (
  <Accordion square disableGutters elevation={0} {...props} />
))(({ theme, active }) => ({
  borderLeft: active ? `4px solid ${theme.palette.highlight.main}` : 'none',
  '& .MuiAccordionSummary-root': {
    paddingLeft: theme.spacing(1.5),
  },
  '&.Mui-expanded': {
    '& .MuiAccordionSummary-root': {
      paddingLeft: theme.spacing(1),
      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
}));

const StyledAccordionSummary = styled(
  AccordionSummary,
  {}
)(({ theme }) => ({
  backgroundColor: '#e3ebeb',
  color: theme.palette.primary.main,
  padding: theme.spacing(1.25, 2),
  fontWeight: 600,
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
  },
}));

const AccordionHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'justifyContent',
})<{ justifyContent?: string }>(({ justifyContent }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: justifyContent || 'flex-start',
  width: '100%',
  height: 'auto',
  boxSizing: 'border-box',
}));

const SideNavCategory: React.FC<{
  label;
  icon;
  iconColor?: string;
  path?: string;
  handleClick;
  hasOptions?: boolean;
  commands?: any[];
  expanded?: boolean;
}> = ({
  children,
  label,
  icon,
  iconColor,
  path,
  handleClick,
  hasOptions = false,
  commands,
  expanded = false,
}) => {
  const router = useRouter();
  const log = useLogging();
  const isActive = router.asPath === path;

  return (
    <StyledAccordion expanded={expanded} active={isActive ? 'true' : undefined}>
      <StyledAccordionSummary
        expandIcon={hasOptions && <Icon icon={faChevronDown} size="sm" />}
        onClick={() => {
          log.event('List Header Clicked', { segment: label });
          handleClick?.();
          path && router.push(path);
        }}
      >
        <AccordionHeader justifyContent="space-between">
          <Typography sx={{ fontWeight: 600, pr: 1, color: '#32383E' }}>
            {icon || ''} {label}
          </Typography>
          {commands}
        </AccordionHeader>
      </StyledAccordionSummary>
      {children}
    </StyledAccordion>
  );
};

export default SideNavCategory;
