import React from 'react';
import { IconProp, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { Box } from '@mui/joy';

library.add(fas);

interface ActivityIconProps {
  item: MedScout.ActivityType;
  height?: string;
  width?: string;
  iconSize?: string;
}

const ActivityIcon = ({ ...props }: ActivityIconProps) => {
  const {
    item,
    height = '32px', // specific to keep the icon size consistent
    width = '32px',
    iconSize = '0.875rem',
  } = props;

  library.add(fas);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: width,
        height: height,
        borderRadius: '100px', // Specific to make the icon round
        backgroundColor: 'white', // set the background to white so that we can mask the line
        color: item?.color ? `#${item.color}` : 'inherit',
        '::before': {
          content: '""',
          position: 'absolute',
          width: width, // Slightly larger than the icon area
          height: height, // Slightly larger than the icon area
          borderRadius: '100px', // Specific to make the icon round
          backgroundColor: item?.color ? `#${item.color}20` : '#00000020', // Set the color the background is supposed to be.
          zIndex: 2, // Ensure it masks the line
        },
      }}
    >
      <Icon
        icon={(item?.icon as IconProp) || 'bolt'}
        style={{
          width: iconSize,
          height: iconSize,
          color: item?.color ? `#${item.color}` : '#000000',
          position: 'relative',
          zIndex: 3, // Ensure it is above the mask
        }}
      />
    </Box>
  );
};

export default ActivityIcon;
