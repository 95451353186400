import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox } from 'src/components/PulseUI/Checkbox';

interface ControlledJoyCheckboxProps {
  name: string;
  rules?: any;
  errorText?: string;
  disabled?: boolean;
  label?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'outlined' | 'solid' | 'soft';
  color?: 'primary' | 'neutral' | 'danger' | 'success' | 'warning';
  className?: string;
}

const ControlledJoyCheckbox = ({ ...props }: ControlledJoyCheckboxProps) => {
  const { control } = useFormContext();

  if (!props) return null;

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field }) => (
        <Checkbox
          {...props}
          checked={field.value}
          onChange={(e) => {
            field.onChange(e.target.checked);
          }}
        />
      )}
    />
  );
};

export default ControlledJoyCheckbox;
