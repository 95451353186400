import React from 'react';
import { styled, Box, CircularProgress } from '@mui/joy';

/**
 * TableLoadingIndicator - renders a loading indicator in the center of the table
 * @param {string} height - height of the table
 * @returns {JSX.Element}
 */

interface TableLoadingIndicatorProps {
  top?: string | number;
}

const TableLoadingIndicator = ({
  top = 0,
  ...props
}: TableLoadingIndicatorProps) => {
  return (
    <Box
      data-testid="loading-indicator"
      sx={{
        position: 'absolute',
        top: top,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 2,
        width: '100%',
        background: 'rgba(97, 97, 97, 0.1)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <CircularProgress variant="soft" color="neutral" size="sm" />
      </Box>
    </Box>
  );
};

export default TableLoadingIndicator;
