'use client';

import React, { useEffect } from 'react';
import { useTabsContext } from '../../context/TabsProvider';

interface TabSetProps {
  children: React.ReactNode;
  id?: string;
  defaultIndex?: number;
  direction?: 'horizontal' | 'vertical';
  className?: string;
}

const TabSet = ({
  children,
  id: providedId,
  defaultIndex = 0,
  direction = 'horizontal',
  className,
}: TabSetProps) => {
  const generatedId = Math.random().toString(36).substring(2, 15);
  const id = providedId || generatedId;
  const { dispatch } = useTabsContext();

  useEffect(() => {
    dispatch({
      type: 'REGISTER_TABSET',
      payload: {
        id,
        defaultIndex,
        direction,
      },
    });
    return () => dispatch({ type: 'UNREGISTER_TABSET', payload: { id } });
  }, [id, defaultIndex, direction, dispatch]);

  const classNames = {
    horizontal: 'flex flex-row',
    vertical: 'flex flex-col',
  };

  return (
    <div
      data-tabset-id={id}
      className={`${classNames[direction]} ${className}`}
    >
      {children}
    </div>
  );
};

export default TabSet;
