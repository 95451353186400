import { faCaretDown, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { Box, Typography } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { SavedSearchFilter } from 'src/components/SavedSearchFilter';
import { faSliders } from '@fortawesome/pro-regular-svg-icons';

const ProfileSavedSearch = ({
  context,
}: {
  context: 'provider' | 'site' | 'payer';
}) => {
  return (
    <Box className="flex flex-col gap-2">
      <Box className="flex items-center gap-2">
        <Icon icon={faSliders} color="#005B38" />
        <Typography className="text-lg text-teal-900 font-bold">
          Filter
        </Typography>
      </Box>
      <Typography className="text-sm text-gray-600 pb-2 mt-[-4px]">
        Filter claims data on this page using one of your pre-saved searches.
      </Typography>
      <SavedSearchFilter
        id={`${context}-saved-search-filter`}
        startDecorator={faFilter}
        endDecorator={faCaretDown}
        context={context}
      />
    </Box>
  );
};

export default ProfileSavedSearch;
