import { request } from 'src/api/request';

interface GetHexUrlProps {
  reportId?: string;
  dashboard?: string;
  queryParams?: string;
}

export const getAllHexReports = ({ queryParams }: GetHexUrlProps) => {
  return request({
    url: `v1/analytics/hex/dashboards/${queryParams}`,
    method: 'get',
  });
};

export const getHexReportById = ({
  dashboard,
  queryParams,
}: GetHexUrlProps) => {
  return request({
    url: `v1/analytics/hex/dashboards/${dashboard}/embedded-link/${queryParams}`,
    method: 'GET',
  });
};

export const getHexUrl = ({ reportId, queryParams }: GetHexUrlProps) => {
  return request({
    url: `v1/admin/hex/embedded-link/${reportId}/${queryParams}`,
    method: 'GET',
  });
};
