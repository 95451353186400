import React, { useMemo, useState } from 'react';
import { Card } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faHandHoldingCircleDollar,
} from '@fortawesome/pro-solid-svg-icons';
import { CircularProgress } from 'src/components';
import {
  ReimbursementCard,
  AllSitesSelect,
  AllPayersSelect,
} from './components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetPayerMixReimbursement } from 'src/hooks';
import { useProfile, useUserSettings } from 'src/context';
import { BetaFlag } from 'src/components/BetaFlag';

const ProfileReimbursement = ({
  singleColumn = false,
}: {
  singleColumn?: boolean;
}) => {
  const { newPayerMix } = useFlags();
  const { details } = useProfile();
  const { state } = useUserSettings();

  const { data: reimbursementData, isLoading } = useGetPayerMixReimbursement({
    id: details?.provider_id,
    savedSearchId: state?.selectedSavedSearch?.id,
  });

  const [showAllCodes, setShowAllCodes] = useState(false);

  const { displayData, hasMoreCards, totalCards } = useMemo(() => {
    if (!reimbursementData?.results || !state?.selectedPayer) {
      return { displayData: [], hasMoreCards: false, totalCards: 0 };
    }

    const filteredData = reimbursementData?.results?.filter((item) => {
      const matchingPayer = item.payers.find(
        (payer) =>
          payer.payer_name?.toLowerCase() ===
            state?.selectedPayer?.payer_name?.toLowerCase() &&
          payer.place_of_service_code === state?.selectedSite
      );

      return matchingPayer !== undefined;
    });

    const displayCount = showAllCodes ? filteredData.length : 6;

    return {
      displayData: filteredData.slice(0, displayCount),
      hasMoreCards: filteredData.length > displayCount,
      totalCards: filteredData.length,
    };
  }, [
    reimbursementData,
    state?.selectedPayer,
    state?.selectedSite,
    showAllCodes,
  ]);

  const renderContent = () => {
    if (!reimbursementData?.results?.length) {
      return (
        <div className="flex items-center justify-center h-full min-h-48">
          {isLoading ? (
            <CircularProgress size="sm" color="neutral" />
          ) : (
            <p className="text-sm font-semibold text-neutral-500">
              No data available
            </p>
          )}
        </div>
      );
    }

    return (
      <>
        <div className="flex items-center gap-2 justify-between">
          <div className="flex items-center gap-2">
            <AllPayersSelect />
            <AllSitesSelect />
          </div>
        </div>
        <div
          className={`${
            singleColumn
              ? 'grid-rows-1 max-w-64'
              : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3'
          } grid gap-4 py-4`}
        >
          {displayData.map((item: any) => {
            const payer = item.payers.find(
              (payer: any) =>
                payer.payer_name === state?.selectedPayer?.payer_name &&
                payer.place_of_service_code === state?.selectedSite
            );
            if (!payer) return null;
            return (
              <ReimbursementCard
                key={item.code}
                item={{
                  code: item.code_name,
                  ...payer,
                }}
              />
            );
          })}
        </div>
        <div className="flex items-center gap-4">
          <p className="text-sm font-normal text-primary">
            {`${displayData.length} of ${totalCards} Codes`}
          </p>

          <button
            disabled={!hasMoreCards}
            onClick={() => setShowAllCodes(!showAllCodes)}
            className="text-sm font-normal text-sky-900 bg-white hover:text-sky-700 transition-colors disabled:opacity-50 disabled:hover:text-sky-900"
          >
            Show All Codes
          </button>
        </div>
      </>
    );
  };

  return (
    <Card
      className={`bg-white p-6 h-full ${
        newPayerMix ? 'border border-teal-100' : ''
      }`}
    >
      <div className="flex flex-col">
        <div className="flex items-center gap-2 pb-4">
          <Icon icon={faHandHoldingCircleDollar} size="lg" />
          <p className="body-lg font-bold text-primary">Reimbursement</p>
          <Icon
            icon={faCircle}
            size="sm"
            className="text-neutral-300 h-1.5 w-1.5"
          />
          <p className="text-sm font-normal text-neutral-500">2024</p>
          <BetaFlag />
        </div>

        {renderContent()}
      </div>
    </Card>
  );
};

export default ProfileReimbursement;
