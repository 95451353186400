import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  Dispatch,
  useCallback,
} from 'react';
import { useLocalStorage } from 'src/hooks';
import { initialUserSettingsState, userSettingsReducer } from '../reducers';

type UserSettingsContextValue = {
  state: typeof initialUserSettingsState;
  dispatch: Dispatch<any>;
};

const UserSettingsContext = createContext<UserSettingsContextValue>({
  state: initialUserSettingsState,
  dispatch: () => null,
});

export const UserSettingsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(
    userSettingsReducer,
    initialUserSettingsState
  );
  const [userSettings, setUserSettings] = useLocalStorage(
    'userSettings',
    initialUserSettingsState
  );

  const isInitialized = React.useRef(false);

  // hydrate only once on mount
  useEffect(() => {
    if (!isInitialized.current && userSettings) {
      dispatch({ type: 'UPDATE_USER_SETTINGS', payload: userSettings });
      isInitialized.current = true;
    }
  }, [userSettings]);

  const dispatchWithStorage = useCallback(
    (action: any) => {
      // Update the reducer state
      dispatch(action);

      // Update the storage with the payload
      setUserSettings((currentState) => ({
        ...currentState,
        ...action.payload,
      }));
    },
    [dispatch, setUserSettings]
  );

  return (
    <UserSettingsContext.Provider
      value={{ state, dispatch: dispatchWithStorage }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useUserSettings = () => {
  const context = useContext(UserSettingsContext);
  if (!context) {
    throw new Error(
      'useUserSettings must be used within a UserSettingsProvider'
    );
  }
  return context;
};
