import { useState } from 'react';
import { useRouter } from 'next/router';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { TableColumn } from 'src/components/Settings/Integrations/components/TableColumn';
import { useGetCRMSyncHistory } from 'src/hooks';
import { currentTableStyle } from 'src/styles/CurrentTableStyle';

const SyncHistoryTable = ({ direction }) => {
  const router = useRouter();
  const { id } = router.query as {
    id: string;
  };

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const [sort, setSort] = useState({
    field: 'started',
    order: 'desc',
  });

  const { data, isLoading } = useGetCRMSyncHistory({
    mappingId: id,
    direction,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    sort: sort.field,
    order: sort.order,
  });

  const tableColumns: GridColDef[] = [
    TableColumn.timestamp('started', 'Date of Sync'),
    TableColumn.syncStatus('status', 'Status'),
  ];

  const handleSortChange = (model) => {
    let newField = 'started';

    if (model.length) {
      const { field } = model[0];
      newField = field;
    }

    setSort({
      field: newField,
      order: model.length ? model[0].sort : 'desc',
    });
  };

  return (
    <DataGridPremium
      autoHeight
      loading={isLoading}
      rows={data?.results || []}
      rowCount={data?.count || 0}
      columns={tableColumns}
      sx={{
        ...currentTableStyle,
        border: '1px solid #e0e0e0',
      }}
      disableColumnMenu
      disableMultipleRowSelection
      disableRowSelectionOnClick
      pagination
      pageSizeOptions={[5, 10, 20]}
      paginationMode="client"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      // Sorting
      sortingMode="server"
      onSortModelChange={handleSortChange}
    />
  );
};

export default SyncHistoryTable;
