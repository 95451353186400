import React from 'react';
import { Tab, TabPanel, Tabs, TabSet } from 'src/components';
import { Admin } from 'src/components/Settings/Admin';
import { NewPreferences } from 'src/components/Settings/Preferences';
import { AddEditCompanyDialog, Users } from 'src/components/Settings/Users';
import { Integrations } from 'src/components/Settings/Integrations';
import { AddUserDialog } from 'src/components/Settings/Users/components/UserManagement/AddUserDialog';
import { useAuth, useDashboard } from 'src/context';
import {
  UsersBreadcrumbs,
  DeactivateDialog,
  DeactivateCompanyDialog,
} from 'src/components/Settings/Users/';
import { IntegrationsBreadcrumbs } from 'src/components/Settings/Integrations/components';

const Settings = () => {
  const { user } = useAuth();
  const {
    showAddEditUserModal,
    setShowAddEditUserModal,
    showConfirmDeleteModal,
    setShowConfirmDeleteModal,
    showAddEditCompanyModal,
    setShowAddEditCompanyModal,
    showDeactivateCompanyModal,
    setShowDeactivateCompanyModal,
  } = useDashboard();
  const isSupportUser = user?.permissions?.is_support_user;
  const isMedScoutUser = user?.is_superuser;
  const isIntegrationAdmin =
    user?.permissions?.is_integration_admin || user?.is_superuser;

  const tabOptions = [
    isMedScoutUser || isSupportUser
      ? {
          label: 'Users',
          component: <Users />,
          breadCumbs: <UsersBreadcrumbs />,
        }
      : null,
    { label: 'Preferences', component: <NewPreferences /> },
    isIntegrationAdmin || isMedScoutUser || isSupportUser
      ? {
          label: 'Integrations',
          component: <Integrations />,
          breadCumbs: <IntegrationsBreadcrumbs />,
        }
      : null,
    isMedScoutUser && !isSupportUser
      ? { label: 'Admin', component: <Admin /> }
      : null,
  ].filter(Boolean);

  const renderBreadcrumbs = () => {
    return tabOptions.map((tab, index) => {
      if (!tab.breadCumbs) return null;
      return (
        <TabPanel key={index} tabSetId="top-nav" index={index}>
          <div className="flex flex-col w-full bg-white py-2 shadow-sm">
            <div className="w-full max-w-7xl mx-auto px-2">
              {tab.breadCumbs}
            </div>
          </div>
        </TabPanel>
      );
    });
  };
  return (
    <Tabs>
      <div className="flex flex-col gap-2 h-full w-screen">
        <div className="sticky top-0 right-0 z-50 flex flex-col bg-white ">
          <div className="pt-4 w-full bg-neutral-050 border-b border-neutral-200">
            <div className="flex flex-col gap-4 px-4 w-full max-w-7xl mx-auto">
              <div className="flex flex-col justify-center gap-1">
                <h1 className="text-2xl font-bold">Settings</h1>
                <p className="text-sm text-gray-500">
                  Manage your account settings and preferences.
                </p>
              </div>
              <TabSet id="top-nav">
                {tabOptions.map((tab, index) => (
                  <Tab key={index} tabSetId="top-nav" index={index}>
                    {tab.label}
                  </Tab>
                ))}
              </TabSet>
            </div>
          </div>

          {renderBreadcrumbs()}
        </div>
        <div className="flex flex-col gap-4 px-4 py-2 h-screen w-full max-w-7xl mx-auto">
          {tabOptions.map((tab, index) => (
            <TabPanel key={index} tabSetId="top-nav" index={index}>
              {tab.component}
            </TabPanel>
          ))}
        </div>
      </div>
      <AddEditCompanyDialog
        open={showAddEditCompanyModal.show}
        onClose={() =>
          setShowAddEditCompanyModal({ show: false, company: null })
        }
      />
      <AddUserDialog
        open={showAddEditUserModal.show}
        onClose={() =>
          setShowAddEditUserModal({ show: false, company: null, user: null })
        }
      />
      <DeactivateDialog
        open={showConfirmDeleteModal.show}
        onClose={() =>
          setShowConfirmDeleteModal({ show: false, company: null, user: null })
        }
      />
      <DeactivateCompanyDialog
        open={showDeactivateCompanyModal}
        onClose={() => setShowDeactivateCompanyModal(false)}
      />
    </Tabs>
  );
};

export default Settings;
