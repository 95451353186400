import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { ClickAwayListener } from 'src/components';
import { useProfile, useProspectSearch, useUserSettings } from 'src/context';
import { titleCase } from 'src/utils';
import { useGetPayerMixReimbursement } from 'src/hooks';

interface SiteOption {
  value: string;
  label: string;
}

const AllSitesSelect = () => {
  const { state: userSettingsState, dispatch: userSettingsDispatch } =
    useUserSettings();
  const { details } = useProfile();
  const { prospectSearch } = useProspectSearch();

  const [open, setOpen] = useState(false);

  const selectedSite = userSettingsState?.selectedSite ?? null;

  const { data: reimbursementData } = useGetPayerMixReimbursement({
    id: details?.provider_id,
    savedSearchId:
      userSettingsState?.selectedSavedSearch?.id ?? prospectSearch?.id,
  });

  const siteOptions = useMemo<SiteOption[]>(() => {
    if (!reimbursementData?.results) return [];

    // Collect all places of service for the selected payer
    const allPayersWithMatchingName = reimbursementData.results
      .flatMap((result) =>
        result.payers.filter(
          (payer) =>
            payer.payer_name === userSettingsState?.selectedPayer?.payer_name
        )
      )
      .filter(Boolean);

    // Get unique place of service codes
    const uniquePlacesOfService = Array.from(
      new Set(
        allPayersWithMatchingName.map(
          (payer: any) => payer.place_of_service_code
        )
      )
    );

    // Format as options with value and label
    return uniquePlacesOfService.map((code: string) => {
      const payer = allPayersWithMatchingName.find(
        (p: any) => p.place_of_service_code === code
      );
      return {
        value: code,
        label: payer?.place_of_service || code,
      };
    });
  }, [reimbursementData, userSettingsState?.selectedPayer]);

  const displayLabel =
    siteOptions.find((option) => option.value === selectedSite)?.label ??
    'All Sites';

  const toggleOption = (value: string) => {
    userSettingsDispatch({
      type: 'UPDATE_USER_SETTINGS',
      payload: {
        selectedSite: value,
      },
    });
    setOpen(false);
  };

  const disabled = !userSettingsState?.selectedPayer;
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="relative">
        <button
          data-testid="all-sites-select-button"
          type="button"
          onClick={() => setOpen(!open)}
          disabled={disabled}
          className="flex items-center gap-2 py-1.5 px-3 max-w-64 min-w-32 h-8 border border-neutral-300 rounded-lg bg-white whitespace-nowrap"
        >
          <span className="text-sm font-medium text-gray-700 ">Site:</span>
          <span className="text-sm font-medium text-gray-700 overflow-hidden text-ellipsis">
            {displayLabel ? titleCase(displayLabel as string) : 'All Sites'}
          </span>
          <Icon
            icon={faCaretDown}
            className={`${
              disabled ? 'text-gray-400' : 'text-gray-500'
            } shrink-0`}
          />
        </button>
        {open && (
          <div className="absolute z-10 mt-1 min-w-48 max-h-64 overflow-y-auto bg-white border border-neutral-300 rounded-lg shadow-md">
            <div className="flex flex-col gap-2 p-4">
              {siteOptions?.map((option: SiteOption) => (
                <label
                  key={option.value}
                  data-testid={`all-sites-select-option-${option.value}`}
                  className="flex items-center gap-1 cursor-pointer whitespace-nowrap"
                  onClick={() => toggleOption(option.value)}
                >
                  <Icon
                    icon={faCheck}
                    size="sm"
                    className={
                      selectedSite === option.value
                        ? 'text-sky-700'
                        : 'text-transparent'
                    }
                  />
                  <span className="text-sm text-gray-700">{option.label}</span>
                </label>
              ))}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default AllSitesSelect;
