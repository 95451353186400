import { useState } from 'react';
import { useGetDataIssues } from 'src/hooks';
import { useLogging, useLayoutControl, useNotification } from 'src/context';
import {
  TableColumn,
  TableSettings,
  TableControls,
  TableToolbar,
} from 'src/components';
import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { currentTableStyle } from 'src/styles/CurrentTableStyle';
import { getExportDataIssues } from 'src/api/dataIssuesApi';

const DataIssueTable = () => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const baseColumns: GridColDef[] = [
    TableColumn.providerName('name', 'Provider'),
    TableColumn.string('status', 'Status'),
    TableColumn.string('issue_type', 'Type'),
    TableColumn.string('reporting_user_email', 'Reported By'),
    TableColumn.date('reported_at', 'Reported At'),
  ];
  const apiRef = useGridApiRef();
  const { setShowResolveDataIssueDialog } = useLayoutControl();
  const [isExporting, setIsExporting] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [sort, setSort] = useState({
    field: 'reported_at',
    order: 'desc',
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    // Initialize column visibility model with all columns visible
    // column model consumers expect { [field]: boolean } to control visbility
    baseColumns.reduce((acc, column) => {
      acc[column.field] = true;
      return acc;
    }, {})
  );

  const { data: { results: data_issues = [], count = 0 } = {}, isLoading } =
    useGetDataIssues({
      q: filterModel.quickFilterValues?.join(' ') || null,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: sort?.field,
      order: sort?.order,
    });

  const onSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      return;
    }

    const sortModelItem = sortModel[0];
    setSort({
      field: sortModelItem.field,
      order: sortModelItem.sort,
    });
  };

  const handleExportList = async (type: string) => {
    const visibleColumns = apiRef.current
      .getVisibleColumns()
      .map((col) => col.field)
      .concat(['claims', 'patients', 'trends'])
      .filter((col) => col.indexOf('__') === -1);

    setIsExporting(true);
    try {
      const res = await getExportDataIssues({
        searchParams: {
          q: filterModel.quickFilterValues?.join(' ') || null,
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
          sort: sort?.field,
          order: sort?.order,
        },
        fileType: type,
        columns: visibleColumns,
        fileName: `data-issues-${new Date()
          .toISOString()
          .replace(/[:.T]/g, '-')
          .slice(0, 16)}`,
      });

      if (!res) return;
      setNotification({
        title: 'Success',
        message:
          'Export initiated - You will receive an email when it is ready for download',
        type: 'success',
      });
      log.event('Export', {
        type: 'data-issues',
        source: `Data Issues`,
        columns: visibleColumns,
        count: data_issues?.length,
      });
    } catch (err: any) {
      log.exception(`Error exporting List: ${err?.error_message}`);

      setNotification({
        title: 'Error',
        message: err?.error_message || 'Error exporting, please try again.',
        type: 'error',
      });
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <div style={{ height: '50rem', minWidth: 0 }}>
      <DataGridPremium
        apiRef={apiRef}
        rows={data_issues || []}
        sx={{
          ...currentTableStyle,
          border: '1px solid #e0e0e0',
          borderRadius: '0.5rem',
          overflow: 'hidden',
        }}
        onRowClick={(row) => {
          setShowResolveDataIssueDialog({
            show: true,
            dataIssueId: row.id as string,
          });
        }}
        rowCount={count}
        loading={isLoading}
        columns={baseColumns}
        initialState={{
          columns: { columnVisibilityModel },
          pagination: { paginationModel: { page: 0, pageSize: 50 } },
        }}
        slots={{
          toolbar: TableToolbar,
          ...TableSettings.slots,
        }}
        slotProps={{
          columnsPanel: {
            disableHideAllButton: true,
            disableShowAllButton: true,
          },
          toolbar: {
            headerSlots: {
              slot1: (
                <TableControls
                  onExport={handleExportList}
                  isExporting={isExporting}
                  columns={baseColumns}
                  columnVisibilityModel={columnVisibilityModel}
                  setColumnVisibilityModel={setColumnVisibilityModel}
                  searchPlaceholder="Search by Provider, Status, or Reporting User"
                  showDeleteButton={false}
                  showExportButton={true}
                />
              ),
            },
          },
        }}
        checkboxSelection={false}
        density="standard"
        // Pagination
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        // Sorting
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        // Filtering
        filterMode="server"
        onFilterModelChange={setFilterModel}
        filterModel={filterModel}
        // Column visibility
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        disableRowSelectionOnClick={true}
      />
    </div>
  );
};

export default DataIssueTable;
