import { Container, Button, Typography, Box, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { IconButton } from 'src/components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPlus, faCog } from '@fortawesome/pro-solid-svg-icons';
import AddEditMappingDialog from './AddEditMappingDialog';
import { MappingTable } from './MappingTable';
import { CRMSettingsDialog } from '../CRMSettings';
import { useIntegrationLayout, useIntegrations } from '../../context';

const ButtonRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.5rem',
  marginBottom: '1rem',
});

const Mappings = () => {
  const { settings, setCurrentMapping } = useIntegrations();
  const {
    showMappingDialog,
    setShowMappingDialog,
    showSettingsDialog,
    setShowSettingsDialog,
  } = useIntegrationLayout();

  const isSettingsEmpty = settings?.some((setting) => !setting?.value);

  return (
    <Container maxWidth={false} sx={{ mt: 3 }} disableGutters>
      <Typography
        sx={{
          mt: 1,
          mb: 2,
          fontWeight: 600,
          fontSize: '1.4rem',
        }}
      >
        Object Mappings
      </Typography>
      <ButtonRow>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowMappingDialog(true)}
            disabled={isSettingsEmpty}
          >
            <Icon icon={faPlus} style={{ marginRight: 5 }} />
            New Mapping
          </Button>
          {isSettingsEmpty && (
            <Typography
              sx={{
                color: 'error.main',
                fontSize: '0.8rem',
                fontWeight: 600,
              }}
            >
              Please fill out all settings before creating a mapping
            </Typography>
          )}
        </Box>

        <IconButton
          icon={faCog}
          size="small"
          style={{
            color: grey[600],
          }}
          onClick={() => setShowSettingsDialog(true)}
        />
      </ButtonRow>

      <MappingTable />
      <AddEditMappingDialog
        open={showMappingDialog}
        onClose={() => {
          setCurrentMapping(null);
          setShowMappingDialog(false);
        }}
      />
      <CRMSettingsDialog
        open={showSettingsDialog}
        onClose={() => setShowSettingsDialog(false)}
      />
    </Container>
  );
};

export default Mappings;
