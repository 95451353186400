import { Tags, TypeIcon } from 'src/components';
import { Link, Box, styled } from '@mui/material';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { exists } from 'src/utils';
import theme from 'src/styles/theme';
import { useFlags } from 'launchdarkly-react-client-sdk';

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '1.1rem',
  height: '100%',
  marginRight: '.5rem',
}));

const NameCell = ({ row }) => {
  const { sameTabRouting } = useFlags();

  const showLink = row.type !== 'group_pac' && row.type !== 'system';
  const contentType = exists(row.type)
    ? row.type
    : exists(row.content_type)
    ? row.content_type
    : 'hcp';
  const linkUrl = `/profiles/${contentType}/${row?.provider_id}`;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {showLink ? (
          <Link
            href={linkUrl}
            target={sameTabRouting ? '_self' : '_blank'}
            rel="noopener noreferrer"
            sx={{ flexGrow: 1 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {exists(row.type) && (
                <IconContainer>
                  <TypeIcon
                    type={row.type}
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  />
                </IconContainer>
              )}
              {row.name || 'n/a'}
              <Icon
                icon={faExternalLinkAlt}
                size="sm"
                style={{ marginLeft: '0.25rem' }}
              />
            </Box>
          </Link>
        ) : (
          <>{row.name || 'n/a'}</>
        )}
      </Box>
      {row.tags?.length > 0 && !exists(row.type) && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tags tags={row.tags} tagType="tag" size="small" />
        </Box>
      )}
    </Box>
  );
};

export default NameCell;
