import { useQuery, UseQueryResult } from 'react-query';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { QUERY_KEYS } from './constants';
import { MapService } from 'src/services';
import { useAuth } from 'src/context';

/**
 * Custom hook for fetching search results.
 *
 * @param {Object} options - The options for fetching search results.
 * @param {Array<number>} options.selectedTerritoryIds - The selected territory IDs.
 * @param {string} options.filterQueryParamString - The filter query parameter string.
 * @param {Array<any>} options.prospectLists - The prospect lists.
 * @param {number} options.page - The page number.
 * @param {number} options.perPage - The number of results per page.
 * @param {string} options.sortBy - The sorting criteria.
 * @param {boolean} options.enabled - Indicates whether the hook is enabled.
 * @param {boolean} options.updateOnMapMove - Indicates whether to update on map move.
 * @param {Object} options.mapState - The map state.
 * @param {string} options.company_id - The company ID.
 * @returns {UseQueryResult<MedScout.SearchResult, { message: string } | null>} The query result.
 */

interface UseGetSearchResultsProps {
  selectedTerritoryIds?;
  sortBy?;
  sort?;
  order?;
  sort_search?;
  sort_value?;
  enabled: boolean;
  updateOnMapMove?: boolean;
  mapState?;
  dateRange?: {
    lte: string;
    gte: string;
  };
  type?: string;
  mode?: string;
  ex_pr?: string;
  map?: string;
  zoom?: string;
  filters?: any;
  shouldRefetch?: boolean;
}

export default function useGetSearchResults({
  sortBy,
  sort,
  order,
  sort_search,
  sort_value,
  selectedTerritoryIds,
  mapState,
  dateRange,
  shouldRefetch,
  type,
  mode,
  ex_pr,
  zoom,
  filters,
  enabled,
  updateOnMapMove = true,
}: UseGetSearchResultsProps): UseQueryResult<
  MedScout.SearchResult,
  { message: string } | null
> {
  const bounds = MapService.getBoundsFromMapState(mapState);
  const { refreshLocalUser } = useAuth();
  const params = getSearchQueryParamsForAPI({
    sort_by: sortBy,
    sort,
    order,
    sort_search,
    sort_value,
    type,
  });

  const data = {
    map: selectedTerritoryIds,
    bounds:
      selectedTerritoryIds?.length === 0 || updateOnMapMove ? bounds : null,
    dateRange,
    mode,
    ex_pr,
    zoom,
    ...filters,
  };

  return useQuery(
    [QUERY_KEYS.search, params, ...Object.values(data)],
    async () => {
      return request({
        url: `/v1/query${params}`,
        method: 'POST',
        data,
      });
    },
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
      staleTime: 60_000,
      refetchInterval: shouldRefetch ? 60000 : false, // 60000 ms = 1 minute
      onSuccess: async (response: any) => {
        if (response?.status === 205) {
          await refreshLocalUser();
        }

        return response;
      },
    }
  );
}
