import React, { useState } from 'react';
import {
  ListItem,
  ListItemProps,
  IconButton,
  CircularProgress,
  styled,
  useTheme,
} from '@mui/material';
import { InlineEdit, Link } from 'src/components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { useDeleteProviderList } from 'src/hooks';
import { useRouter } from 'next/router';
import OptionMenu from './SideNavOptionMenu';

const StyledOption = styled((props: ListItemProps & { active }) => (
  <ListItem {...props} />
))(({ theme, active }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '0.75rem 0.5rem',
  borderLeft: `4px solid ${
    active ? theme.palette.highlight.main : 'transparent'
  }`,
}));

const SideNavOption = ({ option, handleClick }) => {
  const theme = useTheme();
  const { isLoading: isDeleteLoading } = useDeleteProviderList();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const router = useRouter();

  const isActive = router.asPath === option.path;

  return (
    <StyledOption
      key={option?.id}
      active={isActive ? 'true' : undefined}
      aria-label={`List Name`}
    >
      <Link
        sx={{ textDecoration: 'none' }}
        href={option.path}
        onClick={() => handleClick()}
        style={{
          color: '#32383E',
        }}
      >
        <InlineEdit
          iconOnly
          fullWidth
          onSave={(listName) => option.handleEdit(listName, option.id)}
          disabled={!option.handleEdit}
          secondary={option?.secondary}
        >
          {option.label}
        </InlineEdit>
      </Link>
      {!!option.commands && (
        <>
          <IconButton
            onClick={(e) => setAnchorEl(e.currentTarget)}
            aria-label="List Options"
          >
            {isDeleteLoading ? (
              <CircularProgress size={15} color="inherit" />
            ) : (
              <Icon
                icon={faEllipsis}
                size="sm"
                style={{ fontSize: '1rem', color: theme.palette.grey[400] }}
              />
            )}
          </IconButton>

          <OptionMenu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
          >
            {option.commands || null}
          </OptionMenu>
        </>
      )}
    </StyledOption>
  );
};

export default SideNavOption;
