import { Box, Container } from '@mui/joy';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuth } from 'src/context';
import { PayerMix } from 'src/modules/Profile/components/Views/components/PayerMix';
import ProfileDetails from './components/ProfileDetails/ProfileDetails';
import ProfileOrganizations from './components/ProfileOrganizations';
import ProfileAffiliations from './components/ProfileAffiliations';
import ProfileEducationDetails from './components/ProfileEducationDetails';
import ProfileSummary from './components/ProfileSummary/ProfileSummary';
import {
  ProfileVolumeByLocation,
  GridContainer,
  GridColumn,
} from './components';
import { NewPayerMix } from 'src/modules/Profile/components/Views/components/NewPayerMix';
import { ProfileReimbursement } from 'src/components';
import { ProfileTitle } from 'src/modules/Profile/components/ProfileTitle';
import { ProfileSavedSearch } from './components/ProfileSavedSearch';

const ProviderProfile = ({
  singleColumn = false,
}: {
  singleColumn?: boolean;
}) => {
  const { user } = useAuth();
  const { useProfileAiSummary, newPayerMix } = useFlags();

  const userAiProvider = user?.company?.hcp_summary && useProfileAiSummary;

  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{ overflow: 'hidden', paddingBottom: '1rem' }}
    >
      {newPayerMix && <ProfileTitle type="provider" />}
      {newPayerMix ? (
        <GridContainer
          singleColumn={singleColumn}
          sx={{
            gridTemplateColumns: singleColumn
              ? '1fr'
              : { sm: '1fr', lg: '22.5rem 51.5rem' },
          }}
        >
          <GridColumn>
            <ProfileDetails />
            <ProfileOrganizations />
            <ProfileEducationDetails />
            <ProfileAffiliations />
          </GridColumn>
          <GridColumn>
            <Box className="flex flex-col h-full gap-4 rounded-lg px-6 py-5 border border-teal-200 bg-teal-050">
              <ProfileSavedSearch context="payer" />
              {userAiProvider && <ProfileSummary />}
              <ProfileVolumeByLocation />
              <NewPayerMix showLegend singleColumn={singleColumn} />
              <ProfileReimbursement singleColumn={singleColumn} />
            </Box>
          </GridColumn>
        </GridContainer>
      ) : (
        <GridContainer singleColumn={singleColumn}>
          <GridColumn>
            {userAiProvider && <ProfileSummary />}
            <ProfileDetails />
            <PayerMix />
            <ProfileEducationDetails />
          </GridColumn>
          <GridColumn>
            <ProfileVolumeByLocation />
            <ProfileOrganizations />
            <ProfileAffiliations />
          </GridColumn>
        </GridContainer>
      )}
      {/* Always push the bottom of the page by 400px */}
      <div className="h-[400px]" />
    </Container>
  );
};

export default ProviderProfile;
