import React from 'react';
import { RolesAdmin } from './components';
import { Card, CardContent } from 'src/components';
const Security = () => {
  return (
    <Card className="relative">
      <CardContent className="p-6">
        <RolesAdmin />
      </CardContent>
    </Card>
  );
};

export default Security;
