export const SiteActionTypes: { [key: string]: string } = {
  SET_SEARCH: 'SET_SEARCH',
  SET_SELECTED_TYPE: 'SET_SELECTED_TYPE',
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  SET_SELECTED_SEARCHES: 'SET_SELECTED_SEARCHES',
};

export const SavedSearchActionTypes: { [key: string]: string } = {
  // Search Folders
  SET_ACTIVE_FOLDER: 'SET_ACTIVE_FOLDER',
  SET_FOLDERS: 'SET_FOLDERS',
  SET_TEMP_FOLDER: 'SET_TEMP_FOLDER',
  REMOVE_TEMP_FOLDER: 'REMOVE_TEMP_FOLDER',
  // Saved Search
  SET_TEMP_SAVED_SEARCH: 'SET_TEMP_SAVED_SEARCH',
  REMOVE_TEMP_SAVED_SEARCH: 'REMOVE_TEMP_SAVED_SEARCH',
  SET_MOVED_SAVED_SEARCH: 'SET_MOVED_SAVED_SEARCH',
  SET_PREVIOUS_SAVED_SEARCH: 'SET_PREVIOUS_SAVED_SEARCH',
  // Accordion
  SET_ACCORDION: 'SET_ACCORDION',
  // Saved Search Type
  SET_SAVED_SEARCH_TYPE: 'SET_SAVED_SEARCH_TYPE',
  // TOP Providers/Top Locations
  SET_SELECTED_SAVED_SEARCH: 'SET_SELECTED_SAVED_SEARCH',
  SET_SELECTED_SAVED_SEARCHES: 'SET_SELECTED_SAVED_SEARCHES',
  // queryParams
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
};

export const ProfileActionTypes: { [key: string]: string } = {
  OPEN_PROFILE_DRAWER: 'OPEN_PROFILE_DRAWER',
  CLOSE_PROFILE_DRAWER: 'CLOSE_PROFILE_DRAWER',
  SET_CURRENT_PROVIDER: 'SET_CURRENT_PROVIDER',
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
};

export const ActivityActionTypes: { [key: string]: string } = {
  SET_HAS_DATA: 'SET_HAS_DATA',
};

export const ListActionTypes: { [key: string]: string } = {
  SET_FORMATTED_PROSPECT_LISTS: 'SET_FORMATTED_PROSPECT_LISTS',
};

export const UserSettingsActionTypes: { [key: string]: string } = {
  UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
};

export const CompanyAdminActionTypes: { [key: string]: string } = {
  SET_CURRENT_COMPANY: 'SET_CURRENT_COMPANY',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_CURRENT_COMPANY_AND_USER: 'SET_CURRENT_COMPANY_AND_USER',
};

export const MapActionTypes: { [key: string]: string } = {
  ADD_TEMP_SELECTED_STATE: 'ADD_TEMP_SELECTED_STATE',
  ADD_TEMP_SELECTED_COUNTY: 'ADD_TEMP_SELECTED_COUNTY',
  ADD_TEMP_SELECTED_ZIP_CODE: 'ADD_TEMP_SELECTED_ZIP_CODE',
  ADD_TEMP_TERRITORY_COORDINATE: 'ADD_TEMP_TERRITORY_COORDINATE',
  EDIT_TERRITORY: 'EDIT_TERRITORY',
  REMOVE_TEMP_SELECTED_STATE: 'REMOVE_TEMP_SELECTED_STATE',
  REMOVE_TEMP_SELECTED_COUNTY: 'REMOVE_TEMP_SELECTED_COUNTY',
  REMOVE_TEMP_SELECTED_ZIP_CODE: 'REMOVE_TEMP_SELECTED_ZIP_CODE',
  REMOVE_TEMP_TERRITORY_COORDINATE: 'REMOVE_TEMP_TERRITORY_COORDINATE',
  SET_DRAWING_MANAGER_DRAW_MODE: 'SET_DRAWING_MANAGER_DRAW_MODE',
  SET_SELECT_TERRITORIES: 'SET_SELECT_TERRITORIES',
  TOGGLE_EDIT_TERRITORY_MODE: 'TOGGLE_EDIT_TERRITORY_MODE',
  CLEAR_OVERLAY: 'CLEAR_OVERLAY',
  BOUNDARIES_LOADING: 'BOUNDARIES_LOADING',
  TERRITORIES_LOADING: 'TERRITORIES_LOADING',
  SET_EXISTING_TERRITORIES: 'SET_EXISTING_TERRITORIES',
  SET_MAP_DATA: 'SET_MAP_DATA',
  SET_CACHE_REGION: 'SET_CACHE_REGION',
  SET_MAP_LOADING: 'SET_MAP_LOADING',
  SET_MAP_ERROR: 'SET_MAP_ERROR',
};
