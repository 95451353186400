import { Card, Tooltip, Typography } from '@mui/joy';
import { faInfoCircle, faUserMd } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { hasContent } from 'src/utils';
import InfoUnavailable from '../InfoUnavailable';
import ProfessionalMixList from './ProfessionalMixList';
import { DataInfo } from 'src/components';
import { useProfile } from 'src/context';
import { Box } from 'src/components/PulseUI';
const ProfileProfessionalMix = () => {
  const { details } = useProfile();

  const mixes = details?.center?.professional_mix || [];

  return (
    <>
      <Card sx={{ padding: 3 }}>
        <Box
          className="flex flex-row gap-2 items-center"
          aria-label="Hospital Affiliations"
        >
          <Icon icon={faUserMd} color="#525252" className="h-4 w-4" />
          <Typography className="text-lg font-bold">
            Healthcare Professional Mix
            <Tooltip
              placement="top"
              variant="outlined"
              arrow
              title={
                <Box className="flex flex-col max-w-80 justify-center p-1">
                  <DataInfo view="centerProfile" />
                </Box>
              }
            >
              <Icon
                icon={faInfoCircle}
                height={16}
                width={16}
                style={{
                  color: '#737373',
                  marginLeft: '.5rem',
                }}
              />
            </Tooltip>
          </Typography>
        </Box>
        <Box className="flex flex-col gap-4">
          {hasContent(mixes) ? (
            <ProfessionalMixList professionalMixes={mixes} />
          ) : (
            <InfoUnavailable />
          )}
        </Box>
      </Card>
    </>
  );
};

export default ProfileProfessionalMix;
