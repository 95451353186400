import { LoopOutlined } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/joy';
import React from 'react';

const ContinuousSyncStatusCell = ({ row }) => {
  const syncOn = row.use_in_update;
  const tooltipText = syncOn
    ? 'Continuous sync enabled'
    : 'Continuous sync disabled';
  return (
    <Box
      sx={{
        margin: 'auto',
      }}
    >
      <Tooltip title={tooltipText} placement="top">
        <LoopOutlined
          color={syncOn ? 'success' : 'disabled'}
          style={{ fontSize: '1.25rem' }}
        />
      </Tooltip>
    </Box>
  );
};

export default ContinuousSyncStatusCell;
