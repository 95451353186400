import useSWR from 'swr';
import { getVolumeByLocation } from './profilesApi';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useNewProfile } from 'src/context';

const useGetVolumeByLocation = (provider_id) => {
  const { savedSearchQueryParams } = useNewProfile();

  const queryParams = getSearchQueryParamsForAPI({
    cpt: savedSearchQueryParams.cpt?.map((item) => item.value),
    hcpcs: savedSearchQueryParams.hcpcs?.map((item) => item.value),
    icd: savedSearchQueryParams.icd?.map((item) => item.value),
    drg: savedSearchQueryParams.drg?.map((item) => item.value),
    icdp: savedSearchQueryParams.icdp?.map((item) => item.value),
  });

  return useSWR(
    [QUERY_KEYS.GET_VOLUME_BY_LOCATION, queryParams],
    () => getVolumeByLocation(provider_id, queryParams),
    {
      keepPreviousData: true,
    }
  );
};

export default useGetVolumeByLocation;
