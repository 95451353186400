import React, { useEffect, useRef } from 'react';
import { ButtonGroup, Button } from '@mui/joy';
import { useMedScoutMap } from 'src/context';
import { sentenceCase } from 'src/utils';

type DrawingMode = 'STATE' | 'COUNTY' | 'ZIPCODE' | 'DRAW' | 'RADIUS';

interface RadiusPolygon {
  points: any[];
}

interface TerritoryPolygons {
  STATE: any[];
  COUNTY: any[];
  ZIPCODE: any[];
  RADIUS: RadiusPolygon;
  DRAW: any[];
}

const DRAWING_MODES = [
  { label: 'State', value: 'STATE' },
  { label: 'County', value: 'COUNTY' },
  { label: 'Zipcode', value: 'ZIPCODE' },
  { label: 'Draw', value: 'DRAW' },
  { label: 'Radius', value: 'RADIUS' },
] as const;

const DEFAULT_TERRITORY_POLYGONS: TerritoryPolygons = {
  STATE: [],
  COUNTY: [],
  ZIPCODE: [],
  RADIUS: { points: [] },
  DRAW: [],
};

const DrawActionButtons = () => {
  const lastDrawingMode = useRef<DrawingMode>('STATE');

  const {
    currentTerritory,
    isEditing,
    drawingMode,
    setDrawingMode,
    territoryPolygons,
    setTerritoryPolygons,
    setClickedFeatures,
  } = useMedScoutMap();

  useEffect(() => {
    if (!currentTerritory?.source || !isEditing) return;

    const sourceToMode = {
      states: 'STATE',
      counties: 'COUNTY',
      zip_codes: 'ZIPCODE',
      circles: 'RADIUS',
      drawn: 'DRAW',
    } as const;

    for (const [source, mode] of Object.entries(sourceToMode)) {
      if (
        source === 'drawn'
          ? currentTerritory.source[source]
          : currentTerritory.source[source]?.length > 0
      ) {
        setDrawingMode(mode as DrawingMode);
        lastDrawingMode.current = mode as DrawingMode;
        break;
      }
    }
  }, [currentTerritory, isEditing]);

  const hasOtherPolygons = (excludeMode: DrawingMode): boolean => {
    return Object.entries(territoryPolygons as TerritoryPolygons).some(
      ([mode, value]) => {
        if (mode === excludeMode) return false;
        return mode === 'RADIUS'
          ? value?.points && value.points?.length > 0
          : value?.length > 0;
      }
    );
  };

  const handleDrawingMode = (newMode: DrawingMode) => {
    const shouldReset =
      hasOtherPolygons(newMode) &&
      !(newMode === 'COUNTY' && lastDrawingMode.current === 'STATE') &&
      !(newMode === 'ZIPCODE' && lastDrawingMode.current === 'STATE');
    if (shouldReset) {
      const message = `This will erase your current work, are you sure you want to change drawing mode to ${sentenceCase(
        newMode
      )}?`;
      if (!confirm(message)) return;
      setTerritoryPolygons({});
      setClickedFeatures([]);
    }

    lastDrawingMode.current = newMode;
    setDrawingMode(newMode);
  };

  return (
    <ButtonGroup
      variant="solid"
      color="neutral"
      aria-label="outlined primary button group"
      className="w-full"
    >
      {DRAWING_MODES.map(({ label, value }) => (
        <Button
          size="sm"
          id={`discovery-territory-draw-action-button-${value}`}
          key={value}
          onClick={() => handleDrawingMode(value)}
          variant={drawingMode === value ? 'solid' : 'outlined'}
        >
          <span className="text-sm">{label}</span>
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default DrawActionButtons;
