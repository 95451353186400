import React, { useCallback, useMemo } from 'react';
import {
  Box,
  IconButton,
  Autocomplete,
  CircularProgress,
  Tooltip,
} from '@mui/joy';
import { EditRounded } from '@mui/icons-material';
import { AddEditTerritory, TerritoryItem } from './components';
import { TerritoryGroup } from './components/TerritoryGroup';
import { useAuth, useLogging, useMedScoutMap } from 'src/context';
import {
  useGetAllMedTerritories,
  getTerritory,
  useAuthorization,
  useDirectReports,
} from 'src/hooks';
import { HeatmapButton } from './components/HeatmapButton';
import { NO_TERRITORY_ACTIVE_SELECTION } from './constants';
import { useFlags } from 'launchdarkly-react-client-sdk';

const TerritoryPanel = () => {
  const log = useLogging();
  const { user, setUser } = useAuth();
  const auth = useAuthorization();
  const isBoundToAssigned = auth?.isBoundedToTerritory();
  const { checkReport } = useDirectReports();
  const { newPermissionSystem } = useFlags();

  const {
    currentTerritory,
    setCurrentTerritory,
    isEditing,
    setIsEditing,
    isCreating,
    setIsCreating,
    setHeatMap,
  } = useMedScoutMap();

  const { data: myTerritoryData, isLoading: isLoadingTerritories } =
    useGetAllMedTerritories();

  const canUserWrite = useMemo(() => {
    // Handle legacy permission system
    if (!newPermissionSystem) {
      return !!currentTerritory;
    }

    // Handle case when no territory is selected
    if (!currentTerritory) {
      return false;
    }

    // Determine if the territory is canonical if the user is bound to assigned territories
    const isCanonical = isBoundToAssigned && currentTerritory.is_canonical;

    // Check if user owns the territory
    const isOwnTerritory =
      currentTerritory.owner.id === user.id && !isCanonical;

    // Determine permission scope
    const scope = isOwnTerritory
      ? 'own'
      : checkReport(currentTerritory.owner.id)
      ? 'direct_report'
      : 'company';

    return auth?.canWrite('territory', scope);
  }, [currentTerritory, newPermissionSystem, user.id, auth, checkReport]);

  const currentlySetTerritory = useMemo(() => {
    if (
      currentTerritory?.id?.toString() === NO_TERRITORY_ACTIVE_SELECTION?.id
    ) {
      return null;
    }

    return (
      myTerritoryData?.find((territory) => {
        return territory.id === currentTerritory?.id;
      }) || null
    );
  }, [currentTerritory, myTerritoryData]);

  const combinedData = useCallback(() => {
    if (!myTerritoryData?.length) {
      return [];
    }

    // Get rep territories
    const repTerritories = myTerritoryData
      .filter((rep) => rep.owner?.id !== user.id)
      .sort((a, b) => {
        // Prioritize canonical territories
        if (a.is_canonical !== b.is_canonical) {
          return a.is_canonical ? -1 : 1;
        }
        return a.name.localeCompare(b.name);
      })
      .map((territory) => ({
        ...territory,
        repName: `${territory.owner.first_name} ${territory.owner.last_name}`,
      }))
      .sort((a, b) => a.repName.localeCompare(b.repName));

    // Get my territories
    const myTerritories = myTerritoryData.filter(
      (territory) => territory.owner?.id === user.id
    );

    // Add "No Territory" option for legacy system
    if (!newPermissionSystem) {
      myTerritories.push({
        id: NO_TERRITORY_ACTIVE_SELECTION.id,
        value: NO_TERRITORY_ACTIVE_SELECTION.id,
        name: NO_TERRITORY_ACTIVE_SELECTION.name,
      });
    }

    // Sort my territories
    myTerritories.sort((a, b) => {
      if (a.value === 'USE_MAP_NO_TERRITORY') return -1;
      return a.name.localeCompare(b.name);
    });

    // Add "Add Territory" option if user has write permission
    if (newPermissionSystem && auth?.canWrite('territory', '*')) {
      myTerritories.unshift({
        id: 'addNew',
        value: 'addNew',
        name: 'Add a Territory',
      });
    } else {
      myTerritories.unshift({
        id: 'addNew',
        value: 'addNew',
        name: 'Add a Territory',
      });
    }

    return [...myTerritories, ...repTerritories];
  }, [myTerritoryData, user.id, newPermissionSystem, canUserWrite]);

  const handleTerritoryEdit = () => {
    setIsEditing(!isEditing);
    setHeatMap(false);
  };

  const handleTerritoryChange = async (
    e: React.ChangeEvent,
    territory: any
  ) => {
    if (!territory) return;

    if (territory.id === 'addNew') {
      setIsCreating(true);
      setHeatMap(false);
      return;
    } else if (territory.id === NO_TERRITORY_ACTIVE_SELECTION.id) {
      log.event('useMapWithNoTerritorySelected');
      setCurrentTerritory(null);
      setUser((oldUser) => ({
        ...oldUser,
        last_territory: null,
      }));
      setHeatMap(false);
      return;
    }

    try {
      const territoryData = await getTerritory(territory.id);
      if (territoryData) {
        setCurrentTerritory(territoryData);
        setUser((oldUser) => ({
          ...oldUser,
          last_territory: territoryData,
        }));
      } else {
        log.exception('Failed to get territory data', {
          tags: {
            territoryId: territory.id,
          },
        });
        setUser((oldUser) => ({
          ...oldUser,
          last_territory: null,
        }));
        setCurrentTerritory(null);
      }
    } catch (err) {
      log.exception('Failed to get territory data', {
        tags: {
          territoryId: territory.id,
          error: err,
        },
      });
      setCurrentTerritory(null);
    } finally {
      setHeatMap(false);
    }
  };

  return (
    <Box
      id="discovery-territory-panel"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        padding: '0.5rem',
      }}
    >
      {isEditing || isCreating ? (
        <AddEditTerritory />
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            disableClearable
            value={currentlySetTerritory || NO_TERRITORY_ACTIVE_SELECTION}
            options={combinedData() || []}
            loadingText="Loading Territories..."
            loading={isLoadingTerritories}
            clearOnBlur
            getOptionLabel={(option) => option.name}
            onChange={handleTerritoryChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            groupBy={(option) => option.repName}
            renderGroup={(params) => (
              <Box key={params.key}>
                {params.group && (
                  <TerritoryGroup
                    params={params}
                    activeTerritory={currentTerritory}
                  />
                )}
                {!params.group && params.children}
              </Box>
            )}
            renderOption={(props, option) => {
              const name = option.name.toLowerCase().replace(/ /g, '-');
              const id = `discovery-territory-item-${name}`;
              return (
                <TerritoryItem
                  key={option.id}
                  id={id}
                  props={props}
                  option={option}
                  activeTerritory={currentTerritory}
                />
              );
            }}
            slotProps={{
              input: {
                'aria-label': 'Territory Selection Dropdown',
                placeholder: 'Select a Territory',
              },
            }}
            sx={{
              minWidth: '300px',
              borderTopLeftRadius: 'sm', // Round top-left corner
              borderBottomLeftRadius: 'sm', // Round bottom-left corner
              borderTopRightRadius: 0, // Flat top-right corner
              borderBottomRightRadius: 0, // Flat bottom-right corner
            }}
          />
          <Tooltip
            color="neutral"
            title="Select a territory to edit"
            placement="bottom"
            arrow
            disableFocusListener={!!currentTerritory}
            disableHoverListener={!!currentTerritory}
            disableTouchListener={!!currentTerritory}
          >
            <span>
              <IconButton
                variant="outlined"
                color="neutral"
                onClick={handleTerritoryEdit}
                disabled={isLoadingTerritories || !canUserWrite}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: 0,
                }}
              >
                {isLoadingTerritories ? (
                  <CircularProgress size="sm" />
                ) : (
                  <EditRounded fontSize="small" />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <HeatmapButton inGroup />
        </Box>
      )}
    </Box>
  );
};

export default TerritoryPanel;
