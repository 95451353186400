import { request } from 'src/api/request';

export const getUsers = async (id: string, query: any) => {
  if (!id) return;
  return await request({
    url: `v1/accounts/companies/${id}/users/${query}`,
    method: 'GET',
  });
};

export const getInfiniteUsers = async (url: string) => {
  if (!url) return;
  return await request({
    url,
    method: 'GET',
  });
};

export const getUser = async (companyId: string, userId: string) => {
  if (!companyId || !userId) return;
  return await request({
    url: `v1/accounts/companies/${companyId}/users/${userId}/`,
    method: 'GET',
  });
};

export const createUser = async (companyId: string, data: any) => {
  if (!companyId || !data) return;
  return await request({
    url: `v1/accounts/companies/${companyId}/users/`,
    method: 'POST',
    data,
  });
};

export const updateUser = async (
  companyId: string,
  userId: string,
  data: any
) => {
  if (!companyId || !userId || !data) return;
  return await request({
    url: `v1/accounts/companies/${companyId}/users/${userId}/`,
    method: 'PATCH',
    data,
  });
};

export const deleteUser = async (companyId: string, userId: string) => {
  if (!companyId || !userId) return;
  return await request({
    url: `v1/accounts/companies/${companyId}/users/${userId}/`,
    method: 'DELETE',
  });
};

export const sendResetPasswordEmail = async (
  companyId: string,
  userId: string
) => {
  if (!companyId || !userId) return;
  return await request({
    url: `v1/accounts/companies/${companyId}/users/${userId}/`,
    method: 'PATCH',
    data: {
      reset_password: true,
    },
  });
};

export const sendInviteEmail = async (companyId: string, userId: string) => {
  if (!companyId || !userId) return;
  return await request({
    url: `v1/accounts/companies/${companyId}/users/${userId}/`,
    method: 'PATCH',
    data: {
      invite_now: true,
    },
  });
};

export const exportUsers = async (companyId: string, query: any) => {
  if (!companyId) return;
  return request({
    url: `v1/accounts/companies/${companyId}/users/export/${query}`,
    method: 'GET',
  }).then((response) => {
    // Extract filename from Content-Disposition header
    const filename = 'exported-users.csv';

    // Create a URL for the blob
    const url = window.URL.createObjectURL(new Blob([response]));

    // Create an anchor element and set attributes for downloading
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // Use filename from Content-Disposition

    // Append to the document and trigger download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    return filename;
  });
};
