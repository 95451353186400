import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import EnabledCell from './EnabledCell';
import SettingsCell from './SettingsCell';
import LabelCell from './LabelCell';
import OptionSettingsCell from './OptionSettingsCell';
import OptionsEnabledCell from './OptionsEnabledCell';
import SecondaryInputCell from './SecondaryInputCell';
import AttributeCodeCell from './AttributeCodeCell';
import HiddenCell from './HiddenCell';
import RequiredCell from './RequiredCell';
import OptionLabelCell from './OptionLabelCell';
import CRMApiCode from './CRMApiCode';
import ExtraOptionsCell from './ExtraOptionsCell';
import StatusCell from './StatusCell';
import TimestampCell from './TimestampCell';
import ContinuousSyncStatusCell from './ContinuousSyncStatusCell';

const TableColumn: {
  [key: string]: (
    field: string,
    name: string,
    valueGetter?: (arg0: GridValueGetterParams) => any
  ) => GridColDef;
} = {
  label: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.label,
    renderCell: (props) => <LabelCell {...props} />,
    flex: 1,
    sortable: true,
  }),
  optionLabelCell: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.label,
    renderCell: (props) => <OptionLabelCell {...props} />,
    flex: 1,
    sortable: true,
  }),
  crmObjectType: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.crm_object_type,
    flex: 1,
    sortable: true,
  }),
  medscoutObjectType: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.medscout_object_type,
    flex: 1,
    sortable: true,
  }),
  attributeCode: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.attribute_code,
    renderCell: (props) => <AttributeCodeCell {...props} />,
    flex: 1,
    sortable: true,
  }),
  crmApiCode: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.crm_api_code,
    renderCell: (props) => <CRMApiCode {...props} />,
    flex: 1,
    sortable: true,
  }),
  secondaryInput: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.secondary_input,
    renderCell: (props) => <SecondaryInputCell {...props} />,
    flex: 1,
    sortable: true,
  }),
  enabled: (field, name) => ({
    field,
    headerName: name,
    flex: 1,
    sortable: false,
    renderCell: (props) => <EnabledCell {...props} />,
  }),
  optionEnabled: (field, name) => ({
    field,
    headerName: name,
    flex: 1,
    sortable: false,
    renderCell: (props) => <OptionsEnabledCell {...props} />,
  }),
  settings: (field, name) => ({
    field,
    headerName: '',
    flex: 0.2,
    sortable: false,
    renderCell: (props) => <SettingsCell {...props} />,
  }),
  optionSettings: (field, name) => ({
    field,
    headerName: '',
    flex: 0.2,
    sortable: false,
    renderCell: (props) => <OptionSettingsCell {...props} />,
  }),
  inputType: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.input_type,
    flex: 1,
    sortable: true,
  }),
  description: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.description,
    flex: 1,
    sortable: true,
  }),
  required: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.required,
    renderCell: (props) => <RequiredCell {...props} />,
    flex: 1,
    sortable: true,
  }),
  hidden: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.hidden,
    renderCell: (props) => <HiddenCell {...props} />,
    flex: 1,
    sortable: true,
  }),
  extraCrmObjectType: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.extra?.crm_object_type,
    flex: 1,
    sortable: true,
  }),
  extraSearchLabel: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.extra?.search_label,
    renderCell: (props) => <CRMApiCode {...props} />,
    flex: 1,
    sortable: true,
  }),
  extraValueField: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.extra?.value_field,
    renderCell: (props) => <CRMApiCode {...props} />,
    flex: 1,
    sortable: true,
  }),
  extraDefaultValue: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.extra?.default_value,
    flex: 1,
    sortable: true,
  }),
  extraOptions: (field, name) => ({
    field,
    headerName: name,
    valueGetter: ({ row }) => row?.extra?.options,
    renderCell: (props) => <ExtraOptionsCell {...props} />,
    flex: 1,
    minWidth: 150,
    sortable: true,
  }),
  timestamp: (field, name) => ({
    field,
    headerName: name,
    renderCell: (params) => <TimestampCell {...params} />,
    valueGetter: ({ row }) => row?.started,
    flex: 0.4,
    maxWidth: 300,
    sortable: false,
  }),
  syncStatus: (field, name) => ({
    field,
    headerName: name,
    renderCell: (params) => <StatusCell {...params} />,
    valueGetter: ({ row }) => row?.status,
    flex: 0.4,
    maxWidth: 300,
    sortable: false,
  }),
  continuousSyncStatus: (field, name) => ({
    field,
    headerName: name,
    renderCell: (params) => <ContinuousSyncStatusCell {...params} />,
    valueGetter: ({ row }) => row?.use_in_update,
    flex: 0.4,
    maxWidth: 300,
    sortable: false,
  }),
};

export default TableColumn;
