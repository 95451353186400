import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faShieldHalved } from '@fortawesome/pro-regular-svg-icons';

const EmptyState = () => (
  <div className="flex flex-col items-center justify-center py-8 px-4 bg-neutral-50 rounded-lg text-center">
    <div className="flex flex-col items-center gap-3">
      <Icon icon={faShieldHalved} className="text-4xl text-neutral-400" />
      <div className="flex flex-col gap-1">
        <h4 className="text-sm font-medium text-neutral-900">
          No Roles Available
        </h4>
        <p className="text-sm text-neutral-500">
          This company doesn&apos;t have any roles to display.
        </p>
      </div>
    </div>
  </div>
);

export default EmptyState;
