import React, { useMemo, useEffect, useRef } from 'react';
import { Box, Menu, MenuItem, Typography, Dropdown } from '@mui/joy';
import {
  usePushToCRM,
  useGetMappings,
  useGetFieldDefinitions,
  useGetObjectDefinitionsList,
  useUpdateMedRMStatus,
  useOnClickOutside,
} from 'src/hooks';
import { useLogging, useNotification } from 'src/context';
import { CircleRounded as CircleRoundedIcon } from '@mui/icons-material';
import { StatusButton } from './components';

interface MedRMStatusProps {
  provider: MedScout.Opportunity;
  variant?: 'compact' | 'regular';
}

/**
 * @description MedRMStatus component
 * @param provider provider object
 * @param variant compact or regular
 * @returns
 */
const MedRMStatus = ({ provider, variant = 'regular' }: MedRMStatusProps) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const menuRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentStatus, setCurrentStatus] = React.useState({
    label: 'No Status',
    color: '#a7a7a7',
  });

  const { data: objectDefinitions, isLoading: isLoadingObjects } =
    useGetObjectDefinitionsList();

  const { data: fieldDefinitions, isLoading: isLoadingFields } =
    useGetFieldDefinitions({
      objectId: objectDefinitions?.results?.[0]?.id,
    });

  const { mutateAsync: pushToCRM, isLoading: isPushToCRM } = usePushToCRM({
    id: provider?.provider_id,
  });

  const { mutateAsync: updateMedRMStatus, isLoading: isUpdatingMedRmStatus } =
    useUpdateMedRMStatus({
      id: provider?.provider_id,
    });

  const { data: allMappings } = useGetMappings({
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const hasStatus = useMemo(() => {
    return provider?.crm_links?.[0];
  }, [provider]);

  const crmTypes = useMemo(() => {
    return Array.from(
      new Set(
        allMappings?.results
          ?.filter((mapping) => mapping.enabled)
          .map((mapping) => mapping.crm?.toLowerCase())
      )
    );
  }, [allMappings]);

  const mappings = useMemo(() => {
    return allMappings?.results?.filter(
      ({ medscout_object_type, enabled }) =>
        medscout_object_type?.toLowerCase() ===
          provider?.content_type?.toLowerCase() && enabled
    );
  }, [allMappings, provider]);

  const statusField = useMemo(() => {
    return fieldDefinitions?.results?.find(
      (field) => field?.label?.toLowerCase() === 'status'
    );
  }, [fieldDefinitions]);

  const statuses = useMemo(() => {
    // remove statuses with no bucket (precautionary measure)
    const hasBucket = statusField?.options?.filter((option) => option.bucket);

    // order by bucket putting todo at the top, in process in the middle, and done at the end, ordering each section by order
    // this maintains the order the user put them in
    hasBucket?.sort((a, b) => {
      if (a.bucket === b.bucket) {
        return a.order - b.order;
      }
      return a.bucket === 'todo' ? -1 : a.bucket === 'done' ? 1 : 0;
    });

    return hasBucket;
  }, [statusField]);

  useEffect(() => {
    if (!statuses || !hasStatus) return;

    const status = statuses?.find(
      (option) => option.value === provider?.crm_links[0]?.crm_status
    );

    setCurrentStatus((prev) => (status ? status : prev));

    return () => {
      setCurrentStatus({
        label: 'No Status',
        color: '#32383E',
      });
    };
  }, [hasStatus, provider, statuses]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowMenu = (e) => {
    if (!e || !e.currentTarget) return;
    e.stopPropagation();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleStatusUpdate = async (option) => {
    if (!option || !option.value) return;

    handleClose();
    // Check if provider has already been pushed to CRM
    // If so, just change the status
    // If not, push the provider to CRM and set the status
    if (!provider?.provider_id) return;

    const newStatus = {
      object_type: mappings[0]?.medscout_object_type,
      mapping: mappings[0]?.id,
      fields: {
        status: option?.value,
      },
    };

    if (hasStatus) {
      if (!provider?.crm_links[0]?.id) return;
      try {
        await updateMedRMStatus({
          crmLinkId: provider?.crm_links[0]?.id,
          fields: {
            status: option?.value,
          },
        });

        if (provider?.crm_links[0]?.crm_status === option?.value) {
          setCurrentStatus(option);
        }

        log.event('Update provider status', {
          ...provider,
        });
      } catch (error) {
        log.exception('Error updating provider status', {
          tags: {
            providerId: provider.provider_id,
            error,
          },
        });

        setNotification({
          title: 'Error',
          message: 'An error occurred while updating provider status',
          type: 'error',
        });
      }
    } else {
      try {
        await pushToCRM(newStatus);
        setCurrentStatus(option);
        log.event('Push to MedRM', {
          ...provider,
        });
      } catch (err) {
        log.exception('Error pushing provider to CRM', {
          tags: {
            providerId: provider.provider_id,
            error: err,
          },
        });

        setNotification({
          title: 'Error',
          message:
            err?.error_message ||
            'An error occurred while pushing provider to CRM',
          type: 'error',
        });
      }
    }
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  const isLoading =
    isLoadingObjects || isLoadingFields || isPushToCRM || isUpdatingMedRmStatus;

  const isMedRM = crmTypes[0]?.toLowerCase() === 'medrm';
  if (!provider || !mappings?.length || !isMedRM) return null;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
      <Dropdown>
        <StatusButton
          variant={variant}
          onClick={handleShowMenu}
          status={currentStatus}
          loading={isLoading}
        />
        <Menu
          ref={menuRef}
          placement="bottom-start"
          open={!!anchorEl}
          anchorEl={anchorEl}
          sx={{
            boxShadow: 'sm',
            flexGrow: 0,
            width: '15rem',
            maxHeight: 300,
            overflow: 'auto',
            zIndex: 9999,
            padding: '0.25rem 0',
          }}
        >
          {statuses?.map((option, index) => (
            <MenuItem key={index} onClick={() => handleStatusUpdate(option)}>
              <CircleRoundedIcon
                sx={{ fontSize: '0.875rem', color: option?.color }}
              />
              <Typography level="body-sm">{option?.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>
    </Box>
  );
};

export default MedRMStatus;
