'use client';
import React, { useRef, useState } from 'react';
import { Box, Typography, Radio, Dropdown, styled, Menu } from '@mui/joy';
import { UnfoldMore } from '@mui/icons-material';
import { useOnClickOutside } from 'src/hooks';

interface RadioButtonSelectProps {
  id: string;
  header: string;
  selectOnChange: (e) => void;
  selectedOption: string;
}

const MenuContainer = styled(Menu)({
  display: 'flex',
  width: '240px',
  padding: 'var(--1, 0.5rem)',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: '0.5rem',
  border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
  background: 'var(--background-surface, #FBFCFE)',

  /* shadow-md */
  boxShadow:
    '0px 2px 8px -2px rgba(21, 21, 21, 0.08), 0px 6px 12px -2px rgba(21, 21, 21, 0.08)',
});

const RadioButtonSelect = ({
  id,
  header,
  selectOnChange,
  selectedOption,
}: RadioButtonSelectProps) => {
  const timeframeOptions = [
    { value: 'all', label: 'All' },
    { value: 'current_week', label: 'Current Week' },
    { value: 'current_month', label: 'Current Month' },
    { value: 'current_quarter', label: 'Current Quarter' },
    { value: 'last_six_months', label: 'Past 6 Months' },
  ];

  const displayValue = timeframeOptions.find(
    (option) => option.value === selectedOption
  )?.label;
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useRef(null);

  const handleOpenMenu = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleChange = (e) => {
    const value = e.target?.value;

    selectOnChange(value);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  return (
    <Dropdown>
      <button
        id={id}
        onClick={handleOpenMenu}
        style={{
          gap: '1rem',
          alignSelf: 'stretch',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.375rem 0.5rem 0.375rem 0.75rem',
          width: '10rem',
          height: '2rem',
          borderRadius: '3rem',
          border: '1px solid var(--Neutral-200, #DDDFE0)',
          background: 'var(--background-body, #FFF)',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              level="body-sm"
              color="neutral"
              sx={{
                fontWeight: 400,
                flex: '1 0 0',
                textAlign: 'left',
                color: 'var(--Neutral-800, #2C2E2F)',
              }}
            >
              {displayValue}
            </Typography>
            <UnfoldMore
              fontSize="small"
              sx={{
                fontWeight: 200,
                color: 'var(--neutral-600, #6B7280)',
              }}
            />
          </Box>
        </Box>
      </button>
      <MenuContainer
        ref={menuRef}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        placement="bottom-start"
        autoFocus={false}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography
            level="body-sm"
            sx={{ fontWeight: 500, marginLeft: '0.5rem', marginTop: '.5rem' }}
          >
            {header}
          </Typography>

          <Box
            sx={{
              paddingTop: '.5rem',
              maxHeight: '12.5rem',
              overflowY: 'auto',
            }}
          >
            {timeframeOptions?.map((option) => {
              return (
                <Box
                  key={option.value}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    margin: '0.375rem 0.5rem 0.375rem 0.5rem',
                    alignItems: 'center',
                    gap: '0.75rem',
                    height: '1.5rem',
                  }}
                >
                  <Radio
                    label={option.label}
                    value={option.value}
                    onChange={handleChange}
                    checked={selectedOption === option.value}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      </MenuContainer>
    </Dropdown>
  );
};

export default RadioButtonSelect;
