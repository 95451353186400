import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { green, grey } from '@mui/material/colors';

const RequiredCell = ({ row }) => {
  return (
    <Icon
      icon={faCircleCheck}
      color={row?.extra?.required ? green[600] : grey[500]}
    />
  );
};

export default RequiredCell;
