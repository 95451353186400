import React, { useEffect, useMemo, useState } from 'react';
import { useGetRoles, updateUser } from 'src/hooks';
import { useSite, useNotification, useLogging } from 'src/context';
import { Button, CircularProgress } from 'src/components';
import { EmptyState } from '../EmptyState';
import { PermissionCell } from '../PermissionCell';

const actions = ['view', 'create', 'edit', 'delete'];

const RolesAdmin = () => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { state, dispatch } = useSite();
  const { currentCompany, currentUser } = state.companyAdmin;
  const { data: roles, isLoading: isRolesLoading } = useGetRoles({
    companyId: currentCompany.id,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string>('');

  const currentRole = useMemo(() => {
    if (!roles?.results) return null;
    return roles.results.find((role) => {
      // Get unique scopes from role permissions (own, direct_report, company)
      const roleScopes = new Set<string>(
        role.permissions.map((p) => p.split('.')[2])
      );

      // Get unique scopes from user permissions
      const userScopes = new Set<string>(
        currentUser.all_permissions.map((p) => p.split('.')[2])
      );

      // Check if scopes match exactly
      return (
        JSON.stringify(Array.from(roleScopes).sort()) ===
        JSON.stringify(Array.from(userScopes).sort())
      );
    });
  }, [currentUser, roles]);

  // TODO: This is a temporary solution to get the current role
  // TODO: i.e. it can be deleted once the new roles array is populated
  useEffect(() => {
    if (roles?.results && currentRole?.code) {
      setSelectedRole(currentRole?.code);
    }
  }, [roles, currentRole]);

  const nameMap = {
    own: {
      label: 'Personal',
      subLabel: 'Territories assigned to the user',
    },
    bounded: {
      label: 'Bounded',
      subLabel: 'Territories bound to specific region',
    },
    direct_report: {
      label: 'Direct Reports',
      subLabel: "Territories assigned to the user's direct reports",
    },
    company: {
      label: 'Company',
      subLabel: 'All territories in the company',
    },
  };

  const handleCancel = () => {
    setSelectedRole(currentRole?.code || 'distributor');
  };

  const handleSubmit = async () => {
    const data = {
      roles: [selectedRole],
    };

    try {
      setIsLoading(true);
      const updatedUser = await updateUser(
        currentCompany.id,
        String(currentUser?.id),
        data
      );

      if (updatedUser) {
        dispatch({
          type: 'SET_CURRENT_USER',
          payload: updatedUser,
        });
      }
      setNotification({
        title: 'Success',
        message: 'User roles updated',
        type: 'success',
      });
    } catch (err) {
      log.exception(`Failed to update user roles`, err);
      setNotification({
        title: 'Error',
        message: err?.error_message || 'Failed to update user roles',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    if (isRolesLoading) {
      return (
        <div className="flex items-center justify-center py-12">
          <CircularProgress size="sm" color="neutral" />
        </div>
      );
    } else {
      return (
        <>
          <div className="flex gap-6">
            {/* Role Details Column */}
            <div className="flex flex-col gap-4 w-64">
              {roles.results.map((role) => (
                <label key={role.code} className="flex gap-2 cursor-pointer">
                  <div className="flex items-center">
                    <input
                      data-testid={`role-radio-${role.code}`}
                      type="radio"
                      name="role"
                      value={role.code}
                      checked={selectedRole === role.code}
                      onChange={(e) => setSelectedRole(e.target.value)}
                      className="h-4 w-4 text-primary-600 border-neutral-300 focus:ring-primary-500"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-sm font-medium text-neutral-900">
                      {role.label}
                    </div>
                    <div className="text-xs text-neutral-500">
                      {role.description}
                    </div>
                  </div>
                </label>
              ))}
            </div>

            {/* Permissions Table */}
            <div className="flex flex-col flex-1 bg-white rounded-lg border border-neutral-200">
              {/* Header Row */}
              <div className="flex px-3 py-2 bg-neutral-100 border-b border-neutral-200">
                <div className="flex-1 text-sm font-semibold text-neutral-500">
                  Territories
                </div>
                <div className="flex w-80">
                  {actions.map((action) => (
                    <div
                      key={action}
                      className="flex-1 flex justify-center text-xs font-semibold text-neutral-500"
                    >
                      {action}
                    </div>
                  ))}
                </div>
              </div>

              {/* Permission Table Rows */}
              {roles.results.map((role) => (
                <div
                  key={role.code}
                  className={
                    role.code === selectedRole ? 'flex flex-col' : 'hidden'
                  }
                >
                  {Object.entries(nameMap).map(
                    ([scope, { label, subLabel }]) => {
                      return (
                        <div
                          key={`${role.code}-${scope}`}
                          data-testid={`permission-row-${role.code}-${scope}`}
                          className="flex p-3 hover:bg-neutral-50 transition-colors border-b border-neutral-100 last:border-b-0"
                        >
                          <div className="flex flex-col flex-1 gap-1">
                            <div className="text-sm font-semibold text-neutral-700">
                              {label}
                            </div>
                            <div className="text-xs text-neutral-500">
                              {subLabel}
                            </div>
                          </div>
                          <div className="flex w-80">
                            <PermissionCell
                              permissions={role.permissions}
                              action="read"
                              scope={scope}
                            />
                            <PermissionCell
                              permissions={role.permissions}
                              action="write"
                              scope={scope}
                            />
                            <PermissionCell
                              permissions={role.permissions}
                              action="write"
                              scope={scope}
                            />
                            <PermissionCell
                              permissions={role.permissions}
                              action="delete"
                              scope={scope}
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      );
    }
  };

  const isEmpty = !roles?.results || roles.results.length === 0;

  const isDisabled =
    selectedRole === currentRole?.code ||
    isLoading ||
    selectedRole.length === 0 ||
    isEmpty;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center">
        <div className="text-lg font-semibold text-neutral-900">
          User Roles & Permissions
        </div>
      </div>

      {isEmpty ? <EmptyState /> : renderContent()}
      <div className="flex justify-end gap-4">
        <Button
          variant="outlined"
          color="neutral"
          onClick={handleCancel}
          disabled={isDisabled}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
        <Button
          loading={isLoading}
          onClick={handleSubmit}
          variant="solid"
          color="primary"
          disabled={isDisabled}
          data-testid="update-button"
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default RolesAdmin;
