import React, { useMemo, useState } from 'react';
import { Container, Box, Table, Typography, CircularProgress } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import ActivityTableToolbar from '../Tables/components/ActivityTable/ActivityTableToolbar';
import { useAuth, useSite } from 'src/context';
import { useDebounce } from 'use-debounce';
import ActivityTableHeader from '../Tables/components/ActivityTable/ActivityTableHeader';
import { TableFooter } from '../Tables';
import { ProfileBlade } from 'src/components';
import {
  useGetAllStatuses,
  useGetActivities,
  useTableVirtualization,
} from 'src/hooks';
import { ActivityRowItem, ActivityRowPlaceholder } from './components';
import { useIntegrations } from 'src/components/Settings/Integrations';

const Activity = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const isManager =
    user.permissions.is_manager || user.permissions.is_superuser;

  const { dispatch } = useSite();
  const { mappings } = useIntegrations();
  const showStatusColumn = mappings?.length > 0;

  const [sort, setSort] = useState<{
    sort_value: string;
    order: 'asc' | 'desc';
  }>({
    sort_value: null,
    order: null,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const [selectedReps, setSelectedReps] = useState<string[] | null>([]);
  const [selectedActivities, setSelectedActivities] = useState<string[] | null>(
    []
  );
  const [selectedStatuses, setSelectedStatuses] = useState<{
    [key: string]: string[];
  }>({});
  const [selectedTimeframe, setSelectedTimeframe] = useState<string | null>();

  const ROW_HEIGHT = 57; // Height of each row in pixels
  const BUFFER_ROWS = 10; // Number of extra rows to render above and below the viewport for smooth scrolling

  const queryParams = {
    sort: sort?.sort_value,
    order: sort?.order,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    q: debouncedSearchTerm,
  };

  const { data, isLoading } = useGetActivities({
    reps: selectedReps?.length > 0 ? selectedReps : null,
    activityTypes: selectedActivities?.length > 0 ? selectedActivities : null,
    statuses:
      selectedStatuses && Object.keys(selectedStatuses).length > 0
        ? selectedStatuses
        : null,
    timeframe: selectedTimeframe,
    ...queryParams,
  });

  const activities = useMemo(() => data?.results || [], [data]);

  const totalRows = activities.length;

  const {
    visibleStart,
    visibleEnd,
    topPlaceholderRows,
    bottomPlaceholderRows,
  } = useTableVirtualization({
    ref: containerRef,
    height: ROW_HEIGHT,
    count: totalRows,
    buffer: BUFFER_ROWS,
  });

  const { data: allStatuses } = useGetAllStatuses();

  const handleColSort = (col: string, order: 'asc' | 'desc') => {
    setSort({ sort_value: col, order });

    if (containerRef.current) {
      containerRef.current.scrollTop = 0; // Reset scroll to top
    }
  };

  const hasStatuses = allStatuses?.some((obj) => obj.crm_status != null);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handlePageChange = (e, newPage) => {
    setPaginationModel((prev) => ({ ...prev, page: newPage }));

    if (containerRef.current) {
      containerRef.current.scrollTop = 0; // Reset scroll to top
    }
  };

  const handlePageSizeChange = (e) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(e.target.value, 10),
      page: 0,
    }));
  };

  const handleShowBlade = ({ providerId, type }) => {
    if (!providerId || !type) return;
    dispatch({
      type: 'SET_CURRENT_PROVIDER',
      payload: {
        id: providerId,
        type,
      },
    });
    dispatch({ type: 'OPEN_PROFILE_DRAWER' });
  };

  const hasActivities = activities && activities?.length > 0;

  const visibleActivities = activities?.slice(visibleStart, visibleEnd);

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: '#FFFFFF',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          borderBottom: '1px solid var(--Neutral-200, #DDDFE0)',
          backgroundColor: '#FFFFFF',
        }}
      >
        <div
          style={{
            padding: '1rem',
            borderBottom: '1px solid var(--Neutral-200, #DDDFE0)',
            backgroundColor: '#FFFFFF',
          }}
        >
          <Typography level="h4">
            <Icon icon={faBolt} color={'#636B74'} /> Activity
          </Typography>
        </div>
        <ActivityTableToolbar
          searchTerm={searchTerm}
          onSearch={handleSearch}
          selectedReps={selectedReps}
          setSelectedReps={setSelectedReps}
          selectedActivities={selectedActivities}
          setSelectedActivities={setSelectedActivities}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          selectedTimeframe={selectedTimeframe}
          setSelectedTimeframe={setSelectedTimeframe}
          statuses={allStatuses}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: '#FFFFFF',
          borrom: 0,
        }}
      >
        <Table
          noWrap
          stickyHeader
          stickyFooter
          sx={{
            height: '100%',
            tableLayout: 'auto',
          }}
        >
          <ActivityTableHeader
            sort={sort}
            handleColSort={handleColSort}
            isManager={isManager}
            showStatusColumn={showStatusColumn}
          />
          <div
            ref={containerRef}
            style={{
              height: 'calc(100% - 9.5rem)',
              overflowY: 'auto',
              width: '100%',
              position: 'relative',
            }}
          >
            {/* Virtualized table rows */}
            {hasActivities ? (
              <div
                style={{
                  height: `${totalRows * ROW_HEIGHT}px`, // Total height of all rows
                  position: 'relative',
                }}
              >
                {topPlaceholderRows.map((_, index) => (
                  <ActivityRowPlaceholder
                    key={index}
                    sx={{
                      top: `${index * ROW_HEIGHT}px`,
                    }}
                  />
                ))}
                {visibleActivities.map((act, index) => {
                  return (
                    <div
                      key={act.id}
                      style={{
                        position: 'absolute',
                        top: `${(visibleStart + index) * ROW_HEIGHT}px`, // Correct positioning
                        left: 0,
                        right: 0,
                        height: `${ROW_HEIGHT}px`,
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '1px solid var(--Neutral-200, #DDDFE0)',
                      }}
                    >
                      <ActivityRowItem
                        key={act.id}
                        act={act}
                        isManager={isManager}
                        hasStatuses={hasStatuses}
                        onShowblade={handleShowBlade}
                        isVisible
                      />
                    </div>
                  );
                })}
                {bottomPlaceholderRows.map((_, index) => (
                  <ActivityRowPlaceholder
                    key={index}
                    sx={{
                      top: `${(visibleEnd + index) * ROW_HEIGHT}px`, // Correct positioning
                    }}
                  />
                ))}
              </div>
            ) : (
              !isLoading &&
              !data && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'var(--Neutral-600, #5D5E5F)',
                      fontSize: '1rem',
                      fontWeight: 500,
                    }}
                  >
                    No activities
                  </Typography>
                </Box>
              )
            )}

            {isLoading && (
              <Box
                sx={{
                  position: 'absolute', // Keep it relative to the container
                  top: `${containerRef.current?.scrollTop || 0}px`, // Account for the scroll position
                  left: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  minHeight: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  zIndex: 10,
                }}
              >
                <CircularProgress size="sm" color="neutral" />
              </Box>
            )}
          </div>
        </Table>
        <TableFooter
          rowCount={data?.count || 0}
          page={paginationModel.page}
          pageSize={paginationModel.pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      <ProfileBlade />
    </Container>
  );
};

export default Activity;
