import {
  // Business & Communication
  faUser,
  faUsers,
  faUserTie,
  faBuilding,
  faEnvelope,
  faPhone,
  faVideo,
  faComments,
  faInbox,
  // Documents & Data
  faFile,
  faFileContract,
  faFileInvoice,
  faFolder,
  faDatabase,
  // Analytics & Reports
  faChartLine,
  faChartBar,
  faChartPie,
  faProjectDiagram,
  faFilter,
  // Actions & Interface
  faSearch,
  faCog,
  faPlus,
  faMinus,
  faCheck,
  faTimes,
  faEdit,
  faTrash,
  faSave,
  faSync,
  // Time & Planning
  faCalendar,
  faClock,
  faHistory,
  // Security & Access
  faLock,
  faUnlock,
  faKey,
  faShield,
  // Finance
  faDollar,
  faCreditCard,
  faReceipt,
  faHandshake,
  // Status & Notifications
  faInfo,
  faBell,
  faExclamation,
  faQuestion,
  faCheckCircle,
  // Additional Navigation & Interface
  faHome,
  faBars,
  faArrowLeft,
  faArrowRight,
  faExpand,
  faCompress,
  faEllipsisV,
  faSort,
  // Media & Files
  faImage,
  faCamera,
  faPaperclip,
  faPrint,
  faBookmark,
  faCopy,
  faPaste,
  // Location & Travel
  faMapMarker,
  faCompass,
  faGlobe,
  faCar,
  // Social & Communication
  faThumbsUp,
  faThumbsDown,
  faReply,
  faShare,
  // Settings & Tools
  faWrench,
  faSliders,
  faMagic,
  faLightbulb,
  // Additional Status
  faBan,
  faEye,
  faEyeSlash,
  faBolt,
  faPencil,
  faTrashAlt,
  faTimesCircle,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';

// Curated list of common icons
export const COMMON_ICONS = [
  // Business & Communication
  { id: 'user', icon: faUser, name: 'User' },
  { id: 'users', icon: faUsers, name: 'Team' },
  { id: 'userTie', icon: faUserTie, name: 'Employee' },
  { id: 'building', icon: faBuilding, name: 'Company' },
  { id: 'envelope', icon: faEnvelope, name: 'Email' },
  { id: 'phone', icon: faPhone, name: 'Phone' },
  { id: 'video', icon: faVideo, name: 'Video' },
  { id: 'comments', icon: faComments, name: 'Messages' },
  { id: 'inbox', icon: faInbox, name: 'Inbox' },

  // Documents & Data
  { id: 'file', icon: faFile, name: 'Document' },
  { id: 'fileContract', icon: faFileContract, name: 'Contract' },
  { id: 'fileInvoice', icon: faFileInvoice, name: 'Invoice' },
  { id: 'folder', icon: faFolder, name: 'Folder' },
  { id: 'database', icon: faDatabase, name: 'Database' },

  // Analytics & Reports
  { id: 'chartLine', icon: faChartLine, name: 'Trends' },
  { id: 'chartBar', icon: faChartBar, name: 'Statistics' },
  { id: 'chartPie', icon: faChartPie, name: 'Distribution' },
  { id: 'projectDiagram', icon: faProjectDiagram, name: 'Project' },
  { id: 'filter', icon: faFilter, name: 'Filter' },

  // Actions & Controls
  { id: 'search', icon: faSearch, name: 'Search' },
  { id: 'cog', icon: faCog, name: 'Settings' },
  { id: 'plus', icon: faPlus, name: 'Add' },
  { id: 'minus', icon: faMinus, name: 'Remove' },
  { id: 'check', icon: faCheck, name: 'Check' },
  { id: 'times', icon: faTimes, name: 'Close' },
  { id: 'edit', icon: faEdit, name: 'Edit' },
  { id: 'trash', icon: faTrash, name: 'Delete' },
  { id: 'save', icon: faSave, name: 'Save' },
  { id: 'sync', icon: faSync, name: 'Sync' },

  // Time Management
  { id: 'calendar', icon: faCalendar, name: 'Calendar' },
  { id: 'clock', icon: faClock, name: 'Time' },
  { id: 'history', icon: faHistory, name: 'History' },

  // Security
  { id: 'lock', icon: faLock, name: 'Lock' },
  { id: 'unlock', icon: faUnlock, name: 'Unlock' },
  { id: 'key', icon: faKey, name: 'Key' },
  { id: 'shield', icon: faShield, name: 'Security' },

  // Finance
  { id: 'dollar', icon: faDollar, name: 'Payment' },
  { id: 'creditCard', icon: faCreditCard, name: 'Credit Card' },
  { id: 'receipt', icon: faReceipt, name: 'Receipt' },
  { id: 'handshake', icon: faHandshake, name: 'Deal' },

  // Status & Notifications
  { id: 'info', icon: faInfo, name: 'Info Simple' },
  { id: 'bell', icon: faBell, name: 'Notifications' },
  { id: 'exclamation', icon: faExclamation, name: 'Alert Simple' },
  { id: 'question', icon: faQuestion, name: 'Help' },
  { id: 'checkCircle', icon: faCheckCircle, name: 'Success' },

  // Additional Navigation & Interface
  { id: 'home', icon: faHome, name: 'Home' },
  { id: 'bars', icon: faBars, name: 'Menu' },
  { id: 'arrowLeft', icon: faArrowLeft, name: 'Back' },
  { id: 'arrowRight', icon: faArrowRight, name: 'Forward' },
  { id: 'expand', icon: faExpand, name: 'Expand' },
  { id: 'compress', icon: faCompress, name: 'Compress' },
  { id: 'ellipsisV', icon: faEllipsisV, name: 'More' },
  { id: 'sort', icon: faSort, name: 'Sort' },

  // Media & Files
  { id: 'image', icon: faImage, name: 'Image' },
  { id: 'camera', icon: faCamera, name: 'Camera' },
  { id: 'paperclip', icon: faPaperclip, name: 'Attach' },
  { id: 'print', icon: faPrint, name: 'Print' },
  { id: 'bookmark', icon: faBookmark, name: 'Bookmark' },
  { id: 'copy', icon: faCopy, name: 'Copy' },
  { id: 'paste', icon: faPaste, name: 'Paste' },

  // Location & Travel
  { id: 'mapMarker', icon: faMapMarker, name: 'Location' },
  { id: 'compass', icon: faCompass, name: 'Navigate' },
  { id: 'globe', icon: faGlobe, name: 'Global' },
  { id: 'car', icon: faCar, name: 'Transport' },

  // Social & Communication
  { id: 'thumbsUp', icon: faThumbsUp, name: 'Approve' },
  { id: 'thumbsDown', icon: faThumbsDown, name: 'Reject' },
  { id: 'reply', icon: faReply, name: 'Reply' },
  { id: 'share', icon: faShare, name: 'Share' },

  // Settings & Tools
  { id: 'wrench', icon: faWrench, name: 'Tools' },
  { id: 'sliders', icon: faSliders, name: 'Adjust' },
  { id: 'magic', icon: faMagic, name: 'Auto-fix' },
  { id: 'lightbulb', icon: faLightbulb, name: 'Idea' },

  // Additional Status
  { id: 'ban', icon: faBan, name: 'Forbidden' },
  { id: 'eye', icon: faEye, name: 'View' },
  { id: 'eyeSlash', icon: faEyeSlash, name: 'Hide' },
  { id: 'bolt', icon: faBolt, name: 'Bolt' },
  { id: 'pencil', icon: faPencil, name: 'Pencil' },
  { id: 'trashAlt', icon: faTrashAlt, name: 'Trash' },
  { id: 'timesCircle', icon: faTimesCircle, name: 'Error' },
  { id: 'exclamationCircle', icon: faExclamationCircle, name: 'Alert Circle' },
  { id: 'infoCircle', icon: faInfoCircle, name: 'Info Circle' },
];
