import { Typography, useTheme, Tooltip } from '@mui/material';
import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

interface DateRangeLabelProps {
  label?: string;
  tooltipText?: string;
}

const DateRangeLabel = ({ ...props }: DateRangeLabelProps) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        fontSize: '0.85rem',
        fontWeight: 600,
        color: theme.palette.grey[700],
        gap: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0.25rem',
      }}
    >
      {props?.label || 'Date Range'}
      <Tooltip
        title={
          props?.tooltipText || 'This field is required to complete the form'
        }
        placement="bottom-end"
        arrow
      >
        <Icon
          icon={faInfoCircle}
          color={theme.palette.grey[500]}
          style={{ cursor: 'pointer' }}
        />
      </Tooltip>
    </Typography>
  );
};

export default DateRangeLabel;
