import React, { useState } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faEllipsisVertical,
} from '@fortawesome/pro-solid-svg-icons';
interface ReimbursementHeaderProps {
  column: {
    label: string;
    key: string;
  };
  isSortable?: boolean;
  isResizable?: boolean;
  sortOrder?: 'asc' | 'desc' | null;
  onSort?: (key: string) => void;
  onResizing?: (id: string, e: React.MouseEvent) => void;
  sx?: React.CSSProperties;
  sortIcon?: React.ReactNode;
  isSorting?: boolean;
}

const ReimbursementHeader = ({
  column,
  isSortable = false,
  isResizable = false,
  sortOrder = null,
  onResizing = (id: string, e: React.MouseEvent) => null,
  onSort = (key: string) => null,
  sx = {},
  sortIcon,
  isSorting = false,
}: ReimbursementHeaderProps) => {
  const [wasResizing, setWasResizing] = useState(false);
  const classNames = isResizable
    ? 'group relative px-2 py-2 text-left whitespace-nowrap cursor-pointer select-none flex-shrink-0'
    : 'group flex-1 min-w-24 px-2 ml-2 py-2 text-right cursor-pointer flex-shrink-0';

  const handleMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setWasResizing(true);
    onResizing?.(column.key, e);
  };

  const handleMouseUp = () => {
    setTimeout(() => setWasResizing(false), 300);
  };

  return (
    <div
      data-testid={`reimbursement-header-${column.key}`}
      onClick={(e) => {
        if (!wasResizing) {
          onSort?.(column.key);
        }
      }}
      style={{ ...sx }}
      className={`group h-8 hover:bg-neutral-100 rounded-md text-sm font-bold text-neutral-600 ${classNames}`}
    >
      <div
        className={`flex items-center gap-1 ${
          isResizable ? '' : 'justify-end whitespace-nowrap flex-1'
        }`}
      >
        <span className="truncate">{column.label}</span>
        {isSortable && (
          <div
            className={` opacity-0 group-hover:opacity-100 group-active:opacity-100 ${
              isSorting ? 'opacity-100' : 'opacity-0'
            } ${isResizable ? 'mr-4' : ''}`}
          >
            <Icon
              icon={
                isSorting
                  ? sortOrder === 'asc'
                    ? faArrowUp
                    : faArrowDown
                  : faArrowDown
              }
              size="sm"
              className=" text-neutral-600"
            />
          </div>
        )}
      </div>
      {isResizable && (
        <div
          className="absolute right-1 top-0 h-full w-4 flex items-center justify-center cursor-col-resize opacity-0 group-hover:opacity-100"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <Icon
            size="lg"
            icon={faEllipsisVertical}
            className="text-neutral-400"
          />
        </div>
      )}
    </div>
  );
};

export default ReimbursementHeader;
