import React from 'react';
import {
  InitializeIntegration,
  MedRM,
  Mappings,
  Options,
  OneTimeSyncSection,
  DeleteMappingSection,
} from './components';
import { useIntegrations } from './context';

const Integrations = () => {
  const { currentIntegration, currentMapping } = useIntegrations();

  const isMedRMIntegration = currentIntegration === 'medrm';

  return (
    <div className="flex flex-col gap-2 h-[calc(100vh-10rem)]">
      {!currentIntegration && <InitializeIntegration />}
      {currentIntegration && isMedRMIntegration && <MedRM />}
      {currentIntegration && !currentMapping && !isMedRMIntegration && (
        <Mappings />
      )}
      {currentIntegration && currentMapping && !isMedRMIntegration && (
        <div className="flex flex-col gap-2">
          <Options />
          <OneTimeSyncSection />
          <DeleteMappingSection />
        </div>
      )}
    </div>
  );
};

export default Integrations;
