'use client';

import { useTabPanel } from '../../context';
import React from 'react';

interface TabPanelProps {
  tabSetId: string;
  children: React.ReactNode;
  index: number;
  className?: string;
}

const TabPanel = ({ tabSetId, index, children, className }: TabPanelProps) => {
  const { isActive } = useTabPanel(tabSetId, index);
  if (!isActive) return null;
  return (
    <div className={`text-gray-800 transition-all duration-300 ${className}`}>
      {children}
    </div>
  );
};

export default TabPanel;
