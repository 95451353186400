import useSWR from 'swr';
import { getActivityTypes } from 'src/api';
import { QUERY_KEYS } from './constants';

const useGetActivityTypes = () => {
  return useSWR([QUERY_KEYS.GET_ACTIVITY_TYPES], () => getActivityTypes(), {
    keepPreviousData: true,
    revalidateOnFocus: false,
  });
};

export default useGetActivityTypes;
