import { request } from 'src/api/request';

export const getRoles = async ({ companyId }: { companyId: string }) => {
  return await request({
    url: `v1/accounts/companies/${companyId}/roles/`,
    method: 'GET',
  });
};

export const getSecurityPermissions = async ({
  companyId,
  userId,
}: {
  companyId: string;
  userId: string;
}) => {
  return await request({
    url: `v1/accounts/companies/${companyId}/users/${userId}/permissions/`,
    method: 'GET',
  });
};

export const updateSecurityPermissions = async ({
  companyId,
  userId,
  data,
}: {
  companyId: string;
  userId: string;
  data: { permissions: string[] };
}) => {
  return await request({
    url: `v1/accounts/companies/${companyId}/users/${userId}/permissions/`,
    method: 'POST',
    data,
  });
};
