import React from 'react';
import { BreadcrumbItem } from './components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
interface BreadcrumbsProps {
  items: BreadcrumbItem[];
  isLoading?: boolean;
}

const NewBreadcrumbs = ({ items, isLoading }: BreadcrumbsProps) => {
  if (!items.length) return null;

  return (
    <div className="py-2 px-2 rounded-md">
      <div className="flex items-center gap-2">
        {items.map((item, index) => (
          <React.Fragment key={item.id}>
            {index > 0 && (
              <Icon
                size="sm"
                icon={faChevronRight}
                className="text-neutral-400"
              />
            )}
            <BreadcrumbItem {...item} isLoading={isLoading} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default NewBreadcrumbs;
