import React, { useMemo, useCallback } from 'react';
import { useAuth } from 'src/context';

// Define resource types
type ResourceType = 'territory' | 'list';

// Define action types
type ActionType = 'read' | 'write' | 'delete';

// Define scope types
type ScopeType = 'own' | 'direct_report' | 'bounded' | 'company' | '*';

interface Permission {
  action: string;
  resource: string;
  scope: string;
  original: string;
}

interface PermissionRecord {
  [key: string]: boolean;
}

interface StructuredPermissionRecord {
  [key: string]: Permission;
}

interface AuthorizationResult {
  granted: boolean;
  missingPermissions: string[];
}

/**
 * Enhanced useAuthorization hook that provides predefined permission sets
 * and helper functions for checking permissions.
 *
 * @returns An object with permission checking functions and predefined permission sets
 */
const useAuthorization = () => {
  const { user } = useAuth();

  // Parse permissions into structured objects
  const parsedPermissions = useMemo(() => {
    const allPermissions = user?.all_permissions || [];
    const directMap: PermissionRecord = {};
    const structuredMap: StructuredPermissionRecord = {};

    allPermissions.forEach((permission) => {
      directMap[permission] = true;

      const parts = permission.split('.');
      if (parts.length === 3) {
        const [action, resource, scope] = parts;
        structuredMap[permission] = {
          action,
          resource,
          scope,
          original: permission,
        };
      }
    });

    return { directMap, structuredMap };
  }, [user?.all_permissions]);

  // Group permissions by action and resource for easier access
  const permissionsByActionAndResource = useMemo(() => {
    const result: Record<string, Record<string, string[]>> = {};

    Object.values(parsedPermissions.structuredMap).forEach((permission) => {
      if (!result[permission.action]) {
        result[permission.action] = {};
      }

      if (!result[permission.action][permission.resource]) {
        result[permission.action][permission.resource] = [];
      }

      result[permission.action][permission.resource].push(permission.scope);
    });

    return result;
  }, [parsedPermissions.structuredMap]);

  /**
   * Check if the user has a specific permission
   * @param permission The permission string to check
   * @returns True if the user has the permission, false otherwise
   */
  const hasPermission = useCallback(
    (permission: string) => !!parsedPermissions.directMap[permission],
    [parsedPermissions]
  );

  /**
   * Check if the user has any of the specified permissions
   * @param permissions An array of permission strings to check
   * @returns True if the user has any of the permissions, false otherwise
   */
  const hasAnyPermission = useCallback(
    (permissions: string[]) =>
      permissions.some((permission) => hasPermission(permission)),
    [hasPermission]
  );

  /**
   * Check if the user has a specific role
   * @param role The role to check
   * @returns True if the user has the role, false otherwise
   */
  const hasRole = useCallback(
    (role: string) => (user?.roles || []).includes(role),
    [user?.roles]
  );

  /**
   * Check if the user can perform an action on a resource with a specific scope
   * @param action The action to check (read, write, delete)
   * @param resource The resource to check (territory, list)
   * @param scope The scope to check (own, direct_report, bounded, company, *)
   * @returns An object with a granted boolean and an array of missing permissions
   */
  const can = useCallback(
    (
      action: ActionType,
      resource: ResourceType,
      scope: ScopeType
    ): AuthorizationResult => {
      const actionPermissions = permissionsByActionAndResource[action] || {};
      const resourcePermissions = actionPermissions[resource] || [];

      // Check for exact match first
      const hasExactScope = resourcePermissions.includes(scope);
      if (hasExactScope) {
        return {
          granted: true,
          missingPermissions: [],
        };
      }

      // Check for wildcard permission
      const hasWildcardPermission = resourcePermissions.includes('*');
      if (hasWildcardPermission) {
        return {
          granted: true,
          missingPermissions: [],
        };
      }

      const permission = `${action}.${resource}.${scope}`;
      return {
        granted: false,
        missingPermissions: [permission],
      };
    },
    [permissionsByActionAndResource]
  );

  /**
   * Check if the user can read a resource with a specific scope
   * @param resource The resource to check (territory, list)
   * @param scope The scope to check (own, direct_report, bounded, company, *)
   * @returns True if the user has permission to read the resource with the specified scope, false otherwise
   */
  const canRead = useCallback(
    (resource: ResourceType, scope: ScopeType) => {
      if (scope === '*') {
        // Check if user has any read permission for this resource
        const actionPermissions = permissionsByActionAndResource['read'] || {};
        const resourcePermissions = actionPermissions[resource] || [];
        return resourcePermissions.length > 0;
      }
      return can('read', resource, scope).granted;
    },
    [can, permissionsByActionAndResource]
  );

  /**
   * Check if the user can write a resource with a specific scope
   * @param resource The resource to check (territory, list)
   * @param scope The scope to check (own, direct_report, bounded, company, *)
   * @returns True if the user has permission to write the resource with the specified scope, false otherwise
   */
  const canWrite = useCallback(
    (resource: ResourceType, scope: ScopeType) => {
      if (scope === '*') {
        // Check if user has any write permission for this resource
        const actionPermissions = permissionsByActionAndResource['write'] || {};
        const resourcePermissions = actionPermissions[resource] || [];
        return resourcePermissions.length > 0;
      }
      return can('write', resource, scope).granted;
    },
    [can, permissionsByActionAndResource]
  );

  /**
   * Check if the user can delete a resource with a specific scope
   * @param resource The resource to check (territory, list)
   * @param scope The scope to check (own, direct_report, bounded, company, *)
   * @returns True if the user has permission to delete the resource with the specified scope, false otherwise
   */
  const canDelete = useCallback(
    (resource: ResourceType, scope: ScopeType) => {
      if (scope === '*') {
        // Check if user has any delete permission for this resource
        const actionPermissions =
          permissionsByActionAndResource['delete'] || {};
        const resourcePermissions = actionPermissions[resource] || [];
        return resourcePermissions.length > 0;
      }
      return can('delete', resource, scope).granted;
    },
    [can, permissionsByActionAndResource]
  );

  /**
   * Check if the user is bounded to a specific resource
   * This is a special check for territorial restrictions
   * @param resource The resource to check (territory, list)
   * @returns True if the user has any permission with the 'bounded' scope for this resource
   */
  const isBounded = useCallback(
    (resource: ResourceType): boolean => {
      // Check if the user has any permission with the 'bounded' scope for this resource
      for (const action in permissionsByActionAndResource) {
        const resourcePermissions =
          permissionsByActionAndResource[action]?.[resource] || [];
        if (resourcePermissions.includes('bounded')) {
          return true;
        }
      }
      return false;
    },
    [permissionsByActionAndResource]
  );

  /**
   * Check if the user is bounded to a territory
   * This is a shorthand for isBounded('territory')
   * @returns True if the user has any permission with the 'bounded' scope for territories
   */
  const isBoundedToTerritory = useCallback(
    () => isBounded('territory'),
    [isBounded]
  );

  return {
    // Basic permission checking
    permissions: parsedPermissions.directMap,
    structuredPermissions: parsedPermissions.structuredMap,
    roles: user?.roles || [],
    hasPermission,
    hasAnyPermission,
    hasRole,
    can,

    // Generic action checkers
    canRead,
    canWrite,
    canDelete,

    // Bounded checks
    isBounded,
    isBoundedToTerritory,
  };
};

export default useAuthorization;
