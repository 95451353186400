import {
  createContext,
  useReducer,
  useContext,
  useEffect,
  Dispatch,
} from 'react';
import { useLocalStorage, useGetLocalStorage } from 'src/hooks';

const TableActionTypes: { [key: string]: string } = {
  SET_SECONDARY_HEADER_WIDTH: 'SET_SECONDARY_HEADER_WIDTH',
  SET_DATE_RANGE: 'SET_DATE_RANGE',
  SET_SHOW_ICON: 'SET_SHOW_ICON',
  SET_CURRENT_SEARCH_IDS: 'SET_CURRENT_SEARCH_IDS',
};

type TableState = {
  secondaryHeaderWidth: number;
  dateRange: Record<string, any>;
  showIcon: boolean;
  currentSearchIds?: string[];
};

type TableActions = {
  type: keyof typeof TableActionTypes;
  payload: {
    secondaryHeaderWidth?: TableState['secondaryHeaderWidth'];
    dateRange?: TableState['dateRange'];
    showIcon?: TableState['showIcon'];
    currentSearchIds?: TableState['currentSearchIds'];
  };
};

const initialState: TableState = {
  secondaryHeaderWidth: 475,
  dateRange: {
    every_table: {
      gte: '2020',
      lte: '2025',
      isTrending: false,
      range: 'every_table',
    },
  },
  showIcon: false,
  currentSearchIds: [],
};

const TableContext = createContext<{
  state: TableState;
  dispatch: Dispatch<TableActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const tableReducer = (state: TableState, action: TableActions) => {
  switch (action.type) {
    case TableActionTypes.SET_SECONDARY_HEADER_WIDTH:
      return {
        ...state,
        secondaryHeaderWidth: action.payload.secondaryHeaderWidth,
      };
    case TableActionTypes.SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload.dateRange,
      };
    case TableActionTypes.SET_SHOW_ICON:
      return {
        ...state,
        showIcon: action.payload.showIcon,
      };
    case TableActionTypes.SET_CURRENT_SEARCH_IDS:
      return {
        ...state,
        currentSearchIds: action.payload.currentSearchIds,
      };
    default:
      return state;
  }
};

export const TableProvider = (props) => {
  const [state, dispatch] = useReducer(tableReducer, initialState);
  const [_, setDateRangeLocalStorage] = useLocalStorage('dateRange', {});
  const currentDateRange = useGetLocalStorage('dateRange');

  function updateDateRange(dateRange: Record<string, any>) {
    if (!dateRange) return;
    setDateRangeLocalStorage(dateRange);
    dispatch({
      type: TableActionTypes.SET_DATE_RANGE,
      payload: { dateRange },
    });
  }

  useEffect(() => {
    if (Object.keys(currentDateRange)?.length === 0) {
      updateDateRange(initialState.dateRange);
      return;
    }
    updateDateRange(currentDateRange);
  }, []);

  return <TableContext.Provider value={{ state, dispatch }} {...props} />;
};

export const useTable = () => {
  const context = useContext(TableContext);
  const [_, setDateRangeLocalStorage] = useLocalStorage('dateRange', {});

  if (!context) {
    throw new Error('useTable must be used within a TableProvider');
  }

  const {
    state: { secondaryHeaderWidth, dateRange, showIcon, currentSearchIds },
    dispatch,
  } = context;

  function setSecondaryHeaderWidth(secondaryHeaderWidth: number) {
    if (!secondaryHeaderWidth) return;
    dispatch({
      type: TableActionTypes.SET_SECONDARY_HEADER_WIDTH,
      payload: { secondaryHeaderWidth },
    });
  }

  function setDateRange(dateRange: Record<string, any>) {
    if (!dateRange) return;
    setDateRangeLocalStorage(dateRange);
    dispatch({
      type: TableActionTypes.SET_DATE_RANGE,
      payload: { dateRange },
    });
  }

  function setShowIcon(showIcon: boolean) {
    dispatch({
      type: TableActionTypes.SET_SHOW_ICON,
      payload: { showIcon },
    });
  }

  function setCurrentSearchIds(currentSearchIds: string[]) {
    dispatch({
      type: TableActionTypes.SET_CURRENT_SEARCH_IDS,
      payload: { currentSearchIds },
    });
  }

  return {
    currentSearchIds,
    dateRange,
    secondaryHeaderWidth,
    setCurrentSearchIds,
    setDateRange,
    setSecondaryHeaderWidth,
    setShowIcon,
    showIcon,
  };
};
