import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

interface CheckboxProps {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'outlined' | 'solid' | 'soft';
  color?: 'primary' | 'neutral' | 'danger' | 'success' | 'warning';
  className?: string;
  sx?: React.CSSProperties;
}

const Checkbox = ({
  checked,
  onChange,
  label,
  size = 'md',
  variant = 'solid',
  color = 'primary',
  className = '',
  sx,
}: CheckboxProps) => {
  const sizeClasses = {
    sm: 'w-4 h-4 text-xs rounded',
    md: 'w-5 h-5 text-sm rounded',
    lg: 'w-6 h-6 text-base rounded',
  };

  const labelSizes = {
    sm: 'text-xs',
    md: 'text-sm',
    lg: 'text-base',
  };

  const variantClasses = {
    outlined: {
      primary: 'border-sky-600 bg-white hover:bg-sky-50',
      neutral: 'border-neutral-500 bg-white hover:bg-neutral-50',
      danger: 'border-red-500 bg-white hover:bg-red-50',
      success: 'border-green-500 bg-white hover:bg-green-50',
      warning: 'border-yellow-500 bg-white hover:bg-yellow-50',
    },
    solid: {
      primary: 'border-sky-600 bg-sky-600 hover:bg-sky-700',
      neutral: 'border-neutral-500 bg-neutral-500 hover:bg-neutral-600',
      danger: 'border-red-500 bg-red-500 hover:bg-red-600',
      success: 'border-green-500 bg-green-500 hover:bg-green-600',
      warning: 'border-yellow-500 bg-yellow-500 hover:bg-yellow-600',
    },
    soft: {
      primary: 'border-sky-200 bg-sky-50 hover:bg-sky-100',
      neutral: 'border-neutral-100 bg-neutral-50 hover:bg-neutral-100',
      danger: 'border-red-100 bg-red-50 hover:bg-red-100',
      success: 'border-green-100 bg-green-50 hover:bg-green-100',
      warning: 'border-yellow-100 bg-yellow-50 hover:bg-yellow-100',
    },
  };

  const checkmarkColors = {
    outlined: {
      primary: 'text-sky-600',
      neutral: 'text-neutral-500',
      danger: 'text-red-500',
      success: 'text-green-500',
      warning: 'text-yellow-500',
    },
    solid: {
      primary: 'text-white',
      neutral: 'text-white',
      danger: 'text-white',
      success: 'text-white',
      warning: 'text-neutral-900',
    },
    soft: {
      primary: 'text-sky-800',
      neutral: 'text-neutral-700',
      danger: 'text-red-700',
      success: 'text-green-700',
      warning: 'text-yellow-700',
    },
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const syntheticEvent = {
      target: {
        checked: !checked,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    onChange(syntheticEvent);
  };

  return (
    <label
      className={`flex items-center cursor-pointer gap-2 ${className}`}
      onClick={handleClick}
      style={sx}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="hidden"
      />
      <div
        className={`
          ${sizeClasses[size]}
          flex items-center justify-center
          border transition-colors
          ${
            checked
              ? variantClasses[variant][color]
              : 'border-neutral-300 bg-white hover:border-primary-500'
          }
        `}
      >
        {checked && (
          <Icon
            icon={faCheck}
            className={`${checkmarkColors[variant][color]} text-xs`}
          />
        )}
      </div>
      {label && (
        <span className={`text-neutral-800 select-none ${labelSizes[size]}`}>
          {label}
        </span>
      )}
    </label>
  );
};

export default Checkbox;
