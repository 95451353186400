import React, {
  useRef,
  useEffect,
  createContext,
  useContext,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  anchor?: 'left' | 'right' | 'top' | 'bottom';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  hideBackdrop?: boolean;
  showCloseButton?: boolean;
  className?: string;
}

const DrawerContext = createContext<{ onClose: () => void } | undefined>(
  undefined
);

const Drawer = ({
  isOpen,
  onClose,
  children,
  anchor = 'right',
  size = 'md',
  hideBackdrop = false,
  showCloseButton = false,
  className = '',
}: DrawerProps) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setMounted(true);
    } else {
      const timer = setTimeout(() => {
        setMounted(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!mounted) return null;

  const sizeClasses = {
    sm: 'w-64',
    md: 'w-80',
    lg: 'w-96',
    xl: 'w-1/3',
  };

  const heightClasses = {
    sm: 'h-64',
    md: 'h-80',
    lg: 'h-96',
    xl: 'h-1/3',
  };

  const isVertical = anchor === 'left' || anchor === 'right';

  const getAnimationClasses = () => {
    if (isOpen) {
      return {
        right: 'animate-slide-in-right rounded-tl-lg',
        left: 'animate-slide-in-left rounded-tr-lg',
        top: 'animate-slide-in-top ',
        bottom: 'animate-slide-in-bottom ',
      }[anchor];
    }
    return {
      right: 'animate-slide-out-right',
      left: 'animate-slide-out-left',
      top: 'animate-slide-out-top',
      bottom: 'animate-slide-out-bottom',
    }[anchor];
  };

  return createPortal(
    <DrawerContext.Provider value={{ onClose }}>
      <div
        className={`fixed inset-0 z-50 transition-opacity duration-300 ${
          isOpen ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div
          className={`absolute inset-0  ${
            hideBackdrop ? 'backdrop-blur-none' : 'bg-black/50 backdrop-blur-sm'
          }`}
          onClick={onClose}
        />
        <div
          ref={drawerRef}
          className={`
              fixed
              bg-white
              shadow-xl
              flex
              flex-col
              h-[calc(100vh-theme(spacing.14))]
              overflow-hidden
              ${isVertical ? sizeClasses[size] : heightClasses[size]}
              ${anchor === 'right' && 'right-0 top-14 bottom-0'}
              ${anchor === 'left' && 'left-0 top-14 bottom-0'}
              ${anchor === 'top' && 'top-16 left-0 right-0'}
              ${anchor === 'bottom' && 'bottom-0 left-0 right-0'}
              ${getAnimationClasses()}
              ${className}
            `}
        >
          {showCloseButton && <Drawer.CloseButton />}
          {children}
        </div>
      </div>
    </DrawerContext.Provider>,
    document.body
  );
};

const DrawerCloseButton = ({ className = '' }: { className?: string }) => {
  const context = useContext(DrawerContext);
  if (!context) throw new Error('CloseButton must be used within a Drawer');

  return (
    <button
      onClick={context.onClose}
      className={`absolute top-1 right-1 w-5 h-5 flex items-center justify-center text-neutral-400 hover:text-neutral-600 transition-colors ${className}`}
      aria-label="Close drawer"
    >
      <Icon icon={faTimes} className="w-4 h-4" />
    </button>
  );
};
DrawerCloseButton.displayName = 'Drawer.CloseButton';

const DrawerHeader = ({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={`
        px-6 
        py-4 
        flex 
        items-start
        justify-between
        shrink-0
        ${className}
      `}
  >
    {children}
  </div>
);
DrawerHeader.displayName = 'Drawer.Header';

const DrawerContent = ({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={`
        flex-1
        flex
        flex-col
        min-h-0
        overflow-hidden
        ${className}
      `}
  >
    {children}
  </div>
);
DrawerContent.displayName = 'Drawer.Content';

const DrawerFooter = ({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={`
        px-6 
        py-4 
        border-t 
        border-neutral-200 
        flex 
        items-center 
        justify-end 
        gap-2
        shrink-0
        ${className}
      `}
  >
    {children}
  </div>
);
DrawerFooter.displayName = 'Drawer.Footer';

Drawer.CloseButton = DrawerCloseButton;
Drawer.Header = DrawerHeader;
Drawer.Content = DrawerContent;
Drawer.Footer = DrawerFooter;

export default Drawer;
