import { Box } from '@mui/material';
import { useMemo } from 'react';
import { PushToCRMGroup, MedRMStatus } from 'src/components';
import { useGetMappings } from 'src/hooks';

const CrmCell = ({ row }) => {
  const { data: allMappings } = useGetMappings({
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const crmTypes = useMemo(() => {
    return Array.from(
      new Set(
        allMappings?.results
          ?.filter((mapping) => mapping.enabled)
          .map((mapping) => mapping.crm?.toLowerCase())
      )
    );
  }, [allMappings]);

  // This needs to be passed into pushToCRMGroup to define the content type of the row
  // because tables sometimes display content types that don't match the content type of the profile
  const entityType = row?.provider?.type || row?.type || row?.content_type;

  const isMedRM = useMemo(() => crmTypes.includes('medrm'), [crmTypes]);
  return (
    <Box
      id={`crm-cell-${row.provider_id}`}
      onClick={(e) => e.stopPropagation()}
      sx={{
        width: '100%',
      }}
    >
      {isMedRM ? (
        <MedRMStatus provider={row} />
      ) : (
        <PushToCRMGroup
          provider={row}
          variant="regular"
          entityType={entityType}
        />
      )}
    </Box>
  );
};

export default CrmCell;
