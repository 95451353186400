import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  useLogging,
  useNotification,
  useLayoutControl,
  useCRMButtonContext,
} from 'src/context';
import { PushToCRMButton } from '../Buttons';
import {
  usePushToCRM,
  useGetMappings,
  useGetUserInputOptions,
} from 'src/hooks';
import { Box } from '@mui/joy';
import { CRMMaxMenu } from './CRMMaxMenu';

// Case 1: No mappings - Button will not show
// Case 1a: One or More mapping, but mapping not enabled - button will not show
// Case 2: One mapping, no options - Submit
// Case 3: One mapping, one or more options - Dialog Steps
// Case 4: Multiple mappings, 0 or more options - Dialog, then based on mapping, either submit or show the flow

interface PushToCRMGroupProps {
  provider: any;
  variant?: 'compact' | 'regular';
  sx?: any;
  newHeaderView?: boolean;
  entityType?: string;
}

const PushToCRMGroup = ({
  provider,
  variant = 'regular',
  sx = {},
  newHeaderView = false,
  entityType,
}: PushToCRMGroupProps) => {
  const router = useRouter();
  const { type } = router.query as { type: string };
  const log = useLogging();

  const { clickedButtons, setClickedButtons, reenableButton } =
    useCRMButtonContext();
  const isDisabled = clickedButtons.includes(provider?.id);

  const typeNameMap = {
    hcp: 'hcp',
    clinic: 'clinic',
    center: 'center',
  };

  const contentType = entityType
    ? entityType.toLowerCase()
    : !!type && typeNameMap[type?.toLowerCase()]
    ? type?.toLowerCase()
    : provider?.content_type?.toLowerCase();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const { setNotification } = useNotification();
  const { setPushDuplicateToCRM, setPushProviderToCRM } = useLayoutControl();

  const { mutateAsync: pushToCRM, isLoading } = usePushToCRM({
    id: provider?.provider_id,
  });

  const { data: allMappings } = useGetMappings({
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  //find any enabled mappings
  const enabledCrmTypes = useMemo(() => {
    return Array.from(
      new Set(
        allMappings?.results
          ?.filter((mapping) => mapping.enabled)
          .map((mapping) => mapping.crm?.toLowerCase())
      )
    );
  }, [allMappings]);

  //find any disabled mappings
  const disabledCrmTypes = useMemo(() => {
    return Array.from(
      new Set(
        allMappings?.results
          ?.filter((mapping) => !mapping.enabled)
          .map((mapping) => mapping.crm?.toLowerCase())
      )
    );
  }, [allMappings]);

  //check if there are any disabled mappings with links
  const hasDisabledMappingWithLinks = useMemo(() => {
    return disabledCrmTypes.length > 0 && provider?.crm_links?.length > 0;
  }, [disabledCrmTypes, provider]);

  const mappings = useMemo(() => {
    return allMappings?.results?.filter(
      ({ medscout_object_type, enabled }) =>
        medscout_object_type?.toLowerCase() === contentType && enabled
    );
  }, [allMappings, contentType]);

  const isThirdParty = enabledCrmTypes[0]?.toLowerCase() === 'third_party';

  const { data: options, isLoading: optionsLoading } = useGetUserInputOptions({
    mappingId: mappings?.length === 1 ? mappings[0].id : null,
    page: 0,
    pageSize: 100,
  });

  const pushToCrmHandler = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    //Check if disabled
    if (isDisabled) return;

    // Check if mappings or provider_id is missing
    if (!mappings?.length || !provider.provider_id) return;

    // Determine if all options are hidden
    const isAllHidden = options?.results?.every(
      (option) => option?.hidden === true
    );

    // Case: Only one mapping and all options are hidden
    if (mappings.length === 1 && (!options?.results?.length || isAllHidden)) {
      // There could be options, but they are all hidden
      const fieldsToPush = options?.results?.reduce((acc, option) => {
        acc[option?.crm_api_code] = option?.extra?.default_value || '';
        return acc;
      }, {});

      try {
        const response = await pushToCRM({
          object_type: mappings[0]?.medscout_object_type,
          mapping: mappings[0]?.id,
          fields: fieldsToPush,
        });

        if (response.duplicate) {
          const duplicateData = {
            mapping: mappings[0]?.id,
            object_type: mappings[0]?.medscout_object_type,
            object_id: provider?.provider_id,
            ...response,
          };
          setPushDuplicateToCRM(duplicateData);
          return;
        }

        setNotification({
          title: isThirdParty
            ? 'Push to CRM Initiated'
            : 'Push to CRM successful',
          message: isThirdParty
            ? 'Successfully initiated push to CRM. This may take a few minutes.'
            : 'Successfully pushed to CRM.',
          type: 'success',
        });
        if (isThirdParty) {
          setClickedButtons(provider?.id);
        }
        log.event('Push to CRM', {
          ...provider,
        });
      } catch (err: any) {
        setNotification({
          title: 'Push to CRM failed',
          message:
            err?.error_message ||
            'Error pushing to CRM, please try again later.',
          type: 'error',
        });
        log.exception(`Error pushing to CRM: ${err}`);
      }
    } else {
      // Case: Multiple mappings or visible options available
      setPushProviderToCRM(provider, enabledCrmTypes[0]);
    }
  };

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const { count, label, crmLinks } = useMemo(() => {
    const crmLinks =
      provider?.crm_links?.filter((link) => {
        if (hasDisabledMappingWithLinks) {
          // Compare with all CRM types when there are disabled mappings with links
          return disabledCrmTypes.some(
            (crmType) => link.crm?.toLowerCase() === crmType?.toLowerCase()
          );
        }
        // Otherwise use enabled CRM types only
        return link.crm?.toLowerCase() === enabledCrmTypes[0]?.toLowerCase();
      }) || [];

    const count = crmLinks.length;
    const firstCrmLink = crmLinks[0] || {};
    const firstLinkStatus = firstCrmLink.crm_status;
    const label =
      count === 1 && firstLinkStatus
        ? firstLinkStatus
        : count == 1
        ? `${firstCrmLink?.owner?.firstname || ''} ${
            firstCrmLink?.owner?.lastname || ''
          }`.trim() || `View in CRM`
        : count > 1
        ? `${count} Records`
        : 'Push to CRM';

    if (count) reenableButton(provider?.id);

    return { count, label, crmLinks };
  }, [disabledCrmTypes, enabledCrmTypes, provider]);

  const isMedRM =
    enabledCrmTypes[0]?.toLowerCase() === 'medrm' ||
    disabledCrmTypes[0]?.toLowerCase() === 'medrm';

  const pushToCRMEnabled = useMemo(() => {
    return (
      allMappings?.results?.filter(
        ({ medscout_object_type, enabled }) =>
          medscout_object_type?.toLowerCase() === contentType && enabled
      ).length > 0
    );
  }, [allMappings?.results, contentType]);

  const hasLinks = provider?.crm_links?.length > 0;

  const integration = hasDisabledMappingWithLinks
    ? disabledCrmTypes[0]
    : enabledCrmTypes[0];

  // if no provider OR no integration mappings set up OR mapping is not enabled/provider has no links OR is med rm, do not render button
  if (
    !provider ||
    allMappings?.count < 1 ||
    (!pushToCRMEnabled && !hasLinks) ||
    isMedRM
  )
    return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
    >
      <PushToCRMButton
        id={`discovery-push-to-crm-button-${provider?.provider_id}`}
        label={label}
        hasLinks={!!count}
        integration={integration}
        isLoading={isLoading || optionsLoading}
        onSubmit={!!count ? toggleMenu : pushToCrmHandler}
        variant={variant}
        sx={sx}
        disabled={isDisabled}
        newHeaderView={newHeaderView}
      />
      <CRMMaxMenu
        integration={integration}
        options={crmLinks || []}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        provider={provider}
      />
    </Box>
  );
};

export default PushToCRMGroup;
