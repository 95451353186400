import { paragon } from '@useparagon/connect';
import { useForm, Controller } from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  FormControl,
  TextField,
  Typography,
  Box,
  styled,
  useTheme,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { LoadingButton } from '@mui/lab';
import { useGetIntegrationToken, useInitiateIntegration } from 'src/hooks';
import { useLogging, useNotification } from 'src/context';
import { IconButton } from 'src/components';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

interface AddEditIntegrationDialogProps {
  open: boolean;
  onClose: () => void;
  integrationType: string;
  setIntegrationType: (type: string) => void;
}

const FormControlHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  marginBottom: theme.spacing(2),
}));

const AddEditIntegrationDialog = ({
  open,
  onClose,
  integrationType,
  setIntegrationType,
}: AddEditIntegrationDialogProps) => {
  const theme = useTheme();
  const log = useLogging();
  const { setNotification } = useNotification();

  const {
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    values: {
      username: '',
    },
  });

  const { data: token, isLoading: tokenLoading } = useGetIntegrationToken({
    username: watch('username'),
    enabled: !!watch('username') && isValid,
  });

  const { mutateAsync: initiateIntegration, isLoading: initiateLoading } =
    useInitiateIntegration();

  const handleCancel = () => {
    setIntegrationType('');
    reset({
      username: '',
    });
    onClose();
  };

  const onSubmit = async (data: any) => {
    if (!data || !integrationType) return;

    paragon
      .authenticate(process.env.NEXT_PUBLIC_PARAGON_PROJECT_ID, token?.token)
      .then(() => {
        paragon.connect(integrationType, {
          onSuccess: async (res) => {
            await initiateIntegration({
              crm: res.integrationType,
              username: data.username,
            });
            log.event('Integration Added', {
              crm: res.integrationType,
              username: data.username,
            });
          },
          onError: (err) => {
            throw err;
          },
        });
      })
      .catch((err) => {
        setNotification({
          title: 'Error',
          message:
            err?.error_message ||
            `Error connecting to ${integrationType}. Please try again later.`,
          type: 'error',
        });
        log.exception(`Error Paragon Authentication`, {
          tags: {
            source: 'AddEditIntegrationDialog',
            integrationType,
            username: data.username,
            error: err,
          },
        });
      });

    handleCancel();
  };

  const isLoading = tokenLoading || initiateLoading;

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'}>
      <DialogTitle>
        Add Integration
        <IconButton
          icon={faTimes}
          onClick={onClose}
          style={{ color: theme.palette.grey[500] }}
          size="small"
        />
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <FormControlHeader>
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, fontSize: '0.8rem' }}
            >
              CRM Username
            </Typography>
            <Typography variant="caption" sx={{ color: grey[600] }}>
              (required)
            </Typography>
          </FormControlHeader>
          <Controller
            name="username"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value:
                  /^[a-zA-Z0-9._-]+(\.[a-z0-9]+)*(\+[a-z0-9-]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: 'Invalid email address',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ''}
                fullWidth
                placeholder="e.g. email@company.com"
                variant="standard"
                error={!!errors.username}
                helperText={errors.username && 'Username is required'}
                aria-label="Integration Username"
              />
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0.5rem 1rem',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              aria-label="Integration Cancel"
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
              sx={{
                background: green[800],
                '&:hover': { background: green[900] },
              }}
              disabled={!isValid || !token?.token || isLoading}
              aria-label="Integration Save"
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditIntegrationDialog;
