import React, { useState, useRef, useMemo } from 'react';
import {
  Dropdown,
  Menu,
  MenuItem,
  Checkbox,
  IconButton,
  Typography,
} from '@mui/joy';
import { useProspectSearch } from 'src/context';
import { useGetSavedSearches, useOnClickOutside } from 'src/hooks';
import { VOLUME_SELECT_OPTIONS } from 'src/components/VolumeSelectors/constants';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

interface GridVolumeColumnMenuProps {
  columns: any[];
  columnVisibilityModel: any;
  setColumnVisibilityModel: any;
  table?: string;
}

const GridVolumeColumnMenu = ({
  columns = [],
  columnVisibilityModel,
  setColumnVisibilityModel,
  table = '',
}: GridVolumeColumnMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { prospectSearch } = useProspectSearch();
  const { data: savedSearches = [] } = useGetSavedSearches();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (field) => {
    setColumnVisibilityModel((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e, field) => {
    e.stopPropagation();
    handleChange(field);
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  const newColumns = useMemo(() => {
    return columns
      .filter(
        (column) =>
          !VOLUME_SELECT_OPTIONS.map((option) => option.value).includes(
            column.field
          ) &&
          savedSearches
            .map((search) => search.id)
            ?.toString()
            ?.includes(column.field)
      )
      .sort((a, b) => {
        if (a.headerName < b.headerName) {
          return -1;
        }
        if (a.headerName > b.headerName) {
          return 1;
        }
        return 0;
      });
  }, [columns, prospectSearch, savedSearches]);

  // reorder columns putting the visible ones first
  const visibleColumns = useMemo(() => {
    if (!anchorEl) return [];

    return newColumns.sort((a, b) => {
      if (columnVisibilityModel[a.field] && !columnVisibilityModel[b.field]) {
        return -1;
      }
      if (!columnVisibilityModel[a.field] && columnVisibilityModel[b.field]) {
        return 1;
      }
      return 0;
    });
  }, [anchorEl]);

  return (
    <Dropdown>
      <IconButton
        id={`${table}-volume-column-menu-icon-button`}
        onClick={handleClick}
        variant="solid"
        color="primary"
        size="sm"
        sx={{
          fontWeight: 900,
        }}
        aria-label="Columns Volume Menu"
      >
        <AddRoundedIcon fontSize="small" sx={{ ml: 0.33, mr: '-0.33rem' }} />
        <ArrowDropDownRoundedIcon fontSize="small" />
      </IconButton>
      <Menu
        id={`${table}-volume-column-menu`}
        ref={menuRef}
        placement="bottom-end"
        open={!!anchorEl}
        anchorEl={anchorEl}
        sx={{
          boxShadow: 'sm',
          flexGrow: 0,
          maxHeight: 300,
          overflow: 'auto',
          zIndex: 9999,
        }}
      >
        {visibleColumns?.length > 0 &&
          visibleColumns?.map((column) => (
            <MenuItem
              id={`${table}-volume-column-menu-item-${column.field}`}
              key={`column_${column.field}`}
              sx={{
                display: 'flex',
                gap: '0.33rem',
                fontSize: '1rem',
                fontWeight: 500,
                padding: '0.5rem 1rem',
              }}
              onClick={(e) => handleMenuItemClick(e, column.field)}
            >
              <Checkbox
                id={`${table}-volume-column-menu-item-checkbox-${column.field}`}
                size="sm"
                checked={!!columnVisibilityModel[column.field]}
                color={
                  !!columnVisibilityModel[column.field] ? 'success' : 'neutral'
                }
                sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' } }}
              />
              <Typography level="body-sm">{column.headerName}</Typography>
            </MenuItem>
          ))}
      </Menu>
    </Dropdown>
  );
};

export default GridVolumeColumnMenu;
