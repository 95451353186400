import React from 'react';
import { Box } from '@mui/joy';

interface MarkerCircleProps {
  featureId: number;
  sizeAsText: string;
  isHovered: boolean;
  onMouseOut: () => void;
  handleHoverMenu: (e: any, featureId: number) => void;
  id?: string;
}

const MarkerCircle = ({
  featureId,
  sizeAsText,
  isHovered,
  onMouseOut,
  handleHoverMenu,
  id,
}: MarkerCircleProps) => {
  return (
    <Box
      id={id}
      onMouseOver={(e) => handleHoverMenu(e, featureId)}
      onMouseLeave={onMouseOut}
      className={`w-full h-full rounded-full cursor-pointer flex items-center justify-center border-2 border-white transition-opacity duration-800 animate-markerFade ${
        isHovered ? 'bg-sky-700' : 'bg-purple-500'
      } text-white`}
    >
      {sizeAsText}
    </Box>
  );
};

export default MarkerCircle;
