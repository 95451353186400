import { useState } from 'react';
import { IconButton } from '@mui/joy';
import { useProspectSelection } from 'src/context';
import { RemoveProvidersDialog } from 'src/modules/Lists/components';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

interface GridProviderDeleteButtonProps {
  table?: string;
}

const GridProviderDeleteButton = ({
  table = '',
}: GridProviderDeleteButtonProps) => {
  const {
    state: [selectionModel],
  } = useProspectSelection();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <>
      <IconButton
        id={`${table}-provider-delete-button`}
        variant="soft"
        color="danger"
        sx={{
          fontWeight: 900,
        }}
        onClick={() => setShowDeleteDialog(true)}
        disabled={!selectionModel.length}
      >
        <DeleteRoundedIcon fontSize="small" />
      </IconButton>
      <RemoveProvidersDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

export default GridProviderDeleteButton;
