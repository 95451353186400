import { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, FormControlLabel } from '@mui/material';
import { useDebounce } from 'use-debounce';
import { SearchBoxConstants } from './SearchBox.constants';
import { useGetSuggestions } from 'src/hooks';
import { useLastTargetElement } from 'src/utils/hooks/useLastTargetElement';
import { useLogging, useProspectSearch, useAuth } from 'src/context';
import AutocompleteField from './AutocompleteField';
import AutocompleteResults from './AutocompleteResults';
import FilterField from '../FilterField';
import StyledTextField from './StyledTextField';
import theme from 'src/styles/theme';

interface SearchBoxProps {
  type: 'HCP' | 'C';
  context: string;
  allowedWith?: any;
  applyFilter: (arg0: any) => void;
  removeFilter: (arg0: any) => void;
  combinedCategories?: string[];
  requiredContext?: string[];
}

const SearchBox: React.FC<SearchBoxProps> = ({
  type,
  context,
  allowedWith,
  applyFilter,
  removeFilter,
  combinedCategories,
  requiredContext,
}) => {
  const { prospectFilters } = useProspectSearch();
  const typeMapKey = `${type}_${context}`;
  const componentWrapperRef = useRef(null);
  const lastTargetElement = useLastTargetElement();

  const formattedContext = context === 'name' ? typeMapKey : context;
  const isComboVolume =
    context === 'combo_volume' || context === 'center_combo_volume';
  const searchTypeInfo = SearchBoxConstants.searchTypeMap[formattedContext];

  const [disableSearchDueToOtherCategory, setDisableSearchDueToOtherCategory] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [minMaxSelectEntity, setMinMaxSelectEntity] = useState(null);
  const [minMaxSelectError, setMinMaxSelectError] = useState('');
  const [minValue, setMinValue] = useState('0');
  const [maxValue, setMaxValue] = useState('');
  const [filters, setFilters] = useState([]);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [resultsVisible, setResultsVisible] = useState(false);
  const { user } = useAuth();

  const { data: searchResults, isFetching } = useGetSuggestions({
    type,
    context: isComboVolume ? combinedCategories : context,
    q: debouncedSearchTerm,
    company_id: user?.company?.id,
  });
  const log = useLogging();

  useEffect(() => {
    let disableSearchContext = false;

    const allowedContexts = combinedCategories?.length
      ? combinedCategories.reduce(
          (result, category) => ({
            ...result,
            [category]: true,
          }),
          { [context]: true }
        )
      : { [context]: true };

    if (requiredContext) {
      disableSearchContext = !prospectFilters
        .map((f) => f.context)
        .some((r) => requiredContext.includes(r));
    }
    const filteredFilters =
      prospectFilters
        ?.filter((filter) => {
          if (
            !allowedContexts[filter.context] &&
            allowedWith &&
            !allowedWith[filter.context]
          ) {
            disableSearchContext = disableSearchContext || !!filter.context;
          }

          return allowedContexts[filter.context];
        })
        ?.filter(
          (filter, index, self) =>
            index ===
            self.findIndex(
              (f) => f.context === filter.context && f.value === filter.value
            )
        ) || [];
    if (disableSearchContext) {
      // if we are disabling the input due to other filters clear the text field to avoid user confusion
      setSearchTerm('');
    }

    setDisableSearchDueToOtherCategory(disableSearchContext);
    setFilters(filteredFilters);
  }, [prospectFilters, context, allowedWith, combinedCategories]);

  useEffect(() => {
    if (
      lastTargetElement &&
      componentWrapperRef.current &&
      componentWrapperRef.current.contains(lastTargetElement)
    ) {
      // no op
    } else {
      setResultsVisible(false);
    }
  }, [lastTargetElement]);

  if (!searchTypeInfo) return null;

  const filterSelect = (result) => {
    let minMaxFilterDuplicateExists = false;
    const filterExists = filters.find((filter) => {
      if (Array.isArray(result.value) && Array.isArray(filter.value)) {
        // compare ids when [id,min,max] pattern
        const sameArrayValueApplied = result.value[0] === filter.value[0];
        minMaxFilterDuplicateExists =
          minMaxFilterDuplicateExists || sameArrayValueApplied;

        return sameArrayValueApplied;
      }

      return result.value === filter.value;
    });

    if (filterExists && !minMaxFilterDuplicateExists) {
      removeFilter(result);
    } else {
      applyFilter(result);
    }

    if (result.context === 'payments') clearSearch();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setMinMaxSelectEntity(null);
    setMinMaxSelectError('');
    setMinValue('0');
    setMaxValue('');
    setResultsVisible(false);
  };

  const isDropdown =
    context === 'tags' ||
    context === 'site' ||
    context === 'payer' ||
    context === 'lists' ||
    context === 'crm_owners' ||
    context === 'crm_statuses';

  return (
    <div ref={componentWrapperRef} id={`search-shade-${context}-${type}`}>
      <FilterField icon={searchTypeInfo.icon} label={searchTypeInfo.label}>
        <AutocompleteField
          isFetching={isFetching}
          searchTypeInfo={searchTypeInfo}
          disabled={disableSearchDueToOtherCategory}
          minMaxSelectEntity={minMaxSelectEntity}
          setResultsVisible={setResultsVisible}
          handleSearch={handleSearch}
          clearSearch={clearSearch}
          isDropdown={isDropdown}
        />
      </FilterField>
      {minMaxSelectEntity && (
        <Box sx={{ pl: '2rem' }}>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              gap: '.5rem',
            }}
          >
            <FormControlLabel
              label="Min (USD)"
              labelPlacement="top"
              control={
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  value={minValue}
                  size="small"
                  onChange={(e) => setMinValue(e.target.value)}
                />
              }
              sx={{
                width: '100%',
                margin: 0,
                '& .MuiFormControlLabel-label': {
                  fontSize: '0.8rem',
                },
              }}
            />
            <FormControlLabel
              label="Max (USD)"
              labelPlacement="top"
              control={
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  value={maxValue}
                  size="small"
                  onChange={(e) => setMaxValue(e.target.value)}
                  placeholder="Unlimited"
                />
              }
              sx={{
                width: '100%',
                margin: 0,
                '& .MuiFormControlLabel-label': {
                  fontSize: '0.8rem',
                },
              }}
            />
          </Box>
          {minMaxSelectError && (
            <Typography
              className="text-danger"
              sx={{
                fontSize: '0.8rem',
                fontWeight: theme.typography.fontWeightRegular,
              }}
            >
              {minMaxSelectError}
            </Typography>
          )}
          <div className="d-grid mt-2">
            <Button
              size="small"
              variant="contained"
              color="primary"
              aria-label="Add Payment Filter"
              onClick={() => {
                const parsedMin = parseInt(minValue.replace(/[^\d.-]/g, ''));
                const parsedMax = parseInt(maxValue.replace(/[^\d.-]/g, ''));
                const value = [
                  minMaxSelectEntity.id,
                  isNaN(parsedMin) ? 0 : parsedMin,
                ];
                if (!isNaN(parsedMax)) {
                  value.push(parsedMax);
                }
                if (!prospectFilters?.length && parsedMax === 0) {
                  // running an exclusion filter
                  setMinMaxSelectError(
                    'Please add additional category filters prior to an exclusion.'
                  );
                } else {
                  log.event('paymentFilterApplied', {
                    name: minMaxSelectEntity.title,
                    min: parsedMin,
                    max: parsedMax,
                  });
                  filterSelect({ ...minMaxSelectEntity, value });
                }
              }}
            >
              Add Payment Filter
            </Button>
          </div>
        </Box>
      )}

      {searchResults?.length > 0 && (
        <AutocompleteResults
          searchResults={searchResults}
          resultsVisible={resultsVisible}
          setResultsVisible={setResultsVisible}
          searchTypeInfo={searchTypeInfo}
          isComboVolume={isComboVolume}
          context={context}
          filters={filters}
          setMinMaxSelectEntity={setMinMaxSelectEntity}
          setMinMaxSelectError={setMinMaxSelectError}
          onSelectFilter={filterSelect}
          type={type}
        />
      )}
    </div>
  );
};

export default SearchBox;
