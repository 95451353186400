import React, { useMemo } from 'react';
import { Button, Box, Typography, Menu } from '@mui/joy';
import { styled, ClickAwayListener } from '@mui/material';
import DetailsPopover from 'src/components/Blades/ResultComponents/DetailsPopover';
import { CircularProgress } from 'src/components';
import { useProspectSearch } from 'src/context';

const detailsLookup = [
  'claims',
  'patients',
  'year',
  'code',
  'total_payments',
  'avg_reimbursement',
];

const CellLabel = styled(Typography)(({ theme }) => ({
  display: 'inline',
  fontSize: '0.5rem',
  textTransform: 'uppercase',
  marginRight: '0.25rem',
}));

const ProspectDetailsCell = ({ result, loading }) => {
  const { prospectVolumeType } = useProspectSearch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const details = useMemo(() => {
    return detailsLookup.map((detail) => {
      if (!result[detail] && detail !== prospectVolumeType) return null;

      switch (detail) {
        case 'claims':
          return (
            prospectVolumeType === 'claims' && (
              <div className="whitespace-nowrap text-right">
                <CellLabel level="h4">Claims:</CellLabel>
                <span className="font-medium text-black text-lg">
                  {result.claims === '<11'
                    ? result.claims
                    : result.claims?.toLocaleString('en-US')}
                </span>
              </div>
            )
          );
        case 'patients':
          return (
            prospectVolumeType === 'patients' && (
              <div className="whitespace-nowrap text-right">
                <CellLabel level="h4">Patients:</CellLabel>
                <span className="font-medium text-black text-lg">
                  {result.patients === '<11'
                    ? result.patients
                    : result.patients?.toLocaleString('en-US')}
                </span>
              </div>
            )
          );
        case 'year':
          return (
            <div className="whitespace-nowrap text-right">
              <CellLabel level="h4">Year:</CellLabel>
              <span className="font-medium text-black text-lg">
                {result.year}
              </span>
            </div>
          );
        case 'code':
          return (
            <div className="whitespace-nowrap text-right">
              <CellLabel level="h4">Code:</CellLabel>
              <span className="font-medium text-black text-lg">
                {result.code}
              </span>
            </div>
          );
        case 'total_payments':
          return (
            <div className="whitespace-nowrap text-right">
              <CellLabel level="h4">Payments:</CellLabel>
              <span className="font-medium text-black text-lg">
                {result?.total_payments?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </span>
            </div>
          );
        case 'avg_reimbursement':
          return (
            <div className="whitespace-nowrap text-right">
              <CellLabel level="h4">Avg Reimb:</CellLabel>
              <span className="font-medium text-black text-lg">
                {result.avg_reimbursement?.toLocaleString('en-US', {
                  minimumFractionDigits: 0, // Allow no decimal places
                  maximumFractionDigits: 2, // Up to 2 decimal places
                })}
              </span>
            </div>
          );
        default:
          return null;
      }
    });
  }, [result, prospectVolumeType]);

  const togglePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div
        id="discovery-prospect-details-cell"
        className="flex-1"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Button
          variant="outlined"
          color="neutral"
          size="sm"
          sx={{ fontSize: '0.75rem' }}
          onClick={togglePopover}
        >
          {loading ? (
            <CircularProgress size="sm" color="neutral" />
          ) : (
            <div className="flex flex-col">
              {details.map((detail, i) => {
                if (!detail) return null;
                return <Box key={i}>{detail}</Box>;
              })}
            </div>
          )}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          placement="left-start"
          onClick={(e) => {
            e.stopPropagation();
          }}
          sx={{
            width: 200,
            zIndex: 9999,
            padding: 0,
            margin: 0,
          }}
        >
          <DetailsPopover row={result} show={Boolean(anchorEl)} />
        </Menu>
      </div>
    </ClickAwayListener>
  );
};

export default ProspectDetailsCell;
