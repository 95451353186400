import useSWR from 'swr';
import { getSecurityPermissions } from 'src/api';
import { QUERY_KEYS } from './constants';

export const useGetSecurityPermissions = ({
  companyId,
  userId,
}: {
  companyId: string;
  userId: string;
}) => {
  return useSWR(
    [QUERY_KEYS.get_security_permissions, companyId, userId],
    () => getSecurityPermissions({ companyId, userId }),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    }
  );
};

export default useGetSecurityPermissions;
