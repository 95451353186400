import { useState } from 'react';
import {
  Button,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/joy';

import { useSubmitDataIssue } from 'src/hooks';
import { useLogging, useNotification } from 'src/context';

import { DataIssueTypeSelector } from './DataIssueTypeSelector';
import { DataIssueAddressSuggestions } from './DataIssueAddressSuggestions';
import { DataIssueEmailSuggestions } from './DataIssueEmailSuggestions';
import { DataIssueComments } from './DataIssueComments';
import { DataIssuePhone } from './DataIssuePhone';

interface ReportDataIssueDialogProps {
  open: boolean;
  onClose: () => void;
  providerId: string;
  providerType: string;
}

const ReportDataIssueDialog = ({
  open,
  onClose,
  providerId,
  providerType,
}: ReportDataIssueDialogProps) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { mutateAsync: submitDataIssue, isLoading: isSubmitLoading } =
    useSubmitDataIssue();
  const [issueType, setIssueType] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailId, setEmailId] = useState(null);
  const [phone, setPhone] = useState(null);
  const [comments, setComments] = useState('');

  const handleIssueTypeSelection = (newValue) => {
    setIssueType(newValue);
    setAddressData(null);
    setEmail(null);
    setEmailId(null);
    setPhone(null);
    setComments('');
  };

  const handleOnClose = () => {
    setIssueType(null);
    setAddressData(null);
    setEmail(null);
    setEmailId(null);
    setPhone(null);
    setComments('');
    onClose();
  };

  const handleSubmit = async () => {
    const dataToSubmit = {
      phone: phone ? { phone } : null,
      address: addressData ? addressData : null,
      email: email ? { email } : null,
    };
    try {
      await submitDataIssue({
        issue_type: issueType.toUpperCase(),
        provider_id: providerId,
        new_data: dataToSubmit[issueType],
        comments,
      });

      handleOnClose();

      log.event('dataIssueReported', {
        provider_id: providerId,
        content_type: providerType,
        reason: issueType,
      });

      setNotification({
        title: 'Data Issue Submitted',
        message: 'Look out for an email from us soon!',
        type: 'success',
      });
    } catch (err) {
      setNotification({
        title: 'Error',
        message:
          err?.error_message ||
          'An error occurred while submitting data issue. Our team has been notified.',
        type: 'error',
      });
      log.exception(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog minWidth={'md'}>
        <DialogTitle>See something wrong?</DialogTitle>
        <DialogContent>
          <Typography level="body-sm">
            Please select the type of data issue you have encountered, with any
            additional feedback or comments. We use this feedback to improve our
            profile enrichment process and will reach out to you when your issue
            has been resolved.
          </Typography>
          <DataIssueTypeSelector
            providerType={providerType}
            issueType={issueType}
            onChange={handleIssueTypeSelection}
          />
          {providerType === 'hcp' && issueType === 'address' && (
            <DataIssueAddressSuggestions
              issueType={issueType}
              providerId={providerId}
              providerType={providerType}
              setAddressData={setAddressData}
            />
          )}
          {providerType === 'hcp' && issueType === 'email' && (
            <DataIssueEmailSuggestions
              issueType={issueType}
              providerId={providerId}
              providerType={providerType}
              email={email}
              setEmail={setEmail}
              emailId={emailId}
              setEmailId={setEmailId}
            />
          )}
          {issueType && issueType === 'phone' && (
            <DataIssuePhone phone={phone} setPhone={setPhone} />
          )}
          {issueType && (
            <DataIssueComments comments={comments} setComments={setComments} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!issueType}
            onClick={handleSubmit}
            loading={isSubmitLoading}
          >
            Submit
          </Button>
          <Button color="danger" variant="soft" onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ReportDataIssueDialog;
