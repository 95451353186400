import { Container } from '@mui/joy';
import { useAuth } from 'src/context';
import { PayerMix } from 'src/modules/Profile/components/Views/components/PayerMix';
import ProfileDetails from './components/ProfileDetails/ProfileDetails';
import ProfileOrganizations from './components/ProfileOrganizations';
import ProfileSummary from './components/ProfileSummary/ProfileSummary';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProfileTopProviders, GridContainer, GridColumn } from './components';
import { ProfileTitle } from 'src/modules/Profile/components/ProfileTitle';
import { ProfileSavedSearch } from './components/ProfileSavedSearch';
import { NewPayerMix } from 'src/modules/Profile/components/Views/components/NewPayerMix';
import { ProfileReimbursement } from 'src/components';

const ClinicProfile = ({
  singleColumn = false,
}: {
  singleColumn?: boolean;
}) => {
  const { user } = useAuth();
  const { useProfileAiSummary, newPayerMix } = useFlags();
  const userAiProvider = user?.company?.sites_summary && useProfileAiSummary;

  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{ overflow: 'hidden', paddingBottom: '1rem' }}
    >
      {newPayerMix && <ProfileTitle type="clinic" />}
      {newPayerMix ? (
        <GridContainer
          singleColumn={singleColumn}
          sx={{
            gridTemplateColumns: singleColumn
              ? '1fr'
              : { sm: '1fr', lg: '22.5rem 51.5rem' },
          }}
        >
          <GridColumn>
            <ProfileDetails />
            <ProfileOrganizations />
          </GridColumn>
          <GridColumn>
            <div className="flex flex-col gap-4 rounded-lg px-6 py-5 border border-teal-200 bg-teal-050">
              <ProfileSavedSearch context="payer" />
              {userAiProvider && <ProfileSummary />}
              <ProfileTopProviders />
              <NewPayerMix showLegend singleColumn={singleColumn} />
              <ProfileReimbursement />
            </div>
          </GridColumn>
        </GridContainer>
      ) : (
        <GridContainer singleColumn={singleColumn}>
          <GridColumn>
            {userAiProvider && <ProfileSummary />}
            <ProfileOrganizations />
            <ProfileDetails />
          </GridColumn>
          <GridColumn>
            <></>
            <ProfileTopProviders />
            <PayerMix />
          </GridColumn>
        </GridContainer>
      )}
    </Container>
  );
};

export default ClinicProfile;
