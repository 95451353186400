import React, { useEffect, useState } from 'react';
import { Box, Card, Container, Table } from '@mui/joy';

import {
  DashboardColumnHeader,
  TablePagination,
  DashboardLoading,
  TableRow,
} from 'src/components/Settings/Users/components/UserManagement';
import { useDashboard, useSite } from 'src/context';
import { useCompanies } from 'src/hooks';
import { grey } from '@mui/material/colors';
import { AddRounded } from '@mui/icons-material';
import { getCompany } from 'src/hooks/companies/companies/companiesApi';
import { Button } from 'src/components';
/**
 * @description   CompanyTable component is used to display the company table in the admin dashboard
 * @returns       CompanyTable component
 */
const CompanyTable = () => {
  const { dispatch } = useSite();
  const {
    companyQueryParams,
    setCompanyQueryParams,
    setShowAddEditCompanyModal,
  } = useDashboard();

  const { data, isLoading } = useCompanies();

  const [sort, setSort] = useState<{ sort: string; order: 'asc' | 'desc' }>({
    sort: 'name',
    order: 'asc',
  });

  useEffect(() => {
    return () => {
      setCompanyQueryParams({
        q: '',
        order: 'asc',
        page: 1,
        pageSize: 50,
        sort: 'name',
      });
    };
  }, []);

  const handleColSort = (col: string, order: 'asc' | 'desc') => {
    setSort({ sort: col, order });
    setCompanyQueryParams({ ...companyQueryParams, sort: col, order });
  };

  const handleOpenDialog = () => {
    setShowAddEditCompanyModal({ show: true, company: null });
  };

  const handleRowClick = async (company: any) => {
    try {
      const companyData = await getCompany(company.id);
      dispatch({
        type: 'SET_CURRENT_COMPANY',
        payload: companyData,
      });
    } catch (error) {
      console.error('Error setting current company', error);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <Card>
        <Container maxWidth={false} disableGutters>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box sx={{ display: 'flex', padding: '1rem 0' }}>
              <Button
                size="sm"
                variant="outlined"
                color="primary"
                onClick={handleOpenDialog}
                startDecorator={<AddRounded />}
              >
                Add Company
              </Button>
            </Box>

            <Box
              sx={{
                border: `1px solid ${grey[300]}`,
                borderRadius: '0.33rem',
                height: '100%',
                maxHeight: '50rem',
                overflowY: 'auto',
                position: 'relative',
              }}
            >
              <Table
                noWrap
                stickyHeader
                stickyFooter
                sx={{
                  height: '100%',
                  tableLayout: 'auto',
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        width: '25%',
                        textAlign: 'left',
                      }}
                    >
                      <DashboardColumnHeader
                        column="name"
                        headerName="Company Name"
                        onColSort={handleColSort}
                        sort={sort}
                        isSortable
                      />
                    </th>
                    <th
                      style={{
                        textAlign: 'left',
                        width: '15%',
                      }}
                    >
                      <DashboardColumnHeader
                        column="active_user_count"
                        headerName="Active Users"
                        onColSort={handleColSort}
                        sort={sort}
                      />
                    </th>
                    <th
                      style={{
                        textAlign: 'left',
                        width: '15%',
                      }}
                    >
                      <DashboardColumnHeader
                        column="user_count"
                        headerName="Total Users"
                        onColSort={handleColSort}
                        sort={sort}
                      />
                    </th>
                    <th style={{ flex: 1 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.results?.map((company) => {
                    return (
                      <TableRow
                        key={company.id}
                        onClick={() => {
                          handleRowClick(company);
                        }}
                      >
                        <td
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          {company.name}
                        </td>
                        <td
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          {company.active_user_count}
                        </td>
                        <td
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          {company.user_count}
                        </td>
                        <td></td>
                      </TableRow>
                    );
                  })}
                  <tr></tr>
                </tbody>
                <tfoot
                  style={{
                    background: 'white',
                  }}
                >
                  <tr
                    style={{
                      background: 'white',
                    }}
                  >
                    <td
                      colSpan={10}
                      style={{
                        background: 'white',
                      }}
                    >
                      <TablePagination
                        count={data?.count || 0}
                        sortValues={companyQueryParams}
                        setSortValues={setCompanyQueryParams}
                      />
                    </td>
                  </tr>
                </tfoot>
              </Table>
              {isLoading && <DashboardLoading />}
            </Box>
          </Box>
        </Container>
      </Card>
    </div>
  );
};

export default CompanyTable;
