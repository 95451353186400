import React from 'react';
import { Typography, styled, Box, List, ListItem } from '@mui/material';

const ExtraOptionsCell = ({ row }) => {
  if (!row.extra?.options) return null;

  return (
    <Box sx={{ width: '100%', maxHeight: 75, overflowY: 'auto' }}>
      <List>
        {row.extra?.options?.map((option, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              width: '100%',
              pl: 0.5,
              pr: 0.5,
            }}
          >
            <Typography sx={{ fontSize: '0.8rem' }}>
              {option?.value}:
            </Typography>
            <Typography sx={{ fontSize: '0.8rem' }}>{option?.label}</Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ExtraOptionsCell;
