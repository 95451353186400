import { useMemo, useEffect, useCallback } from 'react';
import {
  useLogging,
  useMedScoutMap,
  useNotification,
  useDiscoveryMap,
  useAuth,
} from 'src/context';
import {
  useGetMedBoundaries,
  convertStateData,
  useGetAllTerritories,
  useGetTerritory,
  useAuthorization,
} from 'src/hooks';

const useGetGeoJsonData = () => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { data: boundaries, isLoading } = useGetMedBoundaries();
  const {
    isEditing,
    isCreating,
    currentTerritory,
    territoryPolygons,
    drawingMode,
    clickedFeatures,
    setClickedFeatures,
  } = useMedScoutMap();

  const { existingTerritories } = useDiscoveryMap();
  const { user } = useAuth();
  const auth = useAuthorization();
  const isBoundToAssigned = auth?.isBoundedToTerritory();

  const { data: userTerritories } = useGetAllTerritories();

  const typeNameMap = {
    COUNTY: 'counties',
    ZIPCODE: 'zip_codes',
  };

  let source = [];

  // Paths can be in the form of new or old polygons
  const isNew = !!currentTerritory?.polygons;
  const paths = isNew
    ? currentTerritory?.polygons
    : currentTerritory?.old_polygons;

  // Construct coordinates for current territory
  const coordinates = useMemo(() => {
    if (!paths) return [];
    return paths?.map((path) => {
      return path.map((point) => {
        return [point.lng, point.lat];
      });
    });
  }, [paths]);

  // handle getting source
  useEffect(() => {
    handleGetSource();
  }, [currentTerritory, isEditing, isCreating, drawingMode]);

  useEffect(() => {
    if (isCreating) {
      setClickedFeatures([]);
    }
  }, [isCreating]);

  const handleConvert = async () => {
    try {
      const response = await convertStateData(
        territoryPolygons['STATE'],
        null,
        typeNameMap[drawingMode]
      );
      territoryPolygons['STATE'] = [];
      const newSource = response?.boundaries?.map((item) => item.id) || [];
      territoryPolygons[drawingMode] = newSource;
      setClickedFeatures(newSource);

      return newSource;
    } catch (err) {
      log.event('Error converting state to counties', {
        source: 'handleDrawingManagerLoad',
        error: err,
      });
      setNotification({
        title: 'Error converting state to counties',
        message: err?.error_message || 'Error converting state to counties',
        type: 'error',
      });
      return [];
    }
  };
  const shouldConvert =
    drawingMode !== 'STATE' &&
    drawingMode !== 'RADIUS' &&
    drawingMode !== 'DRAW' &&
    territoryPolygons['STATE']?.length > 0;

  // Handle getting source for the territory
  const handleGetSource = async () => {
    if (
      !drawingMode ||
      !territoryPolygons['STATE']?.length ||
      !isCreating ||
      drawingMode === 'STATE'
    ) {
      if (isEditing && shouldConvert) {
        source = await handleConvert();
      } else if (isEditing && currentTerritory?.source) {
        const {
          states = [],
          counties = [],
          zip_codes = [],
          drawn = false,
          circles = [],
        } = currentTerritory.source || {};

        // drawingMode is not set as fast as this runs.
        if (states.length > 0) {
          source = states.map((state) => state.id);
          territoryPolygons['STATE'] = source;
        } else if (counties.length > 0) {
          source = counties.map((county) => county.id);
          territoryPolygons['COUNTY'] = source;
        } else if (zip_codes.length > 0) {
          source = zip_codes.map((zip) => zip.id);
          territoryPolygons['ZIPCODE'] = source;
        } else if (circles.length > 0 || drawn) {
          source = coordinates;
          territoryPolygons['RADIUS'] = source;
        } else {
          source = coordinates;
          territoryPolygons['DRAW'] = source;
        }

        setClickedFeatures(source);
      }
    } else if (isCreating && shouldConvert) {
      // Handle case where drawing and creating
      source = await handleConvert();
    }

    return Array.from(new Set([...source, ...clickedFeatures]));
  };

  // Construct GeoJson data for the map
  // Valid for all types of territories
  const geoJsonData = useMemo(() => {
    let newBoundaries = [];
    if (isEditing || isCreating) {
      if (!boundaries && !isLoading) return [];
      const tempPolys = [];

      boundaries?.boundaries.map((boundary) => {
        const polys = boundary.geometry.map((coord) => {
          return {
            type: 'Feature',
            properties: {
              id: boundary.id,
              name: boundary.label,
            },
            geometry: {
              type: 'Polygon',
              coordinates: [coord],
            },
          };
        });
        tempPolys.push(...polys);
      });

      newBoundaries = [...tempPolys];
    } else {
      if (!coordinates && !isLoading) return [];
      const polys = coordinates.map((coord) => {
        return {
          type: 'Feature',
          properties: {
            selected: false,
            hovered: false,
          },
          geometry: {
            type: 'Polygon',
            coordinates: [coord],
          },
        };
      });

      newBoundaries = [...polys];
    }

    return newBoundaries;
  }, [isEditing, isCreating, boundaries, isLoading, coordinates]);

  // Single assigned territory fetch
  const canonicalTerritory = useMemo(() => {
    if (!isEditing && !isCreating) return null;
    return userTerritories?.find(
      (t) => t.owner?.id === user?.id && t.is_canonical
    );
  }, [userTerritories, user?.id, isEditing, isCreating]);

  // Fetch single territory details
  const { data: assignedTerritoryDetails } = useGetTerritory({
    territoryId:
      canonicalTerritory?.id && (isEditing || isCreating) && isBoundToAssigned
        ? canonicalTerritory.id
        : undefined,
  });

  const convertToGeoJsonFeature = useCallback((territory) => {
    if (!territory) return null;

    const isNew = !!territory?.polygons;
    const paths = isNew ? territory?.polygons : territory?.old_polygons;
    if (!paths?.length) return null;

    return paths.map((path) => {
      const coordinates = path.map((point) => [point.lng, point.lat]);
      return {
        type: 'Feature',
        properties: {
          id: territory.id,
          name: territory.name,
          selected: false,
          hovered: false,
          fillColor: territory?.color || '#22c55e',
          fillOpacity: 120,
          strokeColor: territory?.color || '#22c55e',
          strokeOpacity: 200,
          strokeWidth: 3,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },
      };
    });
  }, []);

  const existingGeoJsonData = useMemo(() => {
    if ((isEditing || isCreating) && isBoundToAssigned) {
      // Handle single assigned territory case
      const features = convertToGeoJsonFeature(assignedTerritoryDetails);
      return features || [];
    }

    // Handle multiple existing territories case
    if (!existingTerritories?.length) return [];

    return existingTerritories.flatMap((territory) => {
      const features = convertToGeoJsonFeature(territory);
      return features || [];
    });
  }, [
    existingTerritories,
    isEditing,
    isCreating,
    isBoundToAssigned,
    assignedTerritoryDetails,
    convertToGeoJsonFeature,
  ]);

  return {
    geoJsonData,
    existingGeoJsonData,
  };
};

export default useGetGeoJsonData;
