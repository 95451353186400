import { Box, IconButton, Typography } from '@mui/joy';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useFormContext } from 'react-hook-form';
interface StatusAdminGridFooterProps {
  options: MedScout.Status[];
  onAdd: (option: Omit<MedScout.Status, 'id'>) => void;
  bucket: string;
}

const StatusAdminGridFooter = ({
  options,
  onAdd,
  bucket,
}: StatusAdminGridFooterProps) => {
  const {
    formState: { isValid },
  } = useFormContext();

  const handleAddNewOption = () => {
    // find the order of the last option
    const lastOption = options[options.length - 1];
    const newOption = {
      value: '',
      label: '',
      color: '#455a64',
      dynamic: true,
      order: lastOption.order + 1,
      bucket: bucket,
    };

    onAdd(newOption);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center',
        borderTop: `1px solid #CDD7E1`,
        padding: '0.5rem',
        width: '100%',
      }}
    >
      <IconButton
        size="sm"
        variant="soft"
        color="neutral"
        onClick={handleAddNewOption}
      >
        <AddRoundedIcon fontSize="small" />
      </IconButton>
      <Typography level="body-sm">Add new</Typography>
    </Box>
  );
};

export default StatusAdminGridFooter;
