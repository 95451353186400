import React from 'react';
import { TableRow } from '../Card';
import { Box, Typography } from '@mui/joy';
import { TypeIcon } from 'src/components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { useLogging } from 'src/context';
import { useFlags } from 'launchdarkly-react-client-sdk';
interface LocationRowItemProps {
  location: {
    provider_id: string;
    type: string;
    name: string;
    percentage: number;
    obfuscated: boolean;
  };
}

const LocationRowItem = ({ location }: LocationRowItemProps) => {
  const log = useLogging();
  const { sameTabRouting } = useFlags();
  const percentage =
    location?.percentage || location?.percentage === 0
      ? `${location?.percentage}%`
      : 'N/A';

  const name = location?.name || 'Other';
  const hasProviderId = location?.provider_id;
  return (
    <TableRow borderColor="#F0F4F8">
      {hasProviderId ? (
        <>
          <a
            id={`volume-by-location-provider-${location?.provider_id}`}
            href={`/profiles/${location?.type}/${location?.provider_id}`}
            target={sameTabRouting ? '_self' : '_blank'}
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              gap: '0.5rem',
              color: '#021B9C',
            }}
            onClick={(e) => {
              e.stopPropagation();
              log.event('Location Clicked', {
                source: 'web',
                location: 'VolumeByLocation',
                provider_id: location?.provider_id,
              });
            }}
          >
            <TypeIcon
              type={location.type}
              style={{
                marginLeft: '0',
                marginRight: '0',
                color: 'inherit',
              }}
            />
            <Typography
              level="body-sm"
              sx={{ color: 'inherit', fontWeight: 500 }}
            >
              {name}
              <Icon
                icon={faExternalLinkAlt}
                style={{ paddingLeft: '.25rem' }}
                size="xs"
              />
            </Typography>
          </a>
          <Typography level="body-sm" style={{ color: '#292B33' }}>
            {percentage}
          </Typography>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              width: '100%',
            }}
          >
            <Typography level="body-sm" sx={{ fontWeight: 500 }}>
              {name}
            </Typography>
          </Box>
          <Typography level="body-sm">{percentage}</Typography>
        </>
      )}
    </TableRow>
  );
};

export default LocationRowItem;
