import React, { useState } from 'react';
import { useLayoutControl, useLogging, useNotification } from 'src/context';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid, Typography, Box } from '@mui/material';
import { useUpdateMapping } from 'src/hooks';
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors';
import { ControlledCrmObjectSelect } from '../ControlledCrmObjectSelect';
import { ControlledSwitch, ControlledInput } from 'src/components';
import useTestPushToCRM from 'src/hooks/integrations/useTestPushToCRM';
import MappingTestingSection from './MappingTestingSection';
import { useIntegrations } from 'src/components/Settings/Integrations';

const fields = [
  { label: 'Label', name: 'label', controlType: 'TextField' },
  {
    label: 'MedScout Object Type',
    value: 'medscout_object_type',
    name: 'medscout_object_type',
  },
  {
    label: 'CRM Object Type',
    name: 'crm_object_type',
    value: 'crm_object_type',
  },
  {
    label: 'CRM Status Field',
    controlType: 'CRMApiSelect',
    name: 'status_field',
    crmType: 'crm_object_type',
  },
  {
    controlType: 'Switch',
    name: 'enabled',
    label: 'Enabled',
  },
];

const MappingDefinitionForm = ({ userInputOptions }) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { setTestPushToCRMDialog } = useLayoutControl();
  const { currentMapping } = useIntegrations();
  const methods = useForm({
    values: {
      label: currentMapping?.label,
      status_field: currentMapping?.crm_status_config?.status_field,
      enabled: currentMapping?.enabled,
      ...currentMapping,
    },
  });

  const [dryRun, setDryRun] = useState(false);
  const [crmTestLink, setCRMTestLink] = useState(null);
  const [dryRunResponse, setDryRunResponse] = useState(null);
  const [testPushError, setTestPushError] = useState(null);

  const { mutateAsync: testPushToCRM, isLoading: testIsLoading } =
    useTestPushToCRM();

  const hasOptions = !!userInputOptions?.results?.length;

  const testPushToCRMHandler = async (isDryRun) => {
    setTestPushError(null);
    if (isDryRun) {
      setDryRun(true);
      setCRMTestLink(null);
    } else {
      setDryRun(false);
      setDryRunResponse(null);
    }

    // Determine if all options are hidden
    const isAllHidden = userInputOptions.results?.every(
      (option) => option?.hidden === true
    );

    if (!userInputOptions.results?.length || isAllHidden) {
      // grab all userInput options
      const fieldsToPush = userInputOptions.results?.reduce((acc, option) => {
        acc[option?.crm_api_code] = option?.extra?.default_value || '';
        return acc;
      }, {});

      const input = {
        data: { mapping: currentMapping?.id, fields: fieldsToPush },
        isDryRun,
      };

      try {
        const response = await testPushToCRM(input);

        if (isDryRun && !!response) {
          const stringifiedResp = JSON.stringify(
            response.field_mapping,
            null,
            2
          );
          setCRMTestLink(null);
          setDryRunResponse(stringifiedResp);
        } else {
          setDryRunResponse(null);
          setCRMTestLink(response.link);
          setNotification({
            title: 'Test Push to CRM successful',
            message: 'Successfully tested push to CRM',
            type: 'success',
          });
        }

        //TO DO: how to log event?
        log.event('Test Push to CRM', {
          currentMapping,
          dryRun,
        });
      } catch (err) {
        setNotification({
          title: 'Test Push to CRM failed',
          message:
            err?.error_message ||
            'Error testing Push to CRM, please check config.',
          type: 'error',
        });
        log.exception(`Error testing Push to CRM: ${err}`);
        setTestPushError(err?.error_message || 'Unknown error');
      }
    } else {
      // Case: Multiple mappings or visible options available
      setTestPushToCRMDialog(
        currentMapping,
        isDryRun,
        handleCRMTestDialogResponse,
        setTestPushError
      );
    }
  };

  const handleCRMTestDialogResponse = (response: any, isDryRun: boolean) => {
    if (isDryRun) {
      const stringifiedResp = JSON.stringify(response.field_mapping, null, 2);
      setCRMTestLink(null);
      setDryRunResponse(stringifiedResp);
    } else {
      setDryRunResponse(null);
      setCRMTestLink(response.link);
    }
  };
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const { mutateAsync: updateMapping, isLoading } = useUpdateMapping();

  const onSubmit = async (data: any) => {
    // data is an object with the following structure:
    // {
    //    id: mapping.id
    //    label: 'Test Mapping',
    //    status_field: 'status',
    //    enabled: true,
    // }
    try {
      await updateMapping({
        id: currentMapping?.id,
        ...data,
      });

      setNotification({
        title: 'Success',
        message: 'Mapping updated successfully',
        type: 'success',
      });
      log.event('Updated Mapping', {
        id: currentMapping?.id,
        ...data,
      });

      reset(data);
    } catch (err: any) {
      setNotification({
        title: 'Error',
        message:
          err?.error_message ||
          'An error occurred while updating the mapping. Please try again.',
        type: 'error',
      });
    }
  };

  return (
    <Box mb={2} pb={3} sx={{ borderBottom: `1px solid ${grey[200]}` }}>
      <Grid container columnSpacing={8} justifyContent={'space-between'}>
        <Grid
          item
          container
          spacing={1}
          justifyContent="space-between"
          maxWidth="sm"
          height="250px"
          mb={1}
          sx={{ fontSize: '0.9rem' }}
        >
          {fields?.map((item, index) => (
            <React.Fragment key={index}>
              {item.controlType !== 'Switch' && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <strong>{item.label}</strong>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <FormProvider {...methods}>
                  {item.controlType === 'TextField' ? (
                    <ControlledInput
                      name={item.name}
                      label={item.label}
                      rules={{ required: true }}
                      showLabel={false}
                    />
                  ) : item.controlType === 'CRMApiSelect' ? (
                    <ControlledCrmObjectSelect
                      name={item.name}
                      integration={currentMapping?.crm?.toLowerCase()}
                      label={item.label}
                      crmType={currentMapping?.crm_object_type}
                      showLabel={false}
                    />
                  ) : item.controlType === 'Switch' ? (
                    <ControlledSwitch name={item.name} label={item.label} />
                  ) : (
                    <Typography variant="subtitle1">
                      {currentMapping?.[item.value] || 'N/A'}
                    </Typography>
                  )}
                </FormProvider>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12} sm={6} textAlign="end">
            <LoadingButton
              variant="contained"
              color="primary"
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid item mt={1} mb={1}>
          <MappingTestingSection
            testPushToCRMHandler={testPushToCRMHandler}
            dryRunResponse={dryRunResponse}
            crmTestLink={crmTestLink}
            isLoading={testIsLoading}
            dryRun={dryRun}
            hasOptions={hasOptions}
            testPushError={testPushError}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MappingDefinitionForm;
