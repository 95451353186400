import { useEffect, useState } from 'react';
import { Button, IconButton } from '@mui/joy';
import { useGetSavedSearches } from 'src/hooks';
import { createSavedSearch } from 'src/api';
import { useLogging, useProspectSearch, useAuth, useSite } from 'src/context';
import { faSearchPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

const NewSavedSearchDialog = ({ show, onHide }) => {
  const { user } = useAuth();
  const { state } = useSite();
  const { prospectFilters, prospectType, setProspectSearch } =
    useProspectSearch();
  const { mutate } = useGetSavedSearches();
  const manager =
    user?.permissions?.is_manager ||
    user?.company?.manager ||
    user?.is_superuser; // fixes so that MedScout users can save searches as company.

  const [inlineError, setInlineError] = useState('');
  const [scope, setScope] = useState<'personal' | 'company'>('personal');
  const [newName, setNewName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const log = useLogging();

  useEffect(() => {
    if (!show) {
      setInlineError('');
      setNewName('');
    }
  }, [show]);

  useEffect(() => {
    if (state?.savedSearch?.org_saved_search) {
      setScope(state?.savedSearch?.org_saved_search);
    }
  }, [state]);

  const onSubmit = async () => {
    const groupedFiltersByContext = prospectFilters.reduce(
      (result, { context, title, value }) => {
        // If the context is 'name', use 'entity' as the conditionalContext
        const conditionalContext = context === 'name' ? 'entity' : context;

        if (!result[conditionalContext]) {
          // If the context does not exist in result, initialize it as an array with the current filter
          result[conditionalContext] = [{ title, value }];
        } else {
          // Otherwise, append the current filter to the existing array
          result[conditionalContext] = [
            ...result[conditionalContext],
            { title, value },
          ];
        }

        return result;
      },
      {}
    );
    try {
      const newSearch = await createSavedSearch({
        name: newName.trim(),
        content_type: prospectType,
        org_search: scope === 'company',
        ...groupedFiltersByContext,
      });

      setProspectSearch(newSearch);

      log.event('createNewSavedSearch', {
        location: 'SearchShade',
        data: {
          ...newSearch,
        },
        user: {
          ...user,
        },
      });
    } catch (err) {
      setInlineError(err?.error_message);
      log.exception('Error creating new saved search', {
        tags: {
          location: 'SearchShade',
          action: 'onSubmit',
          user: user?.email,
          error: err,
        },
      });
    } finally {
      mutate();
      onHide();
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={show} onClose={onHide}>
      <div className="flex justify-between items-center px-3 py-2 bg-gray-200 border-b">
        <div className="text-lg font-medium flex items-center gap-2 pl-2">
          <Icon icon={faSearchPlus} size="sm" />
          <span>New Saved Search</span>
        </div>
        <IconButton onClick={() => onHide()}>
          <Icon icon={faTimes} />
        </IconButton>
      </div>
      <DialogContent onKeyDown={(e) => e.stopPropagation()}>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Name</label>
            <input
              className="w-full p-2 border border-gray-300 rounded-md"
              type="text"
              onChange={(e) => {
                setNewName(e.target.value);
              }}
              placeholder="e.g. Cardiac CPT® Codes"
              aria-label="Saved Search Name"
            />
          </div>
          {manager && (
            <div className="flex flex-col mt-3 gap-2">
              <label className="text-sm font-medium">Availability</label>
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="personal"
                    checked={scope === 'personal'}
                    onChange={() => {
                      setScope('personal');
                    }}
                    aria-label="Personal Saved Search"
                  />
                  <label htmlFor="personal" className="text-sm font-medium">
                    Personal
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="company"
                    checked={scope === 'company'}
                    onChange={() => {
                      setScope('company');
                    }}
                    aria-label="Company Saved Search"
                  />
                  <label htmlFor="company" className="text-sm font-medium">
                    Company
                  </label>
                </div>
              </div>
            </div>
          )}
        </form>
        {inlineError && (
          <div className="mt-3 bg-red-100 text-red-700 border border-red-400 px-4 py-3 rounded">
            {inlineError}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="neutral"
          onClick={() => {
            onHide();
          }}
          aria-label="Cancel New Saved Search"
        >
          Cancel
        </Button>
        <Button
          variant="solid"
          color="primary"
          loading={isLoading}
          aria-label="Create Saved Search"
          disabled={!newName || !scope}
          onClick={onSubmit}
        >
          <span>Create Saved Search</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewSavedSearchDialog;
