import useSWR from 'swr';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { getPayerMixReimbursement } from 'src/api/payerMix';

interface UseGetPayerMixReimbursementProps {
  id: string;
  savedSearchId: string;
  payer_name?: string;
  payer_type?: string;
  inpatient_outpatient_flag?: string;
}

const useGetPayerMixReimbursement = ({
  id,
  savedSearchId,
  payer_name,
  payer_type,
  inpatient_outpatient_flag,
}: UseGetPayerMixReimbursementProps) => {
  const queryParams = getSearchQueryParamsForAPI({
    payer_name,
    payer_type,
    inpatient_outpatient_flag,
  });

  const shouldFetch = !!id && !!savedSearchId;

  return useSWR(
    shouldFetch
      ? [QUERY_KEYS.PAYER_MIX_REIMBURSEMENT, id, savedSearchId, queryParams]
      : null,
    () => getPayerMixReimbursement({ id, savedSearchId, queryParams })
  );
};

export default useGetPayerMixReimbursement;
