import React from 'react';
import { Box, Typography } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { useLogging, useSite, useUserSettings } from 'src/context';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';
interface SavedSearchProps {
  search: any;
  context?: string | null;
  inFolder?: boolean;
  multiple?: boolean;
  onChange?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    search: any
  ) => void;
}

const SavedSearch = ({
  search,
  inFolder = false,
  multiple = false,
  context,
  onChange,
}: SavedSearchProps) => {
  const { newPayerMix } = useFlags();
  const log = useLogging();
  const { state } = useSite();
  const { state: userSettingsState, dispatch: userSettingsDispatch } =
    useUserSettings();

  const router = useRouter();
  const { type: location } = router.query as { type: string };

  const selectedSavedSearch = multiple
    ? state?.siteProvider[location]?.selectedSearches
    : newPayerMix
    ? userSettingsState?.selectedSavedSearch
    : state?.savedSearch?.filterBySavedSearch?.selectedSavedSearch;

  const isArray = Array.isArray(selectedSavedSearch);
  const isActive = isArray
    ? selectedSavedSearch.some((s) => s.id === search?.id)
    : selectedSavedSearch?.id === search?.id;

  if (!search) return null;

  const disableMap = {
    provider: false,
    site: search.matching_providers === 0,
    payer: !search.has_payer_mix_data && search.matching_providers === 0,
  };

  const isDisabled = disableMap[context as keyof typeof disableMap];

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (isDisabled) return;
    onChange(e, search);
    log.event('Saved Search Clicked', {
      source: 'SavedSearch',
      search: search,
    });
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 'var(--1, 0.25rem)',
        height: '2rem',
        padding: inFolder
          ? 'var(--none, 0px) var(--1, 8px) var(--none, 0px) 2.25rem'
          : '0px var(--1, 0.5rem)',
        borderRadius: 'sm',
        cursor: isDisabled ? 'default' : 'pointer',
        background: isActive ? '#D6F6F1' : 'transparent',
        '&:hover': {
          background: isDisabled
            ? 'transparent'
            : 'var(--background-level1, #F0F4F8)',
        },
      }}
      onClick={handleClick}
    >
      <Typography
        sx={{
          color: isDisabled ? 'text.disabled' : 'var(--text-primary, #171A1C)',
          opacity: isDisabled ? 0.5 : 1,
          fontSize: '0.875rem',
          fontWeight: 500,
          lineHeight: '1.243rem' /* 19.88px */,
          flex: '1 0 0',
          '&:hover': {
            color: 'var(--text-primary, #171A1C)',
          },
        }}
      >
        {search.search_name || search.name}
      </Typography>
      {!!search?.matching_providers && !isActive && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Typography
            level="body-xs"
            sx={{ color: 'text.secondary', fontWeight: 400 }}
          >
            {search.matching_providers}
          </Typography>
          <Typography
            level="body-xs"
            sx={{ color: 'text.secondary', fontWeight: 400 }}
          >
            {search.matching_providers === 1 ? 'Provider' : 'Providers'}
          </Typography>
        </Box>
      )}
      {isActive && (
        <Icon
          icon={faCheck}
          style={{
            color: isActive ? '#00A783' : '#555E68',
            padding: 0,
            fontWeight: 700,
            fontSize: '1.2rem',
          }}
        />
      )}
    </Box>
  );
};

export default SavedSearch;
