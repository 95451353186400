import React, { ChangeEvent, useRef, useEffect } from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleX,
  faFileCsv,
} from '@fortawesome/pro-solid-svg-icons';

const FileSelector: React.FC<{
  file;
  setFile;
  isValid?: boolean;
  isLoading?: boolean;
}> = ({ file, setFile, isValid = true, isLoading = false }) => {
  const inputRef = useRef(null);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      setFile(files[0]);
    }
  };

  useEffect(() => {
    if (!file) {
      inputRef.current.value = null;
    }
  }, [file]);

  return (
    <ButtonGroup sx={{ mb: 1 }}>
      <LoadingButton
        variant="contained"
        component="label"
        sx={{ whiteSpace: 'nowrap' }}
        loading={isLoading}
      >
        Select Territory File
        <input
          type="file"
          hidden
          accept=".csv"
          onChange={handleFileChange}
          ref={inputRef}
        />
      </LoadingButton>
      <Button
        variant="outlined"
        component="label"
        disabled
        sx={{
          backgroundColor: '#f8f8f8',
          textTransform: 'none',
        }}
        endIcon={
          isValid ? (
            <Icon icon={faCircleCheck} size="lg" />
          ) : (
            <Icon icon={faCircleX} size="lg" />
          )
        }
      >
        <Typography
          noWrap
          sx={{
            width: '17.75rem',
          }}
        >
          <Icon icon={faFileCsv} size="1x" style={{ padding: '0 0.25rem' }} />
          {file?.name || '...'}
        </Typography>
      </Button>
    </ButtonGroup>
  );
};

export default FileSelector;
