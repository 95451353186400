import React from 'react';
import { Tabs, TabSet, Tab, TabPanel } from 'src/components';
import { ImpersonateButton } from 'src/components/Settings/Users/components/UserManagement';
import { ManagerCard } from '../ManagerCard';
import { UserCard } from '../UserCard';
import { useSite } from 'src/context';
import { Security } from '../UserManagement';

const UserProfile = () => {
  const { state } = useSite();
  const { currentUser } = state.companyAdmin;
  if (!currentUser) return null;

  return (
    <div className="max-w-7xl mx-auto">
      <Tabs>
        <div className="flex flex-col w-full gap-6">
          <div className="flex justify-end">
            <ImpersonateButton userId={currentUser?.id?.toString()} />
          </div>

          <div className="flex gap-6">
            {/* Sidebar */}
            <div className="w-48 shrink-0">
              <TabSet id="user-profile-tabs" direction="vertical">
                <Tab tabSetId="user-profile-tabs" index={0}>
                  Account
                </Tab>
                <Tab tabSetId="user-profile-tabs" index={2}>
                  Permissions
                </Tab>
              </TabSet>
            </div>

            {/* Main Content */}
            <div className="flex-1">
              <TabPanel tabSetId="user-profile-tabs" index={0}>
                <div className="flex flex-col gap-6">
                  <UserCard />
                  <ManagerCard />
                </div>
              </TabPanel>
              <TabPanel tabSetId="user-profile-tabs" index={2}>
                <Security />
              </TabPanel>
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};

export default UserProfile;
