import React, { useEffect, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faHandHoldingCircleDollar,
} from '@fortawesome/pro-solid-svg-icons';
import { BetaFlag, Card, CardContent, CodeChip } from 'src/components';
import { useProfile, useUserSettings } from 'src/context';
import { useGetPayerMixReimbursement, useColumnResizing } from 'src/hooks';
import { titleCase } from 'src/utils';
import {
  ReimbursementAccordionRow,
  ReimbursementHeader,
  ReimbursementRow,
} from './components';

const tableHeaders = [
  {
    label: 'Payer Name',
    key: 'payer',
    width: 250,
    minWidth: 150,
    isSortable: true,
    isResizable: true,
  },
  {
    label: 'Place of Service',
    key: 'placeOfService',
    width: 175,
    minWidth: 175,
    isSortable: true,
    isResizable: true,
  },
  {
    label: 'Avg. Reimbursement',
    key: 'reimbursement',
    isSortable: true,
    isResizable: false,
  },
  {
    label: 'vs. State Avg. ($)',
    key: 'stateAvg',
    isSortable: true,
    isResizable: false,
  },
  {
    label: '% of Claims',
    key: 'percentOfTotal',
    isSortable: true,
    isResizable: false,
  },
];

const ProfileReimbursementTable = () => {
  const router = useRouter();
  const { code, payer_type, payer_name } = router.query as {
    code: string;
    payer_type: string;
    payer_name: string;
  };

  const { details } = useProfile();
  const { state: userSettingsState } = useUserSettings();

  const { data: reimbursementData } = useGetPayerMixReimbursement({
    id: details?.provider_id,
    savedSearchId: userSettingsState?.selectedSavedSearch?.id,
  });

  // Initialize with default column widths
  const { startResizing, getAdjustedWidth } = useColumnResizing({
    innerColumnOffset: 24,
    defaultWidths: tableHeaders
      .filter((header) => header.isResizable)
      .map((header) => ({
        key: header.key,
        width: header.width,
        minWidth: header.minWidth,
      })),
  });

  const [expandedCode, setExpandedCode] = useState<string | null>(null);
  const [expandedType, setExpandedType] = useState<string | null>(null);
  const [sortColumn, setSortColumn] = useState<string>('percentOfTotal');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const groupedData = useMemo(() => {
    // Group by code first, then by payer type - no sorting here
    return reimbursementData?.results?.reduce((acc, item) => {
      if (!acc[item.code_name]) {
        acc[item.code_name] = {};
      }
      item.payers.forEach((payer) => {
        if (!acc[item.code_name][payer.payer_type]) {
          acc[item.code_name][payer.payer_type] = [];
        }
        acc[item.code_name][payer.payer_type].push(payer);
      });
      return acc;
    }, {} as Record<string, Record<string, typeof reimbursementData.results[0]['payers']>>);
  }, [reimbursementData]);

  const { firstCode, firstPayerType, firstPayer } = useMemo(() => {
    // Early return if groupedData is null/undefined
    if (!groupedData || typeof groupedData !== 'object') {
      return {
        firstCode: null,
        firstPayerType: null,
        firstPayer: null,
      };
    }

    const codes = Object.keys(groupedData);
    if (!codes.length) {
      return {
        firstCode: null,
        firstPayerType: null,
        firstPayer: null,
      };
    }

    const firstCode = codes[0];
    const firstCodeData = groupedData[firstCode];

    if (!firstCodeData || typeof firstCodeData !== 'object') {
      return {
        firstCode,
        firstPayerType: null,
        firstPayer: null,
      };
    }

    const payerTypes = Object.keys(firstCodeData);
    if (!payerTypes.length) {
      return {
        firstCode,
        firstPayerType: null,
        firstPayer: null,
      };
    }

    const firstPayerType = payerTypes[0];
    const firstPayerData = firstCodeData[firstPayerType];

    return {
      firstCode,
      firstPayerType,
      firstPayer: Array.isArray(firstPayerData)
        ? firstPayerData[0] ?? null
        : null,
    };
  }, [groupedData]);

  // Effect to handle initial scroll and expand
  useEffect(() => {
    if (code && groupedData?.[code]) {
      setExpandedCode(code);
      if (payer_type && payer_name) {
        setExpandedType(titleCase(payer_type));
      }

      // Scroll into view with a small delay to ensure rendering is complete
      setTimeout(() => {
        if (payer_type && payer_name) {
          const idName = payer_name?.replace(/ /g, '-')?.toLowerCase();
          // if payer_type and payer_name are set, scroll to the payer row
          const element = document.getElementById(
            `reimbursement-table-payer-row-${payer_type}-${idName}`
          );
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          // if payer_type and payer_name are not set, scroll to the code row
          const element = document.getElementById(
            `reimbursement-table-code-row-${code}`
          );
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    } else if (firstCode && firstPayerType && firstPayer) {
      setExpandedCode(firstCode);
      setExpandedType(firstPayerType);
    }
  }, [
    code,
    groupedData,
    payer_type,
    payer_name,
    firstCode,
    firstPayerType,
    firstPayer,
  ]);

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('desc');
    }
  };

  const getSortedPayers = (
    payers: typeof reimbursementData.results[0]['payers']
  ) => {
    return [...payers].sort((a, b) => {
      const multiplier = sortOrder === 'asc' ? 1 : -1;

      switch (sortColumn) {
        case 'payer':
          return multiplier * a.payer_name.localeCompare(b.payer_name);
        case 'placeOfService':
          return (
            multiplier * a.place_of_service.localeCompare(b.place_of_service)
          );
        case 'reimbursement':
          return (
            multiplier *
            (a.avg_reimbursement_predicted - b.avg_reimbursement_predicted)
          );
        case 'stateAvg':
          return (
            multiplier *
            (a.state_avg_reimbursement_predicted -
              b.state_avg_reimbursement_predicted)
          );
        case 'percentOfTotal':
          return multiplier * (a.percent_of_total - b.percent_of_total);
        default:
          return 0;
      }
    });
  };

  const toggleAccordion = ({
    value,
    type,
  }: {
    value: string;
    type: string;
  }) => {
    switch (type) {
      case 'code': {
        const newExpandedCode = expandedCode === value ? null : value;
        setExpandedCode(newExpandedCode);
        if (!newExpandedCode) {
          setExpandedType(null);
        }
        break;
      }
      case 'type': {
        const newExpandedType = expandedType === value ? null : value;
        setExpandedType(newExpandedType);
        break;
      }
      default:
        break;
    }
  };

  return (
    <Card
      id="payer-mix-reimbursement"
      className="flex flex-col gap-4 p-6 min-h-64"
    >
      <div className="flex items-center gap-2">
        <Icon icon={faHandHoldingCircleDollar} size="lg" />
        <p className="body-lg font-bold text-primary">Reimbursement</p>
        <Icon
          icon={faCircle}
          size="sm"
          className="text-neutral-300 h-1.5 w-1.5"
        />
        <p className="text-sm font-normal text-neutral-500">2024</p>
        <BetaFlag />
      </div>
      <CardContent className="h-full">
        {reimbursementData?.results?.length > 0 ? (
          <div className="flex flex-col gap-2">
            {/* Table Header Row */}
            <div className="w-full flex pl-16 pr-4 gap-4">
              {tableHeaders.map((header) => (
                <ReimbursementHeader
                  key={header.key}
                  column={header}
                  onResizing={startResizing}
                  isSortable={header.isSortable}
                  isResizable={header.isResizable}
                  onSort={handleSort}
                  sortOrder={sortOrder}
                  isSorting={sortColumn === header.key}
                  sx={
                    header.isResizable
                      ? getAdjustedWidth({
                          columnId: header.key,
                        })
                      : {}
                  }
                />
              ))}
            </div>
            {/* Code Table */}
            <div className="flex flex-col gap-2">
              {Object.entries(groupedData ?? {}).map(([code, payerTypes]) => (
                <div
                  key={code}
                  className="bg-neutral-050 rounded-lg overflow-hidden"
                >
                  <ReimbursementAccordionRow
                    id={`reimbursement-table-code-row-${code}`}
                    isExpanded={expandedCode === code}
                    onExpand={() =>
                      toggleAccordion({ value: code, type: 'code' })
                    }
                  >
                    <CodeChip code={code} />
                  </ReimbursementAccordionRow>

                  {expandedCode === code && (
                    // Payer Table
                    <div className="flex flex-col animate-fade-in pt-0 pb-4 px-6">
                      <div className="flex flex-col gap-2 px-4 py-2">
                        {Object.entries(payerTypes ?? {}).map(
                          ([type, payers]) => (
                            <div key={type} className="flex flex-col gap-2">
                              <ReimbursementAccordionRow
                                id={`reimbursement-table-type-row-${type}`}
                                isExpanded={expandedType === type}
                                onExpand={() =>
                                  toggleAccordion({ value: type, type: 'type' })
                                }
                                className="!px-0 "
                              >
                                {titleCase(type)}
                              </ReimbursementAccordionRow>
                              {expandedType === type && (
                                <div className="flex flex-col pl-8 animate-fade-in">
                                  {getSortedPayers(payers)?.map((payer) => {
                                    const name = titleCase(payer.payer_name);
                                    const type = titleCase(payer.payer_type);
                                    const isSelected =
                                      type === payer_type &&
                                      name === payer_name;

                                    return (
                                      <ReimbursementRow
                                        key={`${payer.payer_name}-${payer.place_of_service_code}`}
                                        payer={payer}
                                        isSelected={isSelected}
                                        onResize={getAdjustedWidth}
                                      />
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center h-full min-h-48">
            <p className="text-sm font-semibold text-neutral-500">
              No data available
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ProfileReimbursementTable;
