import React, { useMemo, useEffect } from 'react';
import {
  Card,
  CardContent,
  MedBubbleChart,
  Link,
  BetaFlag,
} from 'src/components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightLong,
  faChartScatterBubble,
  faCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { useGetPayerMixReimbursement, useNewGetPayerMix } from 'src/hooks';
import { useProfile, useUserSettings } from 'src/context';
import { useRouter } from 'next/router';
import { titleCase } from 'src/utils';

const NewPayerMix = ({ showLegend = false, singleColumn = false }) => {
  const router = useRouter();
  const { details } = useProfile();
  const { state: userSettingsState, dispatch: userSettingsDispatch } =
    useUserSettings();
  const { data: reimbursementData } = useGetPayerMixReimbursement({
    id: details?.provider_id,
    savedSearchId: userSettingsState?.selectedSavedSearch?.id,
  });

  const newHref = `${router.asPath.replace(/\/$/, '')}/payer-mix`;

  const { data: payerMixByType } = useNewGetPayerMix({
    id: details?.provider_id,
    savedSearchId: userSettingsState?.selectedSavedSearch?.id,
    groupBy: 'payer_type',
  });

  const { data: payerMixByName } = useNewGetPayerMix({
    id: details?.provider_id,
    savedSearchId: userSettingsState?.selectedSavedSearch?.id,
    groupBy: 'payer_name',
  });

  const formattedPayerMixData = useMemo(() => {
    if (!payerMixByType?.results?.length) return [];

    return payerMixByType?.results?.map((item) => ({
      name: item.payer_type,
      value: item.percentage,
    }));
  }, [payerMixByType]);

  const sortedPayerResults = useMemo(() => {
    if (!payerMixByName?.results?.length) return [];

    return payerMixByName?.results?.sort((a, b) => b.percentage - a.percentage);
  }, [payerMixByName]);

  const selectedPayer = useMemo(
    () => (sortedPayerResults?.length > 0 ? sortedPayerResults[0] : null),
    [sortedPayerResults]
  );

  const topSite = useMemo(() => {
    if (!reimbursementData?.results?.length || !selectedPayer) return null;

    // Find the first result with a matching payer
    const matchingResult = reimbursementData?.results?.find((result) =>
      result?.payers?.some(
        (payer) =>
          payer.payer_name?.toLowerCase() ===
          selectedPayer.payer_name?.toLowerCase()
      )
    );

    if (!matchingResult) return null;

    // Find the matching payer within that result
    const matchingPayer = matchingResult?.payers?.find(
      (payer) =>
        payer.payer_name?.toLowerCase() ===
        selectedPayer.payer_name?.toLowerCase()
    );

    return matchingPayer;
  }, [reimbursementData, selectedPayer]);

  useEffect(() => {
    if (!selectedPayer) return;
    // Check if the site exists in the reimbursement data
    const siteDoesExist = reimbursementData?.results?.some((result) =>
      result.payers.some(
        (p) => p.place_of_service_code === userSettingsState?.selectedSite
      )
    );

    userSettingsDispatch({
      type: 'UPDATE_USER_SETTINGS',
      payload: {
        selectedPayer,
        // If the site does not exist, use the top site
        selectedSite: siteDoesExist
          ? userSettingsState?.selectedSite
          : topSite?.place_of_service_code ?? null,
      },
    });
  }, [selectedPayer, topSite, userSettingsDispatch]);

  const hasValues =
    payerMixByType?.results?.length > 0 &&
    payerMixByType?.results?.some((item) => item.percentage > 0);

  return (
    <Card
      className={`flex flex-col gap-2 p-6 h-full bg-white w-full ${
        showLegend && 'border border-teal-100'
      }`}
    >
      <div className="flex items-center gap-2">
        <Icon icon={faChartScatterBubble} size="lg" />
        <p className="body-lg font-bold text-primary">Payer Mix</p>
        <Icon
          icon={faCircle}
          size="sm"
          className="text-neutral-300 h-1.5 w-1.5"
        />
        <p className="text-sm font-normal text-gray-500">
          2022-2024
          <span className="text-sm relative" style={{ top: '-0.2em' }}>
            *
          </span>
        </p>
        <BetaFlag />
      </div>

      <CardContent className="flex-1 overflow-hidden">
        {hasValues ? (
          <div
            className={`flex flex-col ${
              singleColumn ? '' : 'lg:flex-row'
            } gap-2 h-full justify-center`}
          >
            <div className="w-full overflow-hidden">
              <MedBubbleChart
                data={formattedPayerMixData}
                width={492}
                height={375}
                legend={showLegend}
              />
            </div>
            {showLegend && (
              <div className="flex flex-col justify-center gap-2 h-full w-full">
                <p className="text-neutral-800 font-bold text-sm line-height-1.25rem">
                  Top Payers by Name
                </p>
                <div className="py-1 w-full">
                  <div className="flex gap-2 justify-between">
                    <p className="text-neutral-600 font-bold text-xs line-height-0.9rem">
                      Payer
                    </p>
                    <p className="text-neutral-600 font-bold text-xs line-height-0.9rem">
                      % of Claims
                    </p>
                  </div>
                  {sortedPayerResults?.slice(0, 3).map((item) => (
                    <div
                      key={item.payer_type}
                      className="flex min-w-64 gap-2 justify-between w-full py-1"
                    >
                      <p className="text-neutral-800 text-sm line-height-1.25rem">
                        {titleCase(item.payer_name)}
                      </p>
                      <p className="text-neutral-800 text-sm line-height-1.25rem">
                        {Math.round(item.percentage)}%
                      </p>
                    </div>
                  ))}
                </div>
                <Link
                  id={`payer-mix-view-all-payers-${details?.provider_id}`}
                  href={newHref}
                  underline="none"
                  className="flex flex-row items-center gap-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="text-sky-900 text-sm font-semibold line-height-1.25rem">
                    View All Payers
                  </p>
                  <Icon icon={faArrowRightLong} className="text-sky-900" />
                </Link>
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center h-full min-h-48">
            <p className="text-sm font-semibold text-neutral-500">
              No data available
            </p>
          </div>
        )}
      </CardContent>

      <div className="h-6">
        <p className="text-xs font-normal text-neutral-500">
          <span className="text-sm relative" style={{ top: '-0.2em' }}>
            *
          </span>
          Medicare may appear lower than expected due to CMS reporting lag.
        </p>
      </div>
    </Card>
  );
};

export default NewPayerMix;
