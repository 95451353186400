import React from 'react';
import { Box, Tooltip } from '@mui/joy';
import {
  ImpersonateButton,
  DeactivateButton,
  ResendInviteButton,
  PasswordResetButton,
} from 'src/components/Settings/Users/components';

interface ActionButtonsProps {
  showImpersonate?: boolean;
  selectedUser?: MedScout.User;
  isHovered?: boolean;
}

const ActionButtons = ({
  showImpersonate = false,
  selectedUser,
  isHovered,
}: ActionButtonsProps) => {
  return (
    <Box
      sx={{
        opacity: isHovered ? 1 : 0,
        transition: 'opacity 0.3s ease',
        backgroundColor: 'white',
        position: 'absolute',
        top: '35%',
        right: '0.5rem',
        transform: 'translateY(-65%)',
        zIndex: 9999,
        padding: '0.1rem 0.25rem',
        border: `1px solid ${isHovered ? '#ccc' : 'transparent'}`,
        borderRadius: '2rem',
        boxShadow: 'sm',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Tooltip title="Resend Invite" size="sm" arrow>
          <span>
            <ResendInviteButton
              userId={selectedUser?.id?.toString()}
              variant="icon"
            />
          </span>
        </Tooltip>
        <Tooltip title="Send Password Reset" size="sm" arrow>
          <span>
            <PasswordResetButton
              userId={selectedUser?.id?.toString()}
              variant="icon"
            />
          </span>
        </Tooltip>
        <Tooltip title="Deactivate" size="sm" arrow>
          <span>
            <DeactivateButton selectedUser={selectedUser} variant="icon" />
          </span>
        </Tooltip>
        {showImpersonate && (
          <Tooltip title="Impersonate" size="sm" arrow>
            <span>
              <ImpersonateButton
                userId={selectedUser?.id?.toString()}
                variant="icon"
              />
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default ActionButtons;
