/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{html,js,jsx,ts,tsx}'],
  theme: {
    extend: {
      keyframes: {
        'fade-in': {
          '0%': {
            opacity: '0',
            transform: 'translateY(-10px)',
          },
          '100%': {
            opacity: '1',
            transform: 'translateY(0)',
          },
        },
        pulseFade: {
          '0%': { opacity: '0' },
          '50%': { opacity: '1' },
          '100%': { opacity: '0' },
        },
        markerFade: {
          '0%': {
            opacity: '0',
          },
          '100%': {
            opacity: '1',
          },
        },
        markerFadeOut: {
          '0%': {
            opacity: '1',
          },
          '100%': {
            opacity: '0',
          },
        },
        highlight: {
          '0%': { backgroundColor: 'transparent' },
          '50%': { backgroundColor: 'rgb(186 230 253)' }, // sky-200
          '100%': { backgroundColor: 'transparent' },
        },
        'slide-in-right': {
          '0%': { transform: 'translateX(100%)' },
          '100%': { transform: 'translateX(0)' },
        },
        'slide-out-right': {
          '0%': { transform: 'translateX(0)' },
          '100%': { transform: 'translateX(100%)' },
        },
        'slide-in-left': {
          '0%': { transform: 'translateX(-100%)' },
          '100%': { transform: 'translateX(0)' },
        },
        'slide-out-left': {
          '0%': { transform: 'translateX(0)' },
          '100%': { transform: 'translateX(-100%)' },
        },
        'slide-in-top': {
          '0%': { transform: 'translateY(-100%)' },
          '100%': { transform: 'translateY(0)' },
        },
        'slide-out-top': {
          '0%': { transform: 'translateY(0)' },
          '100%': { transform: 'translateY(-100%)' },
        },
        'slide-in-bottom': {
          '0%': { transform: 'translateY(100%)' },
          '100%': { transform: 'translateY(0)' },
        },
        'slide-out-bottom': {
          '0%': { transform: 'translateY(0)' },
          '100%': { transform: 'translateY(100%)' },
        },
      },
      animation: {
        'fade-in': 'fade-in 0.3s ease-in-out',
        'pulse-fade': 'pulseFade 2s ease-in-out forwards',
        highlight: 'highlight 2s ease-in-out',
        'slide-in-right': 'slide-in-right 300ms ease-out',
        'slide-out-right': 'slide-out-right 300ms ease-out',
        'slide-in-left': 'slide-in-left 300ms ease-out',
        'slide-out-left': 'slide-out-left 300ms ease-out',
        'slide-in-top': 'slide-in-top 300ms ease-out',
        'slide-out-top': 'slide-out-top 300ms ease-out',
        'slide-in-bottom': 'slide-in-bottom 300ms ease-out',
        'slide-out-bottom': 'slide-out-bottom 300ms ease-out',
        markerFade: 'markerFade 800ms cubic-bezier(0.4, 0, 0.2, 1)',
        markerFadeOut: 'markerFadeOut 800ms cubic-bezier(0.4, 0, 0.2, 1)',
      },
      colors: {
        'sky-900': '#1B5FA3',
        'sky-800': '#207FC5',
        'sky-700': '#2391D9',
        'sky-600': '#27A4ED',
        'sky-500': '#2AB2FD',
        'sky-400': '#3BBEFE',
        'sky-300': '#59CAFE',
        'sky-200': '#87D9FF',
        'sky-100': '#B6E8FF',
        'sky-050': '#E2F6FF',
        'teal-900': '#005B38',
        'teal-800': '#007856',
        'teal-700': '#008865',
        'teal-600': '#009976',
        'teal-500': '#00A783',
        'teal-400': '#00B696',
        'teal-300': '#00C6A9',
        'teal-200': '#32D9C3',
        'teal-100': '#97E8DB',
        'teal-050': '#D6F6F1',
        'orange-900': '#cd5620',
        'orange-800': '#d96c23',
        'orange-700': '#e07a26',
        'orange-600': '#e78829',
        'orange-500': '#ec932c',
        'orange-400': '#efa23d',
        'orange-300': '#f1b25a',
        'orange-200': '#f5c887',
        'orange-100': '#f9ddb6',
        'orange-050': '#fcf2e2',
        'indigo-900': '#001ec0',
        'indigo-800': '#0029ca',
        'indigo-700': '#002ed0',
        'indigo-600': '#2335d9',
        'indigo-500': '#3739df',
        'indigo-400': '#6256e5',
        'indigo-300': '#8374e9',
        'indigo-200': '#a99cef',
        'indigo-100': '#ccc4f4',
        'indigo-050': '#ebe7fb',
        'purple-900': '#1900bc',
        'purple-800': '#400ac4',
        'purple-700': '#5113ca',
        'purple-600': '#611ed3',
        'purple-500': '#6c23d9',
        'purple-400': '#854ae0',
        'purple-300': '#9c6de6',
        'purple-200': '#b998ec',
        'purple-100': '#d5c2f3',
        'purple-050': '#efe6fa',
        'magenta-900': '#73005c',
        'magenta-800': '#9b0068',
        'magenta-700': '#b1006e',
        'magenta-600': '#c70075',
        'magenta-500': '#d8007a',
        'magenta-400': '#d92390',
        'magenta-300': '#dc53a6',
        'magenta-200': '#e386c0',
        'magenta-100': '#ecb6d9',
        'magenta-050': '#f7e2f0',
        'neutral-900': '#171717',
        'neutral-800': '#262626',
        'neutral-700': '#404040',
        'neutral-600': '#525252',
        'neutral-500': '#737373',
        'neutral-400': '#a3a3a3',
        'neutral-300': '#d4d4d8',
        'neutral-200': '#e5e5e5',
        'neutral-100': '#f5f5f5',
        'neutral-050': '#fafafa',
        'red-900': '#7f1d1d',
        'red-800': '#991b1b',
        'red-700': '#b91c1c',
        'red-600': '#dc2626',
        'red-500': '#ef4444',
        'red-400': '#f87171',
        'red-300': '#fca5a5',
        'red-200': '#fecaca',
        'red-100': '#fee2e2',
        'red-050': '#fef2f2',
        'green-900': '#14532d',
        'green-800': '#166534',
        'green-700': '#16a34a',
        'green-600': '#16a34a',
        'green-500': '#22c55e',
        'green-400': '#4ade80',
        'green-300': '#86efac',
        'green-200': '#bbf7d0',
        'green-100': '#dcfce7',
        'green-050': '#f0fdf4',
        'amber-900': '#78350f',
        'amber-800': '#92400e',
        'amber-700': '#b45309',
        'amber-600': '#d97706',
        'amber-500': '#f59e0b',
        'amber-400': '#fbbf24',
        'amber-300': '#fcd34d',
        'amber-200': '#fde68a',
        'amber-100': '#fef3c7',
        'amber-050': '#fffbeb',
        // Text Colors
        primary: '#262626',
        secondary: '#525252',
        tertiary: '#737373',
        link: '#2391D9',
        // Background Colors
        'level-1': '#fafafa',
        'level-2': '#f5f5f5',
        'level-3': '#e5e5e5',
        'level-4': '#d4d4d8',
      },
    },
  },
  plugins: [],
};
