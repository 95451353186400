import React from 'react';

interface CardContentProps {
  children: React.ReactNode;
  className?: string;
}

const CardContent = ({ children, className }: CardContentProps) => {
  return <div className={`flex flex-col ${className}`}>{children}</div>;
};

export default CardContent;
