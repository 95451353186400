import useSWR from 'swr';
import { getAllHexReports } from 'src/api';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

const useGetHexReports = ({ profile_type }) => {
  const queryParams = getSearchQueryParamsForAPI({
    profile_type,
  });

  const shouldFetch = !!profile_type;

  return useSWR(
    shouldFetch ? [QUERY_KEYS.GET_ALL_HEX_REPORTS, queryParams] : null,
    () => getAllHexReports({ queryParams }),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
};

export default useGetHexReports;
