'use client';

import React from 'react';
import { AccordionProvider } from './context/AccordionProvider';

const Accordion = ({ children }: { children: React.ReactNode }) => {
  return (
    <AccordionProvider>
      <div className="space-y-2 overflow-hidden">{children}</div>
    </AccordionProvider>
  );
};

export default Accordion;
