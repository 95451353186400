import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

type PieChartData = {
  name: string;
  value: number;
  color: string;
};

interface PieProps {
  data: PieChartData[];
}

const MedPieChart = ({ data }: PieProps) => {
  const svgRef = useRef(null);

  useEffect(() => {
    // Set dimensions and radius
    const width = 250;
    const height = 250;
    const radius = Math.min(width, height) / 2;

    // Create color scale
    // const color = d3.scaleOrdinal(d3.schemeCategory10);

    // Create the pie generator
    const pie = d3.pie<PieChartData>().value((d) => d.value);

    // Create the arc generator
    const arc = d3
      .arc()
      .innerRadius(radius / 2)
      .outerRadius(radius);

    // Clear previous SVG content
    d3.select(svgRef.current).selectAll('*').remove();

    // Create the SVG container
    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Append a tooltip
    const tooltip = d3
      .select(svgRef.current)
      .append('div')
      .style('position', 'absolute')
      .style('background-color', 'white')
      .style('border', '1px solid #ccc')
      .style('border-radius', '5px')
      .style('padding', '5px')
      .style('pointer-events', 'none')
      .style('visibility', 'hidden')
      .style('z-index', '9999');

    // Bind data and create pie chart
    const arcs = svg
      .selectAll('arc')
      .data(pie(data))
      .enter()
      .append('g')
      .attr('class', 'arc')
      .style('cursor', 'pointer');

    // Draw the slices
    arcs
      .append('path')
      .attr('d', arc as any) // Type assertion needed for d3 arc generator
      .attr('fill', (d: d3.PieArcDatum<PieChartData>) => d.data.color || 'gray')
      .on('mouseover', function (event, d) {
        const [mx, my] = d3.pointer(event);
        // Position the tooltip
        tooltip
          .style('left', `${mx + 10}px`)
          .style('top', `${my + 60}px`)
          .style('visibility', 'visible')
          .style('border', '2px solid #ccc')
          .style('border-radius', '0.5rem')
          .style('width', 'auto')
          .html(`<strong>${d.data.name}</strong>: ${d.data.value}`);
      })
      .on('mousemove', function (event) {
        const [x, y] = d3.pointer(event);
        tooltip.style('left', `${x + 10}px`).style('top', `${y + 60}px`);
      })
      .on('mouseout', function () {
        tooltip.style('visibility', 'hidden');
      });

    // Add or update the legend
    // remove any existing legend
    d3.select(svgRef.current.parentElement).selectAll('.legend').remove();
    const legend = d3
      .select(svgRef.current.parentElement) // Parent container
      .selectAll('.legend') // Ensure only one legend container exists
      .data([null]) // Keep one legend
      .join('div')
      .attr('class', 'legend')
      .style('display', 'flex')
      .style('flex-direction', 'column')
      .style('position', 'absolute')
      .style('top', '20px') // Positioning the legend above the pie chart
      .style('right', '15px') // Adjust positioning as needed
      .style('width', '150px')
      .style('max-height', '275px')
      .style('overflow-y', 'auto')
      .style('background-color', '#fff')
      .style('padding', '5px')
      .style('border', '1px solid #ccc')
      .style('border-radius', '5px');

    // Create or update the legend items
    legend
      .selectAll('.legend-item')
      .data(data)
      .join('div')
      .attr('class', 'legend-item')
      .style('display', 'grid')
      .style('grid-template-columns', 'auto 1fr')
      .style('align-items', 'flex-start')
      .style('width', '100%')
      .style('margin-bottom', '5px')
      .each(function (d, i) {
        // Colored square
        d3.select(this)
          .append('div') // Directly append the color square to the legend item
          .style('width', '10px')
          .style('height', '10px')
          .style('border-radius', '50%')
          .style('background-color', d.color || 'gray') // Use colorOption or default to color scale
          .style('margin-right', '7.5px')
          .style('margin-top', '2px');

        // Label text
        d3.select(this)
          .append('span') // Directly append the label text to the legend item
          .text(d.name)
          .style('font-size', '0.625rem') // Make the font size larger for visibility
          .style('font-weight', '700')
          .style('color', '#333');
      });
    // Add labels
    // arcs
    //   .append('text')
    //   .attr('transform', (d) => `translate(${arc.centroid(d)})`)
    //   .attr('text-anchor', 'middle')
    //   .text((d) => Math.round(d.data.y))
    //   .style('fill', 'white')
    //   .style('font-size', '12px');
  }, [data]);

  return (
    <div className="position-relative p-10 w-full h-full">
      <div ref={svgRef}></div>
    </div>
  );
};

export default MedPieChart;
