import React, { useState, useMemo, useCallback } from 'react';

type SortOrder = 'asc' | 'dsc';

interface SortConfig {
  key: string;
  order: SortOrder;
}

interface SortableColumn {
  key: string;
  type: 'string' | 'number' | 'date';
  sortFn?: (a: any, b: any, order: SortOrder) => number;
}

interface useColumnSortingProps<T> {
  data: T[];
  columns: SortableColumn[];
  defaultSortKey: string;
  defaultSortOrder: SortOrder;
}

const useColumnSorting = <T>({
  data,
  columns,
  defaultSortKey,
  defaultSortOrder,
}: useColumnSortingProps<T>) => {
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: defaultSortKey,
    order: defaultSortOrder,
  });

  const handleSort = useCallback((key: string) => {
    setSortConfig((prev) => {
      const newOrder = prev.order === 'asc' ? 'dsc' : 'asc';
      return { key, order: newOrder };
    });
  }, []);

  const sortedData = useMemo(() => {
    if (!data?.length || !sortConfig.key) return data;

    const column = columns.find((col) => col.key === sortConfig.key);
    if (!column) return data;

    return [...data].sort((a, b) => {
      const multiplier = sortConfig.order === 'asc' ? 1 : -1;

      // Use custom sort function if provided
      if (column.sortFn) {
        return column.sortFn(a, b, sortConfig.order);
      }

      // Default sorting based on type
      switch (column.type) {
        case 'string':
          return (
            multiplier *
            String(a[sortConfig.key]).localeCompare(String(b[sortConfig.key]))
          );

        case 'number':
          return (
            multiplier * (Number(a[sortConfig.key]) - Number(b[sortConfig.key]))
          );

        case 'date':
          return (
            multiplier *
            (new Date(a[sortConfig.key]).getTime() -
              new Date(b[sortConfig.key]).getTime())
          );

        default:
          return 0;
      }
    });
  }, [data, sortConfig, columns]);

  return {
    sortedData,
    handleSort,
    sortConfig,
  };
};

export default useColumnSorting;
