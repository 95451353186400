import React, { useEffect, useState } from 'react';

import {
  Card,
  Table,
  Box,
  Typography,
  Grid,
  CardContent,
  IconButton,
  Tooltip,
} from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { CircleRounded, CheckRounded } from '@mui/icons-material';
import {
  DashboardToolbar,
  DashboardColumnHeader,
  TablePagination,
  TableRow,
  DashboardLoading,
} from 'src/components/Settings/Users/components/UserManagement';
import { NewActionButtons } from 'src/components/Settings/Users';
import { useDashboard, useAuth } from 'src/context';
import { useCompany, useCompanyUsers } from 'src/hooks';
import { grey } from '@mui/material/colors';
import { useSite } from 'src/context';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'src/components/PulseUI';

const UserTable = () => {
  const { user } = useAuth();
  const { state, dispatch } = useSite();
  const { currentCompany } = state.companyAdmin;

  const { setShowDeactivateCompanyModal, setShowAddEditCompanyModal } =
    useDashboard();
  // may be on a page with no company selected, then it would just be users company
  const { data: company } = useCompany(user.company.id?.toString());

  const isMedScoutUser =
    user?.is_superuser && company?.name?.includes('MedScout');
  const companyToDisplay = currentCompany || company;
  const isSupportUser = user?.permissions?.is_support_user;

  const { userQueryParams, setUserQueryParams } = useDashboard();
  const { data: users, isLoading } = useCompanyUsers(currentCompany?.id);

  const [isHovered, setIsHovered] = useState(null);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<{ sort: string; order: 'asc' | 'desc' }>({
    sort: 'first_name',
    order: 'asc',
  });

  useEffect(() => {
    return () => {
      setUserQueryParams({
        q: '',
        order: 'asc',
        page: 1,
        pageSize: 50,
        sort: 'first_name',
        active_only: 1,
      });
    };
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    setUserQueryParams({ ...userQueryParams, q: value });
  };

  const handleColSort = (col: string, order: 'asc' | 'desc') => {
    setSort({ sort: col, order });
    setUserQueryParams({ ...userQueryParams, sort: col, order });
  };

  const handleRowClick = (user: MedScout.User) => {
    dispatch({
      type: 'SET_CURRENT_COMPANY_AND_USER',
      payload: {
        company: currentCompany,
        user,
      },
    });
  };

  return (
    <div className="flex flex-col h-full w-full gap-2">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1 w-full justify-center">
          <div className="flex items-center justify-between gap-2">
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <Typography level="title-lg">
                  {companyToDisplay?.name}
                </Typography>
                {isMedScoutUser && (
                  <Tooltip title="Edit Company" arrow size="sm">
                    <IconButton
                      size="sm"
                      variant="plain"
                      color="neutral"
                      onClick={() =>
                        setShowAddEditCompanyModal({
                          show: true,
                          company: companyToDisplay,
                        })
                      }
                    >
                      <Icon
                        icon={faPencil}
                        className="text-gray-500"
                        size="sm"
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <Typography level="body-sm">
                Welcome:{' '}
                {user.first_name
                  ? `${user.first_name} ${user.last_name}`
                  : user.email}
              </Typography>
            </div>

            <div className="flex items-center px-2">
              <Button
                variant="outlined"
                color="danger"
                onClick={() => setShowDeactivateCompanyModal(true)}
                className={
                  isMedScoutUser || isSupportUser
                    ? 'opacity-100'
                    : 'opacity-0 pointer-events-none'
                }
                disabled={companyToDisplay?.name?.includes('MedScout')}
              >
                Deactivate
              </Button>
            </div>
          </div>
          <div className="flex items-center">
            {(isMedScoutUser || isSupportUser) && (
              <div className="text-sm text-neutral-500 py-2">
                Company id: {companyToDisplay?.id}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <Grid container spacing={1}>
          <Grid xs={3}>
            <Card>
              <CardContent>
                <Typography level="body-sm">Total Users</Typography>
                <Typography level="title-lg">
                  {companyToDisplay?.user_count}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={3}>
            <Card>
              <CardContent>
                <Typography level="body-sm">Active Users</Typography>
                <Typography level="title-lg">
                  {companyToDisplay?.active_user_count}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={3}>
            <Card>
              <CardContent>
                <Typography level="body-sm">Max Seats</Typography>
                <Typography level="title-lg">
                  {companyToDisplay?.max_seats || 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <DashboardToolbar
          search={search}
          onSearch={handleSearch}
          showUserControls
          isMedScoutUser={isMedScoutUser && !isSupportUser}
          supportUser={currentCompany?.support_user_id?.toString()}
        />
      </div>
      <Box
        sx={{
          border: `1px solid ${grey[300]}`,
          borderRadius: '0.33rem',
          height: `calc(100vh - 310px)`,
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        <Table
          noWrap
          stickyHeader
          stickyFooter
          sx={{
            height: '100%',
            tableLayout: 'auto',
          }}
        >
          <thead>
            <tr>
              <th style={{ textAlign: 'left', width: '1rem' }}></th>
              <th style={{ textAlign: 'left', width: '5%' }}>
                <DashboardColumnHeader
                  column="is_active"
                  headerName="Active"
                  sort={sort}
                  onColSort={handleColSort}
                />
              </th>
              <th style={{ textAlign: 'left', width: '10%' }}>
                <DashboardColumnHeader
                  column="first_name"
                  headerName="First Name"
                  sort={sort}
                  onColSort={handleColSort}
                  isSortable
                />
              </th>
              <th style={{ textAlign: 'left', width: '10%' }}>
                <DashboardColumnHeader
                  column="last_name"
                  headerName="Last Name"
                  sort={sort}
                  onColSort={handleColSort}
                  isSortable
                />
              </th>
              <th style={{ textAlign: 'left', flex: 1 }}>
                <DashboardColumnHeader
                  column="username"
                  headerName="Username"
                  sort={sort}
                  onColSort={handleColSort}
                  isSortable
                />
              </th>
              <th style={{ textAlign: 'left' }}>
                <DashboardColumnHeader
                  column="manager"
                  headerName="Manager"
                  sort={sort}
                  onColSort={handleColSort}
                />
              </th>
              <th style={{ textAlign: 'left' }}>
                <DashboardColumnHeader
                  column="is_manager"
                  headerName="Is Manager"
                  sort={sort}
                  onColSort={handleColSort}
                />
              </th>
              <th style={{ textAlign: 'left' }}>
                <DashboardColumnHeader
                  column="is_lir_eligible"
                  headerName="LIR Eligible"
                  sort={sort}
                  onColSort={handleColSort}
                />
              </th>
              <th style={{ textAlign: 'left' }}>
                <DashboardColumnHeader
                  column="is_integration_admin"
                  headerName="Integration Admin"
                  sort={sort}
                  onColSort={handleColSort}
                />
              </th>
              <th style={{ textAlign: 'left' }}>
                <DashboardColumnHeader
                  column="last_login"
                  headerName="Last Login"
                  sort={sort}
                  onColSort={handleColSort}
                />
              </th>
              <th style={{ textAlign: 'left', width: '10%' }}></th>
            </tr>
          </thead>
          <tbody
            style={{
              width: '100%',
              position: 'relative',
              overflowY: 'auto',
            }}
          >
            {users?.results?.map((user) => {
              let userManager = null;
              if (user.managers.length > 0) {
                userManager = `${user.managers[0].first_name} ${user.managers[0].last_name}`;
              }
              return (
                <TableRow
                  key={user.id}
                  onClick={() => handleRowClick(user)}
                  onMouseOver={() => setIsHovered(user.id)}
                  onMouseOut={() => setIsHovered(null)}
                  onMouseLeave={() => setIsHovered(null)}
                >
                  <td style={{ textAlign: 'left' }}></td>
                  <td style={{ textAlign: 'left' }}>
                    <CircleRounded
                      sx={{
                        width: '0.9rem',
                        height: '0.9rem',
                      }}
                      color={user.is_active ? 'success' : 'error'}
                    />
                  </td>
                  <td style={{ textAlign: 'left' }}>{user.first_name}</td>
                  <td style={{ textAlign: 'left' }}>{user.last_name}</td>
                  <td style={{ textAlign: 'left' }}>{user.username}</td>
                  <td style={{ textAlign: 'left' }}>
                    {userManager && (
                      <Typography level="body-sm">{userManager}</Typography>
                    )}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {user?.permissions?.is_manager && (
                      <CheckRounded fontSize="small" />
                    )}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {user.is_lir_eligible && <CheckRounded fontSize="small" />}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {user.permissions?.is_integration_admin && (
                      <CheckRounded fontSize="small" />
                    )}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {user.last_login && (
                      <Typography level="body-sm">
                        {new Date(user.last_login).toLocaleString()}
                      </Typography>
                    )}
                  </td>
                  <td
                    style={{ textAlign: 'right', width: 0 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <NewActionButtons
                      showImpersonate
                      selectedUser={user}
                      isHovered={isHovered === user.id}
                    />
                  </td>
                </TableRow>
              );
            })}
            <tr></tr>
          </tbody>
          <tfoot
            style={{
              background: 'white',
            }}
          >
            <tr
              style={{
                background: 'white',
              }}
            >
              <td
                colSpan={12}
                style={{
                  background: 'white',
                }}
              >
                <TablePagination
                  count={users?.count || 0}
                  sortValues={userQueryParams}
                  setSortValues={setUserQueryParams}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
        {isLoading && <DashboardLoading />}
      </Box>
    </div>
  );
};

export default UserTable;
