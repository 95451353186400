import 'src/styles/globals.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import { AppReleaseChecker } from 'src/components';
import { GlobalProvider } from 'src/context';
import { LicenseInfo } from '@mui/x-license-pro';
import type { AppProps } from 'next/app';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { env } from 'src/constants';

config.autoAddCss = false;
LicenseInfo.setLicenseKey(env.MUI_X_KEY);

type AppPropsWithLayout = AppProps & {
  Component;
};

const Noop = ({ children }) => {
  return children;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const Layout = Component.layout || Noop;

  return (
    <GlobalProvider pageProps={pageProps}>
      <AppReleaseChecker />
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </GlobalProvider>
  );
}

export default withLDProvider<any>({
  clientSideID: env.FEATURES_ID,
})(MyApp);
