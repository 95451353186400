import { keyframes } from '@emotion/react';
import { Box, styled } from '@mui/joy';

export const contentStyles = (i, allDetails = false) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `${i === 0 || allDetails ? 'none' : '1px solid #e9e9e9'}`,
    padding: '0.25rem 0',
    '&:first-of-type': {
      borderTop: 'none',
    },
  };
};

const bounce = keyframes`
  from,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -20px, 0) scaleY(1.1);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10px, 0) scaleY(1.05);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }
  90% {
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
    `;

export const bounceBox = {
  display: 'flex',
  alignContent: 'space-between',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '2rem',
  animation: `${bounce} 2s linear`,
  transformOrigin: 'center bottom',
};

const flipComponents = keyframes`
    0%, 50% {
    opacity: 1;
    transform: scale(1)
    }
    100% {
    opacity: 0;
    transform: scale(.5)
    }`;

export const AnimationBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: infinite linear 3s ${flipComponents};
`;

export const medAICard = {
  padding: '1.5rem',
  backgroundColor: '#E2F6FF',
  borderRadius: '0.5rem',
  position: 'relative',
  boxSizing: 'border-box',
  border: '2px solid transparent',
  background:
    'linear-gradient(#E2F6FF 100%, #E2F6FF 100%) padding-box, linear-gradient(#87E9DC, #4184E7) border-box',
};

export const gradientBorder = {
  border: '4px solid linear-gradient(to top left, #4184E7, #87E9DC) !important',
};
