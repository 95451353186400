import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetHexReports } from 'src/hooks';

export const categoryTitles = {
  affiliations: 'Providers',
  ['payer-mix']: 'Payer Insights',
  patient_patterns: 'Referrals',
  referrals: '2015 Inbound Referrals',
  old_affiliations: 'Affiliations',
  apc: 'APC Procedures',
  cpt: 'CPT® Procedures',
  drg: 'DRG Procedures',
  icd: 'Diagnoses',
  icdp: 'ICD Procedures',
  hcpcs: 'HCPCS Level II',
  prescriptions: 'Prescriptions',
  payments: 'Open Payments',
  education: 'Education Network',
  relationships: 'Relationships',
};

type formatMenuContentProps = {
  id: string;
  type: string;
  categories: { [key: string]: boolean };
  viewportWidth: number;
  isSmallHeader: boolean;
};

type ProfileCategory = {
  label: string;
  path?: string;
  id?: string | null;
  tabs?: { label: string; path: string; id: string }[];
  icon?: any;
};

const useMenuContent = ({
  categories,
  id,
  type,
  viewportWidth,
  isSmallHeader,
}: formatMenuContentProps) => {
  const { data: reportsData } = useGetHexReports({
    profile_type: type,
  });
  const { newPayerMix } = useFlags();

  const isGroupPac = type === 'hcp-group';
  const hasReports = reportsData?.results?.length > 0;

  const smallHeaderMenuLength = hasReports ? 0 : 1; // when reports visible, only populate More menu, else show one additional tab

  // Define breakpoints for better readability
  const BREAKPOINTS = {
    sm: 690,
    md: 920,
    lg: 1150,
  };

  // Calculate menu length based on screen size
  const getMenuLength = (width: number, hasReports: boolean) => {
    if (width < BREAKPOINTS.sm) {
      return hasReports ? 0 : 1;
    } else if (width < BREAKPOINTS.md) {
      return hasReports ? 1 : 2;
    } else if (width < BREAKPOINTS.lg) {
      return hasReports ? 3 : 4;
    } else {
      return hasReports ? 4 : 5;
    }
  };

  const menuLength = getMenuLength(viewportWidth, hasReports);

  const tabMenuLength = isSmallHeader ? smallHeaderMenuLength : menuLength;

  const profileCategories: ProfileCategory[] = [
    {
      label: 'Activity',
      path: `/profiles/${type}/${id}/activity`,
      id: 'activity',
      icon: faBolt,
    },
    {
      label: 'Overview',
      path: `/profiles/${type}/${id}`,
      id: 'overview',
    },
  ];

  const procedureCategories = ['drg', 'apc', 'cpt', 'icdp', 'hcpcs'].filter(
    (proc) => !!categories[proc]
  );

  if (procedureCategories.length > 0) {
    profileCategories.push({
      label: 'Procedures',
      path: `/profiles/${type}/${id}/drg`,
      id: 'procedure',
      tabs: procedureCategories.map((proc) => ({
        label: categoryTitles[proc],
        path: `/profiles/${type}/${id}/${proc}`,
        id: proc,
      })),
    });
  }

  if (hasReports) {
    profileCategories.push({
      label: 'Reports',
      path: `/`,
      id: 'reports',
      tabs: reportsData.results.map((report) => ({
        label: report.label,
        path: `/profiles/${type}/${id}/${report.id}`,
        id: report.id,
      })),
    });
  }

  const remainingCategories = Object.keys(categoryTitles)
    .filter(
      (view) =>
        (!procedureCategories.includes(view) &&
          (!!categories[view] ||
            !!categories[`inbound_${view}`] ||
            !!categories[`outbound_${view}`])) ||
        (view === 'payer-mix' && newPayerMix && !isGroupPac)
    )
    .map((view) => {
      if (view === 'old_affiliations') {
        const newView = 'group-affiliations';
        return {
          label: categoryTitles[view],
          path: `/profiles/${type}/${id}/${newView}`,
          id: newView,
        };
      }
      return {
        label: categoryTitles[view],
        path: `/profiles/${type}/${id}/${view}`,
        id: view,
      };
    });

  if (remainingCategories.length > tabMenuLength) {
    const visibleCategories = remainingCategories.slice(0, tabMenuLength);
    const moreMenuItems = remainingCategories.slice(tabMenuLength);

    // Add visible categories
    profileCategories.push(...visibleCategories);

    // Only add More menu if there's more than one item in it
    if (moreMenuItems.length > 1) {
      profileCategories.push({
        label: 'More',
        path: '#',
        id: 'more',
        tabs: moreMenuItems,
      });
    } else if (moreMenuItems.length === 1) {
      // If only one item would be in More menu, just show it directly
      profileCategories.push(moreMenuItems[0]);
    }
  } else {
    profileCategories.push(...remainingCategories);
  }

  return profileCategories;
};

export default useMenuContent;
