import { useEffect, useMemo, useState, useRef } from 'react';
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { useMap } from '@vis.gl/react-google-maps';
import { useMedScoutMap } from 'src/context';
import { useGetClusterObjects } from '../MedMapContent/hooks';
import { useDebounce } from 'use-debounce';

interface HeatMapLayerProps {
  opacity?: number;
}

const HeatMapLayer = ({ opacity = 0.5 }: HeatMapLayerProps) => {
  const map = useMap();
  const activeMarker = null;
  const { clusterObjects } = useGetClusterObjects(activeMarker);
  const { zoom } = useMedScoutMap();

  // Add state for smooth transitions
  const [isVisible, setIsVisible] = useState(false);
  const overlayRef = useRef<GoogleMapsOverlay | null>(null);
  const prevLayerRef = useRef<HeatmapLayer | null>(null);

  // Use longer debounce time for even smoother updates
  const [debouncedClusterObjects] = useDebounce(clusterObjects, 700);
  const [debouncedZoom] = useDebounce(zoom, 700);

  // Fade in effect when component mounts
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  // Calculate the current opacity based on visibility state
  const currentOpacity = isVisible ? opacity : 0;

  let radius = 15;
  const minZoom = 4;
  const maxZoom = 20;
  const minRadius = 15;
  const maxRadius = 100;

  // Dynamically calculate the radius based on the zoom level
  if (debouncedZoom >= maxZoom) {
    radius = maxRadius;
  } else if (debouncedZoom <= minZoom) {
    radius = minRadius;
  } else {
    radius =
      minRadius +
      ((maxRadius - minRadius) * (debouncedZoom - minZoom)) /
        (maxZoom - minZoom);
  }

  // Create the heatmap layer using deck.gl with smooth transitions
  const heatmapLayer = useMemo(() => {
    if (!debouncedClusterObjects) return null;

    // Create a new layer with transition parameters
    const newLayer = new HeatmapLayer({
      id: 'heatmap-layer',
      data: debouncedClusterObjects,
      aggregation: 'SUM',
      getPosition: (d) => d.geometry.coordinates, // Extract position as [lng, lat]
      getWeight: (d) => {
        const claims = d.properties?.claims;
        // Return 1 as default weight if claims is undefined/null/NaN
        return Number.isFinite(Number(claims)) ? Number(claims) : 0;
      },
      radiusPixels: radius, // Dynamic radius based on zoom
      intensity: 2,
      threshold: 0.07,
      opacity: currentOpacity,
      transitions: {
        getPosition: {
          duration: 800,
          easing: (t) => t * (2 - t), // Ease out quad for smoother deceleration
        },
        getWeight: {
          duration: 800,
          easing: (t) => t * (2 - t),
        },
        radiusPixels: {
          duration: 800,
          easing: (t) => t * (2 - t),
        },
        opacity: {
          duration: 800,
          easing: (t) => t * (2 - t),
        },
      },
    });

    // Store the layer for reference
    prevLayerRef.current = newLayer;
    return newLayer;
  }, [debouncedClusterObjects, radius, currentOpacity]);

  // Use effect to add and update the deck.gl overlay to the Google Map with smooth transitions
  useEffect(() => {
    if (!map || !heatmapLayer) return;

    // Create a new overlay or reuse existing one
    if (!overlayRef.current) {
      const overlay = new GoogleMapsOverlay({
        layers: [heatmapLayer],
      });

      overlay.setMap(map);
      overlayRef.current = overlay;
    } else {
      // Update existing overlay with new layer
      overlayRef.current.setProps({
        layers: [heatmapLayer],
      });
    }

    // Clean up the overlay when the component unmounts
    return () => {
      if (overlayRef.current) {
        overlayRef.current.setMap(null);
        overlayRef.current.finalize();
        overlayRef.current = null;
      }
    };
  }, [map, heatmapLayer]);

  return null; // No visible component; it's all in the overlay
};

export default HeatMapLayer;
