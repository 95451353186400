'use client';

import { useTab } from '../../context';
import React from 'react';

interface TabProps {
  tabSetId: string;
  children: React.ReactNode;
  index: number;
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  className?: string;
}

const Tab = ({
  children,
  tabSetId,
  index,
  startDecorator,
  endDecorator,
  className,
}: TabProps) => {
  const { isActive, onClick, direction } = useTab(tabSetId, index);

  const isHorizontal = direction === 'horizontal';
  const directionStyle = isHorizontal
    ? 'px-2 py-3 text-left'
    : 'py-2 px-2 w-full text-left';

  const activeStyle = isActive
    ? `text-neutral-600 hover:text-neutral-700 font-semibold ${
        isHorizontal ? 'border-b-4 border-sky-500' : ''
      }`
    : 'text-neutral-400 hover:text-neutral-500';

  return (
    <button
      role="tab"
      onClick={onClick}
      className={` text-sm flex items-center gap-2  ${directionStyle} ${activeStyle} ${className}`}
    >
      {startDecorator && startDecorator}
      {children}
      {endDecorator && endDecorator}
    </button>
  );
};

export default Tab;
