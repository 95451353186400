import React, { useMemo, useState } from 'react';
import {
  AccordionGroup,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Select,
  Option,
  Table,
  Card,
} from '@mui/joy';
import { useProfile } from 'src/context';
import { useGetPayerMix } from 'src/hooks';
import { DataBox } from 'src/components';
import { faChartPie } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

interface PayerMixProps {
  [key: string]: {
    items: Array<{ [key: string]: any }>;
    total: number;
  };
}

/**
 * @description PayerMix Accordion component
 * @param param0 data: Array<{ title: string, value: number }>
 * @returns React.ReactElement
 */
const PayerMix = () => {
  const { details } = useProfile();
  const { data: payerMixData, isLoading } = useGetPayerMix({
    id: details?.provider_id,
  });

  const [payerMixSort, setPayerMixSort] = useState('type');

  // Group data by type or payer
  const typeMap = {
    type: {
      groupBy: 'payer_category',
      name: 'payer_name',
      header: 'Type',
    },
    payer: {
      groupBy: 'payer_name',
      name: 'payer_category',
      header: 'Payer',
    },
  };

  const groupedData: PayerMixProps = payerMixData?.results?.reduce(
    (acc, curr) => {
      const type = curr?.[typeMap[payerMixSort]?.groupBy];

      if (!acc[type]) {
        acc[type] = {
          items: [],
          total: 0,
        };
      }

      acc[type].items.push(curr);
      acc[type].total += curr.unique_patients;

      return acc;
    },
    {}
  );

  // Convert groupedData to an array
  const groupedAndSorted = useMemo(() => {
    if (!groupedData) return [];

    // Calculate the grand total in a single pass
    const grandTotal = Object.values(groupedData).reduce(
      (sum, group) => sum + group.total,
      0
    );

    // Convert groupedData to an array and add grandTotal to each object
    return Object.entries(groupedData)
      .map(([type, group]) => ({
        type,
        items: group.items
          .slice()
          .sort((a, b) => b.unique_patients - a.unique_patients),
        total: group.total,
        grandTotal,
      }))
      .sort((a, b) => b.total - a.total);
  }, [groupedData]);

  // get percentages
  function getPercent(value: number, total: number) {
    return ((value / total) * 100).toFixed(2);
  }

  if (!payerMixData?.results?.length) return null;
  return (
    <Card sx={{ position: 'relative', padding: '0' }}>
      <Box
        sx={{
          display: 'block',
          alignItems: 'center',
          padding: '1rem 1rem 0',
        }}
      >
        <Icon icon={faChartPie} />
        <Typography sx={{ display: 'inline-block', marginLeft: '0.5rem' }}>
          Payer Mix
        </Typography>
        <Typography
          level="body-xs"
          sx={{ paddingTop: '0.25rem', fontStyle: 'italic' }}
        >
          Does not reflect Part B Medicare claims outside of the hospital
          setting
        </Typography>
      </Box>
      <DataBox
        isCommercial
        isMedicare
        sx={{ position: 'absolute', top: '0.5rem', right: '1.5rem' }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '0.5rem',
          gap: '0.5rem',
        }}
      >
        <Typography level="body-xs">Group by:</Typography>
        <Select
          id="payer-mix-group-by"
          size="sm"
          onChange={(e, newValue) => setPayerMixSort(newValue)}
          value={payerMixSort || 'type'}
          renderValue={(value) => {
            return (
              <Typography level="body-sm" sx={{ fontWeight: 500 }}>
                {value.label}
              </Typography>
            );
          }}
        >
          <Option value="type">Payer Type</Option>
          <Option value="payer">Payer Name</Option>
        </Select>
      </Box>
      <Box sx={{ minHeight: '5rem', maxHeight: '40rem', overflowY: 'auto' }}>
        <AccordionGroup>
          {groupedAndSorted?.map((value, index) => (
            <Accordion key={index}>
              <AccordionSummary
                variant="soft"
                id={`payer-mix-accordion-${value?.type}`}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography level="body-sm">{value?.type}</Typography>
                  <Typography level="title-sm">
                    {getPercent(value?.total, value?.grandTotal)}%
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Table borderAxis="none" size="sm" stripe="odd">
                  <thead>
                    <tr>
                      <th style={{ width: '78%' }}>
                        <Typography level="body-xs">Name</Typography>
                      </th>
                      <th style={{ width: '11%' }}>
                        <Typography
                          level="body-xs"
                          sx={{ fontWeight: 600, textAlign: 'right' }}
                        >
                          % {typeMap[payerMixSort].header}
                        </Typography>
                      </th>
                      <th style={{ width: '11%' }}>
                        <Typography
                          level="body-xs"
                          sx={{ fontWeight: 600, textAlign: 'right' }}
                        >
                          % Total
                        </Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {value?.items?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Typography level="body-xs">
                            {item?.[typeMap[payerMixSort]?.name]}
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            level="body-xs"
                            sx={{ textAlign: 'right' }}
                          >
                            {getPercent(item.unique_patients, value.total)}%
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            level="body-xs"
                            sx={{ textAlign: 'right' }}
                          >
                            {getPercent(item.unique_patients, value.grandTotal)}
                            %
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}
        </AccordionGroup>
      </Box>
    </Card>
  );
};

export default PayerMix;
