import React, { useState } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { CircularProgress, ClickAwayListener } from 'src/components/PulseUI';

type BreadcrumbItem = {
  id: string;
  label: string;
  items?: BreadcrumbItem[];
  isActive?: boolean;
  isLast?: boolean;
  isSelected?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  onScroll?: (e: React.UIEvent<HTMLElement>) => void;
};

const BreadcrumbItem = ({
  label,
  items,
  isLast = false,
  isActive = false,
  onClick,
  onScroll,
  isLoading = false,
}: BreadcrumbItem) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleTopClick = () => {
    if (items?.length) {
      setShowDropdown(!showDropdown);
    } else if (!isLast && onClick) {
      onClick();
    }
  };

  const handleDropdownClick = (item: BreadcrumbItem) => {
    if (item.onClick) item.onClick();
    setShowDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <div className="relative">
        <div
          className={`text-neutral-600 text-sm font-semibold
            ${isActive ? 'text-blue-600' : 'text-neutral-600'}
            ${!isLast ? 'cursor-pointer hover:text-blue-600' : ''}
            flex items-center gap-1`}
          onClick={handleTopClick}
        >
          {label}
          {items?.length > 0 && (
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          )}
        </div>

        {showDropdown && items?.length > 0 && (
          <div
            className="absolute top-full left-0 mt-1 bg-white 
              border border-neutral-200
              shadow-md rounded-md py-1 z-10 min-w-48 max-h-48 overflow-y-auto"
            onScroll={onScroll}
          >
            {items.map((item) => (
              <div
                key={item.id}
                className="flex items-center gap-2 px-2 py-2 hover:bg-gray-100 cursor-pointer text-xs"
                onClick={() => handleDropdownClick(item)}
              >
                <Icon
                  size="sm"
                  icon={faCheck}
                  className={`text-teal-500 ${
                    item.isSelected ? 'opacity-100' : 'opacity-0'
                  }`}
                />
                <span className="whitespace-nowrap">{item.label}</span>
              </div>
            ))}
            {isLoading && (
              <div className="flex items-center justify-center py-2">
                <CircularProgress size="sm" color="neutral" />
              </div>
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default BreadcrumbItem;
