import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faUpRightAndDownLeftFromCenter,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { InfoRow } from '../InfoRow';
import { ProfileBladeActions } from '../ProfileBladeActions';
import { useLogging, useProfile, useSite } from 'src/context';
import ProfileBladeCRMLinks from '../ProfileBladeCRMLinks/ProfileBladeCRMLinks';

const hcpLabelMap = {
  NPI: 'NPI',
  'Addresses.primary_address.Phone': 'Phone',
  Email: 'Email',
};

const ProfileBladeHeader = () => {
  const router = useRouter();
  const log = useLogging();
  const { dispatch } = useSite();

  const { details, refetch } = useProfile();
  const [showMore, setShowMore] = React.useState(false);

  const headerContent = useMemo(() => {
    return details?.hcp?.general
      ? Object.entries(hcpLabelMap)
          .map(([key, title]) => {
            const matchingDetail = details?.hcp?.general.find(
              (detail) => detail.title === title
            );
            return matchingDetail || { title, value: '' };
          })
          .filter((detail) => detail.value !== null) // Filter out objects with a value of null
      : [];
  }, [details]);

  const handleClose = () => {
    dispatch({ type: 'CLOSE_PROFILE_DRAWER' });
  };

  const handleProfileClick = () => {
    handleClose();
    router.push(
      `/profiles/${details.content_type || 'hcp'}/${details.provider_id}`
    );
  };

  const moreDetails = headerContent && headerContent?.length > 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        background: 'var(--Primary-800, #207FC5)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: 'var(--3, 1.5rem)',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 'var(--2, 1rem)',
          alignSelf: 'stretch',
        }}
      >
        {!details ? (
          <Box
            sx={{
              height: '5rem',
            }}
          />
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                gap: 'var(--1, 0.5rem)',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  flex: '1 0 0',
                  color: 'var(--Common-White, #FFF)',
                  fontFamily: 'Inter',
                  fontSize: 'var(--4, 2rem)',
                  fontStyle: 'normal',
                  fontWeight: 900,
                  lineHeight: 'var(--4, 2rem)' /* 100% */,
                }}
              >
                {details?.name}
              </Typography>
              <Icon
                icon={faUpRightAndDownLeftFromCenter}
                color="var(--Common-White, #FFF)"
                onClick={handleProfileClick}
                style={{
                  cursor: 'pointer',
                }}
              />
              <Icon
                icon={faXmark}
                size="lg"
                color="var(--Common-White, #FFF)"
                onClick={handleClose}
                style={{
                  cursor: 'pointer',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 'var(--1, 0.5rem)',
                alignSelf: 'stretch',
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: 'var(--Common-White, #FFF)',
                    fontFamily: 'Inter',
                    fontSize: '0.875rem' /* 14px */,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '135%' /* 18.9px */,
                  }}
                >
                  {details?.hcp?.general_v2?.['Specialty']}
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Common-White, #FFF)',
                    fontFamily: 'Inter',
                    fontSize: '0.875rem' /* 14px */,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '135%' /* 18.9px */,
                  }}
                >
                  {`${details?.city}, ${details?.state}`}
                </Typography>
              </Box>
              {moreDetails && (
                <>
                  {showMore && (
                    <>
                      {headerContent.map((item, i) => (
                        <InfoRow key={i} item={item} />
                      ))}
                      {details.crm_links && <ProfileBladeCRMLinks />}
                    </>
                  )}
                  <Typography
                    sx={{
                      color: 'var(--Secondary-100, #97E8DB)',
                      fontFamily: 'Inter',
                      fontSize: '0.875rem' /* 14px */,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '146%' /* 18.98px */,
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? 'Show Less' : 'Show More'}...
                  </Typography>
                </>
              )}
            </Box>
          </>
        )}
        <ProfileBladeActions provider={details} onRefetch={refetch} />
      </Box>
    </Box>
  );
};

export default ProfileBladeHeader;
