import { useMemo } from 'react';
import { useGetMedMapSearchResults } from 'src/hooks';

const useGetClusterObjects = (activeMarker) => {
  const { data: searchResults } = useGetMedMapSearchResults();

  const clusterObjects = useMemo(() => {
    return searchResults?.results?.map((point) => {
      return {
        details: point,
        properties: {
          cluster: false,
          id: point.id,
          count: point.count,
          claims: point.claims, // for heatmap
          provider_id: point.provider_id,
          hovered: activeMarker
            ? activeMarker === point.id || activeMarker === point.provider_id
            : false,
        },
        geometry: {
          type: 'Point',
          coordinates: [point.lng, point.lat],
        },
      };
    });
  }, [activeMarker, searchResults?.results]);

  return {
    clusterObjects,
  };
};

export default useGetClusterObjects;
