import React, { useState, useEffect, useRef } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config.js';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faEyeDropper } from '@fortawesome/pro-solid-svg-icons';
import { createPortal } from 'react-dom';

interface TailwindColorPickerProps {
  onSelect: (color: string) => void;
}

// Function to calculate relative luminance of a color
const getLuminance = (hexColor: string): number => {
  // Remove # if present
  const hex = hexColor.replace('#', '');

  // Convert hex to RGB
  const r = parseInt(hex.substr(0, 2), 16) / 255;
  const g = parseInt(hex.substr(2, 2), 16) / 255;
  const b = parseInt(hex.substr(4, 2), 16) / 255;

  // Calculate luminance using the relative luminance formula
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

interface ColorGroup {
  name: string;
  colors: Array<{ name: string; value: string }>;
}

const getTailwindColors = () => {
  const fullConfig = resolveConfig(tailwindConfig);
  const colors = fullConfig.theme?.colors || {};

  const colorGroups = Object.entries(colors).reduce((acc, [key, value]) => {
    // Skip utility colors
    if (['primary', 'secondary', 'tertiary', 'link'].includes(key)) {
      return acc;
    }

    if (typeof value === 'object') {
      const groupColors = Object.entries(value).reduce(
        (colors, [shade, colorValue]) => {
          if (typeof colorValue === 'string' && colorValue.startsWith('#')) {
            if (getLuminance(colorValue) < 0.9) {
              colors.push({ name: `${key}-${shade}`, value: colorValue });
            }
          }
          return colors;
        },
        [] as Array<{ name: string; value: string }>
      );

      if (groupColors.length > 0) {
        acc.push({ name: key, colors: groupColors });
      }
    }
    return acc;
  }, [] as Array<ColorGroup>);

  return colorGroups;
};

const TailwindColorPicker = ({ onSelect }: TailwindColorPickerProps) => {
  const [open, setOpen] = useState(false);
  const [colorGroups, setColorGroups] = useState<Array<ColorGroup>>([]);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    const groups = getTailwindColors();
    setColorGroups(groups);
  }, []);

  const handleColorSelect = (color: string) => {
    // strip the # if present
    const colorWithoutHash = color.replace('#', '');
    onSelect(colorWithoutHash);
    // setOpen(false);
  };

  // Get button position for dropdown positioning
  const getDropdownPosition = () => {
    if (!buttonRef.current) return { top: 0, left: 0 };
    const rect = buttonRef.current.getBoundingClientRect();
    return {
      top: rect.bottom + 4,
      left: rect.right - 256, // 256px is the width of the dropdown (w-64)
    };
  };

  return (
    <div>
      <button
        ref={buttonRef}
        className="w-8 h-8 bg-neutral-100 hover:bg-neutral-200 rounded-full
        transition-all duration-300"
        onClick={() => setOpen(!open)}
      >
        <Icon size="sm" icon={faEyeDropper} className="text-neutral-600" />
      </button>
      {open &&
        createPortal(
          <div
            ref={dropdownRef}
            className="fixed z-50 border border-neutral-200 w-64 max-h-72 bg-white rounded-lg shadow-lg overflow-hidden flex flex-col gap-2"
            style={getDropdownPosition()}
          >
            <div className="p-4 h-full overflow-y-auto space-y-4">
              {colorGroups.map((group) => (
                <div key={group.name}>
                  <div className="text-xs font-medium text-neutral-500 mb-2">
                    {group.name}
                  </div>
                  <div className="grid grid-cols-6 gap-2">
                    {group.colors.map((color, index) => (
                      <button
                        key={`${group.name}-${index}`}
                        className="w-full aspect-square rounded-full 
                              hover:ring-2 hover:ring-offset-2 hover:ring-neutral-500"
                        style={{ backgroundColor: color.value }}
                        onClick={(e) => {
                          e.stopPropagation();

                          handleColorSelect(color.value);
                        }}
                        title={color.name}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default TailwindColorPicker;
