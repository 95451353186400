import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import MedBubble, { DataItem } from './MedBubble';

const colorMap = {
  Commercial: '#f97316', //orange-500
  Medicare: '#f43f5e', // rose-500
  Medicaid: '#6366f1', // violet-500
  Other: '#06b6d4', // cyan-500
  'Other Government': '#10b981', // emerald-500
  'Dual Medicare & Medicaid': '#1e40af', // blue-800
};

const defaultWidth = 394;
const defaultHeight = 300;

// Optional bounding box force to keep bubbles in view.
const forceBounds = (width: number, height: number, margin = 20) => {
  let nodes: DataItem[] = [];
  function force() {
    for (const node of nodes) {
      if (node.x != null && node.y != null) {
        node.x = Math.max(margin, Math.min(width - margin, node.x));
        node.y = Math.max(margin, Math.min(height - margin, node.y));
      }
    }
  }
  force.initialize = function (_nodes: DataItem[]) {
    nodes = _nodes;
  };
  return force as d3.Force<DataItem, undefined>;
};

const forceAnchors = (
  anchors: Array<{ x: number; y: number }>,
  strength = 0.2
) => {
  let nodes: DataItem[] = [];
  function force(alpha: number) {
    nodes.forEach((node, i) => {
      if (!anchors[i]) return;
      const ax = anchors[i].x;
      const ay = anchors[i].y;
      if (node.x == null || node.y == null) return;
      node.x += (ax - node.x) * strength * alpha;
      node.y += (ay - node.y) * strength * alpha;
    });
  }
  force.initialize = function (_nodes: DataItem[]) {
    nodes = _nodes;
  };
  return force;
};

const formatPayerName = (name: string): string => {
  return name === 'Other Government' ? "Other Gov't" : name;
};

export interface BubbleChartProps {
  data: DataItem[];
  width?: number;
  height?: number;
  circleProps?: React.SVGProps<SVGCircleElement>;
  textProps?: React.SVGProps<SVGTextElement>;
  diameterRange?: [number, number];
  legend?: boolean;
}

const MedBubbleChart: React.FC<BubbleChartProps> = ({
  data,
  width = defaultWidth,
  height = defaultHeight,
  circleProps,
  textProps,
  diameterRange,
  legend = false,
}) => {
  const [nodes, setNodes] = useState<DataItem[]>([]);
  const centerX = width / 2;
  const centerY = height / 2;
  const offsetX = 60;
  const gap = 40;

  useEffect(() => {
    if (!data || data.length === 0) return;

    // Process and sort the data by value
    const processedData = data
      .filter((d) => d.value > 0)
      .map((d) => {
        const originalName = d.name;
        return {
          ...d,
          name: formatPayerName(d.name),
          color: colorMap[originalName as keyof typeof colorMap] || '#3bbefe',
          value: d.value,
        };
      })
      .sort((a, b) => b.value - a.value);

    // Use the actual container size for calculations
    const actualWidth = width;
    const actualHeight = height;
    const actualCenterX = actualWidth / 2;
    const actualCenterY = actualHeight / 2;

    // Keep the original anchor points but scale the gap slightly with viewport
    const scaledGap = gap * (actualWidth / defaultWidth);
    const scaledOffsetX = offsetX * (actualWidth / defaultWidth);

    const anchors = [
      { x: actualCenterX, y: actualCenterY }, // 1st (largest) - center
      { x: actualCenterX - scaledOffsetX, y: actualCenterY - scaledGap / 2 }, // 2nd - top left of center
      { x: actualCenterX + scaledOffsetX, y: actualCenterY - scaledGap / 2 }, // 3rd - right and up
      { x: actualCenterX - scaledOffsetX, y: actualCenterY + scaledGap / 2 }, // 4th - moved up on bottom left
      { x: actualCenterX + scaledOffsetX, y: actualCenterY }, // 5th - right
      { x: actualCenterX + scaledOffsetX, y: actualCenterY + scaledGap }, // 6th - bottom right
    ];

    const values = processedData.map((d) => d.value);
    const minVal = d3.min(values) ?? 0;
    const maxVal = d3.max(values) ?? 100;

    // Calculate a safe maximum diameter based on container size
    const smallerDimension = Math.min(actualWidth, actualHeight);

    // Special case for a single bubble - make it much larger
    let dynamicDiamRange = diameterRange;
    if (!diameterRange) {
      const count = processedData.length;

      if (count === 1) {
        // For a single bubble, use up to 85% of the smaller dimension
        const singleBubbleSize = smallerDimension * 0.85;
        dynamicDiamRange = [singleBubbleSize, singleBubbleSize];
      } else if (count <= 2) {
        // For 2 bubbles
        const maxSafeDiameter = smallerDimension * 0.75;
        const baseRange: [number, number] = [120, 320];

        if (baseRange[1] > maxSafeDiameter) {
          const scaleFactor = maxSafeDiameter / baseRange[1];
          dynamicDiamRange = [
            Math.max(50, Math.round(baseRange[0] * scaleFactor)),
            Math.round(baseRange[1] * scaleFactor),
          ];
        } else {
          dynamicDiamRange = baseRange;
        }
      } else if (count <= 4) {
        // For 3-4 items
        const maxSafeDiameter = smallerDimension * 0.6;
        const baseRange: [number, number] = [110, 290];

        if (baseRange[1] > maxSafeDiameter) {
          const scaleFactor = maxSafeDiameter / baseRange[1];
          dynamicDiamRange = [
            Math.max(50, Math.round(baseRange[0] * scaleFactor)),
            Math.round(baseRange[1] * scaleFactor),
          ];
        } else {
          dynamicDiamRange = baseRange;
        }
      } else if (count <= 6) {
        // For 5-6 items
        const maxSafeDiameter = smallerDimension * 0.725;
        const baseRange: [number, number] = [100, 260];

        if (baseRange[1] > maxSafeDiameter) {
          const scaleFactor = maxSafeDiameter / baseRange[1];
          dynamicDiamRange = [
            Math.max(50, Math.round(baseRange[0] * scaleFactor)),
            Math.round(baseRange[1] * scaleFactor),
          ];
        } else {
          dynamicDiamRange = baseRange;
        }
      } else {
        // For 7+ items
        const maxSafeDiameter = smallerDimension * 0.725;
        const baseRange: [number, number] = [90, 230];

        if (baseRange[1] > maxSafeDiameter) {
          const scaleFactor = maxSafeDiameter / baseRange[1];
          dynamicDiamRange = [
            Math.max(50, Math.round(baseRange[0] * scaleFactor)),
            Math.round(baseRange[1] * scaleFactor),
          ];
        } else {
          dynamicDiamRange = baseRange;
        }
      }
    }

    const diameterScale = d3
      .scaleSqrt()
      .domain([minVal, maxVal])
      .range(dynamicDiamRange);

    processedData.forEach((d) => {
      d.diameter = diameterScale(d.value);
      d.x = actualCenterX;
      d.y = actualCenterY;
    });

    // Bubble clustering, how they are in relation to each other
    const simulation = d3
      .forceSimulation<DataItem>(processedData)
      .force('charge', d3.forceManyBody<DataItem>().strength(-30)) // repulsion
      .force(
        'collide',
        d3.forceCollide<DataItem>((d) => (d.diameter ?? 60) / 2 + 4) // padding
      )
      .force('anchor', forceAnchors(anchors, 0.3))
      .force('bounds', forceBounds(actualWidth, actualHeight, 20)) // view area
      .stop();

    for (let i = 0; i < 300; i++) {
      simulation.tick();
    }

    setNodes(simulation.nodes());
  }, [data, width, height, centerX, centerY, diameterRange]);

  return (
    <div className="w-full h-full">
      <svg
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMidYMid meet"
        className="w-full h-full"
      >
        {nodes.map((node, index) => (
          <MedBubble
            key={`bubble-${index}-${node.name}`}
            data={node}
            circleProps={circleProps}
            textProps={textProps}
          />
        ))}
      </svg>
    </div>
  );
};

export default MedBubbleChart;
