import React, { useState, useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';

interface TooltipProps {
  content: string | React.ReactNode;
  children: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  sx?: React.CSSProperties;
  slotProps?: {
    containerClassName?: string;
    containerStyle?: React.CSSProperties;
    contentClassName?: string;
    contentStyle?: React.CSSProperties;
    arrowClassName?: string;
    arrowStyle?: React.CSSProperties;
  };
  delay?: number;
  isVisible?: boolean;
  x?: number;
  y?: number;
}

const ARROW_SIZE = 6;

const Tooltip = ({
  content,
  children,
  position = 'top',
  className = '',
  slotProps = {},
  sx = {},
  delay = 0,
  isVisible: externalIsVisible,
  x,
  y,
}: TooltipProps) => {
  const [internalIsVisible, setInternalIsVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Use external visibility if provided, otherwise use internal state
  const isVisible =
    externalIsVisible !== undefined ? externalIsVisible : internalIsVisible;

  // Calculate position of tooltip
  const calculatePosition = useCallback(() => {
    if (x !== undefined && y !== undefined) {
      setTooltipPosition({ top: y, left: x });
      return;
    }

    if (!triggerRef.current) return;

    const rect = triggerRef.current.getBoundingClientRect();
    let top = 0;
    let left = 0;

    switch (position) {
      case 'top':
        left = rect.left + rect.width / 2;
        top = rect.top - 8;
        break;
      case 'bottom':
        left = rect.left + rect.width / 2;
        top = rect.bottom + 8;
        break;
      case 'left':
        left = rect.left - 8;
        top = rect.top + rect.height / 2;
        break;
      case 'right':
        left = rect.right + 8;
        top = rect.top + rect.height / 2;
        break;
    }

    setTooltipPosition({ top, left });
  }, [x, y, position]);

  // Update position when x or y changes
  useEffect(() => {
    if (x !== undefined && y !== undefined) {
      calculatePosition();
    }
  }, [x, y, calculatePosition]);

  const showTooltip = () => {
    if (externalIsVisible !== undefined) return; // Don't show on hover if externally controlled
    calculatePosition();
    timeoutRef.current = setTimeout(() => setInternalIsVisible(true), delay);
  };

  const hideTooltip = () => {
    if (externalIsVisible !== undefined) return; // Don't hide on mouse leave if externally controlled
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setInternalIsVisible(false);
  };

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Get transform and arrow styles based on position
  const getStyles = () => {
    let transform = '';
    let arrowStyle: React.CSSProperties = {
      position: 'absolute',
      width: `${ARROW_SIZE * 2}px`,
      height: `${ARROW_SIZE * 2}px`,
      background: 'inherit',
      transform: 'rotate(45deg)',
      ...(slotProps.arrowStyle || {}),
    };

    switch (position) {
      case 'top':
        transform = 'translate(-50%, -100%)';
        arrowStyle = {
          ...arrowStyle,
          bottom: -ARROW_SIZE,
          left: '50%',
          transform: 'translate(-50%, 0) rotate(45deg)',
        };
        break;
      case 'bottom':
        transform = 'translate(-50%, 0)';
        arrowStyle = {
          ...arrowStyle,
          top: -ARROW_SIZE,
          left: '50%',
          transform: 'translate(-50%, 0) rotate(45deg)',
        };
        break;
      case 'left':
        transform = 'translate(-100%, -50%)';
        arrowStyle = {
          ...arrowStyle,
          right: -ARROW_SIZE,
          top: '50%',
          transform: 'translate(0, -50%) rotate(45deg)',
        };
        break;
      case 'right':
        transform = 'translate(0, -50%)';
        arrowStyle = {
          ...arrowStyle,
          left: -ARROW_SIZE,
          top: '50%',
          transform: 'translate(0, -50%) rotate(45deg)',
        };
        break;
    }

    return { transform, arrowStyle };
  };

  const { transform, arrowStyle } = getStyles();

  return (
    <div
      ref={triggerRef}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onFocus={showTooltip}
      onBlur={hideTooltip}
      className={`inline-block ${className}`}
      data-testid="tooltip"
      style={sx}
    >
      {children}
      {isVisible &&
        typeof document !== 'undefined' &&
        createPortal(
          <div
            role="tooltip"
            className={`
              fixed z-50 px-2 py-1 text-xs bg-gray-800 text-white font-medium rounded-lg
              shadow-lg pointer-events-none w-fit ${
                slotProps.containerClassName || ''
              }
            `}
            style={{
              position: 'fixed',
              top: tooltipPosition.top,
              left: tooltipPosition.left,
              transform,
              opacity: isVisible ? 1 : 0,
              transition: 'opacity 0.15s ease-in-out',
              zIndex: 9999,
              ...(slotProps.containerStyle || {}),
            }}
            data-testid="tooltip-content"
          >
            <div
              className={slotProps.contentClassName || ''}
              style={slotProps.contentStyle || {}}
            >
              {content}
            </div>
            <div
              className={`absolute bg-inherit ${
                slotProps.arrowClassName || ''
              }`}
              style={arrowStyle}
              aria-hidden="true"
            />
          </div>,
          document.body
        )}
    </div>
  );
};

export default Tooltip;
