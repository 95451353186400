'use client';
import React from 'react';
import { useRouter } from 'next/router';
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Input,
  Option,
  Select,
  Tooltip,
} from '@mui/joy';
import { SearchRounded, AddRounded } from '@mui/icons-material';
import { ImpersonateButton } from '../components';
import {
  useDashboard,
  useLogging,
  useNotification,
  useSite,
} from 'src/context';
import { exportUsers } from 'src/hooks';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
interface DashboardToolbarProps {
  search: string;
  showUserControls?: boolean;
  showCompanyControls?: boolean;
  isMedScoutUser?: boolean;
  supportUser?: string;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 *  @description    DashboardToolbar component is used to display the toolbar in the admin dashboard
 * @param param0    search, showUserControls, showCompanyControls, onSearch, setShowDialog
 * @returns         DashboardToolbar component
 */
const DashboardToolbar = ({
  search,
  showUserControls = false,
  showCompanyControls = false,
  isMedScoutUser = false,
  supportUser,
  onSearch,
}: DashboardToolbarProps) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { state } = useSite();
  const { currentCompany } = state.companyAdmin;

  const {
    userQueryParams,
    setUserQueryParams,
    setShowAddEditUserModal,
    setShowAddEditCompanyModal,
  } = useDashboard();

  const [isExporting, setIsExporting] = React.useState(false);
  const [activeOnly, setActiveOnly] = React.useState(
    userQueryParams.active_only || 1
  );

  const handleOpenDialog = () => {
    showCompanyControls &&
      setShowAddEditCompanyModal({ show: true, company: null });
    showUserControls &&
      setShowAddEditUserModal({
        show: true,
        company: currentCompany?.id,
        user: null,
      });
  };

  const handleSetActiveOnly = (e: any, newValue: number) => {
    setActiveOnly(newValue);
    setUserQueryParams({ ...userQueryParams, active_only: newValue });
  };

  const handleExport = async () => {
    setIsExporting(true);

    try {
      if (userQueryParams.managers) {
        delete userQueryParams.managers;
      }
      const searchParams = getSearchQueryParamsForAPI(userQueryParams);
      const filename = await exportUsers(currentCompany?.id, searchParams);

      log.event('Export Users', {
        source: 'DashboardToolbar',
        company: currentCompany?.id,
        filename,
      });
      setNotification({
        title: 'Export Users',
        message: 'Users exported successfully',
        type: 'success',
      });
    } catch (err) {
      log.exception('Export Users', {
        tags: {
          source: 'DashboardToolbar',
          company: currentCompany?.id,
        },
      });

      setNotification({
        title: 'Export Users',
        message: err?.error_message || 'Failed to export users',
        type: 'error',
      });
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          width: '100%',
        }}
      >
        {showUserControls && (
          <Select
            variant="outlined"
            size="md"
            value={activeOnly}
            onChange={handleSetActiveOnly}
            sx={{ flex: 0.125 }}
            aria-label="Active Users"
          >
            <Option value={0}>All Users</Option>
            <Option value={1}>Active Users</Option>
          </Select>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '0.5rem',
          padding: '0.5rem 0',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            width: '100%',
          }}
        >
          <Box sx={{ flex: 0.33, display: 'flex', gap: '0.5rem' }}>
            <Input
              type="search"
              variant="outlined"
              size="md"
              startDecorator={<SearchRounded />}
              placeholder="Search"
              value={search}
              onChange={onSearch}
              fullWidth
            />
          </Box>
          {showUserControls && (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <IconButton
                size="md"
                variant="soft"
                color="primary"
                onClick={handleOpenDialog}
              >
                <AddRounded fontSize="small" />
              </IconButton>
              <Button
                loading={isExporting}
                variant="soft"
                color="neutral"
                size="md"
                onClick={handleExport}
              >
                Export
              </Button>
            </Box>
          )}
          {showCompanyControls && (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                size="md"
                variant="soft"
                color="primary"
                onClick={handleOpenDialog}
                startDecorator={<AddRounded />}
              >
                Add Company
              </Button>
            </Box>
          )}
        </Box>

        {showUserControls && isMedScoutUser && (
          <Tooltip title="Impersonate Super User" size="sm" arrow>
            <span>
              <ImpersonateButton userId={supportUser} />
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default DashboardToolbar;
