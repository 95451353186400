import React from 'react';
import { useGetObjectTypeAttributes } from 'src/hooks';
import { useIntegrations } from 'src/components/Settings/Integrations/context';

const AttributeCodeCell = ({ row }) => {
  const { currentMapping } = useIntegrations();

  const { data: objectTypeAttributes } = useGetObjectTypeAttributes({
    type: currentMapping?.medscout_object_type,
    enabled: !!currentMapping?.medscout_object_type,
  });

  const option = objectTypeAttributes?.find(
    (item) => item.code === row.attribute_code
  );

  return <span className="text-neutral-900">{option?.label}</span>;
};

export default AttributeCodeCell;
