import React, { useState } from 'react';

interface SwitchProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'soft' | 'solid';
}

const sizes = {
  sm: 'w-8 h-4',
  md: 'w-10 h-5',
  lg: 'w-12 h-6',
};

const thumbSizes = {
  sm: 'w-2 h-2 left-1 top-1 peer-checked:left-5',
  md: 'w-3 h-3 left-1 top-1 peer-checked:left-7',
  lg: 'w-4 h-4 left-1 top-1 peer-checked:left-11',
};

const checkedThumbPositions = {
  sm: 'translate-x-4',
  md: 'translate-x-5',
  lg: 'translate-x-6',
};

const variants = {
  primary: 'bg-blue-400 peer-checked:bg-blue-700',
  soft: 'bg-gray-200 peer-checked:bg-gray-400',
  solid: 'bg-gray-400 peer-checked:bg-gray-700',
};

const Switch: React.FC<SwitchProps> = ({
  size = 'md',
  variant = 'soft',
  checked,
  onChange,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const isSelected = checked ?? isChecked;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    onChange?.(event);
  };

  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={isSelected}
        onChange={handleChange}
        {...props}
      />
      <div
        className={`relative flex items-center rounded-full transition-all duration-300 ease-in-out ${sizes[size]} ${variants[variant]}`}
      >
        <span
          className={`absolute bg-white rounded-full shadow-md transition-all duration-300 
            ${thumbSizes[size]} ${
            isSelected ? checkedThumbPositions[size] : ''
          }`}
        />
      </div>
    </label>
  );
};

export default Switch;
