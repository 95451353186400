import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CircularProgress } from 'src/components';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** The variant of the button */
  variant?: 'solid' | 'outlined' | 'plain' | 'soft';
  /** The color scheme of the button */
  color?: 'primary' | 'neutral' | 'danger' | 'success' | 'warning';
  /** The size of the button */
  size?: 'sm' | 'md' | 'lg';
  /** Whether the button is in a loading state */
  loading?: boolean;
  /** The icon to display before the button text */
  startIcon?: IconProp;
  /** The icon to display after the button text */
  endIcon?: IconProp;
  /** Decorative element to display before the button text */
  startDecorator?: React.ReactNode;
  /** Decorative element to display after the button text */
  endDecorator?: React.ReactNode;
  /** Whether the button is full width */
  fullWidth?: boolean;
  /** Whether the button is circular */
  circular?: boolean;
  /** Whether the button has a loading spinner */
  loadingPosition?: 'start' | 'end' | 'center';
  /** The children of the button */
  children?: React.ReactNode;
  /** Additional className for the button */
  className?: string;
  /** SX props for the button */
  sx?: React.CSSProperties;
  /** The onClick handler for the button */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /** The onKeyDown handler for the button */
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'solid',
      color = 'neutral',
      size = 'md',
      loading = false,
      startIcon,
      endIcon,
      startDecorator,
      endDecorator,
      fullWidth = false,
      circular = false,
      disabled,
      className = '',
      children,
      sx = {},
      onClick,
      onKeyDown,
      ...props
    },
    ref
  ) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        onClick?.(event as unknown as React.MouseEvent<HTMLButtonElement>);
      }
      onKeyDown?.(event);
    };

    const baseStyles =
      'inline-flex items-center justify-center font-semibold transition-all duration-200';

    const sizeStyles = {
      sm: circular ? 'w-6 h-6' : 'px-3 py-1 text-xs',
      md: circular ? 'w-8 h-8' : 'px-4 py-2 text-sm',
      lg: circular ? 'w-10 h-10' : 'px-6 py-3 text-base',
    };

    const variantStyles = {
      solid: {
        primary: 'bg-sky-600 text-white hover:bg-sky-700 border border-sky-200',
        neutral:
          'bg-neutral-100 text-neutral-700 hover:bg-neutral-200 border border-neutral-200',
        danger: 'bg-red-500 text-white hover:bg-red-600 border border-red-200',
        success:
          'bg-green-500 text-white hover:bg-green-600 border border-green-200',
        warning:
          'bg-yellow-500 text-white hover:bg-yellow-600 border border-yellow-200',
      },
      outlined: {
        primary: 'border border-sky-600 text-sky-600 hover:bg-sky-50',
        neutral:
          'border border-neutral-300 text-neutral-700 hover:bg-neutral-50',
        danger: 'border border-red-500 text-red-500 hover:bg-red-50',
        success: 'border border-green-500 text-green-500 hover:bg-green-50',
        warning: 'border border-yellow-500 text-yellow-500 hover:bg-yellow-50',
      },
      plain: {
        primary: 'text-sky-600 hover:bg-sky-50',
        neutral: 'text-neutral-700 hover:bg-neutral-50',
        danger: 'text-red-500 hover:bg-red-50',
        success: 'text-green-500 hover:bg-green-50',
        warning: 'text-yellow-500 hover:bg-yellow-50',
      },
      soft: {
        primary: 'bg-sky-50 text-sky-600 hover:bg-sky-100',
        neutral: 'bg-neutral-50 text-neutral-700 hover:bg-neutral-100',
        danger: 'bg-red-50 text-red-500 hover:bg-red-100',
        success: 'bg-green-50 text-green-500 hover:bg-green-100',
        warning: 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100',
      },
    };

    const disabledStyles =
      'disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none';
    const roundedStyles = circular ? 'rounded-full' : 'rounded-md';
    const widthStyles = fullWidth ? 'w-full' : '';

    return (
      <button
        ref={ref}
        disabled={disabled || loading}
        className={`
          ${baseStyles}
          ${sizeStyles[size]}
          ${variantStyles[variant][color]}
          ${disabledStyles}
          ${roundedStyles}
          ${widthStyles}
          ${className}
          relative
        `}
        style={sx}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        {...props}
      >
        <span
          className={`inline-flex items-center justify-center gap-2 ${
            loading ? 'invisible' : 'visible'
          }`}
        >
          {!loading && startDecorator}
          {!loading && startIcon && (
            <Icon
              icon={startIcon}
              className={
                size === 'sm'
                  ? 'text-xs'
                  : size === 'lg'
                  ? 'text-lg'
                  : 'text-sm'
              }
            />
          )}
          {children}
          {!loading && endIcon && (
            <Icon
              icon={endIcon}
              className={
                size === 'sm'
                  ? 'text-xs'
                  : size === 'lg'
                  ? 'text-lg'
                  : 'text-sm'
              }
            />
          )}
          {!loading && endDecorator}
        </span>
        {loading && (
          <span
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            data-testid="loading-spinner"
          >
            <CircularProgress size="sm" color="primary" />
          </span>
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
