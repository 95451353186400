import React from 'react';
import { NewBreadcrumbs } from 'src/components';
import { useSite, useAuth } from 'src/context';
import { useInfiniteCompanyUsers } from 'src/hooks';

type BreadcrumbItem = {
  id: string;
  label: string;
  items?: BreadcrumbItem[];
  isLast?: boolean;
  onScroll?: (e: React.UIEvent<HTMLElement>) => void;
  onClick?: () => void;
};

const UsersBreadcrumbs = () => {
  const { user } = useAuth();
  const { state, dispatch } = useSite();
  const { currentCompany, currentUser } = state.companyAdmin;

  const { data, size, setSize, showLoading, isReachingEnd } =
    useInfiniteCompanyUsers(currentCompany?.id, currentUser?.id);

  const users = data ? data.flatMap((page) => page?.results) : [];

  const isMedScoutUser = user?.is_superuser;
  const isSupportUser = user?.permissions?.is_support_user;

  // First, build the breadcrumbs array
  const breadCrumbsToAdd: BreadcrumbItem[] = [];

  // Add company breadcrumb if needed
  if (currentCompany) {
    breadCrumbsToAdd.push({
      id: 'company',
      label: currentCompany.name,
      onClick: () => {
        dispatch({ type: 'SET_CURRENT_USER', payload: null });
      },
    });
  }

  // Add user breadcrumb if needed
  if (currentUser) {
    breadCrumbsToAdd.push({
      id: 'user',
      label: `${currentUser.first_name} ${currentUser.last_name}`,
      items: users?.map((user) => ({
        id: user.id.toString(),
        label: user.first_name + ' ' + user.last_name,
        isSelected: currentUser.id === user.id,
        onClick: () => dispatch({ type: 'SET_CURRENT_USER', payload: user }),
      })),
      onScroll: (e) => {
        const target = e.target as HTMLElement;
        if (
          !isReachingEnd &&
          !showLoading &&
          target.scrollTop + target.clientHeight >= target.scrollHeight
        ) {
          setSize(size + 1);
        }
      },
      onClick: () => null, // No action when clicking the current user
    });
  }

  // Then create the final breadcrumb arrays
  const superUserBreadcrumbItems: BreadcrumbItem[] = [
    {
      id: 'home',
      label: 'Companies',
      onClick: () => {
        dispatch({ type: 'SET_CURRENT_COMPANY', payload: null });
        dispatch({ type: 'SET_CURRENT_USER', payload: null });
      },
    },
    ...breadCrumbsToAdd,
  ];

  const breadcrumbItems: BreadcrumbItem[] = [
    // {
    //   id: 'home',
    //   label: 'Users',
    //   onClick: () => {
    //     dispatch({ type: 'SET_CURRENT_USER', payload: null });
    //   },
    // },
    ...breadCrumbsToAdd,
  ];

  return (
    <NewBreadcrumbs
      items={
        isMedScoutUser && !isSupportUser
          ? superUserBreadcrumbItems
          : breadcrumbItems
      }
      isLoading={showLoading}
    />
  );
};

export default UsersBreadcrumbs;
