import React, { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import {
  useAuth,
  useLogging,
  useMedScoutMap,
  useNotification,
  useLayoutControl,
} from 'src/context';
import {
  useGetAllMedTerritories,
  useGetAllUsers,
  createTerritory,
  editTerritory,
  deleteTerritory,
  useAuthorization,
  useDirectReports,
} from 'src/hooks';
import { DeleteRounded } from '@mui/icons-material';
import {
  ControlledJoyInput,
  ControlledJoyCheckbox,
  BLADE_NAMES,
} from 'src/components';
import RadiusPanel from '../RadiusPanel';
import DrawActionButtons from '../DrawActionButtons';
import { HeatmapButton } from '../HeatmapButton';
import { useFlags } from 'launchdarkly-react-client-sdk';

// TODO: This component is such a mess, will refactor when we move to a drawer.

const AddEditTerritory = () => {
  const { user, setUser } = useAuth();
  const auth = useAuthorization();
  const isBoundToAssigned = auth?.isBoundedToTerritory();
  const { checkReport } = useDirectReports();
  const { newPermissionSystem } = useFlags();

  const log = useLogging();
  const { setNotification } = useNotification();
  const { setToggleDrawer, toggleDrawer } = useLayoutControl();
  const {
    currentOverlay,
    currentTerritory,
    drawingMode,
    isCreating,
    isEditing,
    territoryPolygons,
    adhocTerritory,
    setClickedFeatures,
    setCurrentTerritory,
    setDrawingMode,
    setIsCreating,
    setIsEditing,
    setTerritoryPolygons,
    setAdhocTerritory,
    setRadius,
    currentCircleRef,
    drawingManagerRef,
    setHeatMap,
  } = useMedScoutMap();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const canUserDelete = useMemo(() => {
    // Handle legacy permission system
    if (!newPermissionSystem) {
      return !!currentTerritory;
    }

    // Handle case when no territory is selected
    if (!currentTerritory) {
      return false;
    }

    // Determine if the territory is canonical if the user is bound to assigned territories
    const isCanonical = isBoundToAssigned && currentTerritory.is_canonical;

    // Check if user owns the territory
    const isOwnTerritory =
      currentTerritory.owner.id === user.id && !isCanonical;

    // Determine permission scope
    const scope = isOwnTerritory
      ? 'own'
      : checkReport(currentTerritory.owner.id)
      ? 'direct_report'
      : 'company';

    return auth?.canDelete('territory', scope);
  }, [
    newPermissionSystem,
    currentTerritory,
    isBoundToAssigned,
    user.id,
    checkReport,
    auth,
  ]);

  const values = {
    ...currentTerritory,
    user_id: currentTerritory?.owner?.id || user?.id,
    is_cononical: currentTerritory?.is_canonical || false,
  };

  const showRepList =
    toggleDrawer?.drawer === BLADE_NAMES.REP_LIST && toggleDrawer?.open;
  const showTerritory =
    !!adhocTerritory &&
    toggleDrawer?.drawer === BLADE_NAMES.TERRITORY_ANALTYICS &&
    toggleDrawer?.open;

  const methods = useForm({
    values: isEditing
      ? values
      : {
          user: user?.id,
          is_canonical: false,
        },
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, isDirty },
  } = methods;

  const { data: allUsersData } = useGetAllUsers(user?.company?.id?.toString());
  const { mutate: mutateAll } = useGetAllMedTerritories();

  const repValuesAndLabels = useMemo(() => {
    if (!allUsersData) return [];

    return allUsersData
      .map((user) => {
        return {
          value: user.id,
          label: `${user.first_name} ${user.last_name}`,
        };
      })
      .filter(
        (rep, index, self) =>
          index === self.findIndex((t) => t.value === rep.value)
      )
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [allUsersData]);

  useEffect(() => {
    if ((!isEditing && !isCreating) || drawingMode === 'RADIUS') {
      setAdhocTerritory(null);
      return;
    }

    const isValidTerritory = territoryPolygons[drawingMode]?.length > 0;

    if (isValidTerritory) {
      const data = isEditing
        ? {
            name: currentTerritory?.name,
            user: currentTerritory?.owner?.id,
            is_canonical: currentTerritory?.is_canonical,
          }
        : {
            name: 'Adhoc Territory',
            user: 'test_user_id',
            is_canonical: false,
          };

      if (drawingMode === 'DRAW') {
        const head = territoryPolygons[drawingMode]?.[0];
        const tail =
          territoryPolygons[drawingMode][
            territoryPolygons[drawingMode]?.length - 1
          ];

        if (!head || !tail || !head?.[0] || !tail?.[0]) return;

        if (head[0] !== tail[0] || head[1] !== tail[1]) {
          territoryPolygons[drawingMode].push(head);
        }

        const formatedPoints = [
          territoryPolygons[drawingMode].map((point) => {
            return { lat: Number(point[1]), lng: Number(point[0]) };
          }),
        ];

        data['points'] = formatedPoints;

        if (data['points'].length < 4) {
          setAdhocTerritory(null);
          return;
        }

        setAdhocTerritory({
          ...data,
        });
      } else {
        data['boundary_ids'] = territoryPolygons[drawingMode];

        if (!data['boundary_ids']) return;

        setAdhocTerritory(data);
      }
    } else {
      setAdhocTerritory(null);
    }

    return () => {
      setAdhocTerritory(null);
    };
  }, [isEditing, isCreating, drawingMode, territoryPolygons]);

  const handleDelete = async () => {
    if (!confirm('Are you sure you want to delete this territory?')) return;
    setIsDeleting(true);
    try {
      await deleteTerritory(currentTerritory.id);
      log.event('territoryDeleteSubmitted');
    } catch (err) {
      log.exception('Error deleting territory', {
        tags: {
          source: 'AddEditTerritory',
          error: err,
        },
      });

      setNotification({
        title: 'Error',
        message: err?.error_message || 'Error deleting territory',
        type: 'error',
      });
    } finally {
      setCurrentTerritory(null);
      handleCancel();
      setUser({
        ...user,
        last_territory: null,
      });
      mutateAll();
      setIsDeleting(false);
    }
  };

  const clearMapOverlays = () => {
    if (drawingMode === 'DRAW') {
      currentOverlay?.setMap(null);
    }
    if (currentCircleRef.current) {
      currentCircleRef.current.setMap(null);
      currentCircleRef.current = null;
    }
  };

  const resetPolygonsAndRadius = () => {
    setTerritoryPolygons({
      STATE: [],
      COUNTY: [],
      ZIPCODE: [],
      DRAW: [],
      RADIUS: [],
    });
    setRadius(80467);
    setAdhocTerritory(null);
  };

  const handleCancel = () => {
    setIsCreating(false);
    setIsEditing(false);
    setHeatMap(false);

    resetPolygonsAndRadius();
    clearMapOverlays();

    drawingManagerRef.current?.setMap(null);
    setDrawingMode('STATE');

    if (toggleDrawer?.drawer !== BLADE_NAMES.RESULTS) {
      setToggleDrawer(BLADE_NAMES.RESULTS, true);
    }
    reset();
  };

  const handleClear = () => {
    resetPolygonsAndRadius();
    clearMapOverlays();
    setClickedFeatures([]);
  };

  const onSubmit = async (data: MedScout.TerritorySubmission) => {
    if (!data) return;

    try {
      setIsLoading(true);
      const { geometry, source, polygons, ...territoryData } = data;

      // Process territory data based on drawing mode
      if (drawingMode === 'DRAW') {
        const polygonPoints = territoryPolygons[drawingMode];

        if (!polygonPoints?.length || !polygonPoints[0]?.[0]) {
          throw new Error('There are no points to create the territory');
        }

        // Close the polygon if needed
        const head = polygonPoints[0];
        const tail = polygonPoints[polygonPoints.length - 1];

        if (head[0] !== tail[0] || head[1] !== tail[1]) {
          polygonPoints.push(head);
        }

        territoryData['points'] = [
          polygonPoints.map((point) => ({
            lat: Number(point[1]),
            lng: Number(point[0]),
          })),
        ];
      } else if (drawingMode === 'RADIUS') {
        const circleData = territoryPolygons[drawingMode];

        if (!circleData?.center) {
          throw new Error(
            'There are no points to create the territory, please create radius'
          );
        }

        territoryData['circles'] = [
          {
            center: circleData.center,
            radius: circleData.radius,
          },
        ];

        // Process points for the circle
        const head = circleData.points?.[0];
        const tail = circleData.points?.[circleData.points?.length - 1];

        if (!head || !tail) {
          throw new Error(
            'There are no points to create the territory, please create radius'
          );
        }

        if (head.lat !== tail.lat || head.lng !== tail.lng) {
          circleData.points.push(head);
        }

        territoryData['points'] = [circleData.points];
      } else {
        // For other drawing modes, use geometry
        territoryData['geometry'] = territoryPolygons[drawingMode];
      }

      // Create or update territory
      const territory = isEditing
        ? await editTerritory(currentTerritory.id, territoryData)
        : await createTerritory(territoryData);

      setCurrentTerritory(territory);
      setUser({
        ...user,
        last_territory: territory,
      });

      log.event(`Territory ${isEditing ? 'Updated' : 'Created'}`, {
        source: 'AddEditTerritory',
        drawingMode,
        territory,
      });

      setNotification({
        title: 'Success',
        message: `Territory ${isEditing ? 'updated' : 'created'} successfully`,
        type: 'success',
      });
    } catch (err) {
      log.exception(`Error ${isEditing ? 'updating' : 'creating'} Territory`, {
        tags: {
          source: 'AddEditTerritory',
          error: err,
        },
      });

      setNotification({
        title: 'Error',
        message:
          err?.error_message ||
          `Error ${isEditing ? 'updating' : 'creating'} Territory`,
        type: 'error',
      });
    } finally {
      handleCancel();
      mutateAll();
      setIsLoading(false);
    }
  };

  const isManager = user?.permissions?.is_manager || user?.is_superuser;
  const isDisabled =
    !isEditing &&
    (!isValid || !isDirty || territoryPolygons[drawingMode]?.length === 0);

  return (
    <FormProvider {...methods}>
      <Card className="max-w-96">
        <CardContent>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-0">
              <span className="text-lg font-bold text-neutral-600">
                {isEditing ? 'Edit' : 'Add'} Territory
              </span>
              <span className="text-xs font-bold text-neutral-500">
                Click on the map to draw your territory.
              </span>
            </div>
            {isBoundToAssigned && (
              // TODO: I really hate -mt-1, but it's the only way to get the text to
              // align properly until this component is refactored for tailwind
              <span className="text-xs text-neutral-500 -mt-1 font-medium">
                You are bound to an assigned territory. Any territories you
                create or edit will be constrained to these bounds.
              </span>
            )}
            <div className="flex items-center justify-center">
              <DrawActionButtons />
            </div>
            <div className="flex items-center justify-end">
              <HeatmapButton />
            </div>
          </div>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="body-xs" sx={{ fontWeight: 600 }}>
              Territory Name
            </Typography>
            <Button
              id={`discovery-territory-show-existing-territories-button`}
              size="sm"
              variant="plain"
              onClick={() => setToggleDrawer(BLADE_NAMES.REP_LIST)}
              sx={{ fontSize: '0.75rem' }}
            >
              {showRepList ? 'Hide' : 'Show'} Existing Territories
            </Button>
          </Box>
          <ControlledJoyInput
            name="name"
            placeholder="Territory Name"
            required
          />
          {drawingMode === 'RADIUS' && <RadiusPanel />}
          {isManager && (
            <>
              <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                Assigned Rep
              </Typography>
              <Controller
                name={isEditing ? 'user_id' : 'user'}
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      size="sm"
                      aria-label="Assigned Rep Select"
                      value={field.value}
                      onChange={(e, newValue) => {
                        field.onChange(newValue);
                      }}
                    >
                      <Option
                        value={user?.id}
                        id={`discovery-territory-assigned-rep-${user?.id}`}
                      >
                        Assigned to Me ({user?.first_name} {user?.last_name})
                      </Option>
                      {repValuesAndLabels.map((rep) => {
                        if (rep.value === user?.id) return null;

                        return (
                          <Option
                            key={rep.value}
                            value={rep.value}
                            id={`discovery-territory-assigned-rep-${rep.value}`}
                          >
                            {rep.label}
                          </Option>
                        );
                      })}
                    </Select>
                  );
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0.5rem 0',
                  gap: '0.5rem',
                }}
              >
                <ControlledJoyCheckbox size="sm" name="is_canonical" />
                <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                  Assigned Territory (Manager Editable Only)
                </Typography>
              </Box>
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              id={`discovery-territory-show-territory-analytics-button`}
              size="sm"
              variant="plain"
              onClick={() => setToggleDrawer(BLADE_NAMES.TERRITORY_ANALTYICS)}
              sx={{ fontSize: '0.75rem' }}
              disabled={!adhocTerritory}
            >
              {showTerritory ? 'Hide' : 'Show'} Territory Analytics
            </Button>
          </Box>

          <CardActions sx={{ justifyContent: 'space-between' }}>
            <Button
              id={`discovery-add-edit-territory-button-delete`}
              size="sm"
              variant="outlined"
              color="danger"
              onClick={isEditing ? handleDelete : handleClear}
              loading={isDeleting}
              disabled={isEditing && !canUserDelete}
            >
              {isEditing ? <DeleteRounded fontSize="small" /> : 'Clear'}
            </Button>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                id={`discovery-add-edit-territory-button-cancel`}
                size="sm"
                variant="outlined"
                color="neutral"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                id={`discovery-add-edit-territory-button-${
                  isEditing ? 'update' : 'create'
                }`}
                size="sm"
                variant="solid"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                disabled={isDisabled}
                loading={isLoading}
              >
                {isEditing ? 'Update' : 'Create'} Territory
              </Button>
            </Box>
          </CardActions>
        </CardContent>
      </Card>
    </FormProvider>
  );
};

export default AddEditTerritory;
