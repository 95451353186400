import {
  CheckCircleOutlineRounded,
  DoNotDisturbAltRounded,
  HorizontalRuleOutlined,
  LoopOutlined,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/joy';
import React from 'react';
import { titleCase } from 'src/utils';

const StatusCell = ({ row }) => {
  const cleanStatus = () => {
    if (row.status === 'NOT_STARTED') {
      return 'Initialized';
    } else {
      return titleCase(row.status.replace(/_/g, ' '));
    }
  };

  const formatIcon = () => {
    switch (row.status) {
      case 'NOT_STARTED':
        return (
          <HorizontalRuleOutlined
            color="primary"
            style={{ fontSize: '1.25rem' }}
          />
        );
      case 'IN_PROGRESS':
        return (
          <LoopOutlined color="secondary" style={{ fontSize: '1.25rem' }} />
        );
      case 'COMPLETED':
        return (
          <CheckCircleOutlineRounded
            color="success"
            style={{ fontSize: '1.25rem' }}
          />
        );
      case 'FAILED':
        return (
          <DoNotDisturbAltRounded
            color="warning"
            style={{ fontSize: '1.25rem' }}
          />
        );
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxHeight: 75,
        overflowY: 'auto',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{ fontSize: '0.8rem', paddingRight: '0.5rem', width: '5rem' }}
      >
        {cleanStatus()}
      </Typography>
      {formatIcon()}
    </Box>
  );
};

export default StatusCell;
