import { grey } from '@mui/material/colors';
import { List, ListItem, styled, Typography } from '@mui/joy';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box } from 'src/components/PulseUI';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

const RowLabel = styled(Typography)(() => ({
  color: grey[800],
  fontWeight: 400,
  fontSize: '0.75rem',
  fontStyle: 'italic',
}));

const ListItemRow = styled(ListItem)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '0.5rem',
  width: '100%',
  padding: '0',
  minHeight: 'fit-content',
}));

const EducationItem = ({ item, isLast }) => {
  const { newPayerMix } = useFlags();

  return newPayerMix ? (
    <Box className="w-full flex flex-col gap-1">
      <Typography level="body-sm" className="text-tertiary font-normal">
        {item.type}
      </Typography>
      <Typography level="body-sm" className="text-primary font-medium">
        {item.institution}
      </Typography>
      <Box className="flex flex-row gap-2 items-center">
        <Typography level="body-sm" className="text-tertiary font-normal">
          {item.graduation_year}
        </Typography>
        {item.focus && (
          <>
            <Icon
              icon={faCircle}
              className="text-neutral-300 h-[0.375rem] w-[0.375rem]"
            />
          </>
        )}
        <Typography level="body-sm" className="text-tertiary font-normal">
          {item.focus}
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        width: '100%',
        borderBottom: isLast ? 'none' : `1px solid ${grey[200]}`,
        marginBottom: 2,
        paddingBottom: 1,
        paddingLeft: 2,
        paddingRight: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: '0.9rem',
          fontWeight: 500,
          borderBottom: `1px solid ${grey[200]}`,
        }}
      >
        {item.type}
      </Typography>
      <List>
        <ListItemRow>
          <RowLabel>Institution:</RowLabel>
          <Typography level="body-xs" textAlign="right">
            {item.institution || 'n/a'}
          </Typography>
        </ListItemRow>
        <ListItemRow>
          <RowLabel>Focus:</RowLabel>
          <Typography level="body-xs" textAlign="right">
            {item.focus || 'n/a'}
          </Typography>
        </ListItemRow>
        <ListItemRow>
          <RowLabel>Year:</RowLabel>
          <Typography level="body-xs" textAlign="right">
            {item.graduation_year || 'n/a'}
          </Typography>
        </ListItemRow>
      </List>
    </Box>
  );
};

export default EducationItem;
