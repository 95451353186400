import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

interface TableVirtualizationProps {
  ref: React.RefObject<HTMLDivElement>;
  height: number;
  buffer: number;
  count: number;
}

const useTableVirtualization = ({
  ref,
  height,
  buffer = 10,
  count,
}: TableVirtualizationProps) => {
  const [visibleStart, setVisibleStart] = useState(0);
  const [visibleEnd, setVisibleEnd] = useState(0);

  const handleScroll = useCallback(() => {
    if (!ref.current) return;

    const scrollTop = ref.current.scrollTop;
    const visibleHeight = ref.current.clientHeight;

    // Efficiently compute visible rows without excessive re-renders
    const startIdx = Math.floor(scrollTop / height);
    const endIdx = Math.ceil((scrollTop + visibleHeight) / height);

    setVisibleStart(Math.max(0, startIdx - buffer));
    setVisibleEnd(Math.min(count, endIdx + buffer));
  }, [height, count, buffer, ref]);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      element.addEventListener('scroll', handleScroll, { passive: true }); // Use passive event listener for performance
      handleScroll(); // Initialize visible range on mount
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, ref]);

  // Optimize placeholder row calculation
  const { topPlaceholderRows, bottomPlaceholderRows } = useMemo(() => {
    return {
      topPlaceholderRows: new Array(visibleStart).fill(null),
      bottomPlaceholderRows: new Array(Math.max(0, count - visibleEnd)).fill(
        null
      ),
    };
  }, [visibleStart, visibleEnd, count]);

  return {
    visibleStart,
    visibleEnd,
    topPlaceholderRows,
    bottomPlaceholderRows,
  };
};

export default useTableVirtualization;
