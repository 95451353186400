import React from 'react';
import { TableRow } from '@mui/material';

interface ActivityRowPlaceholderProps {
  sx?: React.CSSProperties;
  rowHeight?: number;
}

const ActivityRowPlaceholder = ({
  sx,
  rowHeight = 58,
}: ActivityRowPlaceholderProps) => {
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: `${rowHeight}px`,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <TableRow className="grid grid-cols-[1rem_15rem_15rem_15rem_15rem_15rem_auto] items-center h-14 bg-white hover:bg-gray-50 transition-opacity duration-300 ease-in-out w-full">
        <div className="w-4 h-full bg-gray-100 animate-pulse"></div>
        <div className="w-60 h-full bg-gray-100 animate-pulse flex items-center p-2 gap-2">
          <div className="w-6 h-6 rounded-full bg-gray-200 animate-pulse" />
          <div className="w-3/4 h-full bg-gray-200 animate-pulse rounded-lg" />
        </div>
        <div className="w-60 h-full bg-gray-100 animate-pulse flex items-center p-2">
          <div className="w-full h-full bg-gray-200 animate-pulse rounded-lg" />
        </div>
        <div className="w-60 h-full bg-gray-100 animate-pulse flex items-center p-2">
          <div className="w-full h-full bg-gray-200 animate-pulse rounded-lg" />
        </div>
        <div className="w-60 h-full bg-gray-100 animate-pulse flex items-center p-2">
          <div className="w-full h-full bg-gray-200 animate-pulse rounded-lg" />
        </div>
        <div className="w-60 h-full bg-gray-100 animate-pulse flex items-center p-2">
          <div className="w-full h-full bg-gray-200 animate-pulse rounded-lg" />
        </div>
        <div className="flex-grow w-full h-full bg-gray-100 animate-pulse flex items-center p-2">
          <div className="w-full h-full bg-gray-200 animate-pulse rounded-lg" />
        </div>
      </TableRow>
    </div>
  );
};

export default ActivityRowPlaceholder;
