'use client';

import React from 'react';
import { useAccordionItem } from '../../context/AccordionProvider';

interface AccordionItemProps {
  children: React.ReactNode;
  index: number;
  className?: string;
  sx?: React.CSSProperties;
}

interface ChildProps {
  isOpen?: boolean;
  onToggle?: () => void;
  index?: number;
}

const AccordionItem = ({
  children,
  index,
  className,
  sx,
}: AccordionItemProps) => {
  const { isOpen, onToggle } = useAccordionItem(index);

  return (
    <div
      role="region"
      aria-labelledby={`accordion-item-${index}`}
      className={`border border-gray-400 dark:border-gray-600 
                    rounded-md mb-2 overflow-hidden ${className}`}
      style={sx}
    >
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return null;

        return React.cloneElement(child as React.ReactElement<ChildProps>, {
          isOpen,
          onToggle,
          index,
        });
      })}
    </div>
  );
};

export default AccordionItem;
