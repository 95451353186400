import React, { useEffect, useState } from 'react';
import { useGetSecurityPermissions } from 'src/hooks';
import { useSite, useNotification, useLogging } from 'src/context';
import {
  Card,
  CardContent,
  Button,
  CircularProgress,
  Checkbox,
} from 'src/components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faShieldHalved } from '@fortawesome/pro-regular-svg-icons';
import { updateSecurityPermissions } from 'src/api';

const EmptyState = () => (
  <div className="flex flex-col items-center justify-center py-8 px-4 bg-neutral-50 rounded-lg text-center">
    <div className="flex flex-col items-center gap-3">
      <Icon icon={faShieldHalved} className="text-4xl text-neutral-400" />
      <div className="flex flex-col gap-1">
        <h4 className="text-sm font-medium text-neutral-900">
          No Territory Permissions
        </h4>
        <p className="text-sm text-neutral-500">
          This user doesn&apos;t have any security permissions to display.
        </p>
      </div>
    </div>
  </div>
);

const TerritoryPermissions = () => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { state } = useSite();
  const { currentCompany, currentUser } = state.companyAdmin;
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState<
    Record<string, { has_permission: boolean }>
  >({});

  const {
    data,
    error,
    isLoading: isLoadingData,
    mutate,
  } = useGetSecurityPermissions({
    companyId: currentCompany.id,
    userId: currentUser.id,
  });

  // Initialize permissions when data is loaded
  useEffect(() => {
    if (data?.results) {
      const initialPermissions = data.results.reduce(
        (acc, permission) => ({
          ...acc,
          [permission.code]: { has_permission: permission.has_permission },
        }),
        {}
      );
      setPermissions(initialPermissions);
    }
  }, [data]);

  const handleCancel = () => {
    if (data?.results) {
      const initialPermissions = data.results.reduce(
        (acc, permission) => ({
          ...acc,
          [permission.code]: { has_permission: permission.has_permission },
        }),
        {}
      );
      setPermissions(initialPermissions);
    }
  };

  const handlePermissionChange = (code: string, checked: boolean) => {
    setPermissions((prev) => ({
      ...prev,
      [code]: { has_permission: checked },
    }));
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      // Transform permissions into array of enabled permission codes
      const enabledPermissions = Object.entries(permissions)
        .filter(([_, value]) => value.has_permission)
        .map(([code]) => code);

      await updateSecurityPermissions({
        companyId: currentCompany.id,
        userId: currentUser.id,
        data: {
          permissions: enabledPermissions,
        },
      });

      setNotification({
        message: 'Permissions updated successfully',
        type: 'success',
        title: 'Permissions Updated',
      });
    } catch (err) {
      log.exception(
        `Error updating permissions for user ${currentUser.id}`,
        err
      );
      setNotification({
        message: err?.error_message || 'Error updating permissions',
        type: 'error',
        title: 'Error Updating Permissions',
      });
    } finally {
      setIsLoading(false);
      mutate();
    }
  };

  const hasPermissions = data?.results && data.results.length > 0;
  const isDirty =
    JSON.stringify(permissions) !==
    JSON.stringify(
      data?.results.reduce(
        (acc, permission) => ({
          ...acc,
          [permission.code]: { has_permission: permission.has_permission },
        }),
        {}
      )
    );

  const renderPermissionSection = () => {
    const nameMap = {
      own: {
        label: 'Personal',
        subLabel: 'Territories assigned to the user',
      },
      direct_report: {
        label: 'Direct Reports',
        subLabel: "Territories assigned to the user's direct reports",
      },
      company: {
        label: 'Company',
        subLabel: 'All territories in the company',
      },
    };

    const labels = ['view', 'create', 'edit', 'delete'];
    const actions = ['read', 'create', 'update', 'delete'];
    const levels = ['own', 'direct_report', 'company'];

    return (
      <div className="flex flex-col">
        {/* Header Row */}
        <div className="flex items-center px-3 py-2 bg-neutral-100 border-b border-neutral-200">
          <div className="flex-1 text-sm font-semibold text-neutral-500">
            Territories
          </div>
          <div className="flex w-80">
            {actions.map((action, index) => (
              <div
                key={action}
                className="flex-1 text-xs font-semibold text-neutral-500 text-center"
              >
                {labels[index]}
              </div>
            ))}
          </div>
        </div>

        {/* Permission Rows */}
        <div className="flex flex-col divide-y divide-neutral-100">
          {levels.map((level) => (
            <div
              key={level}
              className="flex items-center px-3 py-2.5 hover:bg-neutral-50 transition-colors"
            >
              <div className="flex-1 text-sm text-neutral-700">
                <div className="text-sm font-semibold text-neutral-700">
                  {nameMap[level as keyof typeof nameMap].label}
                </div>
                <div className="text-xs text-neutral-500">
                  {nameMap[level as keyof typeof nameMap].subLabel}
                </div>
              </div>
              <div className="flex w-80">
                {actions.map((action) => {
                  const code = `${action}.territory.${level}`;
                  return (
                    <div key={code} className="flex-1 flex justify-center">
                      <Checkbox
                        size="sm"
                        checked={permissions[code]?.has_permission || false}
                        onChange={(e) =>
                          handlePermissionChange(code, e.target.checked)
                        }
                        data-testid={`permission-checkbox-${level}-${action}`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Card className="relative">
      <CardContent className="p-6">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-medium text-neutral-600">
              Permissions
            </h3>

            {!isLoadingData && !error && !hasPermissions ? (
              <EmptyState />
            ) : (
              <div className="bg-white rounded-lg border border-neutral-200 divide-y divide-neutral-200">
                {renderPermissionSection()}
              </div>
            )}
          </div>

          <div className="flex justify-between items-center border-t border-neutral-200 pt-4">
            <Button
              variant="outlined"
              color="neutral"
              onClick={handleCancel}
              disabled={!isDirty}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              color="primary"
              onClick={onSubmit}
              disabled={!isDirty}
              loading={isLoading}
            >
              Update
            </Button>
          </div>
        </div>

        {isLoadingData && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/20">
            <CircularProgress size="sm" color="neutral" />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default TerritoryPermissions;
