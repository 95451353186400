import {
  Box,
  Button,
  Card,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
import { paragon } from '@useparagon/connect';
import React, { useState } from 'react';
import { useAuth, useLogging, useNotification } from 'src/context';
import { useGetAvailableIntegrations, useInitiateIntegration } from 'src/hooks';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPlus, faCog } from '@fortawesome/pro-solid-svg-icons';
import { BrandIcon } from 'src/components';
import {
  AddEditIntegrationDialog,
  useIntegrations,
} from 'src/components/Settings/Integrations';

const InitializeIntegration = () => {
  const theme = useTheme();
  const log = useLogging();
  const { setNotification } = useNotification();
  const { user } = useAuth();
  const { dispatch } = useIntegrations();
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);
  const [integrationType, setIntegrationType] = useState('');

  const {
    data: integrations,
    isLoading: isIntegrationsLoading,
    isFetching: isIntegrationsFetching,
  } = useGetAvailableIntegrations();

  const handleParagonManage = async (integrationType: string) => {
    if (!integrationType) return;

    // Make sure we have an integration and token
    const integration = integrations.find(
      (item) => item.code === integrationType
    );

    if (!integration || !integration.token || !integration.code) return;

    try {
      await paragon.authenticate(
        process.env.NEXT_PUBLIC_PARAGON_PROJECT_ID,
        integration.token
      );

      paragon.connect(integration.code, {
        onSuccess: async (res) => {
          setNotification({
            title: 'Success',
            message: `Successfully connected to ${integration.code}.`,
            type: 'success',
          });
          log.event('Integration Added', {
            crm: integration.code,
          });
        },
        onError: (err) => {
          throw err;
        },
      });
    } catch (err: any) {
      setNotification({
        title: 'Error',
        message:
          err?.error_message ||
          `Error connecting to ${integration.code}. Please try again later.`,
        type: 'error',
      });
      log.exception(`Error connecting to ${integration.code}, ${err}`);
    }
  };

  const sortedIntegrations = integrations?.sort((a, b) => {
    if (a.enabled === b.enabled) {
      return 0;
    }
    return a.enabled ? -1 : 1;
  });

  const isLoading = isIntegrationsLoading || isIntegrationsFetching;
  const { mutateAsync: initiateIntegration } = useInitiateIntegration();

  const handleAddIntegration = async (type) => {
    log.event('Add Integration Clicked', {
      crm: type.code,
      username: user.email,
    });
    setIntegrationType(type.code);

    if (type.code === 'third_party' || type.code === 'medrm') {
      try {
        await initiateIntegration({
          crm: type.code,
          username: user.email,
        });
        log.event('Integration Added', {
          crm: type.code,
          username: user.email,
        });
      } catch (error) {
        log.exception(`Error initiating integration`, {
          tags: {
            source: 'Add MedRM or Third Party Integration',
            integrationType: type.code,
            username: user.email,
            error,
          },
        });
      }
    } else {
      setAddEditDialogOpen(true);
    }
  };

  const handleCardClick = (type: MedScout.Integration) => {
    if (!type.enabled) return;
    dispatch({ type: 'SET_CURRENT_INTEGRATION', payload: type.code });
    log.event('Integration Clicked', {
      crm: type.code,
    });
  };
  return (
    <div className="flex flex-col gap-2 min-h-[calc(100vh-10rem)]">
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <CircularProgress color="inherit" />
        </div>
      ) : integrations?.length > 0 ? (
        sortedIntegrations?.map((type, index) => (
          <Card
            data-testid={`integration-card-${type.code}`}
            key={index}
            onClick={() => handleCardClick(type)}
            sx={{
              padding: '1.25rem 1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 2,
              backgroundColor: 'white',
              '&:hover:not(:has(*:hover))': {
                pointerEvents: type.enabled ? 'all' : 'none',
                cursor: 'pointer',
                backgroundColor: type.enabled
                  ? theme.palette.grey[100]
                  : 'white',
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <BrandIcon
                brand={type.code?.toLowerCase()}
                height={type.code === 'third_party' ? '1.9rem' : '3rem'}
                width="3rem"
              />
              <Typography variant="h5">{type.label}</Typography>
            </Box>
            <Box onClick={(e) => e.stopPropagation()}>
              {type.enabled ? (
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={
                    type?.code === 'medrm' || type?.code === 'third_party'
                  }
                  onClick={() => {
                    log.event('Manage Integration Clicked', {
                      crm: type.code,
                    });
                    handleParagonManage(type.code);
                  }}
                >
                  <Icon icon={faCog} style={{ marginRight: 5 }} size="sm" />
                  Manage
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleAddIntegration(type)}
                >
                  <Icon icon={faPlus} style={{ marginRight: 5 }} size="sm" />
                  Add Integration
                </Button>
              )}
            </Box>
          </Card>
        ))
      ) : (
        <Typography variant="body1">
          No integrations available. Please contact Customer Success to request
          a new integration.
        </Typography>
      )}

      <AddEditIntegrationDialog
        open={addEditDialogOpen}
        onClose={() => setAddEditDialogOpen(false)}
        integrationType={integrationType}
        setIntegrationType={setIntegrationType}
      />
    </div>
  );
};

export default InitializeIntegration;
