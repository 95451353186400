import { useCallback, useLayoutEffect } from 'react';
import { Blade, BladeHeaderWidget } from 'src/components';
import TerritoryDashboard from './components/TerritoryDashboard';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import {
  useGetSavedTerritory,
  useTerritoryDashboard,
  useTerritoryDashboardExport,
  useGetExportedTerritory,
} from 'src/hooks';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import TerritorySubheader from './components/TerritorySubheader';
import { useEnabledSearchIds } from 'src/modules/Territory/context/enabledSearchIds';
import {
  useProspectSearch,
  useLayoutControl,
  useLogging,
  useNotification,
} from 'src/context';
import { handleNumericValue } from 'src/utils';
import { Box, Card, CardContent, Link } from '@mui/joy';

interface TerritoryBladeProps {
  territoryId: number | string;
  dashboardId: number;
  open: boolean;
  onClose: () => void;
  isAnalytics?: boolean;
  adhoc?: MedScout.Territory;
  width?: number | string;
}

/**
 * TerritoryBlade
 * @description Blade varient for displaying territory
 *
 * @param {*} { territory, open, onClose }
 * @return {*}
 */
const TerritoryBlade: React.FC<TerritoryBladeProps> = ({
  territoryId,
  dashboardId,
  open,
  onClose,
  isAnalytics,
  adhoc,
  width,
}) => {
  const log = useLogging();
  const { setShowFilterDrawer } = useLayoutControl();
  const { prospectSearch, prospectType } = useProspectSearch();

  const { data: savedTerritory } = useGetSavedTerritory({
    territoryId,
    isAnalytics: !!isAnalytics,
  });
  const { setNotification } = useNotification();

  const territory = isAnalytics ? savedTerritory : adhoc;
  const [enabledSearchIds] = useEnabledSearchIds();
  const searchIds = isAnalytics
    ? enabledSearchIds
    : enabledSearchIds.concat(prospectSearch?.id || []);

  const { data: dashboardData, isLoading } = useTerritoryDashboard({
    territoryId,
    dashboardId,
    enabledSearchIds: searchIds,
    territory,
    isAnalytics: !!isAnalytics,
    type: 'HCP',
  });

  const loadedTerritory = open && territory;

  useLayoutEffect(() => {
    if (loadedTerritory) {
      log.event('Toggle Territory Blade', {
        id: loadedTerritory?.id,
        type: 'Opened',
        name: loadedTerritory?.name || adhoc?.name || 'New Territory',
      });
    } else {
      log.event('Toggle Territory Blade', {
        type: 'Closed',
      });
    }
  }, [loadedTerritory]);

  const queryString = getSearchQueryParamsForAPI({
    id: territory?.id,
    dashboard: dashboardId,
    saved_search_ids: enabledSearchIds,
    type: prospectType,
    isAnalytics: !!isAnalytics,
  });

  const { getExport } = useTerritoryDashboardExport({
    dashboardId,
    territoryId,
    enabledSearchIds: searchIds,
    type: prospectType,
  });

  const { getExport: getExportTerritory } = useGetExportedTerritory({
    territoryId,
  });

  const onExport = useCallback(async () => {
    try {
      await getExport();
      setNotification({
        title: 'Success',
        message:
          'Export requested, you will recieve an email when it is ready!',
        type: 'success',
      });
    } catch (err: any) {
      log.exception(`Error exporting territory analytics blade: ${err}`);
      setNotification({
        title: 'Error',
        message: err?.error_message || 'Error requesting export',
        type: 'error',
      });
    }
  }, [getExport, setNotification, log]);

  const onDownload = useCallback(async () => {
    if (!territoryId) return;
    try {
      await getExportTerritory();
      setNotification({
        title: 'Success',
        message: 'Export requested, please check downloads!',
        type: 'success',
      });
    } catch (err: any) {
      log.exception(`Error exporting territory analytics blade: ${err}`);
      setNotification({
        title: 'Error',
        message: err?.error_message || 'Error requesting export',
        type: 'error',
      });
    }
  }, [getExportTerritory, setNotification, log]);

  const territoryVolumeData = dashboardData?.results?.find(
    (result) => result.card_name === 'Total Volume'
  );

  const totalVolume = handleNumericValue(
    territoryVolumeData?.results.reduce(
      (accum, v) =>
        isNaN(Number(v.value)) ? (accum += 0) : (accum += Number(v.value)),
      0
    )
  );

  const analyticsVariant = 'temporary';
  const adhocVariant = open ? 'permanent' : 'temporary';
  const showSubheader = !!isAnalytics && !!territoryVolumeData;
  const territoryName = territory?.name || 'New Territory';

  return (
    <Blade
      title={territoryName}
      icon={faFlag}
      headerSlots={
        !!prospectSearch && {
          slot1: (
            <BladeHeaderWidget
              label={
                territoryVolumeData?.results.length === 1
                  ? `${(
                      prospectSearch?.search_name ||
                      prospectSearch?.name ||
                      ''
                    )?.substring(0, 32)}${
                      (
                        prospectSearch?.search_name ||
                        prospectSearch?.name ||
                        ''
                      ).length > 32
                        ? '...'
                        : ''
                    } Volume`
                  : 'Total Volume'
              }
              value={totalVolume}
            />
          ),
        }
      }
      subHeader={
        showSubheader ? (
          <TerritorySubheader
            data={territory || {}}
            volumeData={territoryVolumeData?.results}
          />
        ) : null
      }
      open={open}
      route={isAnalytics ? `/tearoff/territory/${queryString}` : null}
      onExport={onExport}
      onDownload={onDownload}
      onClose={onClose}
      width={width}
      minWidth={width}
      maxWidth={width}
      variant={isAnalytics ? analyticsVariant : adhocVariant}
      hideBackdrop={!!adhoc}
    >
      {searchIds?.length > 0 ? (
        <TerritoryDashboard
          dashboardData={dashboardData}
          isLoading={isLoading}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card sx={{ margin: '1rem' }}>
            <CardContent>
              You must have at least one saved search selected.{' '}
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowFilterDrawer(true);
                }}
              >
                Add or remove filters
              </Link>
              &nbsp;to view results.
            </CardContent>
          </Card>
        </Box>
      )}
    </Blade>
  );
};

export default TerritoryBlade;
