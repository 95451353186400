import React, { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useCompanyUsers,
  useGetReports,
  updateManagersReports,
} from 'src/hooks';
import { Pagination } from '@mui/material';
import { Button, Checkbox } from 'src/components';
import {
  useDashboard,
  useLogging,
  useNotification,
  useSite,
} from 'src/context';

const ReportsTabContent = () => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const log = useLogging();
  const { setNotification } = useNotification();
  const { state } = useSite();
  const { currentCompany, currentUser } = state.companyAdmin;
  const { data: users } = useCompanyUsers(currentCompany.id);

  const { userQueryParams, setUserQueryParams } = useDashboard();

  const isManager = currentUser?.permissions?.is_manager;

  const { data: reports, mutate: mutateReports } = useGetReports({
    companyId: currentCompany.id,
    managerId: isManager ? currentUser?.id : undefined,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [isLoading, setIsLoading] = useState(false);

  const managerReports = useMemo(() => {
    if (!reports?.results) return [];
    return reports?.results?.map((report) => report.id);
  }, [reports]);

  const methods = useForm({
    values: {
      direct_reports: managerReports,
    },
  });

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = methods;

  const handleOnClearReports = () => {
    setValue('direct_reports', [], { shouldDirty: true });
  };

  const handleCancel = () => {
    reset();
  };

  const onSubmit = async (data) => {
    if (!data) return;
    setIsLoading(true);
    try {
      await updateManagersReports(
        currentCompany.id,
        String(currentUser?.id),
        data
      );

      log.event('Manager Reports Updated', {
        source: 'ManagerCard',
        companyId: currentCompany.id,
        data: data,
      });
      setNotification({
        title: 'Success',
        message: 'Manager reports updated',
        type: 'success',
      });
    } catch (err) {
      log.exception('Error updating manager reports', {
        tags: {
          source: 'ManagerCard',
          companyId: currentCompany.id,
          data: data,
        },
      });
      setNotification({
        title: 'Error',
        message: err?.error_message || 'Error updating manager reports',
        type: 'error',
      });
    } finally {
      mutateReports();
      setIsLoading(false);
    }
  };

  const handleChange = (e, field, user) => {
    const currentReports = getValues('direct_reports');
    field.onChange(
      e.target.checked
        ? [...currentReports, user.id]
        : currentReports.filter((report) => report !== user.id)
    );
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    setUserQueryParams({
      ...userQueryParams,
      page: value,
      pageSize: pageSize,
    });
    // Scroll to the top of the page smoothly
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const pageCount = Math.ceil(users?.count / pageSize);
  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="flex justify-end items-center">
            <button
              onClick={handleOnClearReports}
              className="text-sm text-blue-600 hover:text-blue-700"
            >
              clear all
            </button>
          </div>

          <div
            ref={scrollRef}
            className="bg-neutral-50 rounded-lg divide-y divide-neutral-200 h-48 overflow-hidden overflow-y-auto border border-neutral-200"
            aria-label="reports"
          >
            {users?.results?.map((user, index) => (
              <div
                key={index}
                className="flex items-center justify-between px-3 py-2 first:rounded-t-lg last:rounded-b-lg hover:bg-neutral-100 transition-colors"
              >
                <span className="text-sm text-neutral-700">
                  {`${user.first_name} ${user.last_name}`}
                </span>
                <Controller
                  name="direct_reports"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      size="sm"
                      checked={field.value?.includes(user.id)}
                      onChange={(e) => handleChange(e, field, user)}
                    />
                  )}
                />
              </div>
            ))}
            {users?.count > pageSize && (
              <div className="flex justify-center pt-2 pb-1">
                <Pagination
                  size="small"
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between items-center border-t border-neutral-200 pt-4">
          <Button
            variant="outlined"
            color="neutral"
            onClick={handleCancel}
            disabled={!isDirty}
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            variant="solid"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid}
          >
            Update
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default ReportsTabContent;
