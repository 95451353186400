import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

const PermissionCell = ({
  permissions,
  action,
  scope,
}: {
  permissions: string[];
  action: string;
  scope: string;
}) => {
  const hasPermission = (
    permissions: string[],
    action: string,
    scope: string
  ) => {
    return permissions.includes(`${action}.territory.${scope}`);
  };

  return (
    <div
      className="flex-1 flex justify-center"
      data-testid={`permission-cell-${scope}-${action}`}
    >
      {hasPermission(permissions, action, scope) && (
        <Icon icon={faCheck} className="h-4 w-4 text-green-500" />
      )}
    </div>
  );
};

export default PermissionCell;
