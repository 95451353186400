import { useForm, Controller } from 'react-hook-form';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { useEffect } from 'react';
import { useUpdateMapping } from 'src/hooks';
import { useLogging } from 'src/context';
import { useIntegrations } from '../../context';

const EnabledCell = ({ row }) => {
  const log = useLogging();
  const { settings } = useIntegrations();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      ...row,
    },
  });

  const { mutateAsync: updateMapping } = useUpdateMapping();

  // This resets the form when the row changes if not the data is stale
  useEffect(() => {
    if (!row) return;
    reset({
      ...row,
    });
  }, [row]);

  async function onSubmit(data: any) {
    if (!data) return;
    try {
      await updateMapping(data);
      log.event(`Updated mapping ${data.id}`, {
        ...data,
      });
    } catch (err: any) {
      log.exception(`Error updating mapping: ${err}`);
    }
  }
  const isSettingsEmpty = settings?.some((setting) => !setting?.value);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'default',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Controller
        control={control}
        name="enabled"
        render={({ field }) => (
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                onChange={handleSubmit(onSubmit)}
                onClick={(e) => {
                  e.stopPropagation();
                  field.onChange(e);
                }}
                disabled={isSettingsEmpty}
              />
            }
            label="Enabled"
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '0.8rem',
                fontWeight: 500,
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default EnabledCell;
