import React, { useMemo, useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/joy';
import { CardHeaderContainer, CardHeader, CardLoadingIndicator } from '../Card';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faFilter,
  faLocationDot,
} from '@fortawesome/pro-solid-svg-icons';
import { CardTable, TableRow } from '../Card/CardTable';
import { useGetVolumeByLocation } from 'src/hooks';
import LocationRowItem from './LocationRowItem';
import LocationByDay from './LocationByDay';
import { exists } from 'src/utils';
import { InvalidSavedSearch, NoDataAvailable } from '../NoData';
import { useProfile, useSite, useUserSettings } from 'src/context';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SavedSearchFilter } from 'src/components/SavedSearchFilter';

const ProfileVolumeByLocation = () => {
  const { details } = useProfile();
  const { state } = useSite();
  const { state: userSettingsState } = useUserSettings();
  const { newPayerMix } = useFlags();

  const selectedSavedSearch = newPayerMix
    ? userSettingsState?.selectedSavedSearch
    : state?.savedSearch?.filterBySavedSearch?.selectedSavedSearch;

  const [expanded, setExpanded] = useState<string[]>([]); // Change to an array to support multiple open accordions

  const { data: volumeByLocationData, isLoading } = useGetVolumeByLocation(
    details?.provider_id
  );

  const topLocations = useMemo(() => {
    // get the first 10 locations
    const locations = volumeByLocationData?.results?.slice(0, 10) || [];
    // locations with percentage over 20
    const topVolumeLocations = locations.filter(
      (location) => location.percentage >= 20 && exists(location.provider_id)
    );

    return topVolumeLocations;
  }, [volumeByLocationData?.results]);

  const otherLocations = useMemo(() => {
    // get the first 10 locations
    const locations = volumeByLocationData?.results?.slice(0, 10) || [];
    // locations with percentage under 20
    const lowVolumeLocations = locations?.filter(
      (location) => location.percentage < 20
    );
    // if one doesn't have a provider_id, make it the last item
    const comboOtherLocation = locations?.find(
      (location) => !location.provider_id
    );

    if (comboOtherLocation) {
      const otherIndex = lowVolumeLocations.indexOf(comboOtherLocation);
      lowVolumeLocations.splice(otherIndex, 1);
      lowVolumeLocations.push(comboOtherLocation);
    }
    return lowVolumeLocations;
  }, [volumeByLocationData?.results]);

  // check if the saved search has valid procedure codes
  const isValidSavedSearch = useMemo(() => {
    if (!selectedSavedSearch) return true;

    const hasProcedureCodes =
      selectedSavedSearch?.filters?.cpt?.length > 0 ||
      selectedSavedSearch?.filters?.hcpcs?.length > 0 ||
      selectedSavedSearch?.filters?.icd?.length > 0 ||
      selectedSavedSearch?.filters?.drg?.length > 0 ||
      selectedSavedSearch?.filters?.icdp?.length > 0;

    return hasProcedureCodes;
  }, [selectedSavedSearch]);

  return (
    <Card className={`bg-white ${newPayerMix ? 'border border-teal-100' : ''}`}>
      <CardHeaderContainer>
        <Box className="flex flex-col gap-2">
          <CardHeader>
            <Icon icon={faLocationDot} color="#636B74" />
            <Typography level="title-md" className="font-semibold">
              Top Locations
            </Typography>
          </CardHeader>
          <Typography
            level="body-xs"
            className="text-[#636B74] pb-2 font-normal"
          >
            Percentage of claims volumes* by location attributed; 2022 through
            2025; Medicare + Commercial.
          </Typography>
        </Box>
        {!newPayerMix && (
          <SavedSearchFilter
            id="top-locations-filter"
            startDecorator={faFilter}
            endDecorator={faCaretDown}
            context="provider"
          />
        )}
      </CardHeaderContainer>
      <CardContent className="relative min-h-[5rem]">
        {/* Loading overlay */}
        {isLoading && <CardLoadingIndicator />}

        {/* Main content */}
        {!isValidSavedSearch ? (
          <InvalidSavedSearch />
        ) : volumeByLocationData?.results?.length ? (
          <>
            {topLocations?.map((location, index) => {
              const isFirst = index === 0;
              return (
                <LocationByDay
                  key={index}
                  isFirst={isFirst}
                  location={location}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              );
            })}
            <CardTable>
              <TableRow color="#F0F4F8">
                <Typography
                  level="body-sm"
                  className="font-medium leading-[1.42858rem] text-[#171A1C]"
                >
                  Other Locations
                </Typography>
                <Typography
                  level="body-sm"
                  className="font-medium leading-[1.42858rem]"
                >
                  % of Total
                </Typography>
              </TableRow>
              {otherLocations?.length > 0 ? (
                otherLocations.map((location, index) => (
                  <LocationRowItem key={index} location={location} />
                ))
              ) : (
                <Box className="flex justify-center items-center h-20">
                  <Typography level="body-sm" className="text-[#555E68]">
                    No data available
                  </Typography>
                </Box>
              )}
            </CardTable>
          </>
        ) : (
          !isLoading && <NoDataAvailable />
        )}
      </CardContent>
      <Box className="py-[0.125rem] px-4">
        <Typography level="body-xs" className="text-[#555E68] font-normal">
          Prescription data not available in this widget.
        </Typography>
      </Box>
    </Card>
  );
};

export default ProfileVolumeByLocation;
