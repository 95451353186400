import { ReactNode } from 'react';

interface ContainerProps {
  children: ReactNode;
  maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full' | 'none';
  disableGutters?: boolean;
  className?: string;
  sx?: React.CSSProperties;
}

const Container = ({
  children,
  maxWidth = 'lg',
  disableGutters = false,
  className = '',
  sx,
}: ContainerProps) => {
  const maxWidthClasses = {
    sm: 'max-w-screen-sm', // 640px
    md: 'max-w-screen-md', // 768px
    lg: 'max-w-screen-lg', // 1024px
    xl: 'max-w-screen-xl', // 1280px
    '2xl': 'max-w-screen-2xl', // 1536px
    full: 'max-w-full',
    none: '', // No max-width class applied
  };

  return (
    <div
      className={`
        mx-auto
        w-full
        ${maxWidth !== 'none' ? maxWidthClasses[maxWidth] : ''}
        ${!disableGutters ? 'px-4 sm:px-6 md:px-8' : ''}
        ${className}
      `}
      style={sx}
    >
      {children}
    </div>
  );
};

export default Container;
