import { UserSettingsActionTypes } from './actionTypes';

type InitialState = {
  [key: string]: any;
};

export const initialUserSettingsState: InitialState = {};

export const userSettingsReducer = (
  state = initialUserSettingsState,
  action: any
) => {
  switch (action.type) {
    case UserSettingsActionTypes.UPDATE_USER_SETTINGS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
