import React from 'react';
import { useLogging, useNotification, useLayoutControl } from 'src/context';
import { useLinkToDuplicateCRMObject } from 'src/hooks';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/joy';
import { exists, sentenceCase } from 'src/utils';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';

const PushToCRMDuplicateDialog = ({ open, onClose }) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { pushDuplicateToCRM } = useLayoutControl();
  const { mutateAsync: linkToDuplicateCRMObject, isLoading } =
    useLinkToDuplicateCRMObject();

  const handleSubmit = async () => {
    try {
      await linkToDuplicateCRMObject({
        crm: pushDuplicateToCRM?.crm?.toLowerCase(),
        crm_id: pushDuplicateToCRM.crm_id,
        crm_type: pushDuplicateToCRM.crm_type,
        object_type: pushDuplicateToCRM.object_type,
        object_id: pushDuplicateToCRM.object_id,
        mapping: pushDuplicateToCRM.mapping,
      });
      setNotification({
        title: 'Success',
        message: 'Provider linked to existing CRM record',
        type: 'success',
      });
    } catch (err: any) {
      setNotification({
        title: 'Error',
        message: err?.error_message || 'Error linking to existing CRM record',
        type: 'error',
      });
      log.exception(`Error in PushToCRMDuplicateDialog: ${err}`);
    }

    log.event('Push Duplicate to CRM', {
      ...pushDuplicateToCRM,
    });

    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Record Exists</DialogTitle>
      <DialogContent>
        <Typography>
          We found a {pushDuplicateToCRM?.crm_type} matching this provider in
          your CRM. Would you like to link this provider to the existing record?{' '}
          {exists(pushDuplicateToCRM?.link) && (
            <a
              href={pushDuplicateToCRM?.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontSize: '.875rem', alignItems: 'center' }}
            >
              View {pushDuplicateToCRM?.crm_type} in{' '}
              {sentenceCase(pushDuplicateToCRM?.crm)}{' '}
              <Icon icon={faExternalLinkAlt} size="xs" />
            </a>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0.5rem 1rem',
            gap: '0.5rem',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
              log.event('Push Duplicate to CRM - Cancel', {
                ...pushDuplicateToCRM,
              });
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Link to Existing
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PushToCRMDuplicateDialog;
