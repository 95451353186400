import { CompanyAdminActionTypes } from './actionTypes';

type InitialState = {
  currentCompany: any;
  currentUser: any;
};

export const initialCompanyAdminState: InitialState = {
  currentCompany: null,
  currentUser: null,
};

export const companyAdminReducer = (
  state = initialCompanyAdminState,
  action: any
) => {
  switch (action.type) {
    case CompanyAdminActionTypes.SET_CURRENT_COMPANY:
      return { ...state, currentCompany: action.payload };
    case CompanyAdminActionTypes.SET_CURRENT_USER:
      return { ...state, currentUser: action.payload };
    case CompanyAdminActionTypes.SET_CURRENT_COMPANY_AND_USER:
      return {
        ...state,
        currentCompany: action.payload.company,
        currentUser: action.payload.user,
      };
    default:
      return state;
  }
};
