import React, { useEffect, useState, useMemo } from 'react';
import { Select, MenuItem } from '@mui/material';
import { Toggle } from 'src/components';
import { faUserDoctor, faHospital } from '@fortawesome/pro-solid-svg-icons';
import {
  useProspectSearch,
  useLogging,
  useAuth,
  useUserSettings,
} from 'src/context';
import { useCompany } from 'src/hooks';

type ProspectMode = 'treating' | 'sending' | 'receiving';

const ResultTypeSelector = ({ legacy = false }) => {
  const { user } = useAuth();
  const log = useLogging();
  const { prospectType, setProspectType, prospectMode, setProspectMode } =
    useProspectSearch();

  const [toggleOrientation, setToggleOrientation] = useState<'left' | 'right'>(
    'left'
  );

  const { state, dispatch } = useUserSettings();
  const userSettings = useMemo(
    () => state?.prospectModes || {},
    [state?.prospectModes]
  );

  const { data: company, isLoading: isCompanyLoading } = useCompany(
    user?.company?.id?.toString()
  );

  // Initialize from saved settings
  const [typeStates, setTypeStates] = useState({
    HCP: {
      mode: userSettings?.HCP || ('treating' as ProspectMode),
    },
    C: { mode: userSettings?.C || ('treating' as ProspectMode) },
  });

  // Initialize settings from user preferences or company defaults
  useEffect(() => {
    const settings = state?.prospectModes;

    if (settings) {
      const savedType = settings.currentType || 'HCP';
      const savedMode = settings[savedType] || 'treating';

      setTypeStates({
        HCP: {
          mode: settings.HCP || ('treating' as ProspectMode),
        },
        C: { mode: 'treating' as ProspectMode },
      });

      setProspectType(savedType);
      setProspectMode(savedMode);
      setToggleOrientation(savedType === 'C' ? 'right' : 'left');
    } else if (company) {
      setProspectType(company.discovery_default_type);
      setProspectMode(company.discovery_default_mode);
      setToggleOrientation(
        company.discovery_default_type === 'C' ? 'right' : 'left'
      );
    }
  }, [company, state]);

  // Save changes only when user explicitly changes type or mode
  const handleModeChange = (newMode: ProspectMode) => {
    setProspectMode(newMode);
    const newTypeStates = {
      ...typeStates,
      [prospectType]: { mode: newMode },
    };
    setTypeStates(newTypeStates);

    dispatch({
      type: 'UPDATE_USER_SETTINGS',
      payload: {
        prospectModes: {
          ...userSettings,
          [prospectType]: newMode,
        },
      },
    });
  };

  const handleToggleChange = (key, value) => {
    if (key === 'type' && value === 'C') {
      const newMode = 'treating'; // Sites always treating
      setProspectType('C');
      setProspectMode(newMode);
      setToggleOrientation('right');
    } else if (key === 'type' && value === 'HCP') {
      const newMode = typeStates.HCP.mode;
      setProspectType('HCP');
      setProspectMode(newMode);
      setToggleOrientation('left');
    }

    dispatch({
      type: 'UPDATE_USER_SETTINGS',
      payload: {
        prospectModes: {
          ...userSettings,
          currentType: value,
          [value]: value === 'C' ? 'treating' : typeStates.HCP.mode,
        },
      },
    });

    log.event(`Toggle Changed: ${value === 'C' ? 'Centers' : 'HCP'}`);
  };

  return (
    <Toggle
      id={`discovery-result-type-selector-${prospectType}`}
      leftIcon={faUserDoctor}
      rightIcon={faHospital}
      leftLabel="Providers"
      rightLabel="Sites"
      onLeft={() => {
        handleToggleChange('type', 'HCP');
      }}
      onRight={() => {
        handleToggleChange('type', 'C');
      }}
      status={toggleOrientation}
      showLabels
      slots={
        !legacy
          ? {
              action: () => null,
              thumb: ({ sx, ...props }) => (
                <Select
                  id={`discovery-result-type-selector-${prospectType}-select-${prospectMode}`}
                  value={prospectMode || 'treating'}
                  size="small"
                  onChange={(e) => {
                    log.event('Mode Changed', {
                      mode: e.target.value,
                    });
                    handleModeChange(e.target.value as typeof prospectMode);
                  }}
                  disabled={prospectType === 'C'}
                  sx={{
                    borderRadius: '1rem',
                    '--Switch-thumbHeight': '1.6rem',
                    '--Switch-thumbWidth': '3.5rem',
                    '--mui-palette-text-disabled': 'white',
                    '& .MuiSelect-select': {
                      width: 'var(--Switch-thumbWidth)',
                      fontSize: '0.8rem',
                      fontWeight: 400,
                      // padding: '0.15rem 0.75rem',
                      color: 'white',
                    },
                    '&.Mui-disabled .MuiSelect-icon': {
                      display: 'none',
                    },
                    '& .MuiSelect-icon': {
                      color: 'white',
                    },
                  }}
                  {...props}
                >
                  <MenuItem value="treating">Treating</MenuItem>
                  <MenuItem value="sending">Referring</MenuItem>
                </Select>
              ),
            }
          : {}
      }
      sx={
        !legacy
          ? {
              width: '8rem',
              '& .MuiSwitch-thumb': {
                transform: 'translate(0, 0)',
              },
              '&.Mui-checked .MuiSwitch-thumb': {
                transform: 'translate(20px, 0)',
              },
            }
          : {}
      }
    />
  );
};

export default ResultTypeSelector;
