import { useState, useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  useTheme,
  Box,
  Button,
  Grid,
  Card,
  Typography,
  Radio,
  CardContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { DynamicInput, CRMLookup, IconButton } from 'src/components';
import { useLogging, useNotification, useLayoutControl } from 'src/context';
import {
  usePushToCRM,
  useGetUserInputOptions,
  useGetMappings,
} from 'src/hooks';

import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { green } from '@mui/material/colors';

// FLOW 3: One mapping, one or more options - Dialog Steps
// FLOW 4: Multiple mappings, 0 or more options - Dialog, then based on mapping, either submit or show the flow
interface PushToCRMDialogProps extends DialogProps {
  provider: MedScout.Opportunity;
  integration: string;
}
const PushToCRMDialog = ({
  open,
  onClose,
  provider,
  integration,
}: PushToCRMDialogProps) => {
  const router = useRouter();
  const { type } = router.query as { type: string };

  const typeNameMap = {
    hcp: 'hcp',
    clinic: 'clinic',
    center: 'center',
  };

  const contentType =
    !!type && typeNameMap[type?.toLowerCase()]
      ? type?.toLowerCase()
      : provider?.content_type?.toLowerCase();

  const theme = useTheme();
  const log = useLogging();
  const { setNotification } = useNotification();
  const { setPushDuplicateToCRM } = useLayoutControl();

  const { data: allMappings } = useGetMappings({
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  // Gets mappings for specific provider type, removing used mappings
  const mappings = useMemo(() => {
    const unusedMappings = allMappings?.results
      ?.filter((mapping) => {
        return !provider?.crm_links?.some(
          (link) => link.mapping_id === mapping.id
        );
      })
      ?.filter(
        (mapping) =>
          mapping?.medscout_object_type?.toLowerCase() === contentType &&
          mapping?.enabled &&
          mapping?.crm?.toLowerCase() === integration?.toLowerCase()
      );

    return unusedMappings;
  }, [allMappings, integration, contentType, provider]);

  const [mappingType, setMappingType] = useState<MedScout.Mapping | null>(null);
  const [activeStep, setActiveStep] = useState(0);

  const { mutateAsync: pushToCRM, isLoading } = usePushToCRM({
    id: provider?.provider_id,
  });

  const mapping = mappings?.find((item) => item.id === mappingType?.id);
  const isThirdParty = mapping?.crm?.toLowerCase() === 'third_party';

  const { data: userInputOptions } = useGetUserInputOptions({
    mappingId: mappingType?.id,
    page: 0,
    pageSize: 100,
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
  });

  useEffect(() => {
    if (!mappings || activeStep !== 0) return;
    const handleSingleMapping = () => {
      if (mappings?.length === 1) {
        setMappingType(mappings[0]);
        handleNext();
      }
      return;
    };

    handleSingleMapping();
  }, [activeStep, mappings]);

  useEffect(() => {
    if (!mappingType) return;
    reset();
  }, [mappingType]);

  // Determine all options are hidden
  const isAllHidden = userInputOptions?.results?.every(
    (option) => option?.hidden === true
  );

  const isLastStep = useCallback(() => {
    // For my future self: if activestep equals 1 and there are no options, or all options are hidden, that means there are no further steps needed.
    return (
      activeStep === 1 || userInputOptions?.results?.length === 0 || isAllHidden
    );
  }, [activeStep, isAllHidden, userInputOptions?.results?.length]);

  const handleNext = () => {
    setActiveStep((prev) => {
      if (!isLastStep()) {
        return prev + 1;
      }
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    log.event('Push to CRM - Back', {
      ...provider,
    });
  };

  const handleCancel = (e) => {
    setActiveStep(0);
    setMappingType(null);
    reset();
    onClose(e, 'backdropClick');
  };

  const onSubmit = async (data: any, e) => {
    try {
      // We need to include the hidden fields in the payload if isAllHidden is true
      const fieldsToPush = isAllHidden
        ? userInputOptions?.results?.reduce((acc, option) => {
            if (!option?.hidden) return acc;
            acc[option?.crm_api_code] = option?.extra?.default_value || '';
            return acc;
          }, {})
        : data;

      const response = await pushToCRM({
        object_type: mapping.medscout_object_type,
        mapping: mapping.id,
        fields: fieldsToPush || {},
      });

      if (response.duplicate) {
        const duplicateData = {
          mapping: mapping.id,
          object_type: mapping.medscout_object_type,
          object_id: provider?.provider_id,
          ...response,
        };
        setPushDuplicateToCRM(duplicateData);
        handleCancel(e);
        return;
      }
      setNotification({
        title: isThirdParty
          ? 'Push to CRM Initiated'
          : 'Push to CRM successful',
        message: isThirdParty
          ? 'Successfully initiated push to CRM. This may take a few minutes.'
          : 'Successfully pushed to CRM.',
        type: 'success',
      });
      handleCancel(e);
      log.event('Push to CRM', {
        ...provider,
      });
    } catch (err: any) {
      setNotification({
        title: 'Push to CRM failed',
        message:
          err?.error_message || 'Something went wrong, please try again later.',
        type: 'error',
      });

      log.exception(`Error pushing to CRM: ${err}`);
    }
  };

  // move all Boolean options to the end
  const newOptions = useMemo(() => {
    if (!userInputOptions?.results) return [];
    return userInputOptions.results?.sort((opt) =>
      opt.input_type === 'BOOLEAN' ? 1 : -1
    );
  }, [userInputOptions?.results]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'}>
      <DialogTitle>
        Push to CRM
        <IconButton
          icon={faTimes}
          onClick={(e) => onClose(e, 'backdropClick')}
          style={{ color: theme.palette.grey[500] }}
          size="small"
        />
      </DialogTitle>
      <DialogContent>
        {mappingType && activeStep !== 0 && (
          <Box
            sx={{
              mb: 2,
              pb: 1,
              borderBottom: `1px solid ${theme.palette.grey[200]}`,
            }}
          >
            <Typography variant="h6">{mapping?.label}</Typography>
          </Box>
        )}
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          {activeStep === 0 &&
            mappings?.map((mapping, index) => {
              return (
                <Grid key={index} item sm={12}>
                  <Card
                    elevation={0}
                    sx={{
                      border: `1px solid ${theme.palette.grey[300]}`,
                      borderRadius: '0.25rem',
                      '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: theme.palette.grey[100],
                      },
                    }}
                    onClick={() => setMappingType(mapping)}
                  >
                    <CardContent sx={{ display: 'flex' }}>
                      <Radio
                        size="small"
                        checked={
                          mappingType?.crm_object_type ===
                          mapping?.crm_object_type
                        }
                        onChange={() => setMappingType(mapping)}
                      />
                      <Box>
                        <Typography sx={{ fontWeight: 600 }}>
                          {mapping.crm_object_type}
                        </Typography>
                        <Typography
                          sx={{ fontSize: '0.85rem', fontWeight: 500 }}
                        >
                          {mapping?.label}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          {activeStep === 1 &&
            newOptions?.map((option, index) => {
              if (option.input_type === 'CRM_LOOKUP') {
                const crmType = option?.extra?.crm_object_type;

                return (
                  <Grid key={index} item sm={12}>
                    <Typography
                      sx={{
                        fontSize: '0.8rem',
                        fontWeight: 600,
                        color: theme.palette.grey[700],
                        gap: '0.5rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {option?.label} (required)
                    </Typography>
                    <CRMLookup
                      crmType={crmType}
                      crm={mapping?.crm?.toLowerCase()}
                      option={option}
                      control={control}
                      rules={{
                        required: true,
                      }}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid
                    key={index}
                    item
                    sm={12}
                    justifyContent="flex-end"
                    sx={{
                      display: option?.hidden ? 'none' : 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {option?.input_type !== 'BOOLEAN' && (
                      <Typography
                        sx={{
                          fontSize: '0.8rem',
                          fontWeight: 600,
                          color: theme.palette.grey[700],
                          gap: '0.5rem',
                          alignItems: 'center',
                          display: option?.hidden ? 'none' : 'flex',
                        }}
                      >
                        {option?.label} (required)
                      </Typography>
                    )}
                    <DynamicInput
                      type={option.input_type?.toLowerCase()}
                      name={option.crm_api_code?.toLowerCase()}
                      rules={{
                        required: option?.extra?.required || false,
                      }}
                      hidden={option?.hidden}
                      control={control}
                      variant="standard"
                      value={option?.extra?.default_value}
                      placeholder={
                        `${option?.extra?.default_value}` || 'e.g. 1234'
                      }
                      options={option?.extra?.options || []}
                      label={option?.label}
                    />
                  </Grid>
                );
              }
            })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.5rem 1rem',
          }}
        >
          <Button
            variant="outlined"
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Button
              variant="outlined"
              onClick={(e) => {
                handleCancel(e);
                log.event('Push to CRM - Cancel', {
                  ...provider,
                });
              }}
            >
              Cancel
            </Button>
            {isLastStep() ? (
              <LoadingButton
                variant="contained"
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
                sx={{
                  background: green[800],
                  '&:hover': { background: green[900] },
                }}
                disabled={!isValid}
              >
                Submit
              </LoadingButton>
            ) : (
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!mappingType}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PushToCRMDialog;
