import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface GetExportProps {
  fileType: string;
  fileName: string;
  columns: string[];
  searchParams: {
    page: number;
    pageSize: number;
    sort: any;
    order: any;
    q: string;
  };
}

export const getExportDataIssues = async ({
  searchParams,
  fileType,
  fileName,
  columns,
}: GetExportProps) => {
  const { page, pageSize, sort, order, q } = searchParams;
  const queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    page: page + 1,
    pageSize,
    q,
  });

  const url = `https://api.medscout.io/api/v1/account/data-issues/${queryParams}`;

  return request({
    url: '/v1/admin/export/',
    method: 'POST',
    data: {
      url,
      file_format: fileType,
      columns: columns,
      file_name: fileName,
    },
  });
};
