import { Card, Typography } from '@mui/joy';
import { faGraduationCap } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { hasContent } from 'src/utils';
import EducationItem from 'src/modules/Profile/components/Views/components/EducationItem';
import InfoUnavailable from './InfoUnavailable';
import { useProfile } from 'src/context';
import { Box } from 'src/components/PulseUI';
const ProfileEducationDetails = () => {
  const { details } = useProfile();

  const educationRecords = details?.hcp?.education || [];

  return (
    <>
      <Card sx={{ padding: 3 }}>
        <Box
          className="flex flex-row gap-2 items-center"
          aria-label="Medical School"
        >
          <Icon icon={faGraduationCap} className="text-secondary h-4 w-5" />
          <Typography className="text-lg font-bold">Education</Typography>
        </Box>
        <Box>
          {hasContent(educationRecords) ? (
            educationRecords.map((item, i) => {
              const isLast = i === educationRecords.length - 1;

              return (
                <EducationItem
                  key={`${item.id}-${i}`}
                  item={item}
                  isLast={isLast}
                />
              );
            })
          ) : (
            <InfoUnavailable />
          )}
        </Box>
      </Card>
    </>
  );
};

export default ProfileEducationDetails;
