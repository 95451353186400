import { useState, useRef, useMemo } from 'react';
import {
  IconButton,
  Dropdown,
  Menu,
  List,
  ListItemButton,
  ListItem,
  Checkbox,
} from '@mui/joy';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import {
  useGetFieldDefinitions,
  useGetObjectDefinitionsList,
  useOnClickOutside,
} from 'src/hooks';
import { useCRM, useLogging } from 'src/context';
import { grey } from '@mui/material/colors';
import { buckets } from 'src/components/MedRM/constants';
import GridStatusFilterMenuGroup from './GridStatusFilterMenuGroup';
import GridStatusFilterMenuHeader from './GridStatusFilterMenuHeader';

interface GridStatusFilterMenuProps {
  table?: string;
}

/**
 * @description GridStatusFilterMenu component handles Status filtering on the grid
 * @returns React.Node
 */
const GridStatusFilterMenu = ({ table = '' }: GridStatusFilterMenuProps) => {
  const log = useLogging();
  const menuRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const { statusFilterOptions, setStatusFilterOptions, setSelectedBuckets } =
    useCRM();

  const { data: objectDefinitions, isLoading: isLoadingObjects } =
    useGetObjectDefinitionsList();

  const { data: fieldDefinitions, isLoading: isLoadingFields } =
    useGetFieldDefinitions({
      objectId: objectDefinitions?.results?.[0]?.id,
    });

  const statusField = useMemo(() => {
    return fieldDefinitions?.results?.find(
      (field) => field?.label?.toLowerCase() === 'status'
    );
  }, [fieldDefinitions]);

  const groupedOptions = useMemo(() => {
    const options = statusField?.options;

    // Group options by bucket
    const grouped = options?.reduce((acc, option) => {
      if (!acc[option.bucket]) {
        acc[option.bucket] = [];
      }

      acc[option.bucket].push(option);
      return acc;
    }, {});

    // Add order to each option
    if (grouped) {
      for (const bucket in grouped) {
        grouped[bucket].forEach((option, index) => {
          option.order = index; // Adding order, starting from 1
        });
      }
    }

    return grouped;
  }, [statusField]);

  // Clear all selected options
  const handleClearSelected = () => {
    setStatusFilterOptions([]);
    setSelectedBuckets([]);
  };

  const handleAddNoStatus = () => {
    // if it's in the list, remove it
    if (statusFilterOptions?.includes('no_status')) {
      const newStatusFilterOptions = statusFilterOptions?.filter(
        (status) => status !== 'no_status'
      );
      setStatusFilterOptions(newStatusFilterOptions);
    } else {
      setStatusFilterOptions([...statusFilterOptions, 'no_status']);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  if (isLoadingObjects || isLoadingFields) return null;

  return (
    <Dropdown>
      <IconButton
        id={`${table}-status-filter-menu-icon-button`}
        variant="soft"
        color="neutral"
        sx={{
          fontWeight: 900,
        }}
        onClick={handleClick}
      >
        <FilterAltRoundedIcon fontSize="small" />
      </IconButton>
      <Menu
        id={`${table}-status-filter-menu`}
        placement="bottom-end"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{
          boxShadow: 'sm',
          flexGrow: 0,
          overflow: 'auto',
          paddingBottom: 0,
          width: '14rem',
        }}
        ref={menuRef}
      >
        <List size="sm">
          <ListItem
            id={`${table}-status-filter-menu-item-no-status`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handleAddNoStatus}
          >
            <Checkbox
              size="sm"
              value={'no_status'}
              checked={statusFilterOptions?.includes('no_status')}
              onChange={handleAddNoStatus}
            />
            <GridStatusFilterMenuHeader bucket={'no_status'} />
          </ListItem>
          {groupedOptions &&
            buckets?.map((bucket, index) => {
              return (
                <GridStatusFilterMenuGroup
                  key={index}
                  bucket={bucket}
                  groupedOptions={groupedOptions}
                  statusField={statusField}
                />
              );
            })}
          <ListItemButton
            id={`${table}-status-filter-menu-item-clear-selected`}
            color="neutral"
            sx={{
              fontSize: '0.875rem',
              borderTop: `1px solid ${grey[300]}`,
              marginTop: '0.5rem',
              padding: '0.5rem 0.75rem',
              fontWeight: 500,
            }}
            onClick={handleClearSelected}
          >
            Clear Selected
          </ListItemButton>
        </List>
      </Menu>
    </Dropdown>
  );
};

export default GridStatusFilterMenu;
