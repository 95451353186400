import { useLogging, useNotification } from 'src/context';
import { useAddMapping, useUpdateMapping } from 'src/hooks';
import { useRouter } from 'next/router';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid } from '@mui/material';
import {
  MultiStepDialog,
  ControlledInput,
  ControlledSelect,
  ControlledRow,
  ControlledRadioCard,
  ControlledSwitch,
} from 'src/components';
import { medscoutOptions, crmOptions } from 'src/components/Settings';
import MappingForms from 'src/components/Settings/data/MappingsForms.json';
import { useState } from 'react';
import { useIntegrations } from 'src/components/Settings/Integrations';

interface AddEditMappingDialogProps {
  open: boolean;
  onClose: () => void;
}

const AddEditMappingDialog = ({ open, onClose }: AddEditMappingDialogProps) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { integration } = useRouter().query as { integration: string };
  const { mappings, currentMapping } = useIntegrations();
  const { mutateAsync: addMapping, isLoading } = useAddMapping();
  const { mutateAsync: updateMapping, isLoading: isUpdateLoading } =
    useUpdateMapping();
  const [activeStep, setActiveStep] = useState(0);

  const isEdit = !!currentMapping?.id;
  const formType = isEdit ? 'edit' : 'add';
  const steps = MappingForms[formType]?.steps;
  const defaultValues = isEdit
    ? currentMapping
    : {
        medscout_object_type: '',
        crm_object_type: '',
        label: '',
        form_type: 'standard',
      };

  const methods = useForm({
    values: {
      ...defaultValues,
    },
  });

  const {
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
    formState: { isValid },
  } = methods;

  // Verify mapping does not already exist
  function verifyMapping() {
    const { medscout_object_type, crm_object_type } = watch();
    if (!medscout_object_type || !crm_object_type) return;
    // Verify mapping does not
    const mappingExists = mappings.find(
      (mapping) =>
        mapping.medscout_object_type?.toLowerCase() ===
          medscout_object_type?.toLowerCase() &&
        mapping.crm_object_type?.toLowerCase() ===
          crm_object_type?.toLowerCase()
    );

    if (mappingExists) {
      setError('medscout_object_type', {
        type: 'validate',
        message: 'Object Type Pairing Already Exists',
      });

      return false;
    }
    clearErrors('medscout_object_type');
    return true;
  }

  async function onSubmit(data: MedScout.Mapping) {
    if (!data) return;
    try {
      if (isEdit) {
        await updateMapping(data);
        log.event(`Updated Mapping`, {
          ...data,
        });

        setNotification({
          title: 'Mapping Updated',
          message: 'Mapping has been updated',
          type: 'success',
        });
      } else {
        // add crm into data
        const newData = {
          ...data,
          crm: integration,
        };
        await addMapping(newData);
        log.event(`New Mapping Added`, {
          ...newData,
        });

        setNotification({
          title: 'Mapping Added',
          message: 'Mapping has been added',
          type: 'success',
        });
      }
    } catch (err: any) {
      setNotification({
        title: 'Error',
        message:
          err?.error_message ||
          'An error occurred while adding the mapping, please try again.',
        type: 'error',
      });
      log.exception('Error', {
        tags: {
          source: 'AddEditMappingDialog',
          isEdit,
        },
      });
    }
    handleClose();
  }

  function handleClose() {
    reset();
    onClose();
    setActiveStep(0);
  }

  const mappingType = watch('form_type');
  const isLastStep = isEdit || activeStep === steps?.length - 1;
  const currentFields =
    activeStep === 0 || isLastStep
      ? steps[activeStep]
      : steps[activeStep][mappingType] || [];
  const loading = isLoading || isUpdateLoading;

  const selectionOptionsMap = {
    medscout_object_type: medscoutOptions,
    crm_object_type: crmOptions[integration],
  };

  const inputKeyMap = {
    text: ControlledInput,
    select: ControlledSelect,
    row: ControlledRow,
    radio: ControlledRadioCard,
    switch: ControlledSwitch,
  };

  return (
    <MultiStepDialog
      title={`${isEdit ? 'Edit' : 'Add'} Mapping`}
      steps={steps?.length > 1 ? steps.map((step) => step.title) : []}
      open={open}
      onClose={handleClose}
      maxWidth={'sm'}
      fullWidth
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      disabled={!isValid}
    >
      <FormProvider {...methods}>
        <Grid container spacing={1}>
          {currentFields?.content.map((input, index) => {
            const InputComponent = inputKeyMap[input.type];
            const md = activeStep === 0 || isLastStep ? 12 : 6;
            return (
              <Grid item xs={12} md={md} key={index}>
                <InputComponent
                  key={index}
                  {...input}
                  options={selectionOptionsMap[input.name]}
                  rules={{
                    required: input.rules?.required,
                    validate: input.rules?.validate ? verifyMapping : null,
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </FormProvider>
    </MultiStepDialog>
  );
};

export default AddEditMappingDialog;
