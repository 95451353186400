import { request } from '../request';

interface GetProps {
  id: string;
  queryParams: string;
  savedSearchId: string;
}

interface GetPayerMixByCodeProps {
  id: string;
  queryParams: string;
}

export const getPayerMix = async ({
  id,
  queryParams,
}: GetPayerMixByCodeProps) => {
  return await request({
    url: `v1/providers/${id}/payer-mix-by-code/${queryParams}`,
    method: 'GET',
  });
};

export const getPayerMixReimbursement = async ({
  id,
  savedSearchId,
  queryParams,
}: GetProps) => {
  return await request({
    url: `v1/providers/${id}/saved-search/${savedSearchId}/reimbursement/${queryParams}`,
    method: 'GET',
  });
};
