import React from 'react';
import { useGetCrmObjects } from 'src/hooks';
import { useIntegrations } from 'src/components/Settings/Integrations/context';
import { CircularProgress } from 'src/components';

const CRMApiCode = ({ ...props }) => {
  const { row, field } = props;

  const { currentMapping, currentIntegration } = useIntegrations();

  const isThirdParty = currentMapping?.crm === 'THIRD_PARTY';
  // Get object type and api code from field value
  const objectTypeMap = {
    crm_api_code: {
      objectType: currentMapping?.crm_object_type,
      api_code: row.crm_api_code,
    },
    'extra.search_label': {
      objectType: row.extra?.crm_object_type,
      api_code: row.extra?.search_label,
    },
    'extra.value_field': {
      objectType: row.extra?.crm_object_type,
      api_code: row.extra?.value_field,
    },
  };

  // Get crm objects based on object type
  const { data: crmObjects, isLoading } = useGetCrmObjects({
    crmType: objectTypeMap[field]?.objectType,
    crm: currentIntegration,
    writable_only: false,
    isThirdParty,
  });

  // Find crm object based on api code
  const crmObject = crmObjects?.find(
    (crmObject) => crmObject.code === objectTypeMap[field]?.api_code
  );

  return (
    <div className="flex items-center w-full h-full text-sm text-neutral-900">
      {isLoading ? (
        <CircularProgress color="neutral" size="sm" />
      ) : (
        <>{isThirdParty ? row?.crm_api_code : crmObject?.label}</>
      )}
    </div>
  );
};

export default CRMApiCode;
