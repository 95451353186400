import React, { useMemo } from 'react';
import * as d3 from 'd3';

export interface DataItem extends d3.SimulationNodeDatum {
  name: string;
  value: number;
  color?: string;
  diameter?: number;
  fx?: number;
  fy?: number;
}

export interface MedBubbleProps {
  data: DataItem;
  circleProps?: React.SVGProps<SVGCircleElement>;
  textProps?: React.SVGProps<SVGTextElement>;
}

const computeDiameter = (value: number, scaleFactor = 50) => {
  const computed = 2 * Math.sqrt(value * scaleFactor);
  return Math.max(60, computed);
};

/**
 * Truncates a string to fit within maxWidth (in pixels) using a canvas for measurement.
 */
const truncateText = (
  text: string,
  fontSize: number,
  maxWidth: number,
  fontFamily = 'sans-serif'
) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return text;
  context.font = `${fontSize}px ${fontFamily}`;
  let truncated = text;
  if (context.measureText(truncated).width <= maxWidth) return truncated;
  while (
    truncated.length > 0 &&
    context.measureText(truncated + '…').width > maxWidth
  ) {
    truncated = truncated.slice(0, -1);
  }
  return `${truncated.trim()}…`;
};

const MedBubble: React.FC<MedBubbleProps> = ({
  data,
  circleProps,
  textProps,
}) => {
  // Compute diameter (or use stored value), ensuring a minimum size.
  const diameter = data.diameter ?? computeDiameter(data.value, 75);

  const radius = diameter / 2;

  // Font sizes scale with the bubble's diameter.
  const valueFontSize = diameter * 0.2; // for numeric value
  const percentFontSize = valueFontSize * 0.75; // for "%" symbol
  const nameFontSize = diameter * 0.15; // for bubble name

  // Use 80% of the bubble's diameter as the maximum width for the name.
  const maxNameWidth = diameter * 0.85;
  const nameText = useMemo(
    () => truncateText(data.name, nameFontSize, maxNameWidth, 'sans-serif'),
    [data.name, nameFontSize, maxNameWidth]
  );

  return (
    <g transform={`translate(${data.x ?? 0}, ${data.y ?? 0})`}>
      <circle
        cx={0}
        cy={0}
        r={radius}
        fill={data.color}
        stroke="none"
        {...circleProps}
      />
      <text
        x={0}
        y={-valueFontSize * 0.25}
        textAnchor="middle"
        dominantBaseline="middle"
        fill="#fff"
        {...textProps}
      >
        <tspan fontSize={valueFontSize} fontWeight="800">
          {data.value}
        </tspan>
        <tspan
          dx="2"
          dy="-0.3em"
          fontSize={percentFontSize}
          fontWeight="normal"
        >
          %
        </tspan>
      </text>
      <text
        x={0}
        y={valueFontSize * 0.75}
        textAnchor="middle"
        dominantBaseline="middle"
        fill="#fff"
        fontSize={nameFontSize}
        fontWeight="400"
        {...textProps}
      >
        {nameText}
      </text>
    </g>
  );
};

export default MedBubble;
