import React, { useMemo } from 'react';
import {
  FormProvider,
  useForm,
  useFieldArray,
  UseFieldArrayReturn,
} from 'react-hook-form';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@mui/joy';
import { useLogging } from 'src/context';
import {
  useGetFieldDefinitions,
  useGetObjectDefinitionsList,
  useUpdateFieldDefinitionDetails,
} from 'src/hooks';
import { buckets } from 'src/components/MedRM/constants';
import { Reorder } from 'framer-motion';
import {
  StatusAdminGridHeader,
  StatusAdminGridFooter,
  StatusAdminItem,
} from '../StatusAdminGrid';
import { LoadingIndicator } from 'src/components';

const typeMap = {
  todo: 'To-Do',
  in_progress: 'In Progress',
  done: 'Done',
};

interface FormValues {
  options: MedScout.Status[];
}

const StatusAdmin = () => {
  const log = useLogging();

  const { data: objectDefinitions, isLoading: isObjectLoading } =
    useGetObjectDefinitionsList();
  const { data: fieldDefinitions, isLoading: isLoadingFields } =
    useGetFieldDefinitions({
      objectId: objectDefinitions?.results?.[0]?.id,
    });
  const { mutateAsync: updateFieldDefinition, isLoading: isUpdating } =
    useUpdateFieldDefinitionDetails();

  const statusField = useMemo(() => {
    return fieldDefinitions?.results?.find(
      (field) => field?.label?.toLowerCase() === 'status'
    );
  }, [fieldDefinitions]);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      options: statusField?.options || [],
    },
    values: {
      options: statusField?.options || [],
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, isLoading },
  } = methods;

  const { fields, append, remove, update }: UseFieldArrayReturn<FormValues> =
    useFieldArray<FormValues>({
      control,
      name: 'options',
    });

  const handleReorder = (options) => {
    // remove all with the same bucket
    const currentOptions = methods.getValues('options');
    const bucket = options[0].bucket;
    const filteredOptions = currentOptions.filter(
      (opt) => opt.bucket !== bucket
    );

    const updateOptions = options.map((option, index) => {
      return { ...option, order: index };
    });
    methods.setValue('options', [...filteredOptions, ...updateOptions]);

    [...filteredOptions, ...updateOptions].forEach((option, index) => {
      update(index, option);
    });
  };

  const handleSubmitForm = async (data) => {
    try {
      await updateFieldDefinition({
        data: {
          ...statusField,
          options: data.options,
        },
        objectId: objectDefinitions?.results?.[0]?.id,
        id: statusField?.id,
      });

      // reset the form
      reset({
        options: data.options,
      });
      log.event('Status field options updated', {
        source: 'StatusAdmin',
        options: data.options,
      });
    } catch (err) {
      log.exception('Error updating status field options', {
        tags: {
          source: 'StatusAdmin',
          err,
        },
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        margin: '1rem 0',
        position: 'relative',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography level="h3">Status Setup</Typography>
          <Typography level="body-sm">
            Add, edit or delete your statuses.
          </Typography>
        </Box>
        <Button
          variant="solid"
          color="primary"
          size="sm"
          loading={isUpdating || isLoading}
          sx={{
            height: '2.5rem',
            minHeight: '2.5rem',
            maxHeight: '2.5rem',
          }}
          onClick={handleSubmit(handleSubmitForm)}
          disabled={!isDirty || !isValid}
        >
          Save
        </Button>
      </Box>

      <FormProvider {...methods}>
        {!isLoadingFields &&
        !isObjectLoading &&
        statusField?.options?.length === 0 ? (
          <Typography level="body-sm">
            There are no options available for this field.
          </Typography>
        ) : (
          <>
            {buckets?.map((type, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    padding: '0.5rem 0',
                  }}
                >
                  <Typography level="title-sm">{typeMap[type]}</Typography>
                  <Container
                    maxWidth={false}
                    disableGutters
                    sx={{
                      borderRadius: '0.5rem',
                      border: '1px solid #CDD7E1',
                      background: '#FFF',
                    }}
                  >
                    <StatusAdminGridHeader />
                    {/* rows */}
                    <Reorder.Group
                      axis="y"
                      values={fields?.filter((opt) => {
                        return opt.bucket === type;
                      })}
                      onReorder={handleReorder}
                      style={{ padding: 0, margin: 0 }}
                    >
                      <Grid container>
                        {fields?.map((option, index) => {
                          if (option?.bucket !== type) return null;
                          const currentOptions = methods
                            .getValues('options')
                            .filter((opt) => {
                              return opt.bucket === type;
                            });

                          // Check if the option is the last in the list for the bucket
                          const isLast =
                            currentOptions[currentOptions.length - 1]?.value ===
                            option?.value;
                          const isDraggable = currentOptions.length > 1;
                          return (
                            <StatusAdminItem
                              key={option?.value}
                              index={index}
                              option={option}
                              onDelete={() => {
                                remove(index);
                              }}
                              isLast={isLast}
                              isDraggable={isDraggable}
                            />
                          );
                        })}
                      </Grid>
                      <StatusAdminGridFooter
                        options={fields?.filter((opt) => {
                          return opt.bucket === type;
                        })}
                        onAdd={(newOption) => {
                          append({
                            ...newOption,
                          });
                        }}
                        bucket={type}
                      />
                    </Reorder.Group>
                  </Container>
                </Box>
              );
            })}
          </>
        )}
      </FormProvider>
      {(isObjectLoading || isLoadingFields) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
            height: 'calc(100vh - 64px)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            width: '100%',
          }}
        >
          <LoadingIndicator />
        </Box>
      )}
    </Box>
  );
};

export default StatusAdmin;
