import useSWR from 'swr';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { getPayerMix } from 'src/api/payerMix';

interface UseNewGetPayerMixProps {
  id: string;
  savedSearchId: string;
  groupBy: string;
}

const useNewGetPayerMix = ({
  id,
  savedSearchId,
  groupBy,
}: UseNewGetPayerMixProps) => {
  const queryParams = getSearchQueryParamsForAPI({
    saved_search_id: savedSearchId,
    group_by: groupBy,
  });

  const shouldFetch = !!id;

  return useSWR(
    shouldFetch ? [QUERY_KEYS.PAYER_MIX, id, savedSearchId, queryParams] : null,
    () => getPayerMix({ id, queryParams }),
    {
      keepPreviousData: true,
    }
  );
};

export default useNewGetPayerMix;
