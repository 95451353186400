import React from 'react';
import { Box } from '@mui/material';
import { TypeIcon } from 'src/components';

const LabelCell = ({ row }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <TypeIcon type={row.medscout_object_type} size="sm" />
      {row.label}
    </Box>
  );
};

export default LabelCell;
