import React, { useCallback } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { titleCase } from 'src/utils';
import { getComparisonDetails } from '../../utils';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'src/components';

interface ReimbursementRowProps {
  payer: any;
  onResize: ({
    columnId,
    isInnerTable,
  }: {
    columnId: string;
    isInnerTable?: boolean;
  }) => React.CSSProperties;
  isSelected: boolean;
}

const ReimbursementRow = ({
  payer,
  onResize,
  isSelected,
}: ReimbursementRowProps) => {
  const type = titleCase(payer?.payer_type);
  const name = titleCase(payer?.payer_name);

  const getMetrics = useCallback(
    (item: {
      avg_reimbursement_predicted: number;
      state_avg_reimbursement_predicted: number;
    }) => ({
      ...getComparisonDetails(
        item.avg_reimbursement_predicted,
        item.state_avg_reimbursement_predicted
      ),
    }),
    []
  );

  const { diffArrowIcon, diffArrowColor } = getMetrics(payer);

  // Check if avg_reimbursement_predicted is zero
  const avgReimbursementIsZero = payer.avg_reimbursement_predicted === 0;

  // Check if there's no absolute difference between avg_reimbursement_predicted and state_avg_reimbursement_predicted
  const avgReimbursementDifferenceIsZero =
    Math.abs(
      payer.avg_reimbursement_predicted -
        payer.state_avg_reimbursement_predicted
    ) === 0;

  // Determine if we should hide the actual reimbursement value and show an icon instead
  const hideReimbursementValue = avgReimbursementIsZero;
  
  // Determine if we should hide the comparison with state average
  const hideStateComparison = 
    avgReimbursementIsZero || avgReimbursementDifferenceIsZero;

  const idName = name?.replace(/ /g, '-')?.toLowerCase();
  return (
    <div
      id={`reimbursement-table-payer-row-${type}-${idName}`}
      key={name}
      data-testid={`reimbursement-table-payer-row-${type}-${idName}`}
      className="flex items-center h-8 relative"
    >
      {isSelected && (
        <div className="absolute inset-0 animate-highlight rounded-md -mx-2 z-0" />
      )}

      <div className="flex gap-10 w-full items-center relative">
        <div
          style={onResize({
            columnId: 'payer',
            isInnerTable: true,
          })}
          className="overflow-hidden"
        >
          <span className="block whitespace-nowrap overflow-hidden text-ellipsis font-normal text-primary">
            {name}
          </span>
        </div>
        <div
          style={onResize({
            columnId: 'placeOfService',
            isInnerTable: true,
          })}
          className="overflow-hidden"
        >
          <span className="block whitespace-nowrap overflow-hidden text-ellipsis">
            {titleCase(payer.place_of_service)}
          </span>
        </div>

        <div className="flex-1 text-right font-normal">
          {hideReimbursementValue ? (
            <Tooltip
              content="Not enough evidence for an accurate comparison"
              position="top"
              slotProps={{
                containerClassName: "max-w-56 whitespace-normal bg-neutral-900 text-white"
              }}
            >
              <span>
                <Icon
                  icon={faCircleQuestion}
                  size="sm"
                  className="text-neutral-600 cursor-pointer"
                />
              </span>
            </Tooltip>
          ) : (
            `$${payer.avg_reimbursement_predicted?.toLocaleString()}`
          )}
        </div>

        <div className="flex-1 text-right font-normal">
          <span className="flex items-center justify-end">
            {hideStateComparison ? (
              ''
            ) : (
              <>
                <Icon
                  icon={diffArrowIcon}
                  size="sm"
                  className={diffArrowColor}
                />
                $
                {Math.abs(
                  payer.avg_reimbursement_predicted -
                    payer.state_avg_reimbursement_predicted
                ).toLocaleString()}
              </>
            )}
          </span>
        </div>

        <div className="flex-1 text-right font-normal">
          {payer.percent_of_total}%
        </div>
      </div>
    </div>
  );
};

export default ReimbursementRow;
