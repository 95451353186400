import React from 'react';
import { Grid, Typography } from '@mui/joy';

const StatusAdminGridHeader = () => {
  return (
    <Grid
      container
      sx={{ borderBottom: `1px solid #CDD7E1`, height: '2.5rem' }}
      alignItems="center"
    >
      <Grid xs={1} md={0.5}></Grid>
      <Grid xs={5} md={5.5} sx={{ padding: '0 0.5rem' }}>
        <Typography level="title-sm">Status Label</Typography>
      </Grid>
      <Grid xs={4} sx={{ padding: '0 0.5rem' }}>
        <Typography level="title-sm">Preview</Typography>
      </Grid>
      <Grid xs={2}></Grid>
    </Grid>
  );
};

export default StatusAdminGridHeader;
