import React, { useState } from 'react';
import { ClickAwayListener, Search } from 'src/components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faHospital,
  faCaretDown,
  faSearch,
  faCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { useDebounce } from 'use-debounce';
import { titleCase } from 'src/utils';
import { useProfile, useUserSettings, useProspectSearch } from 'src/context';
import { useGetPayerMixReimbursement } from 'src/hooks';

interface Payer {
  payer_name: string;
  payer_type: string;
}

const AllPayersSelect = () => {
  const { state: userSettingsState, dispatch: userSettingsDispatch } =
    useUserSettings();
  const { details } = useProfile();
  const { prospectSearch } = useProspectSearch();

  const { data: reimbursementData } = useGetPayerMixReimbursement({
    id: details?.provider_id,
    savedSearchId:
      userSettingsState?.selectedSavedSearch?.id ?? prospectSearch?.id,
  });

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);

  const groupedData = React.useMemo(() => {
    if (!reimbursementData?.results) return [];
    // Group by payer_type first, ensuring unique payer names
    return reimbursementData?.results?.reduce((acc, item) => {
      item.payers.forEach((payer) => {
        if (!acc[payer.payer_type]) {
          acc[payer.payer_type] = [];
        }
        // Check if payer with same name already exists in this group
        const exists = acc[payer.payer_type].some(
          (p) => p.payer_name.toLowerCase() === payer.payer_name.toLowerCase()
        );
        if (!exists) {
          acc[payer.payer_type].push(payer);
        }
      });
      return acc;
    }, {} as Record<string, any[]>);
  }, [reimbursementData]);

  const sortedPayers = React.useMemo(() => {
    return Object.entries(groupedData || {}).map(([type, payers]) => ({
      type,
      payers: (payers as Payer[])
        ?.filter((payer) =>
          payer.payer_name.toLowerCase().includes(debouncedSearch.toLowerCase())
        )
        ?.sort((a, b) => a.payer_name.localeCompare(b.payer_name)),
    }));
  }, [groupedData, debouncedSearch]);

  const handlePayerChange = (payer: any) => {
    if (!payer) return;

    // Check if the site exists in the reimbursement data
    const siteDoesExist = reimbursementData?.results?.some((result) =>
      result.payers.some(
        (p) => p.place_of_service_code === userSettingsState?.selectedSite
      )
    );

    userSettingsDispatch({
      type: 'UPDATE_USER_SETTINGS',
      payload: {
        selectedPayer: payer,
        // If the site does not exist, use the payer's site
        selectedSite: siteDoesExist
          ? userSettingsState?.selectedSite
          : payer.place_of_service_code ?? null,
      },
    });
    setOpen(false);
    setSearch('');
  };

  const selectedPayer = userSettingsState?.selectedPayer ?? null;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="relative">
        <button
          data-testid="all-payers-select-button"
          type="button"
          onClick={() => setOpen(!open)}
          className="flex items-center gap-2 py-1.5 px-3 max-w-64 min-w-32 h-8 border border-neutral-300 rounded-lg bg-white whitespace-nowrap"
        >
          <span className="text-sm font-medium text-gray-700 ">Payer:</span>
          <span className="text-sm font-medium text-gray-700 overflow-hidden text-ellipsis">
            {titleCase(
              userSettingsState?.selectedPayer?.payer_name ?? 'All Payers'
            )}
          </span>
          <Icon icon={faCaretDown} className="text-gray-500 shrink-0" />
        </button>
        {open && (
          <div className="absolute z-10 mt-1 min-w-48 max-h-64 overflow-y-auto bg-white border border-neutral-300 rounded-lg shadow-md">
            <div className="flex flex-col gap-2 p-4 border-b border-neutral-300">
              <label className=" text-sm font-semibold text-gray-600">
                Find a payer
              </label>
              <Search
                onSearch={setSearch}
                placeholder="Search..."
                size="sm"
                variant="outlined"
              />
            </div>
            <div className="flex flex-col gap-2 p-4">
              {sortedPayers.map(({ type, payers }) => {
                if (payers.length === 0) return null;
                return (
                  <div key={type} className="flex flex-col gap-2">
                    <div className="text-sm font-semibold text-gray-600">
                      {type}
                    </div>
                    {payers.map((payer, index) => (
                      <label
                        data-testid={`all-payers-select-option-${payer.payer_name}`}
                        key={index}
                        className="flex items-center gap-1 cursor-pointer whitespace-nowrap"
                        onClick={() => handlePayerChange(payer)}
                      >
                        <Icon
                          icon={faCheck}
                          size="sm"
                          className={
                            selectedPayer?.payer_name === payer.payer_name
                              ? 'text-sky-700'
                              : 'text-transparent'
                          }
                        />
                        <span className="text-sm text-gray-700">
                          {titleCase(payer.payer_name)}
                        </span>
                      </label>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default AllPayersSelect;
