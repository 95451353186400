import React, { useEffect } from 'react';

import {
  CompanyTable,
  AdminSearch,
  UserTable,
} from 'src/components/Settings/Users/components/UserManagement';
import { useCompany } from 'src/hooks';
import { useAuth, useSite } from 'src/context';
import { UserProfile } from '../UserProfile';

const UserDashboard = () => {
  const { user } = useAuth();
  const { state, dispatch } = useSite();
  const { currentCompany, currentUser } = state.companyAdmin;

  // may be on a page with no company selected, then it would just be users company
  const { data: company } = useCompany(user.company.id?.toString());
  const isMedScoutUser =
    user?.is_superuser && company?.name?.includes('MedScout');
  const isManager = user?.permissions?.is_manager;
  const isSupportUser = user?.permissions?.is_support_user;

  useEffect(() => {
    if (!isSupportUser) return;
    dispatch({
      type: 'SET_CURRENT_COMPANY',
      payload: company,
    });
  }, [company, dispatch, isSupportUser]);

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full">
        {/* Case 1: No company or user selected */}
        {!currentCompany && !currentUser && isMedScoutUser && (
          <div className="flex flex-col gap-2">
            <AdminSearch />
            <CompanyTable />
          </div>
        )}

        {/* Case 2: No company or user selected, but is admin */}
        {!currentCompany && !currentUser && !isMedScoutUser && isManager && (
          <UserTable />
        )}

        {/* Case 3: Company selected but no user */}
        {currentCompany &&
          !currentUser &&
          (isMedScoutUser || isManager || isSupportUser) && <UserTable />}

        {/* Case 4: Both company and user selected */}
        {currentCompany && currentUser && <UserProfile />}
      </div>
      <div className="h-48" />
    </div>
  );
};

export default UserDashboard;
