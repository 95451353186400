import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Tooltip } from 'src/components';
import { GeoJsonLayer } from '@deck.gl/layers';
import type { GeoJSON } from 'geojson';
import { useLogging, useMedScoutMap } from 'src/context';
import { MedMapDeckOverlay } from './components';
import { useGetGeoJsonData } from './hooks';
import { CircleContent, DrawContent } from '../Geometry';
import { HeatMapLayer } from '../MedHeatMapMarkers';

const MedMapContent = () => {
  const log = useLogging();

  const {
    heatMap,
    isEditing,
    isCreating,
    drawingMode,
    territoryPolygons,
    setTerritoryPolygons,
    clickedFeatures,
    setClickedFeatures,
  } = useMedScoutMap();

  // Get the geojson data
  const { geoJsonData, existingGeoJsonData } = useGetGeoJsonData();
  const [hoveredFeature, setHoveredFeature] = useState<string | null>(null);
  const [tooltipInfo, setTooltipInfo] = useState({ x: 0, y: 0, name: '' });

  useEffect(() => {
    if (!isEditing && !isCreating) {
      setTooltipInfo({
        x: 0,
        y: 0,
        name: '',
      });
    }
    setHoveredFeature(null);
    return () => {
      setTooltipInfo({
        x: 0,
        y: 0,
        name: '',
      });
      setHoveredFeature(null);
    };
  }, [isEditing, isCreating]);

  // Handles hovering over a feature
  const handleHover = useCallback((info) => {
    if (info.object) {
      setHoveredFeature(info.object.properties.id);
      setTooltipInfo({
        x: info.x,
        y: info.y,
        name: info.object.properties.name || info.object.properties.id,
      });
    } else {
      setHoveredFeature(null);
      setTooltipInfo({
        x: 0,
        y: 0,
        name: '',
      });
    }
  }, []);

  // Adds or removes the clicked feature from the list
  const handleClick = useCallback(
    (info) => {
      // does info.object.properties.id exist in clickedFeatures, if yes remove it, if no add it
      const newClickedFeatures = clickedFeatures.includes(
        info.object.properties.id
      )
        ? clickedFeatures.filter((id) => id !== info.object.properties.id)
        : [...clickedFeatures, info.object.properties.id];

      // set the new clicked features
      setClickedFeatures(newClickedFeatures);

      // add to territory polygons if the id is not in the list
      const newTerritoryPolygons = territoryPolygons[drawingMode] || [];
      if (newTerritoryPolygons.includes(info.object.properties?.id)) {
        const index = newTerritoryPolygons.indexOf(info.object.properties?.id);
        newTerritoryPolygons.splice(index, 1);
      } else {
        newTerritoryPolygons.push(info.object.properties?.id);
      }

      // set the new territory polygons
      setTerritoryPolygons({
        [drawingMode]: newTerritoryPolygons,
      });

      log.event('territoryGeoJsonElementClicked', {
        selected: info.object,
        state: info?.object?.properties?.name,
        county: info?.object?.properties?.name,
        mode: drawingMode,
      });
    },
    [
      clickedFeatures,
      drawingMode,
      log,
      territoryPolygons,
      setClickedFeatures,
      setTerritoryPolygons,
    ]
  );

  const hexToRgba = useCallback(
    (hex, alpha = 255): [number, number, number, number] => {
      // Remove the # if it exists
      hex = hex.replace('#', '');

      // Check if the hex code is valid
      if (!/^([0-9A-Fa-f]{3}){1,2}$/.test(hex)) {
        throw new Error('Invalid hex color code');
      }

      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      if (hex.length === 3) {
        hex = hex
          .split('')
          .map((char) => char + char)
          .join('');
      }

      // Parse hex values
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      // Return the rgba string
      return [r, g, b, alpha];
    },
    []
  );

  // Memoize all layers to avoid unnecessary recalculations
  const allLayers = useMemo(() => {
    const layersToRender = [];
    const isNotDrawOrRadius =
      drawingMode !== 'DRAW' && drawingMode !== 'RADIUS';

    // Define the territory layer creation function inside useMemo
    const getDeckGlLayers = (data: GeoJSON | null) => {
      if (!data) return null;

      return [
        new GeoJsonLayer({
          id: 'geojson-layer',
          data,
          stroked: true,
          filled: true,
          extruded: false,
          wireframe: true,
          pointType: 'circle',
          lineWidthScale: 20,
          lineWidthMinPixels: 1,
          getFillColor: (d) => {
            const isHovered = hoveredFeature === String(d.properties.id);
            const isClicked = clickedFeatures.includes(d.properties.id);
            if (isClicked) return [2, 27, 156, 105];
            if (isHovered) return [0, 0, 0, 75];
            return [0, 0, 0, 35]; // Default color
          },
          getLineColor: [0, 0, 0, 100],
          getLineWidth: 1,
          getPointRadius: 200,
          getElevation: 0,
          pickable: isEditing || isCreating,
          onHover: handleHover,
          onClick: handleClick,
          parameters: {
            depthTest: false, // This ensures the lines render on top
          },
          updateTriggers: {
            getFillColor: [hoveredFeature, clickedFeatures],
            getLineColor: [hoveredFeature, clickedFeatures],
          },
        }),
      ];
    };

    // Define the existing territories layer creation function inside useMemo
    const getExistingTerritories = (data: GeoJSON | null) => {
      if (!data) return null;

      return [
        new GeoJsonLayer({
          id: 'existing-territory-layer',
          data,
          stroked: true,
          filled: true,
          extruded: false,
          wireframe: true,
          pointType: 'circle',
          lineWidthScale: 20,
          lineWidthMinPixels: 1,
          getFillColor: (d) => {
            const color = hexToRgba(
              d.properties.fillColor,
              d.properties.fillOpacity
            );
            return color || [0, 0, 0, 100];
          },
          getLineColor: (d) => {
            const color = hexToRgba(
              d.properties.strokeColor,
              d.properties.strokeOpacity
            );
            return color || [0, 0, 0, 100];
          },
          getLineWidth: (d) => d.properties.strokeWidth || 1,
          getPointRadius: 200,
          getElevation: 0,
          pickable: isEditing || isCreating,
        }),
      ];
    };

    // Show assigned territories first (underneath other layers)
    if (existingGeoJsonData && existingGeoJsonData.length > 0) {
      const territoriesToShow =
        (isEditing || isCreating) && existingGeoJsonData;

      const existingLayers = getExistingTerritories({
        type: 'FeatureCollection',
        features: territoriesToShow,
      });

      if (existingLayers) {
        layersToRender.push(...existingLayers);
      }
    }

    // Add territory selection layer on top if applicable
    if (isNotDrawOrRadius && geoJsonData.length > 0) {
      const territoryLayers = getDeckGlLayers({
        type: 'FeatureCollection',
        features: geoJsonData,
      });

      if (territoryLayers) {
        layersToRender.push(...territoryLayers);
      }
    }

    return layersToRender;
  }, [
    drawingMode,
    geoJsonData,
    existingGeoJsonData,
    hoveredFeature,
    clickedFeatures,
    isEditing,
    isCreating,
    handleHover,
    handleClick,
    hexToRgba,
  ]);

  const handleMouseLeave = useCallback(() => {
    setHoveredFeature(null);
    setTooltipInfo({
      x: 0,
      y: 0,
      name: '',
    });
  }, []);

  return (
    <div className="w-full h-full relative" onMouseLeave={handleMouseLeave}>
      {drawingMode === 'RADIUS' && <CircleContent />}
      {drawingMode === 'DRAW' && <DrawContent />}

      {/* Unified layer rendering with a single MedMapDeckOverlay */}
      {allLayers.length > 0 && <MedMapDeckOverlay layers={allLayers} />}

      {/* Heat map layer with opacity control */}
      {heatMap && <HeatMapLayer />}

      {/* PulseUI Tooltip */}
      <Tooltip
        content={tooltipInfo.name}
        position="top"
        isVisible={
          tooltipInfo.x > 0 && tooltipInfo.y > 0 && Boolean(tooltipInfo.name)
        }
        x={tooltipInfo.x}
        y={tooltipInfo.y + 15}
        slotProps={{
          containerClassName: 'bg-neutral-600 text-white font-semibold text-sm',
          containerStyle: {
            transform: 'translateX(-50%)',
          },
        }}
      >
        <span />
      </Tooltip>
    </div>
  );
};

export default MedMapContent;
