import { Box } from '@mui/material';
import React from 'react';
import { ControlledCheckbox } from 'src/components';

const ControlledCheckboxRow = ({ ...props }) => {
  if (!props) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      {props?.checkboxes?.map((checkbox, index) => (
        <ControlledCheckbox key={index} size="small" {...checkbox} />
      ))}
    </Box>
  );
};

export default ControlledCheckboxRow;
