import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Textarea from '@mui/joy/Textarea';
import { Button, Card, Link, Typography } from '@mui/joy';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { ErrorOutlined, OpenInNewRounded } from '@mui/icons-material';
import { useIntegrations } from 'src/components/Settings/Integrations';

const TestSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1.5rem',
  paddingTop: '0',
  width: '100%',
}));
const ResultSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  marginBottom: '0',
  paddingTop: '.5rem',
}));

const MappingTestingSection = ({
  testPushToCRMHandler,
  dryRunResponse,
  crmTestLink,
  isLoading,
  dryRun,
  hasOptions,
  testPushError,
}) => {
  const { currentMapping } = useIntegrations();
  const [isOpen, setIsOpen] = useState(false);
  const [payloadDisplay, setPayloadDisplay] = useState('{ }');

  useEffect(() => {
    setPayloadDisplay(dryRunResponse ? dryRunResponse : '{ }');
  }, [dryRunResponse]);

  const toggleAdvancedOptions = () => {
    setIsOpen(!isOpen);
  };

  const dryRunButtonLabel = hasOptions
    ? 'Choose Options & View Payload'
    : 'View Payload';
  const crmTypeLowercase: string = currentMapping?.crm?.toLowerCase() ?? '';
  const formattedCrmName =
    crmTypeLowercase.charAt(0).toUpperCase() + crmTypeLowercase.slice(1);

  return (
    <Card sx={{ maxWidth: '400px', height: 'fit-content', gap: 0 }}>
      <Typography level="title-lg">Test Your Configuration</Typography>
      <Typography level="body-sm">
        Send an example object to {formattedCrmName} to ensure your mapping is
        set correctly.
      </Typography>
      <Box sx={{ paddingTop: '1rem' }}>
        <Button
          size="sm"
          variant="soft"
          loading={!dryRun && isLoading}
          onClick={() => testPushToCRMHandler(false)}
          sx={{ marginRight: '1.5rem' }}
        >
          Send Test Object
        </Button>
        {crmTestLink && (
          <Link
            href={crmTestLink}
            target="_blank"
            rel="noopener"
            disabled={!crmTestLink}
          >
            <Typography variant="plain" level="body-md">
              View in {formattedCrmName} <OpenInNewRounded fontSize="small" />
            </Typography>
          </Link>
        )}
      </Box>
      {testPushError && (
        <Typography
          variant="plain"
          level="body-sm"
          color="danger"
          sx={{ paddingTop: '1rem' }}
          startDecorator={<ErrorOutlined />}
        >
          {testPushError}
        </Typography>
      )}
      <Button
        variant="plain"
        color="neutral"
        disabled={false}
        sx={{
          width: 'fit-content',
          padding: '.25rem',
          marginTop: `${testPushError ? '.5rem' : '1rem'}`,
        }}
        startDecorator={isOpen ? <FaAngleUp /> : <FaAngleDown />}
        onClick={toggleAdvancedOptions}
      >
        Advanced Options
      </Button>
      {isOpen && (
        <TestSection sx={{}}>
          <Typography level="body-sm">
            Use this tool to view the JSON payload that will be sent to{' '}
            {formattedCrmName}
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            loading={dryRun && isLoading}
            onClick={() => testPushToCRMHandler(true)}
            sx={{ width: 'fit-content', padding: '0.5rem', marginTop: '.5rem' }}
          >
            {dryRunButtonLabel}
          </Button>
          <ResultSection>
            <Typography sx={{ color: `${dryRunResponse ? '' : 'grey'}` }}>
              Payload
            </Typography>
            <Textarea
              sx={{
                marginTop: '.5rem',
                fontSize: '12px',
                paddingBottom: '0',
              }}
              id="standard-multiline-flexible"
              value={payloadDisplay}
              minRows={4}
              maxRows={8}
              readOnly
            />
          </ResultSection>
        </TestSection>
      )}
    </Card>
  );
};

export default MappingTestingSection;
