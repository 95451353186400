import React, { useCallback, useRef } from 'react';
import {
  AdvancedMarker,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { Box, Dropdown, Menu, Typography } from '@mui/joy';
import { useOnClickOutside } from 'src/hooks';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { MarkerCircle } from '../MarkerCircle';
import { useFlags } from 'launchdarkly-react-client-sdk';

type TreeMarkerProps = {
  feature: any;
  position: any;
  featureId: number;
  size: number;
  sizeAsText: string;
  isHovered?: boolean;
  onMarkerClick?: (marker: any, featureId: number) => void;
  onMouseOver?: (clusterId: number) => void;
  onMouseOut?: () => void;
  addToList?: (details: any) => void;
};

const FeatureMarker = ({
  feature,
  position,
  featureId,
  size,
  sizeAsText,
  isHovered = false,
  onMarkerClick,
  onMouseOut,
  onMouseOver,
  addToList,
}: TreeMarkerProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const { sameTabRouting } = useFlags();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isPoint =
    feature.geometry?.type === 'Point' &&
    feature?.details &&
    !feature.properties.count;

  const handleClick = useCallback(
    () => onMarkerClick && onMarkerClick(marker!, featureId),
    [onMarkerClick, marker, featureId]
  );

  const handleHoverMenu = (event: React.MouseEvent<HTMLElement>, featureId) => {
    onMouseOver(featureId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onMouseOut();
    setAnchorEl(null);
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  return (
    <AdvancedMarker
      ref={markerRef}
      zIndex={size}
      position={position}
      onClick={() => {
        handleClick();
      }}
      className="w-9 h-9 rounded-full cursor-pointer transition-opacity duration-800 animate-markerFade"
    >
      <div
        className="absolute w-full h-3 top-0 left-0 bg-transparent pointer-events-auto"
        onMouseOver={(e) => handleHoverMenu(e, featureId)}
      />
      <MarkerCircle
        id={`discovery-marker-circle-${feature?.details?.provider_id}`}
        featureId={featureId}
        sizeAsText={sizeAsText}
        isHovered={isHovered}
        onMouseOut={onMouseOut}
        handleHoverMenu={handleHoverMenu}
      />
      <Dropdown>
        <Menu
          id="discovery-feature-marker-menu"
          ref={menuRef}
          placement="top"
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && isHovered && isPoint}
          onMouseLeave={onMouseOut}
          className="shadow-sm flex-grow-0 max-h-72 overflow-auto"
        >
          <div className="flex items-center justify-between gap-2 p-2 border-b-1 border-neutral-100 last:border-none">
            <div className="flex flex-col">
              <a
                id={`discovery-feature-marker-menu-link-${feature?.details?.provider_id}`}
                href={`/profiles/${feature?.details?.content_type}/${feature?.details?.provider_id}`}
                target={sameTabRouting ? '_self' : '_blank'}
                rel="noopener noreferrer"
                className="w-full no-underline"
              >
                <span className="text-sm text-sky-800 no-underline flex items-center gap-2 hover:text-sky-900">
                  {feature?.details?.name}
                  <Icon icon={faExternalLinkAlt} size="sm" color="inherit" />
                </span>
              </a>
              <span className="text-xs">{feature?.details?.specialty}</span>
              <span className="text-xs">
                {feature?.details?.address_line_1}
              </span>
              <span className="text-xs">
                {feature?.details?.address_line_2}
              </span>
              <span className="text-xs">
                {feature?.details?.city}, {feature?.details?.state}
              </span>
              <span
                id={`discovery-feature-marker-menu-add-to-list-${feature?.details?.provider_id}`}
                className="text-xs text-sky-800 no-underline w-full flex items-center gap-2 hover:cursor-pointer hover:text-sky-900"
                onClick={(e) => {
                  e.stopPropagation();
                  addToList(feature?.details);
                }}
              >
                Add to List
              </span>
            </div>
          </div>
        </Menu>
      </Dropdown>
    </AdvancedMarker>
  );
};

export default FeatureMarker;
