import { useState, useEffect, useRef } from 'react';
import {
  Button,
  ButtonGroup,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

interface SaveComboProps {
  startIcon: React.ReactNode;
  onSave: () => void;
  onSaveAs: () => void;
  canEdit?: boolean;
  loading: boolean;
  size: 'small' | 'medium' | 'large';
  disabled?: boolean;
}

/**
 * SaveCombo
 * @description A combo button that allows the user to update or create a new search.
 *
 * @param {*} {
 *   startIcon,
 *   onSave,
 *   onSaveAs,
 *   size,
 *   ...props
 * }
 * @param {boolean} { canEdit, loading }
 * @return {*}
 */
const SaveCombo: React.FC<SaveComboProps> = ({
  startIcon,
  onSave,
  onSaveAs,
  canEdit = true,
  loading,
  size,
  disabled = false,
  ...props
}) => {
  const options = ['Save as...', 'Update'];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!canEdit) {
      setSelectedIndex(0);
    }
  }, [canEdit]);

  function handleSelectOption(e, index) {
    setSelectedIndex(index);
    setOpen(false);
  }

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleClose(event: Event) {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  }

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef} size={size}>
        <LoadingButton
          id={`search-shade-${options[selectedIndex]}-button`}
          aria-label="Search Shade Save"
          loading={loading}
          variant="contained"
          color="primary"
          startIcon={startIcon}
          onClick={selectedIndex === 0 ? onSaveAs : onSave}
          disabled={disabled}
          {...props}
        >
          {options[selectedIndex]}
        </LoadingButton>
        {!!onSave && (
          <Button onClick={handleToggle}>
            <Icon icon={faCaretDown} />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        disablePortal
        sx={{ zIndex: 1 }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="split-button-menu" autoFocusItem>
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  selected={index === selectedIndex}
                  onClick={(event) => handleSelectOption(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default SaveCombo;
