import {
  GridColDef,
  GridValueGetterParams,
  DataGridPremiumProps,
} from '@mui/x-data-grid-premium';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { format as formatDate } from 'date-fns';
import CrmCell from './CrmCell';
import NameCell from './NameCell';
import ProviderNameCell from './ProviderNameCell';
import TagCell from './TagCell';
import AddressCell from './AddressCell';
import PhoneCell from './PhoneCell';
import SearchVolumeCell from './SearchVolumeCell';
import StatusCell from './StatusCell';
import CrmLinkCell from './CrmLinkCell';
import CommandCell from './CommandCell';
import StreetAddressCell from './StreetAddressCell';
import ListNameCell from './ListNameCell';
import VolumeHeader from './VolumeHeader';
import TrendVolumeCell from './TrendVolumeCell';
import OverlayColumn from './OverlayColumn';
import SpecialityCell from './SpecialityCell';
import TrendVolumeHeader from './TrendVolumeHeader';
import Header from './Header';
import { Typography } from '@mui/joy';
import StringCell from './StringCell';
import SecondaryAddressCell from './SecondaryAddressCell';
import HeaderVolume from './HeaderVolume';
import ListItemsCell from './ListItemsCell';
import StandardCell from './StandardCell';

export { default as CrmSyncCell } from './CrmSyncCell';

export const TableColumn: {
  [key: string]: (
    field: string,
    name: string,
    valueGetter?: (arg0: GridValueGetterParams) => any
  ) => GridColDef;
} = {
  address: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    sortable: false,
    groupable: false,
    valueGetter: ({ row }) => row,
    valueFormatter: ({ value }) =>
      [
        value.address_line_1,
        value.address_line_2,
        value.city,
        value.state,
        value.zip_code,
      ].join(', '),
    renderCell: (params) => <AddressCell {...params} />,
    flex: 1,
    minWidth: 150,
  }),
  affiliations: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    sortable: false,
    groupable: false,
    valueGetter: ({ row }) => row.hcp?.affiliations || row.affiliations || [],
    valueFormatter: ({ value }) =>
      value
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name }) => name)
        .join(', '),
    renderCell: (params) => <ListItemsCell {...params} field="affiliations" />,
    flex: 1,
    minWidth: 150,
  }),
  canonical: (field, name) => ({
    field,
    headerName: name,
    renderCell: (params) => <StandardCell {...params} field={field} />,
    valueGetter: ({ row }) => row?.is_canonical,
    align: 'center',
    headerAlign: 'center',
    flex: 0.1,
    minWidth: 100,
    sortable: true,
  }),
  city: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => {
      const provider = row.provider ? row.provider : row;
      return <Typography level="body-sm">{provider.city}</Typography>;
    },
    minWidth: 150,
    flex: 1,
  }),
  crm_links: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    groupable: false,
    sortable: false,
    renderCell: (params) => <CrmCell {...params} />,
    width: 155,
    minWidth: 150,
  }),
  date: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    renderCell: ({ row }) => (
      <Typography level="body-sm">
        {row[field] ? formatDate(new Date(row[field]), 'PPp') : ''}
      </Typography>
    ),
    minWidth: 150,
  }),
  dateAdded: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    renderCell: ({ row }) => (
      <Typography level="body-sm">
        {row[field] ? formatDate(new Date(row[field]), 'PP') : ''}
      </Typography>
    ),
    minWidth: 150,
    flex: 1,
  }),
  focus: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => (
      <Typography
        level="body-sm"
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {row?.focus || 'n/a'}
      </Typography>
    ),
    flex: 1,
    minWidth: 150,
  }),
  graduationYear: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => (
      <Typography level="body-sm">{row?.graduation_year || 'n/a'}</Typography>
    ),
    flex: 1,
    minWidth: 150,
  }),
  groups: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    sortable: false,
    groupable: false,
    valueGetter: ({ row }) => row.hcp?.groups || [],
    valueFormatter: ({ value }) =>
      value
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name }) => name)
        .join(', '),
    renderCell: (params) => <ListItemsCell {...params} field="groups" />,
    flex: 1,
    minWidth: 150,
  }),
  institution: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => (
      <Typography
        level="body-sm"
        noWrap
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {row?.institution || 'n/a'}
      </Typography>
    ),
    flex: 1,
    minWidth: 150,
  }),
  listName: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    flex: 1,
    valueGetter: ({ row }) => row.name,
    renderCell: ({ row }) => <ListNameCell row={row} />,
    maxWidth: 250,
  }),
  name: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: (params) => <NameCell {...params} />,
    flex: 1,
    minWidth: 150,
    width: 300,
  }),
  numItems: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    flex: 1,
    valueGetter: ({ row }) => row.num_items,
    minWidth: 150,
    maxWidth: 200,
  }),
  overlayColumn: (field, name) => ({
    field: 'overlay',
    headerName: '',
    width: 0,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    resizable: false,
    renderCell: (params) => <OverlayColumn {...params} />,
  }),
  owner: (field, name) => ({
    field,
    headerName: name,
    headerAlign: 'left',
    sortable: true,
    renderCell: (params) => <StandardCell {...params} field={field} />,
    valueGetter: ({ row }) => {
      const { owner } = row;
      return owner?.first_name && owner?.last_name
        ? `${owner?.first_name} ${owner?.last_name}`
        : owner?.username;
    },
    flex: 0.4,
  }),
  phone: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    sortable: false,
    groupable: false,
    renderCell: (params) => <PhoneCell {...params} />,
    flex: 1,
    minWidth: 150,
  }),
  providerName: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: (params) => <ProviderNameCell {...params} />,
    width: 250,
    minWidth: 150,
  }),
  searchVolume: (field, name) => ({
    field,
    headerName: name,
    groupable: false,
    sortable: true,
    headerClassName: 'MuiDataGrid-lightHeader',
    cellClassName: 'MuiDataGrid-lightCell',
    renderHeader: (params) => <HeaderVolume {...params} />,
    renderCell: (params) => <SearchVolumeCell {...params} searchId={field} />,
    type: 'string',
    resizable: false,
    sortingOrder: ['desc', 'asc', null],
  }),
  secondaryAddresses: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    minWidth: 150,
    sortable: false,
    groupable: false,
    renderCell: (params) => <SecondaryAddressCell {...params} />,
    flex: 1,
  }),
  specialty: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: (params) => <SpecialityCell {...params} />,
    valueGetter: ({ row }) => row?.specialty || row?.focus || 'n/a',
    minWidth: 150,
    flex: 1,
  }),
  state: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => {
      const provider = row.provider ? row.provider : row;
      return <Typography level="body-sm">{provider.state}</Typography>;
    },
    minWidth: 90,
    width: 90,
    flex: 1,
  }),
  streetAddress: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    minWidth: 150,
    sortable: false,
    groupable: false,
    valueGetter: ({ row }) => row,
    valueFormatter: ({ value }) =>
      !value.address_line_2
        ? value.address_line_1
        : [value.address_line_1, value.address_line_2].join(', '),
    renderCell: (params) => <StreetAddressCell {...params} />,
    flex: 1,
  }),
  string: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => {
      const value = row[field] || row.provider?.[field];
      return (
        <Typography
          level="body-sm"
          noWrap
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {value || ''}
        </Typography>
      );
    },
    flex: 1,
    minWidth: 150,
  }),
  systems: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    sortable: false,
    groupable: false,
    valueGetter: ({ row }) => row.systems || [],
    valueFormatter: ({ value }) =>
      value
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name }) => name)
        .join(', '),
    renderCell: (params) => <ListItemsCell {...params} field="systems" />,
    flex: 1,
    minWidth: 150,
  }),
  tags: (field, name) => ({
    field,
    headerName: name,
    sortable: false,
    groupable: false,
    renderHeader: (params) => <Header {...params} />,
    renderCell: (params) => <TagCell {...params} />,
    flex: 1,
    minWidth: 150,
  }),
  territoryName: (field, name) => ({
    field,
    headerName: name,
    renderCell: (params) => <StandardCell {...params} field={field} />,
    valueGetter: ({ row }) => row?.name,
    flex: 0.4,
    maxWidth: 250,
    sortable: true,
  }),
  totalClaims: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    sortable: true,
    renderCell: ({ row }) => <StringCell field={field} row={row} />,
    valueGetter: ({ row }) => row.total_claims,
    align: 'right',
    headerAlign: 'right',
    flex: 0.25,
    minWidth: 150,
  }),
  totalPatients: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    sortable: true,
    renderCell: ({ row }) => <StringCell field={field} row={row} />,
    valueGetter: ({ row }) => row.total_patients,
    align: 'right',
    headerAlign: 'right',
    flex: 0.25,
    minWidth: 150,
  }),
  trendVolume: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <TrendVolumeHeader {...params} />,
    groupable: false,
    sortable: false,
    headerClassName: 'MuiDataGrid-lightHeader',
    cellClassName: 'MuiDataGrid-lightCell',
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => <TrendVolumeCell {...params} field={field} />,
    type: 'string',
    resizable: false,
    sortingOrder: ['desc', 'asc', null],
  }),
  zip: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => {
      const value = row.zip_code || row.provider?.zip_code;
      return (
        <Typography
          level="body-sm"
          noWrap
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {value || ''}
        </Typography>
      );
    },
    sortable: false,
    minWidth: 90,
    width: 90,
    flex: 1,
  }),
};

export const TableSettings: Partial<DataGridPremiumProps> = {
  slots: {
    columnSortedAscendingIcon: () => <Icon icon={faChevronUp} size="sm" />,
    columnSortedDescendingIcon: () => <Icon icon={faChevronDown} size="sm" />,
  },
};

const TableCell = {
  Name: NameCell,
  ProviderName: ProviderNameCell,
  Tag: TagCell,
  Address: AddressCell,
  Phone: PhoneCell,
  Status: StatusCell,
  CrmLink: CrmLinkCell,
  Commands: CommandCell,
  VolumeHeader: VolumeHeader,
};

export { TableCell, OverlayColumn };
