export const COLOR_MAP = {
  white: '#ffffff',
  black: '#292b33',
  'gray-dark': '#3f4148',
  gray: '#7f8085',
  'gray-light': '#a9aaad',
  'blue-light': '#e8f8ff',
  'gray-lighter': '#bebfc1',
  'gray-lightest': '#e9e9e9',
  'disabled-gray': '#fbfbfb',

  'background-gray': '#f8f8f8',
  'form-border': '#ced4da',

  'blue-gray-dark': '#2c2e36',
  'blue-dark': '#021578',
  blue: '#021b9c',
  'light-blue': '#2391d9',
  turquoise: '#32d9c3',
  green: '#2ed9c3',
  'green-dark': '#0bbf00',

  red: '#ff4343',
  orange: '#f9b57c',
  purple: '#693cbd',
  pink: '#cc5599',
  yellow: '#23d77d',
};

export const THEME_COLORS = {
  primary: COLOR_MAP.blue,
  secondary: COLOR_MAP.purple,
  tertiary: COLOR_MAP.turquoise,
  info: COLOR_MAP['blue-gray-dark'],
  muted: COLOR_MAP['gray-dark'],
  success: COLOR_MAP['green-dark'],
  danger: COLOR_MAP.red,
};

export const SCREEN_SIZES = {
  xs: 0,
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

export const GRID_BREAKPOINTS = {
  xs: SCREEN_SIZES.xs,
  sm: SCREEN_SIZES.sm,
  md: SCREEN_SIZES.md,
  lg: SCREEN_SIZES.lg,
  xl: SCREEN_SIZES.xl,
  xxl: SCREEN_SIZES.xxl,
};

export const BORDER_STYLES = {
  'border-thin': 'thin solid rgb(244, 244, 244)',
};

export const NAV_HEIGHT = '58px';
export const HEIGHT_MINUS_NAV = `calc(100vh - ${NAV_HEIGHT})`;
export const SIDEBAR_WIDTH = '275px';

export const Z_INDEX = {
  mapLoadingOverlay: 3,
  mapMarkerPulse: -2,
  mapMarkerInfobox: 1,
  mapMarkerInfoboxContent: 2,
  searchResults: 1500,
  toastContainer: 10000,
};
