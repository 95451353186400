import { grey } from '@mui/material/colors';

export const currentTableStyle = {
  border: 'none',
  borderRadius: 0,
  '& .MuiDataGrid-row': {
    cursor: 'pointer',
  },
  '& .MuiDataGrid-main, & .MuiDataGrid-footerContainer': {
    backgroundColor: 'white',
  },
  '& .MuiDataGrid-toolbarContainer': {
    justifyContent: 'space-between',
    padding: '0',
  },
  '& .MuiDataGrid-row:hover, & .Mui-hovered': {
    backgroundColor: grey[100],
    '&.Mui-hovered': {
      backgroundColor: grey[200],
    },
  },
  '& .MuiDataGrid-lightHeader': {
    backgroundColor: '#E5F3FB',
  },
  '& .MuiDataGrid-lightCell': {
    backgroundColor: '#E5F3FB',
    borderLeft: `1px solid #E5F3FB`,
    borderRight: `1px solid #E5F3FB`,
  },
  '& .MuiDataGrid-columnHeaders': {
    minHeight: 'unset !important',
    '& .MuiDataGrid-columnSeparator': {
      color: '#E5F3FB',
    },
  },
  '& .MuiDataGrid-groupHeader, & .MuiDataGrid-columnHeader': {
    paddingTop: '0 !important',
  },
  '& .MuiDataGrid-groupHeader': {
    backgroundColor: '#E5F3FB',
  },
  '& .MuiDataGrid-columnHeadersInner>div:first-of-type, & .MuiDataGrid-groupHeader, & .MuiDataGrid-columnHeader--emptyGroup':
    {
      // display: 'none !important',
      height: '0 !important',
    },
  '& .MuiDataGrid-columnHeadersInner>div:only-child': {
    height: 'inherit !important',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    border: 'none !important',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
    /* title-sm */
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '142%' /* 19.88px */,
  },
  '& .MuiDataGrid-menuIcon *': {
    display: 'none !important',
  },
  '& .MuiDataGrid-pinnedColumnHeaders>div': {
    height: 'unset !important',
  },
  '& .MuiDataGrid-pinnedColumnHeaders, &  .MuiDataGrid-pinnedColumnHeaders--right':
    {
      backgroundColor: '#E5F3FB',
    },
  '& .MuiDataGrid-pinnedColumn, & .MuiDataGrid-pinnedColumns--right': {
    backgroundColor: '#E5F3FB',
  },
};
