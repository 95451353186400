import { Card, Typography } from '@mui/joy';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { hasContent } from 'src/utils';
import InfoUnavailable from './InfoUnavailable';
import DetailsDisplay from './ProfileDetails/DetailsDisplay';
import { useProfile } from 'src/context';
import { Box } from 'src/components/PulseUI';

const ProfileExecTeam = () => {
  const { details } = useProfile();
  const team = details?.center?.boards || [];

  return (
    <Card sx={{ padding: 3 }}>
      <Box
        className="flex flex-row gap-2 items-center"
        aria-label="Organization"
      >
        <Icon icon={faUserTie} color="#525252" className="h-4 w-4" />
        <Typography className="text-lg font-bold">Executive Team</Typography>
      </Box>
      <Box className="flex flex-col gap-2">
        {hasContent(team) ? (
          <DetailsDisplay items={team} allDetails={true} />
        ) : (
          <InfoUnavailable />
        )}
      </Box>
    </Card>
  );
};

export default ProfileExecTeam;
