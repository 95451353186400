import {
  DataGridPremium,
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  GridFilterModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import React, { useEffect, useMemo, useState } from 'react';
import DataBox from 'src/components/DataBox';
import { VOLUME_SELECT_OPTIONS } from 'src/components/VolumeSelectors/constants';
import {
  useLogging,
  useNotification,
  useProfile,
  useProspectSearch,
  useProspectSelection,
  useTable,
} from 'src/context';
import {
  useCurrentTable,
  useGetSavedSearches,
  useLocalStorage,
  useSyncRowHovered,
  useSyncTableScroll,
  useVolumeColumns,
} from 'src/hooks';
import {
  baseColumns,
  InnerContainer,
  NewTableToolbar,
  NoVolumeOverlay,
  PrimaryTableContainer,
  TableCheckbox,
  TableControls,
  TableFooter,
  TableSecondaryHeader,
  VolumeTableContainer,
} from '../components';
import { TableColumn, TableSettings } from 'src/components/Table';
import ReferralSelector from 'src/components/ReferralSelector';
import { VolumeSelectors } from 'src/components/VolumeSelectors';
import { TableLoadingIndicator } from 'src/components/LoadingIndicator';
import { getReferralExport } from 'src/api/profiles/referralsExportApi';
import { useRouter } from 'next/router';

interface ReferralTableProps {
  rows: any;
  loading: boolean;
  sort?: {
    field: string;
    order: string;
    sort_search: string;
    sort_value: string;
  };
  setSort: (sort: {
    field: string;
    order: string;
    sort_search: string;
    sort_value: string;
  }) => void;
  queryParams: any;
}

const referralColumns: GridColDef[] = [
  TableColumn.totalPatients('total_patients', 'All Shared Patients'),
  TableColumn.totalClaims('total_claims', 'All Shared Claims'),
];

const NewReferralTable = ({
  rows,
  loading,
  setSort,
  queryParams,
}: ReferralTableProps) => {
  const log = useLogging();
  const apiRef = useGridApiRef();
  const apiRef2 = useGridApiRef();
  const router = useRouter();
  const { id } = router.query;
  const { details } = useProfile();

  // Sync the scroll of the two tables
  useSyncTableScroll(apiRef, apiRef2);
  const { isRowHovered } = useSyncRowHovered(apiRef, apiRef2);

  const mainColumns = useMemo(() => {
    return [
      ...baseColumns.slice(0, 1),
      ...referralColumns,
      ...baseColumns.slice(2),
    ];
  }, []);

  const { setNotification } = useNotification();
  const { prospectSearch, prospectVolumeType } = useProspectSearch();
  const { setCurrentSearchIds } = useTable();
  const { currentTable, currentYears } = useCurrentTable('referrals');
  const { volumeColumns, optionColumns } = useVolumeColumns();
  const {
    state: [, setNewProspects],
    selectedRows: [rowsAreSelected],
  } = useProspectSelection();

  const [selectionModel, setSelectionModel] = useState<any[]>([]);
  const [isExporting, setIsExporting] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const { data: savedSearches = [] } = useGetSavedSearches();

  useEffect(() => {
    if (!prospectSearch?.id) return;
    // set the sort value to the current saved search if one is selected
    setSort({
      field: 'saved_search',
      order: 'desc',
      sort_search: `${prospectSearch?.id}`,
      sort_value: prospectVolumeType,
    });

    return () =>
      setSort({
        field: 'total_patients',
        order: 'desc',
        sort_search: '',
        sort_value: '',
      });
  }, []);

  // toggle state to clear selected rows
  useEffect(() => {
    setSelectionModel(() => []);
  }, [rowsAreSelected]);

  // update selected rows when DataGrid selection model changes
  useEffect(() => {
    if (!apiRef.current?.getSelectedRows) return;
    setNewProspects(Array.from(apiRef.current.getSelectedRows().values()));
    return () => setNewProspects([]);
  }, [apiRef, selectionModel, setNewProspects]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage(
    'newReferralTable',
    {
      name: true,
      specialty: true,
      total_patients: true,
      total_claims: true,
      provider_id: false,
      address: false,
      city: false,
      state: false,
      zip_code: false,
      phone: false,
      email: false,
    }
  );

  const [showVolume, setShowVolume] = useLocalStorage(
    'showVolumeReferrals',
    true
  );

  useEffect(() => {
    if (prospectSearch?.id) {
      setShowVolume(columnVisibilityModel[prospectSearch?.id]);
    }
  }, [columnVisibilityModel, prospectSearch?.id, setShowVolume]);

  const handlePageChange = (e, newPage) => {
    setPaginationModel((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (e) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(e.target.value, 10),
      page: 0,
    }));
  };

  const searchIds = useMemo(
    () =>
      Object.keys(columnVisibilityModel).filter(
        (k) =>
          columnVisibilityModel[k] &&
          savedSearches.map((s) => s.id).indexOf(Number(k)) > -1
      ),
    [columnVisibilityModel, savedSearches]
  );

  useEffect(() => {
    setCurrentSearchIds(searchIds);
  }, [searchIds]);

  // maintain column visibility state when currentTable trending changes.. this is to show the volume columns
  useEffect(() => {
    if (!currentTable) return;
    if (!volumeColumns?.length) return;
    if (!optionColumns?.length) return;
    // set options columns to visible if the table is trending
    setColumnVisibilityModel((columnVisibilityState) => ({
      ...columnVisibilityState,
      ...serializeColumnVisibility(columnVisibilityState),
    }));
  }, [currentTable, prospectSearch, volumeColumns]);

  const filteredData = useMemo(() => {
    if (!rows?.data) return [];

    const query = filterModel.quickFilterValues?.join(' ');
    if (!query) return rows.data;

    return rows.data.filter((row) =>
      Object.values(row)
        .map((value) => (value ? value.toString().toLowerCase() : ''))
        .join(' ')
        .includes(query ? query.toLowerCase() : '')
    );
  }, [filterModel, rows]);

  const enhancedRows = useMemo(() => {
    if (!filteredData || !prospectSearch) return [];

    return filteredData?.map((row) => {
      return row;
    });
  }, [filteredData, prospectSearch]);

  const serializeColumnVisibility = (currentVisibility) => {
    // Process volume columns
    const volumeVisibility = volumeColumns.reduce((acc, col) => {
      // Special handling for prospect search column
      if (col.field === prospectSearch?.id?.toString()) {
        // If currentVisibility[prospectSearch.id] is false but showVolume is true,
        // this might be the initial render
        acc[prospectSearch.id] =
          (showVolume && !!prospectSearch?.id) ||
          !!currentVisibility[prospectSearch.id];
        return acc;
      }

      // Standard volume column handling
      acc[col.field] = !!currentVisibility[col.field];
      return acc;
    }, {});

    // Process option (trending) columns
    const optionVisibility = optionColumns.reduce((acc, col) => {
      const [_, year] = col.field.split('_');
      const isValidYear = currentYears.includes(Number(year));
      const isInOptions = VOLUME_SELECT_OPTIONS?.some((o) => o.value === year);

      acc[col.field] =
        isInOptions &&
        isValidYear &&
        currentTable?.isTrending &&
        searchIds?.length > 0;
      return acc;
    }, {});

    // Combine all visibility settings
    return {
      ...volumeVisibility,
      ...optionVisibility,
    };
  };

  const handleSortChange = (model) => {
    let newField = 'total_patients';
    let sort_search = '';
    let sort_value = '';

    if (model?.length) {
      const { field } = model[0];
      newField =
        isNaN(Number(field)) && field !== 'volume' ? field : 'saved_search';

      if (newField === 'saved_search') {
        sort_search = field !== 'volume' ? field : `${prospectSearch?.id}`;
        sort_value = prospectVolumeType;
      }
    }

    setSort({
      field: newField,
      order: model?.length ? model[0].sort : 'desc',
      sort_search,
      sort_value,
    });
  };

  const handleExportList = (type: string) => {
    const visibleColumns = apiRef.current
      .getVisibleColumns()
      .map((col) => col.field)
      .concat(['volume', 'patients', 'claims'])
      .filter((col) => col.indexOf('__') === -1);

    setIsExporting(true);

    const referralDirection =
      queryParams.direction === 'outbound' ? 'ReferredBy' : 'ReferredTo';
    const fileName = `${referralDirection}_${details?.name}`;

    try {
      const res = getReferralExport({
        searchParams: {
          ...queryParams,

          providerId: id,
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
        },
        fileType: type,
        fileName,
        columns: visibleColumns,
      });

      if (!res) return;
      setNotification({
        title: 'Success',
        message:
          'Export initiated - You will receive an email when it is ready for download',
        type: 'success',
      });
      log.event('Export', {
        type: 'referrals',
        source: `Referral Table - ${queryParams.direction}`,
        columns: visibleColumns,
        count: rows?.data?.length,
      });
    } catch (err: any) {
      log.exception(`Error exporting Referral table: ${err}`);

      setNotification({
        title: 'Error',
        message: err?.error_message || 'Error exporting, please try again.',
        type: 'error',
      });
    } finally {
      setIsExporting(false);
    }
  };

  const handleFilterChange = (model) => {
    setFilterModel(model);
    if (paginationModel.page !== 0) {
      setPaginationModel((prev) => ({ ...prev, page: 0 }));
    }
  };

  return (
    <DataBox sx={{ height: '50rem', minWidth: 0 }} isCommercial>
      <PrimaryTableContainer>
        <InnerContainer>
          <DataGridPremium
            disableVirtualization
            apiRef={apiRef}
            rows={enhancedRows || []}
            rowCount={rows?.data?.length || 0}
            columns={mainColumns}
            scrollbarSize={0}
            hideFooter={true}
            checkboxSelection
            getRowClassName={isRowHovered}
            disableColumnMenu
            disableRowSelectionOnClick
            sx={{
              // remove border from table and only show overflow on x axis
              border: 'none',
              borderRadius: 0,
              '& .MuiDataGrid-main, & .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
              },
              '& .MuiDataGrid-virtualScroller': {
                overflowY: 'scroll',
                overflowX: 'auto',
                marginRight: '-1rem', // hides the Y axis scrollbar
              },
              '& .MuiDataGrid-columnHeadersInner>div:first-of-type, & .MuiDataGrid-groupHeader, & .MuiDataGrid-columnHeader--emptyGroup':
                {
                  // display: 'none !important',
                  height: 'auto !important',
                },
              '& .MuiDataGrid-row:hover, & .Mui-hovered': {
                backgroundColor: 'rgba(204, 204, 194, 0.13)',
                '&.Mui-hovered': {
                  backgroundColor: 'rgba(204, 204, 194, 0.13)',
                  transition: 'background-color 0.3s ease-in-out',
                },
              },
            }}
            initialState={{
              columns: { columnVisibilityModel },
              pagination: { paginationModel: { page: 0, pageSize: 50 } },
            }}
            slots={{
              toolbar: NewTableToolbar,
              baseCheckbox: TableCheckbox,
              columnMenu: CustomColumnMenu,
              ...TableSettings.slots,
            }}
            slotProps={{
              columnsPanel: {
                disableHideAllButton: true,
                disableShowAllButton: true,
              },
              toolbar: {
                variant: 'primary',
                bottomHeight: '100px',
                headerSlots: {
                  slot1: <ReferralSelector />,
                  slot2: (
                    <TableControls
                      onExport={handleExportList}
                      isExporting={isExporting}
                      columns={mainColumns}
                      columnVisibilityModel={columnVisibilityModel}
                      setColumnVisibilityModel={setColumnVisibilityModel}
                      showAddToListButton
                      table="referrals"
                    />
                  ),
                },
              },
            }}
            // Pagination
            pagination
            paginationModel={paginationModel}
            // Sorting
            sortingMode="server"
            onSortModelChange={handleSortChange}
            // Filtering
            filterMode="server"
            onFilterModelChange={handleFilterChange}
            filterModel={filterModel}
            // Column visibility
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            // Rows
            getRowHeight={({ model, densityFactor }) => densityFactor * 80}
            onRowSelectionModelChange={setSelectionModel}
            rowSelectionModel={selectionModel}
            getRowId={(row) => row.provider_id}
          />
          <VolumeTableContainer width="500px">
            <DataGridPremium
              disableVirtualization
              apiRef={apiRef2}
              rows={searchIds?.length > 0 ? enhancedRows : []}
              rowCount={rows?.data?.length || 0}
              columns={[...volumeColumns, ...optionColumns]}
              hideFooter={true}
              getRowClassName={isRowHovered}
              disableColumnMenu
              disableRowSelectionOnClick
              sx={{
                // remove border from table
                border: 'none',
                borderLeft: '#005b3860 1px solid',
                borderRadius: 0,
                background: 'var(--secondary-outlined-ActiveBg, #D6F6F1)',
                '& .muiDataGrid-withBorderColor': {
                  border: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {
                  overflowY: 'auto',
                  overflowX: 'hidden',
                },
                '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                  display: 'flex',
                  alignItems: 'center',
                  flex: '1 0 0',
                  alignSelf: 'stretch',
                },
                '& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer':
                  {
                    borderBottom: '#005b3860 1px solid',
                  },
                // This fixes that little notch on the right created by the scrollbar
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor:
                    'var(--secondary-outlined-ActiveBg, #D6F6F1)',
                  borderBottom: '#005b3860 1px solid',
                  '& .MuiDataGrid-columnSeparator': {
                    color: 'none',
                  },
                },
                '& .MuiDataGrid-overlayWrapperInner': {
                  backgroundColor:
                    'var(--secondary-outlined-ActiveBg, #D6F6F1)',
                },
                '& .MuiDataGrid-columnHeaderGroupCell': {
                  borderBottom: 'none', // Remove group header cell borders if present
                },
                '& .MuiDataGrid-row:hover, & .Mui-hovered': {
                  backgroundColor: 'rgba(204, 204, 194, 0.13)',
                  '&.Mui-hovered': {
                    backgroundColor: 'rgba(204, 204, 194, 0.13)',
                  },
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: '#005b3860 1px solid',
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none !important',
                },
              }}
              slots={{
                toolbar: NewTableToolbar,
                noRowsOverlay: NoVolumeOverlay,
                noResultsOverlay: NoVolumeOverlay,
                ...TableSettings.slots,
              }}
              slotProps={{
                columnsPanel: {
                  disableHideAllButton: true,
                  disableShowAllButton: true,
                },
                toolbar: {
                  bottomHeight: '100px',
                  headerSlots: {
                    slot1: (
                      <TableSecondaryHeader
                        columns={volumeColumns}
                        columnVisibilityModel={columnVisibilityModel}
                        setColumnVisibilityModel={setColumnVisibilityModel}
                        showVolumeMenu
                        table="referrals"
                      />
                    ),
                    slot2: (
                      <VolumeSelectors
                        showTreatingReferring
                        table="referrals"
                        enabledSearchIds={searchIds}
                      />
                    ),
                  },
                },
              }}
              pagination
              paginationModel={paginationModel}
              // Sorting
              sortingMode="server"
              onSortModelChange={handleSortChange}
              // Column visibility
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={setColumnVisibilityModel}
              // Rows
              getRowHeight={({ model, densityFactor }) => densityFactor * 80}
              getRowId={(row) => row.provider_id}
            />
          </VolumeTableContainer>
          {loading && <TableLoadingIndicator top="255px" />}
        </InnerContainer>
        <TableFooter
          page={paginationModel.page}
          pageSize={paginationModel.pageSize}
          rowCount={rows?.data?.length || 0}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </PrimaryTableContainer>
    </DataBox>
  );
};

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuFilterItem: null,
        columnMenuAggregationItem: null,
        columnMenuGroupingItem: null,
      }}
    />
  );
};

export default NewReferralTable;
