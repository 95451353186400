import { Box } from '@mui/material';
import { Typography } from '@mui/joy';

const AddressCell = ({ row }) => {
  // Get address data either from row directly or from row.provider
  const addressData = row.provider || row;

  if (
    !addressData.address &&
    !addressData.address_line_1 &&
    !addressData.address_line_2 &&
    !addressData.city &&
    !addressData.state &&
    !addressData.zip_code
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {addressData.address ? (
        // If single address string exists, split it into lines
        addressData.address.split('\n').reduce((acc, line, index, array) => {
          if (array.length === 3) {
            // For 3-line addresses, combine lines 1 and 2
            if (index === 0) {
              return [
                ...acc,
                <Typography
                  key={index}
                  level="body-sm"
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {line} {array[1]}
                </Typography>,
              ];
            } else if (index === 2) {
              return [
                ...acc,
                <Typography
                  key={index}
                  level="body-sm"
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {line}
                </Typography>,
              ];
            }
            return acc;
          } else {
            // For other cases, keep original behavior
            return [
              ...acc,
              <Typography
                key={index}
                level="body-sm"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {line}
              </Typography>,
            ];
          }
        }, [])
      ) : (
        // Otherwise use structured address fields
        <>
          {addressData.address_line_1 && (
            <Typography
              level="body-sm"
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {addressData.address_line_1}
              {addressData.address_line_2 && ` ${addressData.address_line_2}`}
            </Typography>
          )}
          <Typography
            level="body-sm"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {addressData.city}, {addressData.state}, {addressData.zip_code}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default AddressCell;
