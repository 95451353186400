import React, { useState, useCallback } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  fas,
  faPencil,
  faCheck,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { ActivityIcon } from 'src/components/Activity';
import { IconPicker } from 'src/components';
import { useDebounce } from 'use-debounce';
import { updateActivityType, deleteActivityType } from 'src/api';
import { useGetActivityTypes } from 'src/hooks';
import { Tooltip, CircularProgress } from 'src/components';
import { useNotification } from 'src/context';

library.add(fas);

const ActivityTypeItem = ({ item }: { item: MedScout.ActivityType }) => {
  const { setNotification } = useNotification();
  const [isEditing, setIsEditing] = useState(false);
  const [editedItem, setEditedItem] = useState<MedScout.ActivityType | null>(
    item
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [debouncedItem] = useDebounce(editedItem, 1000);
  const hasActivities = item.activity_count > 0;
  const { mutate } = useGetActivityTypes();
  const saveChanges = useCallback(
    async (data: Partial<MedScout.ActivityType>) => {
      try {
        setIsLoading(true);
        const response = await updateActivityType({
          activityTypeId: item.id,
          data,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        mutate();
      }
    },
    [editedItem]
  );

  // Auto-save when debounced item changes
  React.useEffect(() => {
    if (
      debouncedItem &&
      (debouncedItem.color !== item.color || debouncedItem.icon !== item.icon)
    ) {
      saveChanges({
        color: debouncedItem.color,
        icon: debouncedItem.icon,
      });
    }
  }, [debouncedItem, item, saveChanges]);

  const handleIconSelect = (icon: any) => {
    setEditedItem((prev) =>
      prev ? { ...prev, icon: icon?.icon?.iconName } : null
    );
  };

  const handleColorSelect = (color: string) => {
    setEditedItem((prev) => (prev ? { ...prev, color } : null));
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedItem((prev) => (prev ? { ...prev, label: e.target.value } : null));
  };

  const handleUpdateItem = async () => {
    if (!editedItem?.label?.trim()) return;
    try {
      await saveChanges({
        label: editedItem.label,
        icon: editedItem.icon,
        color: editedItem.color,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsEditing(false);
    }
  };

  const handleDeleteItem = async () => {
    try {
      setIsDeleting(true);
      if (hasActivities) {
        // disable the item
      } else {
        // delete the item
        await deleteActivityType({ activityTypeId: item.id });
      }
    } catch (err) {
      console.error(err);
      setNotification({
        title: 'Error',
        message: err?.error_message || 'Failed to delete activity type',
        type: 'error',
      });
    } finally {
      setIsDeleting(false);
      mutate();
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedItem(item);
  };

  return (
    <div className="relative w-full h-full">
      <div
        className={`flex items-center justify-between w-full py-4 ${
          item.deactivated ? 'opacity-60' : ''
        }`}
      >
        <div className="flex items-center gap-2">
          <IconPicker
            onIconSelect={handleIconSelect}
            onColorSelect={handleColorSelect}
            slots={{
              anchor: (
                <div
                  className={
                    item.deactivated
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:opacity-60 transition-all duration-300 cursor-pointer'
                  }
                >
                  <ActivityIcon item={editedItem} />
                </div>
              ),
            }}
            placement="bottom-start"
          />

          {isEditing ? (
            <div className="flex items-center gap-2 flex-1">
              <div
                className="flex items-center gap-2 
            w-full border border-neutral-200 rounded-lg px-2 py-1
           focus-within:border-neutral-300"
              >
                <input
                  type="text"
                  value={editedItem.label}
                  onChange={handleLabelChange}
                  onFocus={(e) => e.target.select()}
                  onBlur={handleUpdateItem}
                  className="border-none outline-none"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUpdateItem();
                    }
                    if (e.key === 'Escape') {
                      handleCancel();
                    }
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-2 items-center">
              <label
                className={
                  item.deactivated ? 'line-through text-neutral-500' : ''
                }
              >
                {editedItem.label}
              </label>
              <Icon
                size="sm"
                icon={faPencil}
                className="text-neutral-300 hover:text-neutral-400 cursor-pointer"
                onClick={() => setIsEditing(!isEditing)}
              />
            </div>
          )}
          {isEditing && (
            <div className="flex items-center gap-2">
              <button
                className="w-6 h-6 bg-sky-700 hover:bg-sky-800 rounded-full disabled:opacity-50 transition-all duration-300"
                onClick={handleUpdateItem}
                disabled={isLoading || !editedItem.label?.trim()}
              >
                <Icon size="sm" icon={faCheck} className="text-white" />
              </button>
              <button
                className="w-6 h-6 bg-red-200 hover:bg-red-300 rounded-full transition-all duration-300"
                onClick={handleCancel}
              >
                <Icon size="sm" icon={faTimes} className="text-red-600" />
              </button>
            </div>
          )}
        </div>
        <div className="flex items-center gap-4">
          <div className="relative flex items-center">
            {hasActivities && (
              <sup className="text-neutral-500 text-xs absolute -top-0.5 -inset-2">
                *
              </sup>
            )}
            <label
              className={`text-xs text-neutral-500 ${
                item.deactivated ? 'line-through' : ''
              }`}
            >
              {item.activity_count} activities
            </label>
          </div>
          <div
            className={`flex gap-2 items-center justify-end ${
              item.deactivated ? 'pointer-events-none opacity-50' : ''
            }`}
          >
            <Tooltip
              content={
                !hasActivities
                  ? 'Delete activity type'
                  : item.deactivated
                  ? 'This activity type is deactivated because it has associated activities'
                  : 'Deactivate activity type (has associated activities)'
              }
            >
              <button
                className="w-8 h-8 bg-neutral-100 hover:bg-neutral-200 rounded-full transition-all duration-300"
                onClick={handleDeleteItem}
              >
                {!hasActivities ? (
                  <Icon
                    size="sm"
                    icon={faTrashAlt}
                    className="text-neutral-500 hover:text-red-700 cursor-pointer transition-all duration-300"
                  />
                ) : (
                  <Icon
                    size="sm"
                    icon={item.deactivated ? faTimes : faCheck}
                    className={`cursor-pointer transition-all duration-300 ${
                      item.deactivated
                        ? 'text-neutral-400 hover:text-neutral-500'
                        : 'text-green-600 hover:text-green-700'
                    }`}
                  />
                )}
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      {isDeleting && (
        <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-neutral-100/50">
          <CircularProgress size="sm" color="neutral" />
        </div>
      )}
    </div>
  );
};

export default ActivityTypeItem;
