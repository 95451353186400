import { Box, Typography, Select, Option } from '@mui/joy';
import React, { useEffect, useState } from 'react';

interface TypeSelectProps {
  selectedType: string;
  setSelectedType: (type: string) => void;
  id?: string;
}

const TypeSelect = ({
  selectedType,
  setSelectedType,
  id = 'filter-providers-type-select',
}: TypeSelectProps) => {
  const [localType, setLocalType] = useState(selectedType);

  // Sync local input with context state after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      if (localType !== selectedType) {
        setSelectedType(localType);
      }
    }, 300); // Adjust delay as needed

    return () => clearTimeout(handler); // Cleanup on unmount or change
  }, [localType, selectedType, setSelectedType]);

  return (
    <Box
      id={id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '0.5rem',
        flex: 0.25,
      }}
    >
      <Typography
        level="body-sm"
        sx={{
          fontSize: '0.875rem',
          fontWeight: 500,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        Type
      </Typography>
      <Select
        id={`select-for-${id}`}
        value={localType}
        sx={{
          width: '100%',
          minWidth: 110,
          borderRadius: '0.5rem',
        }}
        aria-label="Volume Type"
        onChange={(e, newValue) => setLocalType(newValue)}
      >
        <Option
          id={`select-option-for-${id}-claims`}
          value="claims"
          aria-label="claims"
        >
          Claims
        </Option>
        <Option
          id={`select-option-for-${id}-patients`}
          value="patients"
          aria-label="patients"
        >
          Patients
        </Option>
      </Select>
    </Box>
  );
};

export default TypeSelect;
