import React from 'react';
import { useAuth } from 'src/context';
import { TabSet, Tab, TabPanel } from 'src/components';
import {
  DiscoveryPreferences,
  UserPreferences,
  ActivitiesTypeAdmin,
} from './components';

const NewPreferences = () => {
  const { user } = useAuth();
  const isAdmin = user?.permissions?.is_manager || user?.is_superuser;
  const tabOptions = [
    isAdmin
      ? { label: 'Activities', component: <ActivitiesTypeAdmin /> }
      : null,
    isAdmin ? { label: 'Company', component: <DiscoveryPreferences /> } : null,
    { label: 'User', component: <UserPreferences /> },
  ].filter(Boolean);

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-6 gap-2">
        <div className="col-span-2 sm:col-span-1">
          <TabSet id="preferences-tabs" direction="vertical">
            {tabOptions.map((tab, index) => (
              <Tab key={tab.label} tabSetId="preferences-tabs" index={index}>
                {tab.label}
              </Tab>
            ))}
          </TabSet>
        </div>
        <div className="col-span-4 sm:col-span-5">
          {tabOptions.map((tab, index) => (
            <TabPanel key={tab.label} tabSetId="preferences-tabs" index={index}>
              <div className="px-4">{tab.component}</div>
            </TabPanel>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewPreferences;
