import axiosInstance from './axiosInstance';
import storage from 'local-storage-fallback';
import { AUTH } from 'src/constants';
import { AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/nextjs';

interface RequesProps extends AxiosRequestConfig {
  rawResponse?: boolean;
}

export const request = async ({
  url,
  data = undefined,
  method = 'GET',
  headers = {},
  timeout = 120_000,
  cancelToken = null,
  responseType = 'json',
  rawResponse = false,
}: RequesProps) => {
  let accessToken;
  let refreshToken;

  try {
    accessToken = JSON.parse(storage.getItem(AUTH.ACCESS_TOKEN));
    refreshToken = JSON.parse(storage.getItem(AUTH.REFRESH_TOKEN));
  } catch (err) {
    // if the token is not a valid json, it's legacy
    accessToken = storage.getItem(AUTH.ACCESS_TOKEN);
    refreshToken = storage.getItem(AUTH.REFRESH_TOKEN);
  }

  const options = {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      'Refresh-Token': refreshToken,
      ...headers,
    },
    timeout,
    method,
    data,
    url,
    cancelToken,
    responseType,
  };

  try {
    const response = await axiosInstance.request(options);

    const headers = response?.headers || {};
    const newAccessToken = headers?.['x-access-token'];
    const newRefreshToken = headers?.['x-refresh-token'];

    // if these tokens are present, its a new refresh and access token to be saved
    if (newAccessToken && newRefreshToken) {
      storage.setItem(AUTH.ACCESS_TOKEN, JSON.stringify(newAccessToken));
      storage.setItem(AUTH.REFRESH_TOKEN, JSON.stringify(newRefreshToken));
    }

    return rawResponse || response?.status === 205
      ? response
      : (response && response?.data && response?.data?.result) ||
          response?.data;
  } catch (err) {
    Sentry.captureException(err);
    throw err.response?.data || err;
  }
};

export const instance = axiosInstance;
