import React, { useEffect, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { Autocomplete, Typography, Grid } from '@mui/joy';
import { CircularProgress } from 'src/components';
import { useAuth, useDashboard } from 'src/context';
import { useGetAllCompanies, useCompanyUsers } from 'src/hooks';
import { useDebounce } from 'use-debounce';

const CompanyUserSelector = () => {
  const { user, isSuperUser } = useAuth();
  const { control, setValue } = useFormContext();
  const { userQueryParams, setUserQueryParams } = useDashboard();
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [debouncedSearch] = useDebounce(search, 100);

  const companyId = isSuperUser ? selectedCompany?.value : user?.company?.id;

  const { data: companies, isLoading: isCompanyLoading } = useGetAllCompanies();
  const { data: companyUsers, isLoading: isUsersLoading } =
    useCompanyUsers(companyId);

  useEffect(() => {
    if (!isSuperUser) return;
    setUserQueryParams({ ...userQueryParams, include_support_user: 1 });
    return () => {
      setUserQueryParams({ ...userQueryParams, include_support_user: 0 });
    };
  }, []);

  useEffect(() => {
    if (!isSuperUser) return;
    setUserQueryParams({ ...userQueryParams, q: debouncedSearch });
  }, [debouncedSearch]);

  // convert companies and companyUsers to value and label
  const companyIdAndValue = useMemo(() => {
    if (!companies || companies.length === 0) return [];

    return companies?.map((company) => ({
      value: company?.id,
      label: company?.name,
    }));
  }, [companies]);

  const userIdAndValue = useMemo(() => {
    return companyUsers?.results?.map((user) => ({
      value: user?.id,
      label: `${user?.first_name} ${user?.last_name}`,
    }));
  }, [companyUsers]);

  const handleCompanyChange = (event, newValue) => {
    setSelectedCompany(newValue);
  };

  const handleUserChange = (event, newValue) => {
    setValue('user', newValue?.value); // we don't need the full object
  };

  const handleIsOptionEqualToValue = (option, value) =>
    option.value === value.value;

  return (
    <Grid container spacing={1} sx={{ mb: 1 }}>
      {isSuperUser && (
        <>
          <Grid xs={6}>
            <Typography level="body-sm" sx={{ fontWeight: 600, pb: '0.25rem' }}>
              Company
            </Typography>
            <Autocomplete
              freeSolo
              disableClearable
              loading={isCompanyLoading}
              placeholder="Select a company"
              value={selectedCompany || null}
              onChange={handleCompanyChange}
              options={companyIdAndValue || []}
              isOptionEqualToValue={handleIsOptionEqualToValue}
              endDecorator={
                isCompanyLoading ? (
                  <CircularProgress size="sm" color="neutral" />
                ) : (
                  <Icon icon={faChevronDown} size="sm" />
                )
              }
            />
          </Grid>
          <Grid xs={6}>
            <Typography level="body-sm" sx={{ fontWeight: 600, pb: '0.25rem' }}>
              User
            </Typography>
            <Controller
              name="user"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  freeSolo
                  loading={isUsersLoading}
                  placeholder="Select a user"
                  disabled={!selectedCompany}
                  value={
                    field.value
                      ? userIdAndValue?.find((u) => u.value === field.value) ||
                        null
                      : null
                  }
                  onChange={handleUserChange}
                  onInputChange={(event, newValue) => {
                    setSearch(newValue);
                  }}
                  options={userIdAndValue}
                  isOptionEqualToValue={handleIsOptionEqualToValue}
                  endDecorator={
                    isUsersLoading ? (
                      <CircularProgress size="sm" color="neutral" />
                    ) : !search.length ? (
                      <Icon icon={faChevronDown} size="sm" />
                    ) : null
                  }
                />
              )}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CompanyUserSelector;
